import React from 'react';
import moment from 'moment';
import {
    DeleteOutlined,
    EditOutlined,
    ArrowUpOutlined,
    ArrowDownOutlined
} from '@ant-design/icons';
import {
    List,
    Button,
    Space,
    Tabs,
    Form,
    Input,
    DatePicker,
    message,
    Affix,
    Divider,
    Radio,
    Col,
    Row,
    Modal,
    InputNumber,
    Tag,
    Table
} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import {
    createInfo,
    getInfo,
    updateInfo,
    sortProducts,
    getTabs,
    deleteTab,
    editTabs,
    getTabProducts,
    batchAddProduct,
    addOperation,
    updateOperation,
    deleteTouchBarContent,
    deleteFloor,
    exportProduct
} from '../../api/PromotionApi';
import LoadingLayer from '../common/LoadingLayer';
import ProductCategoryMultiClassSelector from '../selector/ProductCategoryMultiClassSelector';
import MarketingProductItem from './ProductItem';
import UploadImage from '../upload/Image';
import UploadFileSpu from '../upload/FileSpu';
import ArrayUtil from '../../util/ArrayUtil';
import '../../css/marketing/editMarketing.scss';

const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

class EditMarketing extends React.Component {

    state = {
        type: this.props.type ? this.props.type : 1,
        id: this.props.value,
        title: '',
        startTime: '',
        endTime: '',
        showTime: [],
        loading: false,
        tip: '',
        boomList: {
            allIds: [],
            selectIds: [],
            left: [],
            right: []
        },
        normalList: {
            allIds: [],
            selectIds: [],
            left: [],
            right: []
        },
        selectProductIds: [],
        selectSpuCodes: [],
        tabs: [],
        tabsEdit: [],
        tabsVis: false,
        tabSelectId: 0,
        radioKey: 0,
        tabKey: 'boom',
        copyModalVis: false,
        copyModalContent: '',
        operationId: 0,
        operationModalVis: false,
        operationParams: {
            image: '',
            jumpUrl: '',
            type: 1,
            sort: 0
        },
        floorList: [],
        normalListMap: {},
        floorId: 0,
        floorModalVis: false,
        floorParams: {
            floorLabels: '',
            floorLabelsTitle: '推荐理由',
            sort: 0,
            styleEnable: 0,
            title: '',
            productIds: [],
            productList: []
        },
        uploadErrorReasonFile: ''
    };

    componentDidMount = async _ => {
        if (this.state.id) {
            await this.getInfo();
            await this.getTabs();
        }
    }

    getInfo = async _ => {
        this.setState({
            loading: true,
            tip: '数据加载中'
        });
        let response = await getInfo(this.state.id);
        this.setState({
            loading: false
        });
        let { code, data } = response.data;
        if (code === 200) {
            let startTime = data.startTime ? moment(data.startTime).format() : null;
            let endTime = data.endTime ? moment(data.endTime).format() : null;
            this.setState({
                title: data.title,
                startTime: startTime,
                endTime: endTime,
                showTime: [startTime ? moment(data.startTime) : null, endTime ? moment(data.endTime) : null]
            });
        }
    }

    getTabs = async _ => {
        this.setState({
            loading: true,
            tip: '数据加载中'
        });
        let response = await getTabs(this.state.id);
        this.setState({
            loading: false
        });
        let { code, data } = response.data;
        if (code === 200) {
            this.setState({
                tabs: data,
                tabsEdit: JSON.parse(JSON.stringify(data))
            });

            this.onTabPaneChange('boom');
        }
    }

    deleteTab = async tabId => {
        this.setState({
            loading: true,
            tip: 'tab删除中'
        });
        let response = await deleteTab(tabId);
        this.setState({
            loading: false
        });
        let { code, data } = response.data;
        if (code === 200) {
            this.onTabPaneChange('boom');
            return data;
        }
        return false;
    }

    editTabs = async _ => {
        this.setState({
            loading: true,
            tip: 'tab变更中'
        });
        let tabs = this.state.tabsEdit;
        let index = tabs.length;
        tabs.map(item => {
            item.score = index--;
        });
        let response = await editTabs(this.state.id, tabs);
        this.setState({
            loading: false
        });
        let { code } = response.data;
        if (code === 200) {
            await this.getTabs();
            return true;
        }
        return false;
    }

    getTabProducts = async _ => {
        this.setState({
            loading: true,
            tip: '数据加载中'
        });
        let response = await getTabProducts(this.state.id, this.state.tabSelectId);
        this.setState({
            loading: false
        });
        let { code, data } = response.data;
        if (code === 200) {
            let boomList = data.boomList ? ArrayUtil.partition(data.boomList, Math.ceil(data.boomList.length / 2)) : [];
            let normalList = (data.normalList && !data.floorList) ? ArrayUtil.partition(data.normalList, Math.ceil(data.normalList.length / 2)) : [];

            let boomListPart = {
                selectIds: [],
                left: (boomList && boomList[0]) ? boomList[0] : [],
                right: (boomList && boomList[1]) ? boomList[1] : []
            };
            let normalListPart = {
                selectIds: [],
                left: (normalList && normalList[0]) ? normalList[0] : [],
                right: (normalList && normalList[1]) ? normalList[1] : []
            };

            data.boomList && data.boomList.forEach(item => {
                if (item.id) {
                    boomListPart.selectIds.push(item.id.toString());
                }
            });

            !data.floorList && data.normalList && data.normalList.forEach(item => {
                if (item.id) {
                    normalListPart.selectIds.push(item.id.toString());
                }
            });

            this.setState({
                boomList: boomListPart,
                normalList: normalListPart,
                floorList: data.floorList,
                normalListMap: data.normalListMap
            });

            this.onTabPaneChange(this.state.tabKey);
        }
    }

    onTabSelect = async e => {
        this.setState({
            tabSelectId: parseInt(e.target.value)
        }, async () => await this.getTabProducts());
    }

    onTabPaneChange = tabKey => {
        if (tabKey === 'boom') {
            this.setState({
                selectProductIds: this.state.boomList.selectIds,
                tabKey: tabKey
            });
        } else if (tabKey === 'normal') {
            this.setState({
                selectProductIds: this.state.normalList.selectIds,
                tabKey: tabKey
            });
        }
        
    }

    formHandler = {
        onTitleChange: e => {
            this.setState({
                title: e.target.value
            });
        },
        onShowTimeChange: (dates, dateStrings) => {
            this.setState({
                showTime: dates,
                startTime: dates[0].format(),
                endTime: dates[1].format()
            });
        },
        onSubmit: async _ => {
            this.setState({
                loading: true,
                tip: '数据提交中'
            });
            if (this.state.id) {
                let response = await updateInfo(this.state.id, this.state);
                this.setState({
                    loading: false
                });
                let { code } = response.data;
                if (code === 200) {
                    message.success('营销活动更新成功');
                }
            } else {
                let response = await createInfo(this.state);
                this.setState({
                    loading: false
                });
                let { code } = response.data;
                if (code === 200) {
                    message.success('营销活动创建成功');
                    if (this.props.onSuccess) {
                        this.props.onSuccess();
                    }
                }
            }
        },
        onSortChange: (type, pos, index, number) => {
            if (type === 'boom') {
                let list = this.state.boomList;
                if (pos === 'left') {
                    list.left[index].sort = number;
                    this.setState({
                        boomList: list
                    });
                } else {
                    list.right[index].sort = number;
                    this.setState({
                        boomList: list
                    });
                }
            } else {
                let list = this.state.normalList;
                if (pos === 'left') {
                    list.left[index].sort = number;
                    this.setState({
                        normalList: list
                    });
                } else {
                    list.right[index].sort = number;
                    this.setState({
                        normalList: list
                    });
                }
            }
        },
        onProductSelect: productIds => {
            this.setState({
                selectProductIds: productIds
            });
        },
        onAddProduct: async _ => {
            this.setState({
                loading: true,
                tip: '数据提交中'
            });

            let addProductIds = this.state.selectProductIds;
            let selectProductIds = [];
            if (this.state.tabKey === 'boom') {
                selectProductIds = this.state.boomList.selectIds;
            } else {
                selectProductIds = this.state.normalList.selectIds;
            }

            let finalAddProductIds = []
            addProductIds.forEach(id => {
                if (!selectProductIds.includes(id)) {
                    finalAddProductIds.push(id)
                }
            })

            let data = {
                promotionId: this.state.id,
                tabId: this.state.tabSelectId,
                productIds: finalAddProductIds,
                type: this.state.tabKey === 'boom' ? 1 : 0
            };
            let response = await batchAddProduct(data);
            this.setState({
                loading: false
            });
            let { code } = response.data;
            if (code === 200) {
                message.success('商品添加成功');
                await this.getTabProducts();
            }
        },
        onDeleteProduct: async (promotionProductId, type) => {
            if (window.confirm('确认删除吗？')) {
                this.setState({
                    loading: true,
                    tip: '数据提交中'
                });
                let response = await deleteTouchBarContent(promotionProductId);
                this.setState({
                    loading: false
                });
                let { code } = response.data;
                if (code === 200) {
                    message.success('商品删除成功');
                    await this.getTabProducts();
                }
            }
        },
        sortProducts: async _ => {
            this.setState({
                loading: true,
                tip: '数据提交中'
            });
            let data = [];
            if (this.state.tabKey === 'boom') {
                let list = this.state.boomList.left;
                list = list.concat(this.state.boomList.right);
                list.forEach(item => {
                    if (item.id) {
                        data.push({
                            productId: item.id,
                            type: 1,
                            sort: item.sort
                        });
                    }
                });
            } else {
                let list = this.state.normalList.left;
                list = list.concat(this.state.normalList.right);
                list.forEach(item => {
                    if (item.id) {
                        data.push({
                            productId: item.id,
                            type: 0,
                            sort: item.sort
                        });
                    }
                });
            }

            let response = await sortProducts(this.state.id, this.state.tabSelectId, data);
            this.setState({
                loading: false
            });
            let { code } = response.data;
            if (code === 200) {
                message.success('商品排序成功');
                await this.getTabProducts();
            }
        },
        onTabAdd: _ => {
            let tabsEdit = this.state.tabsEdit;
            tabsEdit.push({
                id: 0,
                score: 0,
                title: ''
            });
            this.setState({
                tabsEdit: tabsEdit
            });
        },
        onTabModalEdit: async _ => {
            await this.getTabs();
            this.setState({
                tabsVis: true
            })
        },
        onTabTitleChange: (index, e) => {
            let tabsEdit = this.state.tabsEdit;
            tabsEdit[index].title = e.target.value;
            this.setState({
                tabsEdit: tabsEdit
            });
        },
        onTabSortChange: (type, index, e) => {
            let tabsEdit = this.state.tabsEdit;
            if (type === 'up') {
                ArrayUtil.upGo(tabsEdit, index);
                this.setState({
                    tabsEdit: tabsEdit
                });
            } else if (type === 'down') {
                ArrayUtil.upDown(tabsEdit, index);
                this.setState({
                    tabsEdit: tabsEdit
                });
            }
        },
        onTabDelete: async (index, tabId, e) => {
            let tabsEdit = this.state.tabsEdit;
            if (tabId === 0) {
                tabsEdit.splice(index, 1);
            } else {
                if (window.confirm('删除后，tab下关联的商品将同步删除，确定要删除吗？')) {
                    let response = await this.deleteTab(tabId);
                    if (response) {
                        tabsEdit.splice(index, 1);
                    }
                }
            }
            this.setState({
                tabsEdit: tabsEdit,
                tabSelectId: 0
            });
        },
        onTabModalOk: async _ => {
            let response = await this.editTabs();
            let radioKey = this.state.radioKey;
            if (response) {
                this.setState({
                    tabsVis: false,
                    tabSelectId: 0,
                    radioKey: radioKey + 1
                });
            }
        }
    }

    copyModalHandler = {
        onShow: _ => {
            this.setState({
                copyModalVis: true
            });
        },
        onCancel: _ => {
            this.setState({
                copyModalVis: false,
                copyModalContent: ''
            });
        },
        onContentChange: e => {
            this.setState({
                copyModalContent: e.target.value
            });
        },
        onOk: async _ => {
            let content = this.state.copyModalContent;
            let selectSpuCodes = content.split("\n");

            this.setState({
                loading: true,
                tip: '数据提交中'
            });

            let data = {
                promotionId: this.state.id,
                tabId: this.state.tabSelectId,
                spuCodes: selectSpuCodes,
                type: this.state.tabKey === 'boom' ? 1 : 0
            };
            let response = await batchAddProduct(data);
            this.setState({
                loading: false
            });
            let { code } = response.data;
            if (code === 200) {
                this.setState({
                    copyModalVis: false,
                    copyModalContent: ''
                });
                message.success('商品添加成功');
                await this.getTabProducts();
            }
        }
    }

    onOperationEdit = item => {
        this.setState({
            operationId: item.promotionProductId,
            operationParams: {
                image: item.operationInfo.image,
                jumpUrl: item.operationInfo.jumpUrl,
                type: 1,
                sort: item.sort
            }
        })
        this.operationModalHandler.onShow()
    }

    deleteOperation = async id => {
        if (window.confirm('确认删除？')) {
            this.setState({
                loading: true,
                tip: '数据删除中'
            });
            let response = await deleteTouchBarContent(id)
            this.setState({
                loading: false
            });
            let { code } = response.data;
            if (code === 200) {
                this.operationModalHandler.onCancel()
                message.success('删除成功');
                await this.getTabProducts();
            }
        }
    }

    operationModalHandler = {
        onShow: _ => {
            this.setState({
                operationModalVis: true
            });
        },
        onCancel: _ => {
            this.setState({
                operationModalVis: false,
                operationParams: {
                    image: '',
                    jumpUrl: '',
                    type: 1,
                    sort: 0
                },
                operationId: 0
            })
        },
        onImageChange: value => {
            let operationParams = this.state.operationParams
            operationParams.image = value
            this.setState({
                operationParams: operationParams
            });
        },
        onJumpUrlChange: e => {
            let operationParams = this.state.operationParams
            operationParams.jumpUrl = e.target.value
            this.setState({
                operationParams: operationParams
            });
        },
        onSortChange: number => {
            let operationParams = this.state.operationParams
            operationParams.sort = number
            this.setState({
                operationParams: operationParams
            });
        },
        onOk: async _ => {
            let params = {
                image: this.state.operationParams.image,
                jumpUrl: this.state.operationParams.jumpUrl,
                promotionId: this.state.id,
                sort: this.state.operationParams.sort,
                tabId: this.state.tabSelectId,
                type: 1
            }
            this.setState({
                loading: true,
                tip: '提交中'
            });

            let response
            if (this.state.operationId) {
                response = await updateOperation(this.state.operationId, params)
            } else {
                response = await addOperation(params)
            }
            this.setState({
                loading: false
            })
            let { code } = response.data
            if (code === 200) {
                this.setState({
                    operationModalVis: false,
                    operationParams: {
                        image: '',
                        jumpUrl: '',
                        type: 1,
                        sort: 0
                    },
                    operationId: 0
                });
                message.success('提交成功');
                await this.getTabProducts();
            }
        }
    }

    floorModalHandler = {
        onShow: _ => {
            this.setState({
                floorModalVis: true
            });
        },
        onEditShow: item => {
            let productIds = []
            let productList = []
            this.state.normalListMap[item.floorId.toString()].forEach(x => {
                productIds.push(x.id)
                productList.push({
                    productId: x.id,
                    productName: x.name,
                    spu: x.spuCode
                })
            })
            
            this.setState({
                floorModalVis: true,
                floorId: item.floorId,
                floorParams: {
                    floorLabels: item.floorLabels,
                    floorLabelsTitle: item.floorLabelsTitle,
                    sort: item.sort,
                    styleEnable: item.styleEnable,
                    title: item.title,
                    productIds: productIds,
                    productList: productList
                }
            });
        },
        onCancel: _ => {
            this.setState({
                floorId: 0,
                floorModalVis: false,
                floorParams: {
                    floorLabels: '',
                    floorLabelsTitle: '推荐理由',
                    sort: 0,
                    styleEnable: 0,
                    title: '',
                    productIds: [],
                    productList: []
                }
            })
        },
        onTitleChange: e => {
            let floorParams = this.state.floorParams
            floorParams.title = e.target.value
            this.setState({
                floorParams: floorParams
            })
        },
        onFloorLabelsTitleChange: e => {
            let floorParams = this.state.floorParams
            floorParams.floorLabelsTitle = e.target.value
            this.setState({
                floorParams: floorParams
            })
        },
        onSortChange: number => {
            let floorParams = this.state.floorParams
            floorParams.sort = number
            this.setState({
                floorParams: floorParams
            });
        },
        onStyleEnableChange: e => {
            let floorParams = this.state.floorParams
            floorParams.styleEnable = e.target.value
            this.setState({
                floorParams: floorParams
            });
        },
        onFloorLabelsChange: e => {
            let floorParams = this.state.floorParams
            floorParams.floorLabels = e.target.value
            this.setState({
                floorParams: floorParams
            })
        },
        onFileSpuChange: (data, errorFileUrl) => {
            let productIds = []
            if (data) {
                data.forEach(item => item.productId && productIds.push(item.productId))
            }

            let floorParams = this.state.floorParams
            floorParams.productIds = productIds
            floorParams.productList = data ? data : []
            this.setState({
                floorParams: floorParams,
                uploadErrorReasonFile: errorFileUrl
            });
        },
        onOk: async _ => {
            this.setState({
                loading: true,
                tip: '数据提交中'
            });

            let floorParams = this.state.floorParams
            if (this.state.floorId) {
                floorParams.floorId = this.state.floorId
            }

            let data = {
                promotionId: this.state.id,
                tabId: this.state.tabSelectId,
                productIds: this.state.floorParams.productIds,
                type: this.state.tabKey === 'boom' ? 1 : 0,
                floorInfo: floorParams
            };

            let response = await batchAddProduct(data);
            this.setState({
                loading: false
            });
            let { code } = response.data;
            if (code === 200) {
                message.success('楼层提交成功');
                this.setState({
                    floorId: 0,
                    floorModalVis: false,
                    floorParams: {
                        floorLabels: '',
                        floorLabelsTitle: '推荐理由',
                        sort: 0,
                        styleEnable: 0,
                        title: '',
                        productIds: [],
                        productList: []
                    }
                })
                await this.getTabProducts();
            }
        },
        onDelete: async id => {
            if (window.confirm('确认删除？')) {
                this.setState({
                    loading: true,
                    tip: '数据删除中'
                });
                let response = await deleteFloor(id)
                this.setState({
                    loading: false
                });
                let { code } = response.data;
                if (code === 200) {
                    message.success('楼层删除成功');
                    await this.getTabProducts();
                }
            }
        },
        export: async _ => {
            let params = {
                promotionId: this.state.id,
                tabId: this.state.tabSelectId,
                type: this.state.tabKey === 'boom' ? 1 : 0,
                floorId: this.state.floorId
            }
            this.setState({
                loading: true,
                tip: '导出中'
            });
            let response = await exportProduct(params)
            this.setState({
                loading: false
            });
            let url = window.URL.createObjectURL(new Blob([response.data], {type: "application/vnd.ms-excel;charset=UTF-8"}))
            let link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', `楼层商品${new Date().getTime()}.xls`)
            document.body.appendChild(link)
            link.click()
            window.URL.revokeObjectURL(url)
            document.body.removeChild(link)
        }
    }

    render() {
        const tabRadios = this.state.tabs.map(item => <Radio.Button value={item.id}>{item.title}</Radio.Button>);
        const tabEditList = this.state.tabsEdit.map((item, index) => (
            <div key={'tabs_' + index} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Space size="large">
                    <div>
                        <Input value={item.title} onChange={this.formHandler.onTabTitleChange.bind(this, index)} />
                    </div>
                    <DeleteOutlined style={{ cursor: 'pointer', color: 'red' }} onClick={this.formHandler.onTabDelete.bind(this, index, item.id)} />
                    {index > 0 ? <ArrowUpOutlined style={{ cursor: 'pointer' }}  onClick={this.formHandler.onTabSortChange.bind(this, 'up', index)} /> : ''}
                    {index + 1 === this.state.tabsEdit.length ? '' : <ArrowDownOutlined style={{ cursor: 'pointer' }}  onClick={this.formHandler.onTabSortChange.bind(this, 'down', index)} />}
                </Space>
            </div>
        ));

        const columns = [{
            title: '商品ID',
            dataIndex: 'productId',
            key: 'productId'
        }, {
            title: '商品名称',
            dataIndex: 'productName',
            key: 'productName'
        }, {
            title: 'spu编码',
            dataIndex: 'spu',
            key: 'spu'
        }];

        const oldFloorList = <Row>
            <Col span={11}>
                <List
                    itemLayout="vertical"
                    size="large"
                    dataSource={this.state.normalList.left}
                    renderItem={(item, index) => (
                        <MarketingProductItem
                            value={item}
                            key={"normal_MarketingProductItem_left_" + index}
                            onSortChange={this.formHandler.onSortChange.bind(this, 'normal', 'left', index)}
                            onDelete={this.formHandler.onDeleteProduct.bind(this, item.promotionProductId, 0)}
                        />
                    )}
                />
            </Col>
            <Col span={2}></Col>
            <Col span={11}>
                <List
                    itemLayout="vertical"
                    size="large"
                    dataSource={this.state.normalList.right}
                    renderItem={(item, index) => (
                        <MarketingProductItem
                            value={item}
                            key={"normal_MarketingProductItem_right_" + index}
                            onSortChange={this.formHandler.onSortChange.bind(this, 'normal', 'right', index)}
                            onDelete={this.formHandler.onDeleteProduct.bind(this, item.promotionProductId, 0)}
                        />
                    )}
                />
            </Col>
        </Row>

        const newFloorList = this.state.floorList && this.state.floorList.map(item => {
            return <div style={{marginBottom: 20, borderBottom: '1px solid #333333', paddingBottom: 20}}>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <div>
                        <span style={{ fontSize: 18, fontWeight: 500 }}>{item.title}</span>
                    </div>
                    {item.styleEnable ? <div style={{marginLeft: 10}}><Tag color="cyan">楼层</Tag></div> : ''}
                    <div style={{marginLeft: 10}}>
                        <Button type="link" style={{color: 'red'}} onClick={this.floorModalHandler.onDelete.bind(this, item.floorId)}>删除</Button>
                    </div>
                    <div style={{marginLeft: 10}}>
                        <Button type="primary" onClick={this.floorModalHandler.onEditShow.bind(this, item)}>编辑</Button>
                    </div>
                </div>
                {item.styleEnable ? 
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 5}}>
                        {item.floorLabels && item.floorLabels.split(',').map(x => <Tag>{x}</Tag>)}
                    </div>    
                    : ''
                }
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap',marginTop: 5}}>
                    {this.state.normalListMap[item.floorId.toString()].map(x => <div style={{margin: 10}}>
                        <img src={x.pic} style={{width: 100, height: 100}}/>
                        <div>{x.name}</div>
                    </div>)}
                </div>
            </div>
        })

        return (
            <div className="EditMarketing">
                <LoadingLayer loading={this.state.loading} tip={this.state.tip}/>
                <PageHeader
                    title={this.state.id ? '编辑营销活动' : '新建营销活动'}
                />

                <div>
                    <Divider orientation="left">活动基础信息</Divider>
                    <Form
                        labelCol={{ span: 0 }}
                        wrapperCol={{ span: 10 }}
                        layout="horizontal"
                        style={{ marginTop: 10 }}
                    >
                        <Form.Item label="活动名称">
                            <Input value={this.state.title} onChange={this.formHandler.onTitleChange}/>
                        </Form.Item>
                        <Form.Item label="售卖时段">
                            <RangePicker
                                showTime={{
                                    defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')]
                                }}
                                value={this.state.showTime}
                                onChange={this.formHandler.onShowTimeChange}
                            />
                        </Form.Item>
                    </Form>
                </div>
                <br/>

                <div className="detail-box" style={{ display: this.state.id ? 'block' : 'none' }}>
                    <Divider orientation="left">Tab信息</Divider>
                    <div className="tab-box">
                        <div>
                            <Space size="large">
                                <Radio.Group key={'tabRadio_' + this.state.radioKey} buttonStyle="solid" onChange={this.onTabSelect}>
                                    {tabRadios}
                                </Radio.Group>
                                <Button type="primary" onClick={this.formHandler.onTabModalEdit}>编辑tab</Button>
                            </Space>
                        </div>
                    </div>

                    <br/><br/>
                    <div style={{ display: this.state.tabSelectId > 0 ? 'block' : 'none' }}>
                        <Divider orientation="left">商品信息</Divider>
                        <Space>
                            {(this.state.normalList.selectIds.length === 0 && this.state.tabKey === 'normal') ? '' :
                                <>
                                    <ProductCategoryMultiClassSelector
                                        value={this.state.selectProductIds}
                                        onChange={this.formHandler.onProductSelect}
                                        onClear={this.onTabPaneChange.bind(this, this.state.tabKey)}
                                    />
                                    <Button type="primary" onClick={this.formHandler.onAddProduct}>添加</Button>
                                    <Button onClick={this.copyModalHandler.onShow}>从excel批量添加</Button>
                                </>
                            }
                            {this.state.tabKey === 'boom' ? 
                                <Button type="primary" onClick={this.operationModalHandler.onShow}>新增运营位</Button> :
                                (this.state.normalList.selectIds.length === 0 && this.state.tabKey === 'normal') ? 
                                    <Button type="primary" onClick={this.floorModalHandler.onShow}>新增楼层</Button> :
                                    ''
                            }
                        </Space>
                        <Tabs defaultActiveKey="boom" onChange={this.onTabPaneChange}>
                            <TabPane tab="轮播信息" key="boom">
                                <Row>
                                    <Col span={11}>
                                        <List
                                            itemLayout="vertical"
                                            size="large"
                                            dataSource={this.state.boomList.left}
                                            renderItem={(item, index) => {
                                                if (item.operationInfo) {
                                                    return (<List.Item
                                                        actions={[
                                                            <Space style={{ color: 'red' }} onClick={this.deleteOperation.bind(this, item.promotionProductId)}>
                                                                <DeleteOutlined/>
                                                                删除
                                                            </Space>,
                                                            <Space onClick={this.onOperationEdit.bind(this, item)}>
                                                                <EditOutlined/>
                                                                编辑
                                                            </Space>
                                                        ]}
                                                        extra={
                                                            <img
                                                                width={160}
                                                                alt="logo"
                                                                src={item.operationInfo.image}
                                                            />
                                                        }
                                                    >
                                                        {item.operationInfo.jumpUrl}
                                                    </List.Item>)
                                                } else {
                                                    return (
                                                        <MarketingProductItem
                                                            value={item}
                                                            key={"MarketingProductItem_left_" + index}
                                                            onSortChange={this.formHandler.onSortChange.bind(this, 'boom', 'left', index)}
                                                            onDelete={this.formHandler.onDeleteProduct.bind(this, item.promotionProductId, 1)}
                                                        />
                                                    )
                                                }
                                            }}
                                        />
                                    </Col>
                                    <Col span={2}></Col>
                                    <Col span={11}>
                                        <List
                                            itemLayout="vertical"
                                            size="large"
                                            dataSource={this.state.boomList.right}
                                            renderItem={(item, index) => {
                                                if (item.operationInfo) {
                                                    return (<List.Item
                                                        actions={[
                                                            <Space style={{ color: 'red' }} onClick={this.deleteOperation.bind(this, item.promotionProductId)}>
                                                                <DeleteOutlined/>
                                                                删除
                                                            </Space>,
                                                            <Space onClick={this.onOperationEdit.bind(this, item)}>
                                                                <EditOutlined/>
                                                                编辑
                                                            </Space>
                                                        ]}
                                                        extra={
                                                            <img
                                                                width={160}
                                                                alt="logo"
                                                                src={item.operationInfo.image}
                                                            />
                                                        }
                                                    >
                                                        {item.operationInfo.jumpUrl}
                                                    </List.Item>)
                                                } else {
                                                    return <MarketingProductItem
                                                        value={item}
                                                        key={"MarketingProductItem_right_" + index}
                                                        onSortChange={this.formHandler.onSortChange.bind(this, 'boom', 'right', index)}
                                                        onDelete={this.formHandler.onDeleteProduct.bind(this, item.promotionProductId, 1)}
                                                />
                                                }
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </TabPane>
                            <TabPane tab="楼层信息" key="normal">
                                {this.state.floorList ? newFloorList : oldFloorList}
                            </TabPane>
                        </Tabs>
                    </div>
                </div>

                <Affix offsetBottom={0}>
                    <div className="submit-box">
                        <Space size="large">
                            {this.state.id && ((this.state.tabKey === 'boom' && this.state.boomList.left.length > 0) || (this.state.tabKey === 'normal' && this.state.normalList.left.length > 0))
                                ? <Button onClick={this.formHandler.sortProducts}>提交商品排序</Button> : ''}
                            <Button type="primary" onClick={this.formHandler.onSubmit}>提交活动信息</Button>
                        </Space>
                    </div>
                </Affix>

                <Modal
                    title="编辑Tab"
                    className="tabs-modal"
                    visible={this.state.tabsVis}
                    onOk={this.formHandler.onTabModalOk}
                    onCancel={() => this.setState({tabsVis: false})}
                >
                    <Space direction="vertical">
                        {tabEditList}
                        <Button onClick={this.formHandler.onTabAdd}>+添加tab</Button>
                    </Space>
                </Modal>

                <Modal
                    title="从excel中复制"
                    visible={this.state.copyModalVis}
                    onOk={this.copyModalHandler.onOk}
                    onCancel={this.copyModalHandler.onCancel}
                >
                    <Input.TextArea rows={6} value={this.state.copyModalContent} onChange={this.copyModalHandler.onContentChange}/>
                </Modal>

                <Modal
                    title={this.state.operationId ? "编辑运营位" : "新增运营位"}
                    visible={this.state.operationModalVis}
                    onOk={this.operationModalHandler.onOk}
                    onCancel={this.operationModalHandler.onCancel}
                >
                    <Form
                        labelCol={{ span: 4 }}
                        wrapperCol={{ span: 16 }}
                        layout="horizontal"
                        style={{ marginTop: 10 }}
                    >
                        <Form.Item label="图片">
                            <UploadImage value={this.state.operationParams.image} onChange={this.operationModalHandler.onImageChange}/>
                        </Form.Item>
                        <Form.Item label="跳转链接">
                            <Input value={this.state.operationParams.jumpUrl} onChange={this.operationModalHandler.onJumpUrlChange}/>
                        </Form.Item>
                        <Form.Item label="排序">
                            <InputNumber min={0} value={this.state.operationParams.sort} onChange={this.operationModalHandler.onSortChange}/>
                        </Form.Item>
                    </Form>
                </Modal>

                <Modal
                    title={this.state.operationId ? "编辑运营位" : "新增运营位"}
                    visible={this.state.operationModalVis}
                    onOk={this.operationModalHandler.onOk}
                    onCancel={this.operationModalHandler.onCancel}
                >
                    <Form
                        labelCol={{ span: 4 }}
                        wrapperCol={{ span: 16 }}
                        layout="horizontal"
                        style={{ marginTop: 10 }}
                    >
                        <Form.Item label="图片">
                            <UploadImage value={this.state.operationParams.image} onChange={this.operationModalHandler.onImageChange}/>
                        </Form.Item>
                        <Form.Item label="跳转链接">
                            <Input value={this.state.operationParams.jumpUrl} onChange={this.operationModalHandler.onJumpUrlChange}/>
                        </Form.Item>
                        <Form.Item label="排序">
                            <InputNumber min={0} value={this.state.operationParams.sort} onChange={this.operationModalHandler.onSortChange}/>
                        </Form.Item>
                    </Form>
                </Modal>

                <Modal
                    title={this.state.floorId ? "编辑楼层" : "新增楼层"}
                    visible={this.state.floorModalVis}
                    onOk={this.floorModalHandler.onOk}
                    onCancel={this.floorModalHandler.onCancel}
                >
                    <Form
                        labelCol={{ span: 4 }}
                        layout="horizontal"
                        style={{ marginTop: 10 }}
                    >
                        <Form.Item label="楼层名称">
                            <Input value={this.state.floorParams.title} onChange={this.floorModalHandler.onTitleChange}/>
                        </Form.Item>
                        <Form.Item label="排序">
                            <InputNumber min={0} value={this.state.floorParams.sort} onChange={this.floorModalHandler.onSortChange}/>
                        </Form.Item>
                        <Form.Item label="楼层样式">
                            <Radio.Group onChange={this.floorModalHandler.onStyleEnableChange} value={this.state.floorParams.styleEnable}>
                                <Radio value={0}>不启用</Radio>
                                <Radio value={1}>启用</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item label="推荐标题">
                            <Input value={this.state.floorParams.floorLabelsTitle} onChange={this.floorModalHandler.onFloorLabelsTitleChange}/>
                        </Form.Item>
                        <Form.Item label="标签">
                            <Input value={this.state.floorParams.floorLabels} onChange={this.floorModalHandler.onFloorLabelsChange}/>
                        </Form.Item>
                        <Form.Item label="关联商品">
                            <Space>
                                <UploadFileSpu onChange={this.floorModalHandler.onFileSpuChange}/>
                                <a href='https://bj-prod-1309320985.cos.ap-beijing.myqcloud.com/20220525/3bccc9d45d2c7d78f9fe4fa81177a33.xls' target="_blank">下载模版</a>
                            </Space>
                            <br/>
                            <br/>
                            <Space>
                                {this.state.floorId ? <Button onClick={this.floorModalHandler.export}>下载附件</Button> : ''}
                                {this.state.uploadErrorReasonFile ? <a style={{color: 'red'}} href={this.state.uploadErrorReasonFile} target="_blank">下载异常文件</a> : ''}
                            </Space>
                            <br/><br/>
                            <Table
                                size="small"
                                columns={columns}
                                dataSource={this.state.floorParams.productList}
                                bordered
                                pagination={false}
                            />
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        );
    }
}

export default EditMarketing;
