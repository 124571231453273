class RandomUtil {

    static chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';

    static randomString(len = 8) {
        let maxPos = this.chars.length;
        let pwd = '';
        for (let i = 0; i < len; i++) {
            pwd += this.chars.charAt(Math.floor(Math.random() * maxPos));
        }
        return pwd;
    }
}

export default RandomUtil;
