import RequestUtil from "../util/request/RequestUtil";

export function getCouponList(showName = '', voucherCode = '', voucherId = 0,
                              voucherStatus = 0, pageNum = 1, pageSize = 5) {
    let data = {
        currentPage: pageNum,
        pageSize: pageSize
    };
    if (showName) {
        data.showName = showName;
    }
    if (voucherCode) {
        data.voucherCode = voucherCode;
    }
    if (voucherId) {
        data.voucherId = voucherId;
    }
    if (voucherStatus) {
        data.voucherStatus = voucherStatus;
    }
    return RequestUtil.getInstance().post(`/voucher-info/pageList`, data);
}

export function addCoupon(params) {
    let data = {
        desc: params.desc,
        discountAmounts: params.discountAmounts,
        showName: params.showName,
        stock: params.stock,
        useTimePlusDay: params.useTimePlusDay
    };
    return RequestUtil.getInstance().post(`/voucher-info`, data);
}

export function getCoupon(couponId) {
    return RequestUtil.getInstance().get(`/voucher-info/${couponId}`);
}

export function updateCoupon(couponId, params) {
    let data = {
        voucherId: couponId,
        desc: params.desc,
        discountAmounts: params.discountAmounts,
        showName: params.showName,
        stock: params.stock,
        useTimePlusDay: params.useTimePlusDay
    };
    return RequestUtil.getInstance().put(`/voucher-info`, data);
}

export function updateCouponStatus(couponId, enabledStatus) {
    let data = {
        voucherId: couponId,
        enabledStatus: enabledStatus
    };
    return RequestUtil.getInstance().patch(`/voucher-info`, data);
}
