import React from 'react';
import { message, Layout, Avatar, Image } from 'antd';
import TopNav from './TopNav';
import SiderNav from './SiderNav';
import LoadingLayer from '../common/LoadingLayer';
import '../../css/commonLayout.scss';
import LocalStorageUtil from '../../util/LocalStorageUtil';
import { logout } from '../../api/LoginApi';

const { Header, Content, Sider } = Layout;

class CommonLayout extends React.Component {

    state = {
        userInfo: null,
        loading: false,
        tip: '退出中'
    }

    componentWillMount() {
        let userInfo = LocalStorageUtil.get('userInfo');
        if (!userInfo) {
            window.location.href = '/login';
        }
        this.setState({
            userInfo: JSON.parse(userInfo)
        });
    }

    logout = async _ => {
        this.setState({
            loading: true
        });
        let response = await logout();
        let { code } = response.data;
        if (code === 200) {
            message.success('退出成功');
            LocalStorageUtil.remove('Authorization');
            LocalStorageUtil.remove('userInfo');
            window.location.href = '/login';
        }
        this.setState({
            loading: false
        });
    }

    render() {
        return (
            <Layout className="CommonLayout">
                <LoadingLayer loading={this.state.loading} tip={this.state.tip}/>
                <Header style={{ position: 'fixed', zIndex: 100, width: '100%', backgroundColor: '#fff', boxShadow: '0 2px 8px #f0f1f2', padding: '0 25px' }}>
                    <div className="logo">
                        <div style={{ marginRight: 10, height: 36, width: 36, display: 'inline-flex' }}>
                            <img style={{ borderRadius: '50%' }} width="100%" src='https://bj-prod-1309320985.cos.ap-beijing.myqcloud.com/20220408/db190f045c0f2c6296bcecd2174ba141.png'/>
                        </div>
                        <div>鲸澄管理平台</div>
                    </div>
                    <div className="avatar">
                        <Avatar
                            size={40}
                            src={<Image src={this.state.userInfo.icon} />}
                        />
                        <span>{this.state.userInfo.username}</span>
                        <a onClick={this.logout}>退出</a>
                    </div>
                    <TopNav selectedKey={this.props.topNavSelectedKey}/>
                </Header>
                <Layout>
                    <Sider
                        width={180}
                        className="sider-background"
                        style={{ overflow: 'auto', height: '100vh', position: 'fixed', left: 0, top: 65 }}
                    >
                        <SiderNav
                            category={this.props.siderNavCategory}
                            selectedKey={this.props.siderNavSelectedKey}
                        />
                    </Sider>
                    <Layout style={{ padding: '5px', margin: '64px 0 0 180px', backgroundColor: '#fafafa' }}>
                        <Content
                            style={{
                                padding: 12,
                                margin: 0,
                                minHeight: '92vh',
                                overflow: 'initial',
                                backgroundColor: '#fff'
                            }}
                        >
                            {this.props.children}
                        </Content>
                    </Layout>
                </Layout>
            </Layout>
        );
    }
}

export default CommonLayout;
