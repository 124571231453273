import React from 'react';
import { Space, Input, InputNumber, Button } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { getInfo, updateSku } from '../../api/StockApi';
import LoadingLayer from '../common/LoadingLayer';

class EditStock extends React.Component {

    state = {
        skuId: this.props.value,
        loading: false,
        tip: '',
        info: {
            attr_1_name: '',
            attr_1_val: '',
            attr_2_name: '',
            attr_2_val: '',
            brandName: '',
            categoryName: '',
            productName: '',
            salesPrice: 0,
            spucode: '',
            skuStockCode: '',
            stockNum: 0,
            sales: ''
        }
    };

    componentDidMount = async _ => {
        this.setState({
            loading: true,
            tip: '数据加载中'
        });
        let response = await getInfo(this.state.skuId);
        let { code, data } = response.data;
        if (code === 200) {
            let skuAttr = JSON.parse(data.skuData);
            let attr1Name = skuAttr[0].key;
            let attr1Val = skuAttr[0].value;
            let attr2Name = '';
            let attr2Val = '';
            if (skuAttr.length > 1) {
                attr2Name = skuAttr[1].key;
                attr2Val = skuAttr[1].value;
            }
            this.setState({
                loading: false,
                info: {
                    attr_1_name: attr1Name,
                    attr_1_val: attr1Val,
                    attr_2_name: attr2Name,
                    attr_2_val: attr2Val,
                    brandName: data.brandName,
                    categoryName: data.categoryName,
                    productName: data.productName,
                    salesPrice: data.salesPrice,
                    spucode: data.spuCode,
                    skuStockCode: data.skuStockCode,
                    stockNum: data.stockNum,
                    sales: data.sales
                }
            });
        }
    }

    formHandler = {
        onAttr1NameChange: e => {
            let info = this.state.info;
            info.attr_1_name = e.target.value;
            this.setState({
                info: info
            });
        },
        onAttr2NameChange: e => {
            let info = this.state.info;
            info.attr_2_name = e.target.value;
            this.setState({
                info: info
            });
        },
        onSpuCodeChange: e => {
            let info = this.state.info;
            info.spucode = e.target.value;
            this.setState({
                info: info
            });
        },
        onSkuStockCodeChange: e => {
            let info = this.state.info;
            info.skuStockCode = e.target.value;
            this.setState({
                info: info
            });
        },
        onBrandNameChange: e => {
            let info = this.state.info;
            info.brandName = e.target.value;
            this.setState({
                info: info
            });
        },
        onCategoryNameChange: e => {
            let info = this.state.info;
            info.categoryName = e.target.value;
            this.setState({
                info: info
            });
        },
        onProductNameChange: e => {
            let info = this.state.info;
            info.productName = e.target.value;
            this.setState({
                info: info
            });
        },
        onAttr1ValChange: e => {
            let info = this.state.info;
            info.attr_1_val = e.target.value;
            this.setState({
                info: info
            });
        },
        onAttr2ValChange: e => {
            let info = this.state.info;
            info.attr_2_val = e.target.value;
            this.setState({
                info: info
            });
        },
        onSalesPriceChange: number => {
            let info = this.state.info;
            info.salesPrice = number;
            this.setState({
                info: info
            });
        },
        onStockNumChange: number => {
            let info = this.state.info;
            info.stockNum = number;
            this.setState({
                info: info
            });
        },
        onSalesChange: e => {
            let info = this.state.info;
            info.sales = e.target.value;
            this.setState({
                info: info
            });
        },
        submit: async _ => {
            let params = this.state.info;
            this.setState({
                loading: true,
                tip: '数据提交中'
            });
            let response = await updateSku(this.state.skuId, params);
            this.setState({
                loading: false
            });
            let { code } = response.data;
            if (code === 200) {
                this.props.onSuccess();
            }
        },
        cancel: _ => {
            this.props.onSuccess();
        }
    }

    render() {
        return (
            <>
                <LoadingLayer loading={this.state.loading} tip={this.state.tip}/>
                <PageHeader
                    title='编辑sku'
                />
                <table border="1" cellPadding="10">
                    <tr>
                        <th>品牌</th>
                        <th>分类</th>
                        <th>商品名称</th>
                        <th>sku编号</th>
                        <th>spu编号</th>
                        <th>商家</th>
                        <th>入库价</th>
                        <th>入库量</th>
                        <th>
                            <Space>
                                规格1：
                                <Input
                                    style={{ width: 100 }}
                                    value={this.state.info.attr_1_name}
                                    onChange={this.formHandler.onAttr1NameChange}
                                />
                            </Space>
                        </th>
                        <th>
                            <Space>
                                规格2：
                                <Input
                                    style={{ width: 100 }}
                                    value={this.state.info.attr_2_name}
                                    onChange={this.formHandler.onAttr2NameChange}
                                />
                            </Space>
                        </th>
                    </tr>
                    <tr>
                        <td><Input value={this.state.info.brandName} onChange={this.formHandler.onBrandNameChange}/></td>
                        <td><Input value={this.state.info.categoryName} onChange={this.formHandler.onCategoryNameChange}/></td>
                        <td><Input value={this.state.info.productName} onChange={this.formHandler.onProductNameChange}/></td>
                        <td><Input value={this.state.info.skuStockCode} onChange={this.formHandler.onSkuStockCodeChange}/></td>
                        <td><Input value={this.state.info.spucode} onChange={this.formHandler.onSpuCodeChange}/></td>
                        <td><Input value={this.state.info.sales} onChange={this.formHandler.onSalesChange}/></td>
                        <td><InputNumber min={0} value={this.state.info.salesPrice} onChange={this.formHandler.onSalesPriceChange}/></td>
                        <td><InputNumber min={0} value={this.state.info.stockNum} onChange={this.formHandler.onStockNumChange}/></td>
                        <td><Input value={this.state.info.attr_1_val} onChange={this.formHandler.onAttr1ValChange}/></td>
                        <td><Input value={this.state.info.attr_2_val} onChange={this.formHandler.onAttr2ValChange}/></td>
                    </tr>
                </table>
                <br/>
                <Space>
                    <Button type="link" onClick={this.formHandler.cancel}>取消</Button>
                    <Button type="primary" onClick={this.formHandler.submit}>提交</Button>
                </Space>
            </>
        );
    }
}

export default EditStock;
