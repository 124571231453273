import React from 'react';
import { Select } from 'antd';
import { getInfo } from '../../api/ProductApi';

const { Option } = Select;

class SkuSelector extends React.Component {

    state = {
        list: [],
        value: ''
    };

    componentDidMount = async _ => {
        this.setState({
            value: this.props.value
        });
        await this.getInfo(this.props.spuId);
    }

    componentWillReceiveProps = async props => {
        if (props.spuId) {
            await this.getInfo(props.spuId);
            this.setState({
                value: props.value ? props.value : undefined
            });
        }
    }

    getInfo = async productId => {
        let response = await getInfo(productId);
        let { code, data } = response.data;
        if (code === 200 && data && data.skuStockList) {
            let list = data.skuStockList.map(item => {
                let spDataArr = JSON.parse(item.spData);
                let spDataValueArr = spDataArr.map(x => x.value);
                return {
                    key: item.skuCode,
                    value: spDataValueArr.join('、') + `（${item.skuCode}）`
                }
            })

            this.setState({
                list: list
            });
        }
    }

    onChange = key => {
        if (this.props.onChange) {
            this.props.onChange(key);
        }
    }

    render() {
        const options = this.state.list.map(item => <Option key={item.key}>{item.value}</Option>)

        return (
            <>
                <Select
                    allowClear
                    showSearch
                    style={{ width: this.props.width ? this.props.width : 300 }}
                    placeholder="选择一个SKU"
                    value={this.state.value}
                    optionFilterProp="children"
                    onChange={this.onChange}
                    filterOption={(input, option) =>
                        option.children.toLocaleLowerCase().indexOf(input.toLocaleLowerCase()) >= 0
                    }
                >
                    {options}
                </Select>
            </>
        );
    }
}

export default SkuSelector;
