import React from 'react';
import { Spin } from 'antd';
import '../../css/common/loadingLayer.scss';

class LoadingLayer extends React.Component {

    render() {
        return (
            <div className="LoadingLayer" style={{ display: this.props.loading ? 'block' : 'none' }}>
                <div className="spin">
                    <Spin size="large" tip={this.props.tip}/>
                </div>
            </div>
        );
    }
}

export default LoadingLayer;
