import RequestUtil from "../util/request/RequestUtil";

export function login(username, password) {
    let data = {
        username: username,
        password: password
    }
    return RequestUtil.getInstance(5000).post('/admin/login', data);
}

export function logout() {
    return RequestUtil.getInstance().post('/admin/logout');
}

export function getUserInfo() {
    return RequestUtil.getInstance().get('/admin/info');
}
