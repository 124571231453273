import React from "react";
import {
    Button,
    Space,
    Row,
    Col,
    DatePicker,
    Alert
} from 'antd';
import * as echarts from 'echarts';
import LoadingLayer from '../common/LoadingLayer';
import {
    getBiTotalSpuClickData,
    getBiTotalSpuClickPositionData,
    getBiTotalTabClickData,
    getBiTotalGeneralClickData,
    exportSpuClickDetail,
    exportTabClickDetail
} from '../../api/BiApi';

const { RangePicker } = DatePicker;

class DataDailyBehavior extends React.Component {

    state = {
        loadingLayer: false,
        tip: '',
        showTime: [],
        startDate: '',
        endDate: '',
        type: 1
    }

    onShowTimeChange = (dates, dateStrings) => {
        this.setState({
            showTime: dates,
            startDate: dateStrings[0].replaceAll('-', ''),
            endDate: dateStrings[1].replaceAll('-', '')
        });
    }

    onSearch = async _ => {
        this.setState({
            loadingLayer: true,
            tip: '数据加载中'
        });

        await this.getSpuClickData()
        await this.getSpuClickPositionData()
        await this.getTabClickData()
        await this.getGeneralClickData()

        this.setState({
            loadingLayer: false
        });
    }

    onExportSpuClickData = async _ => {
        this.setState({
            loadingLayer: true,
            tip: '导出中'
        });

        let response = await exportSpuClickDetail(this.state)
        this.setState({
            loadingLayer: false
        });
        let url = window.URL.createObjectURL(new Blob([response.data], {type: "application/vnd.ms-excel;charset=UTF-8"}))
        let link = document.createElement('a')
        link.style.display = 'none'
        link.href = url
        link.setAttribute('download', `商品点击数据${this.state.startDate}-${this.state.endDate}.xlsx`)
        document.body.appendChild(link)
        link.click()
        window.URL.revokeObjectURL(url)
        document.body.removeChild(link)
    }

    onExportTabClickData = async _ => {
        this.setState({
            loadingLayer: true,
            tip: '导出中'
        });

        let response = await exportTabClickDetail(this.state)
        this.setState({
            loadingLayer: false
        });
        let url = window.URL.createObjectURL(new Blob([response.data], {type: "application/vnd.ms-excel;charset=UTF-8"}))
        let link = document.createElement('a')
        link.style.display = 'none'
        link.href = url
        link.setAttribute('download', `tab点击数据${this.state.startDate}-${this.state.endDate}.xlsx`)
        document.body.appendChild(link)
        link.click()
        window.URL.revokeObjectURL(url)
        document.body.removeChild(link)
    }

    getSpuClickData = async _ => {
        let response = await getBiTotalSpuClickData(this.state)
        let { code, data } = response.data
        if (code === 200 && data) {
            let xAxisData = []
            let clickData = []
            data.forEach(item => {
                xAxisData.push(item.spuName + ' - ' + item.skuCode)
                clickData.push(item.clickNum)
            })

            let chartDom = document.getElementById('echarts-spuclick')
            let chart = echarts.init(chartDom)
            let option = {
                title: {
                    text: '用户点击商品数据'
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        data: xAxisData,
                        axisTick: {
                            alignWithLabel: true
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value'
                    }
                ],
                series: [
                    {
                        name: '点击数',
                        type: 'bar',
                        barWidth: '60%',
                        data: clickData
                    }
                ]
            }

            chart.setOption(option)
        }
    }

    getSpuClickPositionData = async _ => {
        let response = await getBiTotalSpuClickPositionData(this.state)
        let { code, data } = response.data
        if (code === 200 && data) {
            let xAxisData = []
            let clickData = []
            data.forEach(item => {
                xAxisData.push(item.title)
                clickData.push(item.clickNum)
            })

            let chartDom = document.getElementById('echarts-spuclick-position')
            let chart = echarts.init(chartDom)
            let option = {
                title: {
                    text: '用户点击商品位置数据'
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        data: xAxisData,
                        axisTick: {
                            alignWithLabel: true
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value'
                    }
                ],
                series: [
                    {
                        name: '点击数',
                        type: 'bar',
                        barWidth: '60%',
                        data: clickData
                    }
                ]
            }

            chart.setOption(option)
        }
    }

    getTabClickData = async _ => {
        let response = await getBiTotalTabClickData(this.state)
        let { code, data } = response.data
        if (code === 200 && data) {
            let xAxisData = []
            let clickData = []
            data.forEach(item => {
                xAxisData.push(item.tabName)
                clickData.push(item.clickNum)
            })

            let chartDom = document.getElementById('echarts-tabclick')
            let chart = echarts.init(chartDom)
            let option = {
                title: {
                    text: '用户点击首页标签数据'
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        data: xAxisData,
                        axisTick: {
                            alignWithLabel: true
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value'
                    }
                ],
                series: [
                    {
                        name: '点击数',
                        type: 'bar',
                        barWidth: '60%',
                        data: clickData
                    }
                ]
            }

            chart.setOption(option)
        }
    }

    getGeneralClickData = async _ => {
        let response = await getBiTotalGeneralClickData(this.state)
        let { code, data } = response.data
        if (code === 200 && data) {
            let xAxisData = []
            let clickData = []
            data.forEach(item => {
                let type = "未知";
                if (item.type === 1) {
                    type = "活动页";
                }
                let pageType = "未知位置";
                if (item.pageType === 1) {
                    pageType = "首页位置";
                }
                xAxisData.push(type + "-" + pageType + "-" + item.bizName)
                clickData.push(item.clickNum)
            })

            let chartDom = document.getElementById('echarts-activitypage-click')
            let chart = echarts.init(chartDom)
            let option = {
                title: {
                    text: '用户点击活动页入口数据'
                },
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        data: xAxisData,
                        axisTick: {
                            alignWithLabel: true
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value'
                    }
                ],
                series: [
                    {
                        name: '点击数',
                        type: 'bar',
                        barWidth: '60%',
                        data: clickData
                    }
                ]
            }

            chart.setOption(option)
        }
    }

    render() {
        return (
            <>
                <LoadingLayer loading={this.state.loadingLayer} tip={this.state.tip}/>
                <Row>
                    <Col span={6}>
                        日期范围：<RangePicker value={this.state.showTime} onChange={this.onShowTimeChange} />
                    </Col>
                    <Col span={6}>
                        <Space>
                            <Button type="primary" onClick={this.onSearch}>搜索</Button>
                            <Button onClick={this.onExportSpuClickData}>下载商品点击数据</Button>
                            <Button onClick={this.onExportTabClickData}>下载tab点击数据</Button>
                        </Space>
                    </Col>
                </Row>
                <br/>
                <Alert message="已去除内部用户数据" type="warning" />
                <br/>

                <Row>
                    <Col span={24}>
                        <div id="echarts-spuclick" style={{width: '100%', height: 400}}/>
                    </Col>
                </Row>
                <br/>
                <br/>
                <Row>
                    <Col span={24}>
                        <div id="echarts-spuclick-position" style={{width: '100%', height: 400}}/>
                    </Col>
                </Row>
                <br/>
                <br/>
                <Row>
                    <Col span={24}>
                        <div id="echarts-tabclick" style={{width: '100%', height: 400}}/>
                    </Col>
                </Row>
                <br/>
                <br/>
                <Row>
                    <Col span={24}>
                        <div id="echarts-activitypage-click" style={{width: '100%', height: 400}}/>
                    </Col>
                </Row>
            </>
        )
    }
}

export default DataDailyBehavior
