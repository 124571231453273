import React from 'react';
import CommonLayout from '../../components/layout/CommonLayout';
import AdminList from '../../components/user/AdminList';

class Admins extends React.Component {

    render() {
        return (
            <CommonLayout
                topNavSelectedKey="user"
                siderNavCategory="user"
                siderNavSelectedKey="admins"
            >
                <AdminList/>
            </CommonLayout>
        );
    }
}

export default Admins;
