import React from 'react';
import { Modal, message, InputNumber, Table, Input, Space, Button, Tag, Tabs, Divider, Select } from 'antd';
import {
    getStockPageList,
    getOverSkuList,
    getHistorySkuList,
    deleteSku,
    stopCater,
    getProductPublishStatus,
    batchAddSkuStock
} from '../../api/StockApi';
import { getListByParentId } from '../../api/ProductCategoryApi';
import LoadingLayer from '../common/LoadingLayer';
import UploadFileSku from '../upload/FileSku';
import BrandSelector from '../selector/BrandSelector';
import moment from 'moment';
import '../../css/stock/stockList.scss';

const { TabPane } = Tabs;
const { Option } = Select;

class StockList extends React.Component {

    state = {
        categoryId: this.props.categoryId,
        loading: false,
        loadingLayer: false,
        tip: '',
        tabKey: 'list',
        firstClass: [],
        secondClass: [],
        selectedFirstId: 0,
        selectedSecondId: 0,
        list: {
            search: {
                brandId: null,
                productName: ''
            },
            pageNum: 1,
            pageSize: 20,
            total: 0,
            list: [],
            columns: [
                {
                title: 'sku编号',
                dataIndex: 'skuStockCode',
                key: 'skuStockCode',
                width: 120
            }, {
                title: 'spu编号',
                dataIndex: 'spuCode',
                key: 'spuCode',
                width: 80
            }, {
                title: '入库时间',
                dataIndex: 'createTime',
                key: 'createTime',
                width: 120,
                render: time => (
                    moment(time).format('yyyy-MM-DD HH:mm:ss')
                )
            }, {
                title: '品牌',
                dataIndex: 'brandName',
                key: 'brandName',
                width: 60
            }, {
                title: '一级分类',
                dataIndex: 'firstCategoryName',
                key: 'firstCategoryName',
                width: 60
            }, {
                title: '二级分类',
                dataIndex: 'secondCategoryName',
                key: 'secondCategoryName',
                width: 60
            }, {
                title: '商品名称',
                dataIndex: 'productName',
                key: 'productName',
                width: 140
            }, {
                title: '规格',
                dataIndex: 'skuData',
                key: 'skuData',
                width: 140,
                render: skuData => (
                    JSON.parse(skuData).map(item => <Tag>{item.key} : {item.value}</Tag>)
                )
            }, {
                title: '入库价格',
                dataIndex: 'salesPrice',
                key: 'salesPrice',
                width: 60
            }, {
                title: '官方价',
                dataIndex: 'officialPrice',
                key: 'officialPrice',
                width: 60
            }, {
                title: '累计销售',
                dataIndex: 'totoleSales',
                key: 'totoleSales',
                width: 60
            }, {
                title: '库存',
                dataIndex: 'stockNum',
                key: 'stockNum',
                width: 60
            }, {
                title: '7日平均销售',
                dataIndex: 'sevenDayAverageSales',
                key: 'sevenDayAverageSales',
                width: 60
            }, {
                title: '7日DOS',
                dataIndex: 'sevenDayDOS',
                key: 'sevenDayDOS',
                width: 60
            }, {
                title: '操作',
                dataIndex: 'action',
                key: 'action',
                width: 100,
                fixed: 'right',
                render: (_, record) => (
                    <Space>
                        <Button style={{ color: 'red' }} type="link" onClick={this.deleteSku.bind(this, record.skuStockCode)}>删除</Button>
                        <Button type="link" onClick={this.stopCater.bind(this, record.skuStockCode)}>停止供应</Button>
                        {/* <Link to={`/stock/edit/${record.skuId}`}><Button type="link">编辑</Button></Link> */}
                    </Space>
                )
            }]
        },
        over: {
            search: {
                brandId: null,
                productName: ''
            },
            pageNum: 1,
            pageSize: 20,
            total: 0,
            list: [],
            columns: [
                {
                title: 'sku编号',
                dataIndex: 'skuStockCode',
                key: 'skuStockCode',
            }, {
                title: 'spu编号',
                dataIndex: 'spuCode',
                key: 'spuCode'
            }, {
                title: '入库时间',
                dataIndex: 'createTime',
                key: 'createTime',
                render: time => (
                    moment(time).format('yyyy-MM-DD HH:mm:ss')
                )
            }, {
                title: '品牌',
                dataIndex: 'brandName',
                key: 'brandName',
            },  {
                title: '一级分类',
                dataIndex: 'firstCategoryName',
                key: 'firstCategoryName',
            }, {
                title: '二级分类',
                dataIndex: 'secondCategoryName',
                key: 'secondCategoryName',
            }, {
                title: '商品名称',
                dataIndex: 'productName',
                key: 'productName',
            }, {
                title: '规格',
                dataIndex: 'skuData',
                key: 'skuData',
                render: skuData => (
                    JSON.parse(skuData).map(item => <Tag>{item.key} : {item.value}</Tag>)
                )
            }, {
                title: '入库价格',
                dataIndex: 'salesPrice',
                key: 'salesPrice'
            }, {
                title: '官方价',
                dataIndex: 'officialPrice',
                key: 'officialPrice'
            }, {
                title: '累计销售',
                dataIndex: 'totoleSales',
                key: 'totoleSales'
            }, {
                title: '库存',
                dataIndex: 'stockNum',
                key: 'stockNum'
            }, {
                title: '7日平均销售',
                dataIndex: 'sevenDayAverageSales',
                key: 'sevenDayAverageSales'
            }, {
                title: '7日DOS',
                dataIndex: 'sevenDayDOS',
                key: 'sevenDayDOS'
            }, {
                title: '操作',
                dataIndex: 'action',
                key: 'action',
                render: (_, record) => (
                    <Space>
                        <Button type="link" onClick={this.startCater.bind(this, record.skuStockCode)}>恢复供应</Button>
                    </Space>
                )
            }]
        },
        history: {
            pageNum: 1,
            pageSize: 20,
            total: 0,
            list: [],
            columns: [{
                title: '编号',
                dataIndex: 'skuCode',
                key: 'skuCode',
            }, {
                title: 'spu编号',
                dataIndex: 'spuCode',
                key: 'spuCode'
            }, {
                title: '入库时间',
                dataIndex: 'createTime',
                key: 'createTime',
                render: time => (
                    moment(time).format('yyyy-MM-DD HH:mm:ss')
                )
            }, {
                title: '品牌',
                dataIndex: 'brandName',
                key: 'brandName',
            }, {
                title: '一级分类',
                dataIndex: 'firstCategoryName',
                key: 'firstCategoryName',
            }, {
                title: '二级分类',
                dataIndex: 'secondCategoryName',
                key: 'secondCategoryName',
            }, {
                title: '商品名称',
                dataIndex: 'productName',
                key: 'productName',
            }, {
                title: '规格',
                dataIndex: 'skuData',
                key: 'skuData',
                render: skuData => (
                    JSON.parse(skuData).map(item => <Tag>{item.key} : {item.value}</Tag>)
                )
            }, {
                title: '入库价格',
                dataIndex: 'salesPrice',
                key: 'salesPrice'
            }, {
                title: '官方价',
                dataIndex: 'officialPrice',
                key: 'officialPrice'
            }, {
                title: '库存',
                dataIndex: 'stockNum',
                key: 'stockNum'
            }, {
                title: '操作行为',
                dataIndex: 'historyState',
                key: 'historyState'
            }]
        },
        sku1Name: '',
        sku2Name: '',
        skuStock: [{
            attr_1: '',
            attr_2: '',
            brandName: '',
            firstCategoryName: '',
            secondCategoryName: '',
            productName: '',
            stockPrice: 0,
            spuCode: '',
            skuCode: '',
            stockTotal: 0,
            merchant: '',
            officialPrice: 0
        }],
        copyModalVis: false,
        copyModalContent: ''
    };

    componentDidMount = async _ => {
        await this.getSkuList(1);
        await this.getListByParentId(0);
    }

    componentWillReceiveProps = async props => {
        if (props.categoryId !== this.state.categoryId) {
            this.setState({
                categoryId: props.categoryId,
                tabKey: 'list'
            }, async () => await this.getSkuList(1));
        }
    }

    getListByParentId = async parentId => {
        parentId = parentId ? parentId : 0;
        let response = await getListByParentId(parentId);
        let { code, data } = response.data;
        if (code === 200) {
            if (parentId === 0) {
                this.setState({
                    firstClass: data
                });
            } else {
                this.setState({
                    secondClass: data
                });
            }
        }
    }

    onFirstClassChange = async parentId => {
        this.setState({
            selectedFirstId: parentId,
            selectedSecondId: 0
        }, async _ => {
            await this.getListByParentId(parentId);
            if (this.state.tabKey === 'list') {
                await this.getSkuList(1);
            } else if (this.state.tabKey === 'over') {
                await this.getSkuList(2);
            }
        });
    }

    onSecondClassChange = async parentId => {
        console.log(parentId)
        this.setState({
            selectedSecondId: parentId ? parentId : 0
        }, async _ => {
            if (this.state.tabKey === 'list') {
                await this.getSkuList(1);
            } else if (this.state.tabKey === 'over') {
                await this.getSkuList(2);
            }
        });
    }

    onSearchBrand = async brandId => {
        if (this.state.tabKey === 'list') {
            let list = this.state.list;
            list.search.brandId = brandId;
            list.pageNum = 1;
            this.setState({
                list: list
            }, async _ => await this.getSkuList(1));
        } else if (this.state.tabKey === 'over') {
            let over = this.state.over;
            over.search.brandId = brandId;
            over.pageNum = 1;
            this.setState({
                over: over
            }, async _ => await this.getSkuList(2));
        }
    }

    onSearchProduct = async e => {
        if (this.state.tabKey === 'list') {
            let list = this.state.list;
            list.search.productName = e.target.value;
            list.pageNum = 1;
            this.setState({
                list: list
            }, async _ => await this.getSkuList(1));
        } else if (this.state.tabKey === 'over') {
            let over = this.state.over;
            over.search.productName = e.target.value;
            over.pageNum = 1;
            this.setState({
                over: over
            }, async _ => await this.getSkuList(2));
        }
    }

    getSkuList = async type => {
        this.setState({
            loading: true
        });

        if (type === 1) {
            let params = {
                brandId: this.state.list.search.brandId,
                currentPage: this.state.list.pageNum,
                pageSize: this.state.list.pageSize,
                primaryCategoryId: this.state.selectedFirstId,
                secondCategoryId: this.state.selectedSecondId,
                productName: this.state.list.search.productName,
                type: type
            };

            let response = await getStockPageList(params);
            let { code, data } = response.data;
            this.setState({
                loading: false
            });
            if (code === 200) {
                data.list.map((item, index) => item.key = 'list_' + index);
                let list = this.state.list;
                list.total = data.total;
                list.list = data.list;
                this.setState({
                    list: list
                });
            }
        } else if (type === 2) {
            let params = {
                brandId: this.state.over.search.brandId,
                currentPage: this.state.over.pageNum,
                pageSize: this.state.over.pageSize,
                // primaryCategoryId: this.state.categoryId,
                productName: this.state.over.search.productName,
                type: type
            };

            let response = await getStockPageList(params);
            let { code, data } = response.data;
            this.setState({
                loading: false
            });
            if (code === 200) {
                data.list.map((item, index) => item.key = 'over_' + index);
                let over = this.state.over;
                over.total = data.total;
                over.list = data.list;
                this.setState({
                    over: over
                });
            }
        }
    }

    onSearchKeywordChange = e => {
        let list = this.state.list;
        list.search.keyword = e.target.value;
        this.setState({
            list: list
        });
    }

    getOverSkuList = async _ => {
        this.setState({
            loading: true
        });
        let response = await getOverSkuList(this.state.over.pageNum, this.state.over.pageSize);
        let { code, data } = response.data;
        this.setState({
            loading: false
        });
        if (code === 200) {
            data.list.map((item, index) => item.key = 'over_' + index);
            let over = this.state.over;
            over.total = data.total;
            over.list = data.list;
            this.setState({
                over: over
            });
        }
    }

    getHistorySkuList = async _ => {
        this.setState({
            loading: true
        });
        let response = await getHistorySkuList(this.state.history.pageNum, this.state.history.pageSize);
        let { code, data } = response.data;
        this.setState({
            loading: false
        });
        if (code === 200) {
            data.list.map((item, index) => item.key = 'history_' + index);
            let history = this.state.history;
            history.total = data.total;
            history.list = data.list;
            this.setState({
                history: history
            });
        }
    }

    deleteSku = async skuCode => {
        let publishStatus = await getProductPublishStatus(skuCode);
        let { code, data } = publishStatus.data;
        if (code === 200) {
            if (data) {
                if (window.confirm('该商品是上架状态，确认删除吗？')) {
                    this.setState({
                        loadingLayer: true,
                        tip: 'sku删除中'
                    });
                    let response = await deleteSku(skuCode);
                    this.setState({
                        loadingLayer: false
                    });
                    let { code } = response.data;
                    if (code === 200) {
                        message.success('删除成功');
                        await this.getSkuList(1);
                    }
                }
            } else {
                if (window.confirm('确认删除？')) {
                    this.setState({
                        loadingLayer: true,
                        tip: 'sku删除中'
                    });
                    let response = await deleteSku(skuCode);
                    this.setState({
                        loadingLayer: false
                    });
                    let { code } = response.data;
                    if (code === 200) {
                        message.success('删除成功');
                        await this.getSkuList(1);
                    }
                }
            }
        }
    }

    stopCater = async skuCode => {
        let publishStatus = await getProductPublishStatus(skuCode);
        let { code, data } = publishStatus.data;
        if (code === 200) {
            if (data) {
                if (window.confirm('该商品是上架状态，确认停止供应吗？')) {
                    this.setState({
                        loadingLayer: true,
                        tip: 'sku停止供应中'
                    });
                    let response = await stopCater(skuCode);
                    this.setState({
                        loadingLayer: false
                    });
                    let { code } = response.data;
                    if (code === 200) {
                        message.success('停止供应成功');
                        await this.getSkuList(1);
                    }
                }
            } else {
                if (window.confirm('确认停止供应？')) {
                    this.setState({
                        loadingLayer: true,
                        tip: 'sku停止供应中'
                    });
                    let response = await stopCater(skuCode);
                    this.setState({
                        loadingLayer: false
                    });
                    let { code } = response.data;
                    if (code === 200) {
                        message.success('停止供应成功');
                        await this.getSkuList(1);
                    }
                }
            }
        }
    }

    startCater = async skuCode => {
        if (window.confirm('确认恢复供应？')) {
            this.setState({
                loadingLayer: true,
                tip: 'sku恢复供应中'
            });
            let response = await stopCater(skuCode);
            this.setState({
                loadingLayer: false
            });
            let { code } = response.data;
            if (code === 200) {
                message.success('恢复供应成功');
                await this.getSkuList(1);
            }
        }
    }
    
    onPageChange = (pageNum, pageSize) => {
        let obj = this.state[this.state.tabKey];
        obj.pageNum = pageNum;
        obj.pageSize = pageSize;

        if (this.state.tabKey === 'list') {
            this.setState({
                list: obj
            }, () => this.getSkuList(1));
        } else if (this.state.tabKey === 'over') {
            this.setState({
                over: obj
            }, () => this.getSkuList(2));
        } else if (this.state.tabKey === 'history') {
            this.setState({
                history: obj
            }, () => this.getHistorySkuList());
        }
    }

    onTabChange = async key => {
        this.setState({
            tabKey: key
        });
        if (key === 'list') {
            await this.getSkuList(1);
        } else if (key === 'over') {
            await this.getSkuList(2);
        } else if (key === 'history') {
            await this.getHistorySkuList();
        }
    }

    formHandler = {
        onAddSkuItem: _ => {
            let skuStock = this.state.skuStock;
            skuStock.push({
                attr_1: '',
                attr_2: '',
                brandName: '',
                firstCategoryName: '',
                secondCategoryName: '',
                productName: '',
                stockPrice: 0,
                spuCode: '',
                skuCode: '',
                stockTotal: 0,
                merchant: '',
                officialPrice: 0
            });
            this.setState({
                skuStock: skuStock
            });
        },
        onDeleteItem: index => {
            let skuStock = this.state.skuStock;
            skuStock.splice(index, 1);
            this.setState({
                skuStock: skuStock
            });
        },
        onSku1NameChange: e => {
            this.setState({
                sku1Name: e.target.value
            });
        },
        onSku2NameChange: e => {
            this.setState({
                sku2Name: e.target.value
            });
        },
        onSpuCodeChange: (index, e) => {
            let skuStock = this.state.skuStock;
            skuStock[index].spuCode = e.target.value;
            this.setState({
                skuStock: skuStock
            });
        },
        onSkuStockCodeChange: (index, e) => {
            let skuStock = this.state.skuStock;
            skuStock[index].skuCode = e.target.value;
            this.setState({
                skuStock: skuStock
            });
        },
        onBrandNameChange: (index, e) => {
            let skuStock = this.state.skuStock;
            skuStock[index].brandName = e.target.value;
            this.setState({
                skuStock: skuStock
            });
        },
        onFirstCategoryNameChange: (index, e) => {
            let skuStock = this.state.skuStock;
            skuStock[index].firstCategoryName = e.target.value;
            this.setState({
                skuStock: skuStock
            });
        },
        onCategoryNameChange: (index, e) => {
            let skuStock = this.state.skuStock;
            skuStock[index].secondCategoryName = e.target.value;
            this.setState({
                skuStock: skuStock
            });
        },
        onProductNameChange: (index, e) => {
            let skuStock = this.state.skuStock;
            skuStock[index].productName = e.target.value;
            this.setState({
                skuStock: skuStock
            });
        },
        onAttr1ValChange: (index, e) => {
            let skuStock = this.state.skuStock;
            skuStock[index].attr_1 = e.target.value;
            this.setState({
                skuStock: skuStock
            });
        },
        onAttr2ValChange: (index, e) => {
            let skuStock = this.state.skuStock;
            skuStock[index].attr_2 = e.target.value;
            this.setState({
                skuStock: skuStock
            });
        },
        onSalesPriceChange: (index, number) => {
            let skuStock = this.state.skuStock;
            skuStock[index].stockPrice = number;
            this.setState({
                skuStock: skuStock
            });
        },
        onOfficialPriceChange: (index, number) => {
            let skuStock = this.state.skuStock;
            skuStock[index].officialPrice = number;
            this.setState({
                skuStock: skuStock
            });
        },
        onStockNumChange: (index, number) => {
            let skuStock = this.state.skuStock;
            skuStock[index].stockTotal = number;
            this.setState({
                skuStock: skuStock
            });
        },
        onSalesChange: (index, e) => {
            let skuStock = this.state.skuStock;
            skuStock[index].merchant = e.target.value;
            this.setState({
                skuStock: skuStock
            });
        },
        submit: async _ => {
            if (!this.state.sku1Name) {
                message.error('规格1名称不能为空');
                return;
            }

            let headers = {
                attr_1: this.state.sku1Name,
                attr_2: this.state.sku2Name
            };

            this.setState({
                loadingLayer: true,
                tip: 'sku创建中'
            });

            let response = await batchAddSkuStock(this.state.categoryId, headers, this.state.skuStock);
            this.setState({
                loadingLayer: false
            });
            let { code } = response.data;
            if (code === 200) {
                message.success(`创建成功`);
                await this.onTabChange('list');
                this.setState({
                    skuStock: [{
                        attr_1: '',
                        attr_2: '',
                        brandName: '',
                        firstCategoryName: '',
                        secondCategoryName: '',
                        productName: '',
                        stockPrice: 0,
                        spuCode: '',
                        skuCode: '',
                        stockTotal: 0,
                        merchant: '',
                        officialPrice: 0
                    }]
                });
            }
        }
    }

    copyModalHandler = {
        onShow: _ => {
            this.setState({
                copyModalVis: true
            });
        },
        onCancel: _ => {
            this.setState({
                copyModalVis: false,
                copyModalContent: ''
            });
        },
        onContentChange: e => {
            this.setState({
                copyModalContent: e.target.value
            });
        },
        onOk: _ => {
            let content = this.state.copyModalContent;
            let rows = content.split("\n");
            let skuStock = this.state.skuStock;
            rows.forEach(item => {
                let line = item.split("\t");
                skuStock.push({
                    attr_1: line[10],
                    attr_2: line[11],
                    brandName: line[0],
                    firstCategoryName: line[1],
                    secondCategoryName: line[2],
                    productName: line[3],
                    stockPrice: line[7],
                    spuCode: line[4],
                    skuCode: line[5],
                    stockTotal: line[9],
                    merchant: line[6],
                    officialPrice: line[8]
                });
            });
            skuStock = skuStock.filter(item => item.skuCode);
            this.setState({
                skuStock: skuStock,
                copyModalVis: false,
                copyModalContent: ''
            });
        }
    }

    render() {
        const skuInputTr = this.state.skuStock.map((item, index) => (
            <tr>
                <td><Input value={item.brandName} onChange={this.formHandler.onBrandNameChange.bind(this, index)}/></td>
                <td><Input value={item.firstCategoryName} onChange={this.formHandler.onFirstCategoryNameChange.bind(this, index)}/></td>
                <td><Input value={item.secondCategoryName} onChange={this.formHandler.onCategoryNameChange.bind(this, index)}/></td>
                <td><Input value={item.productName} onChange={this.formHandler.onProductNameChange.bind(this, index)}/></td>
                <td><Input value={item.spuCode} onChange={this.formHandler.onSpuCodeChange.bind(this, index)}/></td>
                <td><Input value={item.skuCode} onChange={this.formHandler.onSkuStockCodeChange.bind(this, index)}/></td>
                <td><Input value={item.merchant} onChange={this.formHandler.onSalesChange.bind(this, index)}/></td>
                <td><InputNumber min={0} value={item.stockPrice} onChange={this.formHandler.onSalesPriceChange.bind(this, index)}/></td>
                <td><InputNumber min={0} value={item.officialPrice} onChange={this.formHandler.onOfficialPriceChange.bind(this, index)}/></td>
                <td><InputNumber min={0} value={item.stockTotal} onChange={this.formHandler.onStockNumChange.bind(this, index)}/></td>
                <td><Input value={item.attr_1} onChange={this.formHandler.onAttr1ValChange.bind(this, index)}/></td>
                <td><Input value={item.attr_2} onChange={this.formHandler.onAttr2ValChange.bind(this, index)}/></td>
                <td><Button type="link" onClick={this.formHandler.onDeleteItem.bind(this, index)}>删除</Button></td>
            </tr>
        ));

        const firstClassOptions = this.state.firstClass.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)
        const secondClassOptions = this.state.secondClass.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)

        return (
            <div className="StockList">
                <LoadingLayer loading={this.state.loadingLayer} tip={this.state.tip}/>
                <Tabs activeKey={this.state.tabKey} onChange={this.onTabChange}>
                    <TabPane tab="库存列表" key="list">
                        <Space>
                            <BrandSelector onChange={this.onSearchBrand}/>
                            <Input placeholder="商品名称" onPressEnter={this.onSearchProduct}/>
                            <Select
                                allowClear
                                showSearch
                                style={{ width: 140 }}
                                placeholder="选择一级品类"
                                value={this.state.selectedFirstId ? parseInt(this.state.selectedFirstId) : undefined}
                                optionFilterProp="children"
                                onChange={this.onFirstClassChange}
                                filterOption={(input, option) =>
                                    option.children.toLocaleLowerCase().indexOf(input.toLocaleLowerCase()) >= 0
                                }
                            >
                                {firstClassOptions}
                            </Select>
                            <Select
                                allowClear
                                showSearch
                                style={{ width: 140 }}
                                placeholder="选择二级品类"
                                value={this.state.selectedSecondId ? parseInt(this.state.selectedSecondId) : undefined}
                                optionFilterProp="children"
                                onChange={this.onSecondClassChange}
                                filterOption={(input, option) =>
                                    option.children.toLocaleLowerCase().indexOf(input.toLocaleLowerCase()) >= 0
                                }
                            >
                                {secondClassOptions}
                            </Select>
                        </Space>
                        <br/><br/>
                        <Table
                            scroll={{ x: 1900 }}
                            loading={this.state.loading}
                            size="small"
                            columns={this.state.list.columns}
                            dataSource={this.state.list.list}
                            bordered
                            pagination={{ total: this.state.list.total, pageSize: this.state.list.pageSize, onChange: this.onPageChange }}
                        />
                    </TabPane>
                    <TabPane tab="已结束的库存" key="over">
                        <Space>
                            <BrandSelector onChange={this.onSearchBrand}/>
                            <Input placeholder="商品名称" onPressEnter={this.onSearchProduct}/>
                        </Space>
                        <br/><br/>
                        <Table
                            loading={this.state.loading}
                            size="small"
                            columns={this.state.over.columns}
                            dataSource={this.state.over.list}
                            bordered
                            pagination={{ total: this.state.over.total, pageSize: this.state.over.pageSize, onChange: this.onPageChange }}
                        />
                    </TabPane>
                    <TabPane tab="添加库存" key="add">
                        <Divider orientation="left" plain>
                            批量上传
                        </Divider>
                        <UploadFileSku categoryId={this.state.categoryId} />
                        <a
                            style={{ marginLeft: 10 }}
                            target="_blank"
                            rel="noreferrer"
                            href="https://bj-prod-1309320985.cos.ap-beijing.myqcloud.com/20211130/e2c7e42a3cfdd473c1d8d43323d1c78.xlsx"
                        >点击下载格式模版</a>
                        <br/>
                        <br/>
                        <Divider orientation="left" plain>
                            手动录入
                        </Divider>
                        <table border="1" cellPadding="10">
                            <tr>
                                <th>品牌</th>
                                <th>一级类目</th>
                                <th>二级类目</th>
                                <th>商品名称</th>
                                <th>spu编号</th>
                                <th>sku编号</th>
                                <th>商家</th>
                                <th>入库价</th>
                                <th>官方价</th>
                                <th>入库量</th>
                                <th>
                                    <Space>
                                        规格1：
                                        <Input
                                            style={{ width: 100 }}
                                            value={this.state.sku1Name}
                                            onChange={this.formHandler.onSku1NameChange}
                                        />
                                    </Space>
                                </th>
                                <th>
                                    <Space>
                                        规格2：
                                        <Input
                                            style={{ width: 100 }}
                                            value={this.state.sku2Name}
                                            onChange={this.formHandler.onSku2NameChange}
                                        />
                                    </Space>
                                </th>
                                <th>操作</th>
                            </tr>
                            {skuInputTr}
                        </table>
                        <br/>
                        <Space>
                            <Button onClick={this.copyModalHandler.onShow}>从excel中复制</Button>
                            <Button onClick={this.formHandler.onAddSkuItem}>增加一条sku</Button>
                            <Button type="primary" onClick={this.formHandler.submit}>提交</Button>
                        </Space>
                        <Modal
                            title="从excel中复制"
                            visible={this.state.copyModalVis}
                            onOk={this.copyModalHandler.onOk}
                            onCancel={this.copyModalHandler.onCancel}
                        >
                            <Input.TextArea rows={6} value={this.state.copyModalContent} onChange={this.copyModalHandler.onContentChange}/>
                        </Modal>
                    </TabPane>
                    <TabPane tab="添加记录" key="history">
                        <Table
                            loading={this.state.loading}
                            size="small"
                            columns={this.state.history.columns}
                            dataSource={this.state.history.list}
                            bordered
                            pagination={{ total: this.state.history.total, pageSize: this.state.history.pageSize, onChange: this.onPageChange }}
                        />
                    </TabPane>
                </Tabs>
            </div>
        );
    }
}

export default StockList;
