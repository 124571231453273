class UrlUtil {

    static getQueryString = (queryPath, param) => {
        let reg = new RegExp('(^|&)' + param + '=([^&]*)(&|$)', 'i');
        let r = queryPath.substr(1).match(reg);
        if (r != null) {
            return unescape(r[2]);
        }
        return null;
    }
}

export default UrlUtil;
