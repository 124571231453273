import React from 'react';
import CommonLayout from '../../components/layout/CommonLayout';
import OrderInfo from '../../components/order/OrderInfo';

class OrderDetail extends React.Component {

    state = {
        orderId: this.props.match.params.id
    };

    render() {
        return (
            <CommonLayout
                topNavSelectedKey="order"
                siderNavCategory="order"
                siderNavSelectedKey="list"
            >
                <OrderInfo value={this.state.orderId}/>
            </CommonLayout>
        );
    }
}

export default OrderDetail;
