import React from 'react';
import CommonLayout from '../../components/layout/CommonLayout';
import CouponList from '../../components/coupon/CouponList';

class Coupon extends React.Component {

    render() {
        return (
            <CommonLayout
                topNavSelectedKey="mall"
                siderNavCategory="mall"
                siderNavSelectedKey="coupon"
            >
                <CouponList/>
            </CommonLayout>
        );
    }
}

export default Coupon;
