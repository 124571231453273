import React from 'react';
import {
    Table,
    Button,
    Space,
    message,
    Row,
    Col,
    Input,
    Select
} from 'antd';
import { Link } from 'react-router-dom';
import LoadingLayer from '../common/LoadingLayer';
import { getCouponList, updateCouponStatus } from '../../api/CouponApi';

const { Option } = Select;

const couponStatusMap = {
    1: '发放中',
    2: '已领完',
    3: '停止发放'
};

class CouponList extends React.Component {

    state = {
        loading: false,
        loadingLayer: false,
        tip: '',
        showName: '',
        voucherId: 0,
        voucherCode: '',
        voucherStatus: null,
        list: [],
        total: 0,
        pageNum: 1,
        pageSize: 20,
    };

    componentDidMount = async _ => {
        await this.getCouponList();
    }

    getCouponList = async _ => {
        this.setState({
            loading: true
        });
        let response = await getCouponList(this.state.showName, this.state.voucherCode, this.state.voucherId,
            this.state.voucherStatus, this.state.pageNum, this.state.pageSize);
        let { code, data } = response.data;
        this.setState({
            loading: false
        });
        if (code === 200) {
            if (data.list) {
                data.list.map(item => item.key = item.id);
            }
            this.setState({
                total: data.total,
                list: data.list
            });
        }
    }

    onPageChange = (pageNum, pageSize) => {
        this.setState({
            pageNum: pageNum,
            pageSize: pageSize
        }, () => this.getCouponList());
    }

    updateCouponStatus = async (couponId, status) => {
        if (window.confirm('确定操作吗？')) {
            this.setState({
                loadingLayer: true,
                tip: '操作中'
            });
            let response = await updateCouponStatus(couponId, status);
            this.setState({
                loadingLayer: false
            });
            let { code } = response.data;
            if (code === 200) {
                message.success('操作成功');
                this.getCouponList();
            }
        }
    }

    searchHandler = {
        onShowNameChange: e => {
            this.setState({
                showName: e.target.value
            })
        },
        onVoucherCodeChange: e => {
            this.setState({
                voucherCode: e.target.value
            })
        },
        onVoucherStatusChange: value => {
            this.setState({
                voucherStatus: value
            })
        },
        onSearch: async _ => {
            this.setState({
                pageNum: 1
            }, async _ => await this.getCouponList())
        }
    }

    render() {
        const columns = [{
            title: '优惠券ID',
            dataIndex: 'voucherId',
            key: 'voucherId',
            align: 'center'
        }, {
            title: '优惠券code',
            dataIndex: 'voucherCode',
            key: 'voucherCode',
            align: 'center'
        }, {
            title: '优惠券名称',
            dataIndex: 'showName',
            key: 'showName',
            align: 'center'
        }, {
            title: '优惠额度',
            dataIndex: 'discountAmounts',
            key: 'discountAmounts',
            align: 'center'
        }, {
            title: '发放状态',
            dataIndex: 'voucherStatus',
            key: 'voucherStatus',
            align: 'center',
            render: voucherStatus => (
                couponStatusMap[voucherStatus]
            )
        }, {
            title: '有效时长（天）',
            dataIndex: 'useTimePlusDay',
            key: 'useTimePlusDay',
            align: 'center'
        }, {
            title: '发放数量',
            dataIndex: 'totalNum',
            key: 'totalNum',
            align: 'center'
        }, {
            title: '已领取数量',
            dataIndex: 'receivedNum',
            key: 'receivedNum',
            align: 'center'
        }, {
            title: '已使用数量',
            dataIndex: 'useNum',
            key: 'useNum',
            align: 'center'
        }, {
            title: '规则说明',
            dataIndex: 'desc',
            key: 'desc',
            align: 'center'
        }, {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
            align: 'center'
        }, {
            title: '操作',
            key: 'action',
            align: 'center',
            render: (text, record) => (
                <>
                    <Space>
                        {record.receivedNum === 0 && <Link to={`/mall/coupon/edit/${record.voucherId}`}><Button type="link">编辑</Button></Link>}
                        {record.voucherStatus === 1 && <Button type="link" onClick={this.updateCouponStatus.bind(this, record.voucherId, 0)}>停止发放</Button>}
                        {record.voucherStatus === 3 && <Button type="link" onClick={this.updateCouponStatus.bind(this, record.voucherId, 1)}>恢复发放</Button>}
                    </Space>
                </>
            )
        }];

        return (
            <>
                <LoadingLayer loading={this.state.loadingLayer} tip={this.state.tip}/>
                <Row>
                    <Col span={5}>
                        优惠券名称：<Input style={{ width: 200 }} value={this.state.showName} onChange={this.searchHandler.onShowNameChange} onPressEnter={this.searchHandler.onSearch}/>
                    </Col>
                    <Col span={5}>
                        优惠券code：<Input style={{ width: 200 }} value={this.state.voucherCode} onChange={this.searchHandler.onVoucherCodeChange} onPressEnter={this.searchHandler.onSearch}/>
                    </Col>
                    <Col span={5}>
                        优惠券状态：<Select style={{ width: 200 }} allowClear value={this.state.voucherStatus} onChange={this.searchHandler.onVoucherStatusChange}>
                        <Option value={1}>发放中</Option>
                        <Option value={2}>已领完</Option>
                        <Option value={3}>停止发放</Option>
                    </Select>
                    </Col>
                    <Col span={5}>
                        <Space>
                            <Button type="primary" onClick={this.searchHandler.onSearch}>搜索</Button>
                            <Link to="/mall/coupon/add">
                                <Button>新建优惠券</Button>
                            </Link>
                        </Space>
                    </Col>
                </Row>
                <br/>
                <Table
                    loading={this.state.loading}
                    size="small"
                    columns={columns}
                    dataSource={this.state.list}
                    bordered
                    pagination={{ total: this.state.total, pageSize: this.state.pageSize, onChange: this.onPageChange }}
                />
            </>
        );
    }
}

export default CouponList;
