import React from 'react';
import {
    Button,
    Card,
    List,
    Input
} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import {
    getList as getPlanTemplateList,
    updateSort as updateTemplateSort,
    update as updateTemplate,
    deleteInfo as deleteTemplate,
    create as createTemplate
} from '../../api/painter/PainterPagePlanTemplateApi';
import {
    getList as getTemplateList
} from '../../api/painter/PainterTemplateApi';
import { getInfo } from '../../api/painter/PainterPagePlanApi';
import LoadingLayer from '../common/LoadingLayer';
import '../../css/painter/editPainterPagePlan.scss';
import ArrayUtil from '../../util/ArrayUtil';

const { Meta } = Card;

class EditPainterPagePlan extends React.Component {

    state = {
        id: this.props.value,
        loading: false,
        tip: '',
        pagePlan: {},
        templateList: [],
        planTemplateList: [],
        selected: {
            planTemplateId: 0,
            templateCode: '',
            css: ''
        }
    };

    componentDidMount = async _ => {
        if (this.state.id) {
            this.setState({
                loading: true,
                tip: '数据加载中'
            });
            await this.getPagePlanInfo();
            await this.getTemplateList();
            await this.getPlanTemplateList(this.state.id);
            this.setState({
                loading: false
            });
        }
    }

    getPagePlanInfo = async _ => {
        let response = await getInfo(this.state.id);
        let { code, data } = response.data;
        if (code === 200) {
            this.setState({
                pagePlan: data
            });
        }
    }

    getTemplateList = async _ => {
        let response = await getTemplateList(this.state.pagePlan.pageCode, 1, 500);
        let { code, data } = response.data;
        if (code === 200) {
            this.setState({
                templateList: data.list ? data.list : []
            });
        }
    }

    getPlanTemplateList = async pagePlanId => {
        let response = await getPlanTemplateList(pagePlanId, 1, 500);
        let { code, data } = response.data;
        if (code === 200) {
            this.setState({
                planTemplateList: data.list ? data.list : []
            });
        }
    }

    onAddTemplate = async templateCode => {
        let params = {
            css: '',
            pagePlanId: this.state.id,
            sort: this.state.planTemplateList.length,
            templateCode: templateCode
        };
        this.setState({
            loading: true,
            tip: '数据创建中'
        });
        let response = await createTemplate(params);
        this.setState({
            loading: false
        });
        let { code } = response.data;
        if (code === 200) {
            await this.getPlanTemplateList(this.state.id);
        }
    }

    onDeletePlanTemplate = async id => {
        if (window.confirm('确认删除？')) {
            this.setState({
                loading: true,
                tip: '数据删除中'
            });
            let response = await deleteTemplate(id);
            this.setState({
                loading: false
            });
            let { code } = response.data;
            if (code === 200) {
                await this.getPlanTemplateList(this.state.id);
            }
        }
    }

    onShift = (type, index) => {
        let planTemplateList = this.state.planTemplateList;
        if (type === 'up') {
            ArrayUtil.upGo(planTemplateList, index);
            this.setState({
                planTemplateList: planTemplateList
            });
        } else if (type === 'down') {
            ArrayUtil.upDown(planTemplateList, index);
            this.setState({
                planTemplateList: planTemplateList
            });
        }
    }

    onSortUpdate = async _ => {
        let planTemplateList = this.state.planTemplateList;
        let params = planTemplateList.map((item, index) => {
            return {
                id: item.id,
                sort: index
            }
        });
        this.setState({
            loading: true,
            tip: '数据提交中'
        });
        let response = await updateTemplateSort(params);
        this.setState({
            loading: false
        });
        let { code } = response.data;
        if (code === 200) {
            await this.getPlanTemplateList(this.state.id);
        }
    }

    onSelectTemplate = (id, templateCode, css) => {
        this.setState({
            selected: {
                planTemplateId: id,
                templateCode: templateCode,
                css: css
            }
        });
    }

    onUpdatePlanTemplate = async (id, css) => {
        this.setState({
            loading: true,
            tip: '数据提交中'
        });
        let params = {
            css: this.state.selected.css
        };
        let response = await updateTemplate(this.state.selected.planTemplateId, params);
        this.setState({
            loading: false
        });
        let { code } = response.data;
        if (code === 200) {
            await this.getPlanTemplateList(this.state.id);
        }
    }

    onCssChange = e => {
        let selected = this.state.selected;
        selected.css = e.target.value;
        this.setState({
            selected: selected
        });
    }

    render() {
        const templates = this.state.templateList.map(item => 
            <Card
                hoverable
                actions={[<a onClick={this.onAddTemplate.bind(this, item.templateCode)}>添加</a>]}
                style={{ width: '100%', marginBottom: 20 }}
                cover={<img src={item.previewImage} />}
            >
                <Meta title={'模版名称：' + item.name} description={'模版编码：' + item.templateCode} />
            </Card>
        );

        const preview = this.state.planTemplateList.map(item => <div><img width='100%' src={item.template.previewImage} /></div>);

        return (
            <div className="EditPainterPagePlan">
                <LoadingLayer loading={this.state.loading} tip={this.state.tip}/>
                <PageHeader
                    title={`编辑页面方案：${this.state.pagePlan.name}`}
                />
                <div className="container">
                    <div className="templates">
                        <h3>模版列表</h3>
                        {templates}
                    </div>
                    <div className="plan-templates">
                        <h3>已选模版</h3>
                        <List
                            itemLayout="horizontal"
                            dataSource={this.state.planTemplateList}
                            renderItem={(item, index) => {
                                let actions = [<a onClick={this.onDeletePlanTemplate.bind(this, item.id)} style={{ color: "red" }}>删除</a>];
                                if (index === 0) {
                                    actions.push(<a onClick={this.onShift.bind(this, 'down', index)}>下移</a>);
                                } else if (index === this.state.planTemplateList.length - 1) {
                                    actions.push(<a onClick={this.onShift.bind(this, 'up', index)}>上移</a>);
                                } else {
                                    actions.push(<a onClick={this.onShift.bind(this, 'up', index)}>上移</a>);
                                    actions.push(<a onClick={this.onShift.bind(this, 'down', index)}>下移</a>);
                                }
                                

                                return (
                                    <List.Item
                                        actions={actions}
                                    >
                                        <List.Item.Meta
                                            style={{ cursor: 'pointer' }}
                                            title={item.template.name}
                                            description={item.template.templateCode}
                                            onClick={this.onSelectTemplate.bind(this, item.id, item.templateCode, item.css)}
                                        />
                                    </List.Item>
                                )
                            }}
                        />
                        <br/>
                        <Button type="primary" onClick={this.onSortUpdate}>提交排序</Button>
                    </div>
                    <div className="preview">
                        <h3>预览效果</h3>
                        <div className='content'>
                            {preview}
                        </div>
                    </div>
                    <div className="plan-template-config">
                        <h3>模版参数</h3>
                        <div style={{ display: this.state.selected.planTemplateId ? 'block' : 'none' }}>
                            <div>当前模版：{this.state.selected.templateCode}</div>
                            <br/>
                            <Input.TextArea
                                placeholder="css样式"
                                autoSize={{ minRows: 4, maxRows: 20 }}
                                value={this.state.selected.css}
                                onChange={this.onCssChange}
                            />
                            <br/><br/>
                            <Button type="primary" onClick={this.onUpdatePlanTemplate}>保存</Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default EditPainterPagePlan;
