import React from 'react';
import {
    Table,
    Tag,
    Button,
    Space,
    Modal,
    Form,
    Input,
    message
} from 'antd';
import LoadingLayer from '../common/LoadingLayer';
import {
    getChannelList,
    addChannel,
    updateChannel,
    changeChannelEnabledStatus
} from '../../api/ChannelApi';

class ChannelList extends React.Component {

    state = {
        loadingLayer: false,
        tip: '',
        loading: false,
        list: [],
        total: 0,
        pageNum: 1,
        pageSize: 20,
        modalVis: false,
        modalType: 1,
        modalTitle: '新建渠道',
        modalParams: {
            id: 0,
            title: ''
        }
    };

    componentDidMount = async _ => {
        await this.getChannelList();
    }

    getChannelList = async _ => {
        this.setState({
            loading: true
        });
        let response = await getChannelList('', this.state.pageNum, this.state.pageSize);
        let { code, data } = response.data;
        this.setState({
            loading: false
        });
        if (code === 200) {
            data.list.map(item => item.key = item.id);
            this.setState({
                total: data.total,
                list: data.list
            });
        }
    }

    onPageChange = (pageNum, pageSize) => {
        this.setState({
            pageNum: pageNum,
            pageSize: pageSize
        }, () => this.getChannelList());
    }

    changeChannelEnabledStatus = async (id, enabledStatus) => {
        this.setState({
            loadingLayer: true,
            tip: '提交中'
        });
        let response = await changeChannelEnabledStatus(id, enabledStatus);
        this.setState({
            loadingLayer: false
        });
        let { code } = response.data;
        if (code === 200) {
            await this.getChannelList();
        }
    }

    onModalShow = (title, type, record) => {
        this.setState({
            modalTitle: title,
            modalVis: true,
            modalType: type,
            modalParams: {
                id: record ? record.channelId : 0,
                title: record ? record.title : ''
            }
        });
    }

    formHandler = {
        onTitleChange: e => {
            let params = this.state.modalParams;
            params.title = e.target.value;
            this.setState({
                modalParams: params
            });
        },
        onSubmit: async _ => {
            this.setState({
                loadingLayer: true,
                tip: '提交中'
            });
            let response;
            if (this.state.modalType === 1) {
                response = await addChannel(this.state.modalParams.title);
            } else {
                response = await updateChannel(this.state.modalParams.id, this.state.modalParams.title);
            }
        
            this.setState({
                loadingLayer: false
            });
            let { code } = response.data;
            if (code === 200) {
                message.success('数据提交成功');
                this.getChannelList();
                this.setState({
                    modalVis: false,
                    modalParams: {
                        title: ''
                    }
                });
            }
        }
    };

    render() {
        const columns = [{
            title: '渠道ID',
            dataIndex: 'channelId',
            key: 'channelId',
            align: 'center'
        }, {
            title: '渠道名称',
            dataIndex: 'title',
            key: 'title',
            align: 'center'
        }, {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
            align: 'center'
        }, {
            title: '启用状态',
            dataIndex: 'enabledStatus',
            key: 'enabledStatus',
            align: 'center',
            render: enabledStatus => (
                enabledStatus ? <Tag color="#87d068">启用</Tag> : <Tag color="#f50">禁用</Tag>
            )
        }, {
            title: '操作人',
            dataIndex: 'operator',
            key: 'operator',
            align: 'center'
        }, {
            title: '操作',
            key: 'action',
            align: 'center',
            render: (text, record) => (
                <>
                    <Space>
                        <Button type="link" onClick={this.onModalShow.bind(this, '编辑渠道', 2, record)}>编辑</Button>
                        {record.enabledStatus ? 
                            <Button type="link" onClick={this.changeChannelEnabledStatus.bind(this, record.channelId, 0)} style={{ color: 'red' }}>禁用</Button> :
                            <Button type="link" onClick={this.changeChannelEnabledStatus.bind(this, record.channelId, 1)}>启用</Button>
                        }
                    </Space>
                </>
            )
        }];

        return (
            <>
                <LoadingLayer loading={this.state.loadingLayer} tip={this.state.tip}/>
                <Button type="primary" onClick={this.onModalShow.bind(this, '新建渠道', 1, null)}>新建渠道</Button>
                <br/><br/>
                <Table
                    loading={this.state.loading}
                    size="small"
                    columns={columns}
                    dataSource={this.state.list}
                    bordered
                    pagination={{ total: this.state.total, pageSize: this.state.pageSize, onChange: this.onPageChange }}
                />

                <Modal
                    title={this.state.modalTitle}
                    visible={this.state.modalVis}
                    onOk={this.formHandler.onSubmit}
                    onCancel={() => this.setState({modalVis: false})}
                >
                    <Form
                        labelCol={{ span: 4 }}
                        wrapperCol={{ span: 10 }}
                        layout="horizontal"
                        style={{ marginTop: 10 }}
                    >
                        <Form.Item label="渠道名称">
                            <Input value={this.state.modalParams.title} onChange={this.formHandler.onTitleChange}/>
                        </Form.Item>
                    </Form>
                </Modal>
            </>
        );
    }
}

export default ChannelList;
