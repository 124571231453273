import RequestUtil from "../util/request/RequestUtil";

export function getList(type = 1, pageNum = 1, pageSize = 5) {
    return RequestUtil.getInstance().get(`/promotion/list?type=${type}&pageNum=${pageNum}&pageSize=${pageSize}`);
}

export function triggerOnlineStatus(id) {
    return RequestUtil.getInstance().put(`/promotion/onlineStatus/${id}`);
}

export function deleteInfo(id) {
    return RequestUtil.getInstance().delete(`/promotion/info/${id}`);
}

export function createInfo(params) {
    let data = {
        title: params.title,
        type: params.type,
        startTime: params.startTime ? params.startTime : null,
        endTime: params.endTime ? params.endTime :null
    };
    return RequestUtil.getInstance().post(`/promotion/info`, data);
}

export function updateInfo(id, params) {
    let data = {
        title: params.title,
        startTime: params.startTime,
        endTime: params.endTime
    };
    return RequestUtil.getInstance().put(`/promotion/info/${id}`, data);
}

export function getInfo(id) {
    return RequestUtil.getInstance().get(`/promotion/info/${id}`);
}

export function addProduct(id, params) {
    let data = {
        productId: params.productId,
        promotionId: id,
        type: params.type,
        tabId: params.tabId
    };
    return RequestUtil.getInstance().post(`/promotion/addProduct`, data);
}

export function deleteProduct(id, params) {
    let data = {
        productId: params.productId,
        promotionId: id,
        tabId: params.tabId,
        type: params.type
    };
    return RequestUtil.getInstance().delete(`/promotion/deleteProduct`, {data: data});
}

export function sortProducts(id, tabId, listParams) {
    let sortList = [];
    listParams.forEach(item => {
        sortList.push({
            productId: item.productId,
            type: item.type,
            sort: item.sort,
            jumpType: item.jumpType,
            promotionProductId: item.promotionProductId
        });
    });
    let data = {
        promotionId: id,
        sortList: sortList,
        tabId: tabId
    };
    
    return RequestUtil.getInstance().post(`/promotion/sortProducts`, data);
}

export function copyInfo(id) {
    return RequestUtil.getInstance().get(`/promotion/copy/${id}`);
}

export function editTabs(id, params) {
    let tabs = params.map(item => {
        return {
            id: item.id,
            score: item.score,
            title: item.title,
            isHome: item.isHome,
            icon: item.icon
        }
    });

    let data = {
        promotionId: id,
        tabs: tabs
    };
    return RequestUtil.getInstance().post(`/promotionTab/editTab`, data);
}

export function getTabs(id) {
    return RequestUtil.getInstance().get(`/promotionTab/${id}`);
}

export function deleteTab(tabId) {
    return RequestUtil.getInstance().delete(`/promotionTab/logicDelete/${tabId}`);
}

export function getTabProducts(id, tabId) {
    return RequestUtil.getInstance().get(`/promotion/getProducts?promotionId=${id}&tabId=${tabId}`);
}

export function batchAddProduct(params) {
    let data = {
        productIds: params.productIds ? params.productIds : [],
        promotionId: params.promotionId,
        spuCodes: params.spuCodes ? params.spuCodes : [],
        sort: 0,
        tabId: params.tabId,
        type: params.type
    }

    if (params.floorInfo) {
        data.floorInfo = {
            floorLabels: params.floorInfo.floorLabels,
            floorLabelsTitle: params.floorInfo.floorLabelsTitle,
            sort: params.floorInfo.sort,
            styleEnable: params.floorInfo.styleEnable,
            title: params.floorInfo.title
        }

        if (params.floorInfo.floorId) {
            data.floorInfo.floorId = params.floorInfo.floorId
        }
    }
    
    return RequestUtil.getInstance().post(`/promotion/batchAddProduct`, data);
}

export function addOperation(params) {
    let data = {
        image: params.image,
        jumpUrl: params.jumpUrl,
        promotionId: params.promotionId,
        sort: params.sort,
        tabId: params.tabId,
        type: params.type
    }
    return RequestUtil.getInstance().post(`/promotion/addOperation`, data);
}

export function updateOperation(id, params) {
    let data = {
        image: params.image,
        jumpUrl: params.jumpUrl,
        promotionProductId: id,
        sort: params.sort
    }
    return RequestUtil.getInstance().post(`/promotion/editOperation`, data);
}

export function deleteTouchBarContent(id) {
    return RequestUtil.getInstance().delete(`/promotion/deleteTouchBarContent?promotionProductId=${id}`);
}

export function deleteFloor(id) {
    return RequestUtil.getInstance().delete(`/promotion/deleteFloor?floorId=${id}`);
}

export function exportProduct(params) {
    let queryString = '';
    if (params.promotionId) {
        queryString += `&promotionId=${params.promotionId}`;
    }
    if (params.tabId) {
        queryString += `&tabId=${params.tabId}`;
    }
    if (params.type || params.type === 0) {
        queryString += `&type=${params.type}`;
    }
    if (params.floorId) {
        queryString += `&floorId=${params.floorId}`;
    }

    return RequestUtil.getInstance().get(`/promotion/exportProduct?${queryString}`, {responseType: 'blob'});
}

export function addFloor(params) {
    let data = {
        title: params.title,
        styleEnable: params.styleEnable,
        floorLabelsTitle: params.floorLabelsTitle,
        floorLabels: params.floorLabels,
        tabId: params.tabId
    }
    return RequestUtil.getInstance().post(`/promotion/floor`, data);
}

export function updateFloor(floorId, params) {
    let data = {
        title: params.title,
        styleEnable: params.styleEnable,
        floorLabelsTitle: params.floorLabelsTitle,
        floorLabels: params.floorLabels,
        tabId: params.tabId
    }
    return RequestUtil.getInstance().put(`/promotion/floor/${floorId}`, data);
}

export function getFloorList(tabId) {
    return RequestUtil.getInstance().get(`/promotion/floor/list?tabId=${tabId}`);
}

export function getFloorProducts(floorId) {
    return RequestUtil.getInstance().get(`/promotion/floor/product/list?floorId=${floorId}`);
}

export function updateFloorProducts(floorId, productIds) {
    let data = {
        floorId: floorId,
        productIds: productIds
    };
    return RequestUtil.getInstance().post(`/promotion/floor/products`, data);
}

export function sortFloors(params) {
    let data = [];
    params.forEach(item => data.push({
        floorId: item.floorId,
        sort: item.sort
    }));
    return RequestUtil.getInstance().put(`/promotion/floor/sort`, data);
}
