import React from 'react';
import CommonLayout from '../../components/layout/CommonLayout';
import AddMallProductAttribute from '../../components/mall/AddProductAttribute';
import UpdateMallProductAttribute from '../../components/mall/UpdateProductAttribute';

class MallProductAttributeEdit extends React.Component {

    state = {
        categoryId: this.props.match.params.id
    };

    render() {
        return (
            <CommonLayout
                topNavSelectedKey="mall"
                siderNavCategory="mall"
                siderNavSelectedKey="product_attribute"
            >
                {this.props.location.pathname === '/mall/product_attribute/add' ?
                    <AddMallProductAttribute
                        onSuccess={data => this.props.history.push(`/mall/product_attribute?tab=attribute&category=${data.categoryId}&type=${data.type}`)}
                    /> :
                    <UpdateMallProductAttribute
                        value={this.state.categoryId}
                        onSuccess={data => this.props.history.push(`/mall/product_attribute?tab=attribute&category=${data.categoryId}&type=${data.type}`)}
                    />
                }
            </CommonLayout>
        );
    }
}

export default MallProductAttributeEdit;
