import React from 'react';
import {
    message,
    Table,
    Space,
    Button,
    Row,
    Col,
    Input,
    Select,
    Modal,
    Form,
    InputNumber
} from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';
import LoadingLayer from '../common/LoadingLayer';
import ChannelSelector from '../selector/ChannelSelector';
import EventSelector from '../selector/EventSelector';
import { getActivityPageList, deleteActivityPage } from '../../api/ActivityPageApi';
import { generateActivityPageLink } from '../../api/WechatLinkApi';

const { Option } = Select;
const { TextArea } = Input;

class ActivityPageList extends React.Component {

    state = {
        loading: false,
        loadingLayer: false,
        tip: '',
        list: [],
        pageNum: 1,
        pageSize: 20,
        total: 0,
        activityId: '',
        innerName: '',
        activityStatus: null,
        channelLinkVis: false,
        channelLinkParams: {
            linkType: 'url_scheme',
            activityPageId: 0,
            expireInterval: 30,
            channelId: '',
            eventId: '',
            title: '',
            result: ''
        }
    };

    componentDidMount = async _ => {
        await this.getPageList();
    }

    getPageList = async _ => {
        this.setState({
            loading: true
        });
        let response = await getActivityPageList(this.state.pageNum, this.state.pageSize,
            this.state.activityId, this.state.innerName, this.state.activityStatus);
        let { code, data } = response.data;
        this.setState({
            loading: false
        });
        if (code === 200) {
            data.list.map(item => item.key = item.id);
            this.setState({
                total: data.total,
                list: data.list
            });
        }
    }

    onPageChange = (pageNum, pageSize) => {
        this.setState({
            pageNum: pageNum,
            pageSize: pageSize
        }, () => this.getPageList());
    }

    searchHandler = {
        onActivityIdChange: e => {
            this.setState({
                activityId: e.target.value
            })
        },
        onInnerNameChange: e => {
            this.setState({
                innerName: e.target.value
            })
        },
        onActivityStatusChange: value => {
            this.setState({
                activityStatus: value
            })
        }
    }

    deleteActivityPage = async id => {
        if (window.confirm('确认删除？')) {
            this.setState({
                loadingLayer: true,
                tip: '数据删除中'
            })
            let response = await deleteActivityPage(id)
            let { code } = response.data
            this.setState({
                loadingLayer: false
            })

            if (code === 200) {
                message.success('删除成功');
                await this.getPageList();
            }
        }
    }

    onChannelLinkModalShow = record => {
        this.setState({
            channelLinkVis: true,
            channelLinkParams: {
                linkType: 'url_scheme',
                activityPageId: record.id,
                expireInterval: 30,
                channelId: '',
                eventId: '',
                title: record.title,
                result: ''
            }
        });
    }

    channelLinkFormHandler = {
        onLinkTypeChange: key => {
            let channelLinkParams = this.state.channelLinkParams;
            channelLinkParams.linkType = key;
            this.setState({
                channelLinkParams: channelLinkParams
            });
        },
        onExpireIntervalChange: number => {
            let channelLinkParams = this.state.channelLinkParams;
            channelLinkParams.expireInterval = number;
            this.setState({
                channelLinkParams: channelLinkParams
            });
        },
        onTitleChange: e => {
            let channelLinkParams = this.state.channelLinkParams;
            channelLinkParams.title = e.target.value;
            this.setState({
                channelLinkParams: channelLinkParams
            });
        },
        onChannelChange: key => {
            let channelLinkParams = this.state.channelLinkParams;
            channelLinkParams.channelId = key;
            this.setState({
                channelLinkParams: channelLinkParams
            });
        },
        onEventChange: key => {
            let channelLinkParams = this.state.channelLinkParams;
            channelLinkParams.eventId = key;
            this.setState({
                channelLinkParams: channelLinkParams
            });
        },
        onSubmit: async _ => {
            let channelLinkParams = this.state.channelLinkParams;
            let response = await generateActivityPageLink(channelLinkParams);
            let { code, data } = response.data;
            if (code === 200) {
                channelLinkParams.result = data;
                this.setState({
                    channelLinkParams: channelLinkParams
                });
            }
        }
    }

    render() {
        const columns = [{
            title: 'ID',
            dataIndex: 'id',
            key: 'id'
        }, {
            title: '活动名称',
            dataIndex: 'innerName',
            key: 'innerName'
        }, {
            title: '页面标题',
            dataIndex: 'title',
            key: 'title'
        }, {
            title: '活动状态',
            dataIndex: 'activityStatusStr',
            key: 'activityStatusStr'
        }, {
            title: '小程序路径',
            dataIndex: 'id',
            key: 'id',
            render: id => <p>{`/pages/activity/page?activityPageId=${id}`}</p>
        }, {
            title: '创建时间',
            dataIndex: 'startTime',
            key: 'startTime',
            render: time => (
                <>{time ? moment(time).format('yyyy-MM-DD HH:mm:ss') : ''}</>
            )
        }, {
            title: '修改时间',
            dataIndex: 'endTime',
            key: 'endTime',
            render: time => (
                <>{time ? moment(time).format('yyyy-MM-DD HH:mm:ss') : ''}</>
            )
        }, {
            title: '操作',
            key: 'action',
            fixed: 'right',
            render: (text, record) => (
              <Space size="middle">
                <Link to={`/mall/activity/edit/${record.id}`}><Button type="link">编辑</Button></Link>
                <Button type="link" onClick={this.onChannelLinkModalShow.bind(this, record)}>投放链接</Button>
                <Button type="link" style={{color: 'red'}} onClick={this.deleteActivityPage.bind(this, record.id)}>删除</Button>
              </Space>
            )
        }];

        return (
            <>
                <LoadingLayer loading={this.state.loadingLayer} tip={this.state.tip}/>
                <Row>
                    <Col span={5}>
                        活动id：<Input style={{ width: 200 }} value={this.state.activityId} onChange={this.searchHandler.onActivityIdChange} onPressEnter={this.getPageList}/>
                    </Col>
                    <Col span={5}>
                        活动名称：<Input style={{ width: 200 }} value={this.state.innerName} onChange={this.searchHandler.onInnerNameChange} onPressEnter={this.getPageList}/>
                    </Col>
                    <Col span={5}>
                        活动状态：<Select style={{ width: 200 }} allowClear value={this.state.activityStatus} onChange={this.searchHandler.onActivityStatusChange}>
                            <Option value={0}>未开始</Option>
                            <Option value={1}>进行中</Option>
                            <Option value={2}>已结束</Option>
                        </Select>
                    </Col>
                    <Col span={5}>
                        <Space>
                            <Button type="primary" onClick={this.getPageList}>搜索</Button>
                            <Link to={"/mall/activity/add"}><Button>新建活动页面</Button></Link>
                        </Space>
                    </Col>
                </Row>
                <br/>
                <Table
                    loading={this.state.loading}
                    size="small"
                    columns={columns}
                    dataSource={this.state.list}
                    bordered
                    pagination={{ total: this.state.total, pageSize: this.state.pageSize, onChange: this.onPageChange }}
                />

                <Modal
                    title="生成渠道链接"
                    visible={this.state.channelLinkVis}
                    onOk={this.channelLinkFormHandler.onSubmit}
                    onCancel={() => this.setState({channelLinkVis: false})}
                >
                    <Form
                        labelCol={{ span: 4 }}
                        wrapperCol={{ span: 18 }}
                        layout="horizontal"
                        style={{ marginTop: 10 }}
                    >
                        <Form.Item label="链接类型">
                            <Select
                                value={this.state.channelLinkParams.linkType}
                                onChange={this.channelLinkFormHandler.onLinkTypeChange}
                            >
                                <Option key="url_scheme">URL Scheme</Option>
                                <Option key="url_link">URL Link</Option>
                                <Option key="short_link">Short Link</Option>
                                <Option key="qrcode">小程序码</Option>
                            </Select>
                        </Form.Item>
                        {this.state.channelLinkParams.linkType !== 'qrcode' &&
                            <Form.Item label="过期天数">
                                <InputNumber
                                    min={1}
                                    max={180}
                                    value={this.state.channelLinkParams.expireInterval}
                                    onChange={this.channelLinkFormHandler.onExpireIntervalChange}
                                />
                            </Form.Item>
                        }
                        {this.state.channelLinkParams.linkType === 'short_link' &&
                            <Form.Item label="标题">
                                <Input
                                    style={{ width: 300 }}
                                    value={this.state.channelLinkParams.title}
                                    onChange={this.channelLinkFormHandler.onTitleChange}
                                />
                            </Form.Item>
                        }
                        <Form.Item label="渠道">
                            <ChannelSelector
                                value={this.state.channelLinkParams.channelId}
                                onChange={this.channelLinkFormHandler.onChannelChange}
                            />
                        </Form.Item>
                        {this.state.channelLinkParams.channelId &&
                            <Form.Item label="事件">
                                <EventSelector
                                    channelId={this.state.channelLinkParams.channelId}
                                    value={this.state.channelLinkParams.eventId}
                                    onChange={this.channelLinkFormHandler.onEventChange}
                                />
                            </Form.Item>
                        }
                        <Form.Item label="生成结果">
                            <TextArea
                                disabled
                                value={this.state.channelLinkParams.result}
                            />
                        </Form.Item>
                    </Form>
                </Modal>
            </>
        );
    }
}

export default ActivityPageList;
