import React from 'react';
import {
    Col,
    Row,
    Button,
    Input,
    Table,
    Space,
    Tag,
    Modal,
    Form
} from 'antd';
import { getList, updateStatus, updatePassword } from '../../api/AdminApi';
import moment from 'moment';
import { Link } from 'react-router-dom';
import LoadingLayer from '../common/LoadingLayer';
import '../../css/user/adminList.scss';

class AdminList extends React.Component {

    state = {
        loading: false,
        loadingLayer: false,
        tip: '',
        username: '',
        list: [],
        pageNum: 1,
        pageSize: 20,
        total: 0,
        passVis: false,
        newPass: '',
        oldPass: '',
        passUsername: ''
    };

    componentDidMount = async _ => {
        await this.getAdminList();
    }

    onUsernameChange = e => {
        this.setState({
            username: e.target.value
        });
    }

    getAdminList = async _ => {
        this.setState({
            loading: true
        });
        let response = await getList(this.state.username, this.state.pageNum, this.state.pageSize);
        let { code, data } = response.data;
        if (code === 200) {
            data.list.map(item => item.key = item.id);
            this.setState({
                total: data.total,
                list: data.list,
                loading: false
            });
        }
    }

    updateStatus = async (userId, status) => {
        this.setState({
            loadingLayer: true,
            tip: '状态更新中'
        });
        let response = await updateStatus(userId, status);
        let { code } = response.data;
        this.setState({
            loadingLayer: false
        });
        if (code === 200) {
            await this.getAdminList();
        }
    }

    onPageChange = (pageNum, pageSize) => {
        this.setState({
            pageNum: pageNum,
            pageSize: pageSize
        }, () => this.getAdminList());
    }

    onOldPassChange = e => {
        this.setState({
            oldPass: e.target.value
        })
    }

    onNewPassChange = e => {
        this.setState({
            newPass: e.target.value
        })
    }

    onPassOk = async _ => {
        this.setState({
            loadingLayer: true,
            tip: '密码更新中'
        });
        let response = await updatePassword(this.state.passUsername, this.state.newPass, this.state.oldPass);
        let { code } = response.data;
        this.setState({
            loadingLayer: false
        });
        if (code === 200) {
            this.onPassCancel()
            await this.getAdminList();
        }
    }

    onPassCancel = _ => {
        this.setState({
            passUsername: '',
            oldPass: '',
            newPass: '',
            passVis: false
        })
    }

    render() {
        const columns = [{
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        }, {
            title: '用户名（账号）',
            dataIndex: 'username',
            key: 'username',
        }, {
            title: '昵称',
            dataIndex: 'nickName',
            key: 'nickName',
        }, {
            title: '备注',
            dataIndex: 'note',
            key: 'note',
        }, {
            title: 'email',
            dataIndex: 'email',
            key: 'email',
        }, {
            title: '账号状态',
            dataIndex: 'status',
            key: 'status',
            render: status => (
                status === 1 ? <Tag color="#87d068">正常</Tag> : <Tag color="#f50">禁用</Tag>
            )
        }, {
            title: '最后登录时间',
            dataIndex: 'loginTime',
            key: 'loginTime',
            render: text => (
                text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : '--'
            )
        }, {
            title: '操作',
            key: 'action',
            render: (text, record) => (
              <Space size="middle">
                <Link to={`/user/admins/edit/${record.id}`}><Button type="link">编辑</Button></Link>
                <Button type="link" onClick={() => this.setState({passVis: true, passUsername: record.username})}>修改密码</Button>
                {record.status === 1 ? 
                    <Button type="link" onClick={this.updateStatus.bind(this, record.id, 0)} style={{ color: 'red' }}>禁用</Button> :
                    <Button type="link" onClick={this.updateStatus.bind(this, record.id, 1)}>启用</Button>
                }
              </Space>
            ),
        }];

        return (
            <div className="AdminList">
                <LoadingLayer loading={this.state.loadingLayer} tip={this.state.tip}/>
                <Row className="search-box">
                    <Col>
                        用户名：<Input value={this.state.username} onChange={this.onUsernameChange}/>
                    </Col>
                    <Col>
                        <Space>
                            <Button type="primary" onClick={this.getAdminList}>搜索</Button>
                            <Link to="/user/admins/add">
                                <Button>新建用户</Button>
                            </Link>
                        </Space>
                    </Col>
                </Row>

                <br/>
                <Table
                    loading={this.state.loading}
                    size="small"
                    columns={columns}
                    dataSource={this.state.list}
                    bordered
                    pagination={{ total: this.state.total, pageSize: this.state.pageSize, onChange: this.onPageChange }}
                />

                <Modal
                    title="修改密码"
                    visible={this.state.passVis}
                    onOk={this.onPassOk}
                    onCancel={this.onPassCancel}
                >
                    <Form
                        labelCol={{ span: 4 }}
                        layout="horizontal"
                        style={{ marginTop: 10 }}
                    >
                        <Form.Item label="原密码">
                            <Input.Password value={this.state.oldPass} onChange={this.onOldPassChange}/>
                        </Form.Item>
                        <Form.Item label="新密码">
                            <Input.Password value={this.state.newPass} onChange={this.onNewPassChange}/>
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        );
    }
}

export default AdminList;
