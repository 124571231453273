import RequestUtil from "../../util/request/RequestUtil";

export function create(params) {
    let data = {
        pageCode: params.pageCode,
        modelId: params.modelId,
        name: params.name,
        previewImage: params.previewImage,
        protocolScript: params.protocolScript,
        protocolType: params.protocolType,
        templateCode: params.templateCode
    };
    return RequestUtil.getInstance().post('/painter/template', data);
}

export function update(id, params) {
    let data = {
        pageCode: params.pageCode,
        modelId: params.modelId,
        name: params.name,
        previewImage: params.previewImage,
        protocolScript: params.protocolScript,
        protocolType: params.protocolType,
        templateCode: params.templateCode
    };
    return RequestUtil.getInstance().put(`/painter/template/${id}`, data);
}

export function triggerStatus(id) {
    return RequestUtil.getInstance().put(`/painter/template/triggerStatus/${id}`);
}

export function getInfo(id) {
    return RequestUtil.getInstance().get(`/painter/template/${id}`);
}

export function getList(pageCode = '', pageNum = 1, pageSize = 10) {
    return RequestUtil.getInstance().get(`/painter/template/list?pageCode=${pageCode}&pageNum=${pageNum}&pageSize=${pageSize}`);
}
