import RequestUtil from "../util/request/RequestUtil";

export function getList(params = {}, pageNum = 1, pageSize = 5) {
    let queryString = '';
    if (params.orderSn) {
        queryString += `&orderSn=${params.orderSn}`;
    }
    if (params.orderType || params.orderType === 0) {
        queryString += `&orderType=${params.orderType}`;
    }
    if (params.receiverKeyword) {
        queryString += `&receiverKeyword=${params.receiverKeyword}`;
    }
    if (params.sourceType || params.sourceType === 0) {
        queryString += `&sourceType=${params.sourceType}`;
    }
    if (params.status || params.status === 0) {
        queryString += `&status=${params.status}`;
    }
    if (params.startTime) {
        queryString += `&startTime=${params.startTime}`;
    }
    if (params.endTime) {
        queryString += `&endTime=${params.endTime}`;
    }
    return RequestUtil.getInstance().get(`/order/list?pageNum=${pageNum}&pageSize=${pageSize}${queryString}`);
}

export function getDetail(id) {
    return RequestUtil.getInstance().get(`/order/${id}`);
}

export function updateNote(id, note, status) {
    return RequestUtil.getInstance().post(`/order/update/note?id=${id}&note=${note}&status=${status}`);
}

export function updateReceiverInfo(params) {
    let data = {
        orderId: params.orderId,
        receiverCity: params.receiverCity,
        receiverDetailAddress: params.receiverDetailAddress,
        receiverName: params.receiverName,
        receiverPhone: params.receiverPhone,
        receiverPostCode: params.receiverPostCode,
        receiverProvince: params.receiverProvince,
        receiverRegion: params.receiverRegion,
        status: params.status
    };
    return RequestUtil.getInstance().post(`/order/update/receiverInfo`, data);
}

export function updateDelivery(params) {
    let data = [{
        deliveryCompany: params.deliveryCompany,
        deliverySn: params.deliverySn,
        orderId: params.orderId
    }];
    return RequestUtil.getInstance().post(`/order/update/delivery`, data);
}

export function getDeliveryCompanyList() {
    return RequestUtil.getInstance().get(`/order/delivery`);
}

export function orderExport(params = {}) {
    let queryString = '';
    if (params.orderSn) {
        queryString += `&orderSn=${params.orderSn}`;
    }
    if (params.orderType || params.orderType === 0) {
        queryString += `&orderType=${params.orderType}`;
    }
    if (params.receiverKeyword) {
        queryString += `&receiverKeyword=${params.receiverKeyword}`;
    }
    if (params.sourceType || params.sourceType === 0) {
        queryString += `&sourceType=${params.sourceType}`;
    }
    if (params.status || params.status === 0) {
        queryString += `&status=${params.status}`;
    }
    if (params.startTime) {
        queryString += `&startTime=${params.startTime}`;
    }
    if (params.endTime) {
        queryString += `&endTime=${params.endTime}`;
    }
    return RequestUtil.getInstance().post(`/order/orderexecle?${queryString}`)
}

export function refundConfirm(params) {
    let data = {
        confirmDesc: params.confirmDesc ? params.confirmDesc : '',
        confirmStatus: params.confirmStatus,
        orderId: params.orderId,
        touchStatus: params.touchStatus ? params.touchStatus : 0
    };
    return RequestUtil.getInstance().patch(`/order/return/confirm`, data);
}

export function refundFollowMerchant(params) {
    let data = {
        merchantStatus: params.merchantStatus,
	    orderId: params.orderId
    }
    return RequestUtil.getInstance().patch(`/order/return/followMerchant`, data);
}

export function refundDone(orderId) {
    return RequestUtil.getInstance().patch(`/order/return/done?orderId=${orderId}`);
}
