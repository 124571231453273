import React from 'react';
import { Select } from 'antd';
import { getChannelDict } from '../../api/ChannelApi';

const { Option } = Select;

class ChannelMultiSelector extends React.Component {

    state = {
        channelList: [],
        selectedIds: []
    };

    componentDidMount = async _ => {
        if (this.props.value) {
            this.setState({
                selectedIds: this.props.value
            });
        }
        await this.getChannelDict();
    }

    componentWillReceiveProps = async props => {
        if (props.value) {
            this.setState({
                selectedIds: props.value
            });
        }
    }

    getChannelDict = async _ => {
        let response = await getChannelDict();
        let { code, data } = response.data;
        if (code === 200) {
            this.setState({
                channelList: data
            });
        }
    }

    onIdChange = ids => {
        this.setState({
            selectedIds: ids
        });
        if (this.props.onChange) {
            this.props.onChange(ids);
        }
    }

    render() {
        const options = this.state.channelList.map(item => <Option key={item.key} value={item.key}>{item.value}</Option>)

        return (
            <>
                <Select
                    allowClear
                    showSearch
                    mode="tags"
                    maxTagCount={3}
                    style={{ width: this.props.width ? this.props.width : 300 }}
                    placeholder="选择渠道"
                    value={this.state.selectedIds ? this.state.selectedIds : []}
                    optionFilterProp="children"
                    onChange={this.onIdChange}
                    filterOption={(input, option) =>
                        option.children.toLocaleLowerCase().indexOf(input.toLocaleLowerCase()) >= 0
                    }
                >
                    {options}
                </Select>
            </>
        );
    }
}

export default ChannelMultiSelector;
