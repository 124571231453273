import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import App from './App';
import Login from './pages/Login';
import Admins from './pages/user/Admins';
import AdminEdit from './pages/user/AdminEdit';
import Members from './pages/user/Members';
import StoreBrand from './pages/store/Brand';
import StoreBrandEdit from './pages/store/BrandEdit';
import MallProductCategory from './pages/mall/ProductCategory';
import MallProductCategoryEdit from './pages/mall/ProductCategoryEdit';
import MallProductAttribute from './pages/mall/ProductAttribute';
import MallProductAttributeCategoryEdit from './pages/mall/ProductAttributeCategoryEdit';
import MallProductAttributeEdit from './pages/mall/ProductAttributeEdit';
import MallProduct from './pages/mall/Product';
import MallProductEdit from './pages/mall/ProductEdit';
import Stock from './pages/stock/Stock';
import StockEdit from './pages/stock/StockEdit';
import Order from './pages/order/Order';
import OrderDetail from './pages/order/OrderDetail';
import Marketing from './pages/marketing/Marketing';
import MarketingEdit from './pages/marketing/MarketingEdit';
import MarketingEditV2 from './pages/marketing/MarketingEditV2';
import ActivityPage from './pages/activity/ActivityPage';
import ActivityPageEdit from './pages/activity/ActivityPageEdit';
import Insourcing from './pages/insourcing/Insourcing';
import InsourcingEdit from './pages/insourcing/InsourcingEdit';
import Operation from './pages/operation/Operation';
import OperationEdit from './pages/operation/OperationEdit';
import Coupon from './pages/coupon/Coupon';
import CouponEdit from './pages/coupon/CouponEdit';

import PainterModel from './pages/painter/PainterModel';
import PainterModelEdit from './pages/painter/PainterModelEdit';
import PainterPage from './pages/painter/PainterPage';
import PainterPageEdit from './pages/painter/PainterPageEdit';
import PainterTemplate from './pages/painter/PainterTemplate';
import PainterTemplateEdit from './pages/painter/PainterTemplateEdit';
import PainterPagePlan from './pages/painter/PainterPagePlan';
import PainterPagePlanEdit from './pages/painter/PainterPagePlanEdit';

import Channel from './pages/channel/Channel';
import Event from './pages/channel/Event';

import DataCore from "./pages/data/Core";
import DataPv from "./pages/data/Pv";
import DataBehavior from "./pages/data/Behavior";

import NoPermission from './pages/403';
import NotFound from './pages/404';

const BasicRoute = () => (
    <BrowserRouter>
        <Switch>
            <Route exact path="/" component={App}/>
            <Route path="/login" component={Login}/>
            <Route path="/user/admins/add" component={AdminEdit}/>
            <Route path="/user/admins/edit/:id" component={AdminEdit}/>
            <Route path="/user/admins" component={Admins}/>
            <Route path="/user/members" component={Members}/>
            <Route path="/mall/brand/add" component={StoreBrandEdit}/>
            <Route path="/mall/brand/edit/:id" component={StoreBrandEdit}/>
            <Route path="/mall/brand" component={StoreBrand}/>
            <Route path="/mall/product_category/add" component={MallProductCategoryEdit}/>
            <Route path="/mall/product_category/edit/:id" component={MallProductCategoryEdit}/>
            <Route path="/mall/product_category" component={MallProductCategory}/>
            <Route path="/mall/product_attribute/category/add" component={MallProductAttributeCategoryEdit}/>
            <Route path="/mall/product_attribute/category/edit/:id" component={MallProductAttributeCategoryEdit}/>
            <Route path="/mall/product_attribute/add" component={MallProductAttributeEdit}/>
            <Route path="/mall/product_attribute/edit/:id" component={MallProductAttributeEdit}/>
            <Route path="/mall/product_attribute" component={MallProductAttribute}/>
            <Route path="/mall/product/add" component={MallProductEdit}/>
            <Route path="/mall/product/edit/:id" component={MallProductEdit}/>
            <Route path="/mall/product" component={MallProduct}/>
            <Route path="/mall/marketing/add/v2" component={MarketingEditV2}/>
            <Route path="/mall/marketing/add" component={MarketingEdit}/>
            <Route path="/mall/marketing/edit/v2/:id" component={MarketingEditV2}/>
            <Route path="/mall/marketing/edit/:id" component={MarketingEdit}/>
            <Route path="/mall/marketing" component={Marketing}/>
            <Route path="/mall/activity/add" component={ActivityPageEdit}/>
            <Route path="/mall/activity/edit/:id" component={ActivityPageEdit}/>
            <Route path="/mall/activity" component={ActivityPage}/>
            <Route path="/mall/insourcing/add" component={InsourcingEdit}/>
            <Route path="/mall/insourcing/edit/:id" component={InsourcingEdit}/>
            <Route path="/mall/insourcing" component={Insourcing}/>
            <Route path="/mall/operation" component={OperationEdit}/>
            <Route path="/mall/coupon/add" component={CouponEdit}/>
            <Route path="/mall/coupon/edit/:id" component={CouponEdit}/>
            <Route path="/mall/coupon" component={Coupon}/>
            <Route path="/stock/list" component={Stock}/>
            <Route path="/stock/edit/:id" component={StockEdit}/>
            <Route path="/order/list" component={Order}/>
            <Route path="/order/detail/:id" component={OrderDetail}/>

            <Route path="/painter/model/add" component={PainterModelEdit}/>
            <Route path="/painter/model/:id" component={PainterModelEdit}/>
            <Route path="/painter/model" component={PainterModel}/>
            <Route path="/painter/page/add" component={PainterPageEdit}/>
            <Route path="/painter/page/:id" component={PainterPageEdit}/>
            <Route path="/painter/page" component={PainterPage}/>
            <Route path="/painter/template/add" component={PainterTemplateEdit}/>
            <Route path="/painter/template/:id" component={PainterTemplateEdit}/>
            <Route path="/painter/template" component={PainterTemplate}/>
            <Route path="/painter/pagePlan/add" component={PainterPagePlanEdit}/>
            <Route path="/painter/pagePlan/:id" component={PainterPagePlanEdit}/>
            <Route path="/painter/pagePlan" component={PainterPagePlan}/>

            <Route path="/channel/list" component={Channel}/>
            <Route path="/channel/event" component={Event}/>

            <Route path="/data/core" component={DataCore}/>
            <Route path="/data/pv" component={DataPv}/>
            <Route path="/data/behavior" component={DataBehavior}/>

            <Route path="/403" component={NoPermission}/>
            {/* 404页面，必须放在最下面 */}
            <Route path="/404" component={NotFound}/>
            <Redirect to="/404" />
        </Switch>
    </BrowserRouter>
);

export default BasicRoute;
