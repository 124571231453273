import RequestUtil from "../util/request/RequestUtil";

export function addActivityPage(params) {
    let data = {
        bottomPic: params.bottomPic,
        bottomPicRoute: params.bottomPicJumpUrl,
        endTime: params.endTime,
        headerPic: params.headerPic,
        headerPicRoute: params.headerPicJumpUrl,
        innerName: params.name,
        pageBackgroundColor: params.backgroundColor,
        productIds: params.productIds,
        sharePic: params.sharePic,
        shareTitle: params.shareTitle,
        startTime: params.startTime,
        title: params.title,
        topBackgroundColor: params.navColor,
        couponIds: params.couponIds
    }

    return RequestUtil.getInstance().post(`/activity-page`, data);
}

export function updateActivityPage(id, params) {
    let data = {
        bottomPic: params.bottomPic,
        bottomPicRoute: params.bottomPicJumpUrl,
        endTime: params.endTime,
        headerPic: params.headerPic,
        headerPicRoute: params.headerPicJumpUrl,
        innerName: params.name,
        pageBackgroundColor: params.backgroundColor,
        productIds: params.productIds,
        sharePic: params.sharePic,
        shareTitle: params.shareTitle,
        startTime: params.startTime,
        title: params.title,
        topBackgroundColor: params.navColor,
        couponIds: params.couponIds
    }

    return RequestUtil.getInstance().put(`/activity-page/${id}`, data);
}

export function getActivityPageList(pageNum = 1, pageSize = 10, activityId, innerName, activityStatus) {
    let data = {
        currentPage: pageNum,
        pageSize: pageSize
    }
    if (activityId) {
        data.activityId = activityId
    }
    if (innerName) {
        data.innerName = innerName
    }
    if (typeof activityStatus !== "undefined" && activityStatus != null) {
        data.activityStatus = activityStatus
    }

    return RequestUtil.getInstance().post(`/activity-page/pageList`, data);
}

export function getActivityPage(id) {
    return RequestUtil.getInstance().get(`/activity-page/${id}`);
}

export function uploadParseProduct(file) {
    let formData = new FormData();
    formData.append('file', file, file.name);
    return RequestUtil.getInstance(30000, {
        headers: {'Content-Type': 'multipart/form-data'}
    }).post(`/activity-page/uploadParseProduct`, formData);
}

export function deleteActivityPage(id) {
    return RequestUtil.getInstance().delete(`/activity-page/${id}`);
}
