import React from 'react';
import { message, Button, Form, Input, Radio, InputNumber } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import UploadImage from '../upload/Image';
import ProductCategoryFirstClassSelector from '../selector/ProductCategoryFirstClassSelector';
import LoadingLayer from '../common/LoadingLayer';
import { createCategory } from '../../api/ProductCategoryApi';

class AddMallProductCategory extends React.Component {

    state = {
        description: '',
        icon: '',
        keywords: '',
        name: '',
        navStatus: 1,
        parentId: 0,
        productUnit: '',
        showStatus: 1,
        sort: 0,
        loading: false,
        tip: ''
    };

    formHandler = {
        onNameChange: e => {
            this.setState({
                name: e.target.value
            });
        },
        onParentIdChange: value => {
            this.setState({
                parentId: value
            });
        },
        onKeywordsChange: e => {
            this.setState({
                keywords: e.target.value
            });
        },
        onDescriptionChange: e => {
            this.setState({
                description: e.target.value
            });
        },
        onIconChange: url => {
            this.setState({
                icon: url
            });
        },
        onProductUnitChange: e => {
            this.setState({
                productUnit: e.target.value
            });
        },
        onNavStatusChange: e => {
            this.setState({
                navStatus: e.target.value
            });
        },
        onShowStatusChange: e => {
            this.setState({
                showStatus: e.target.value
            });
        },
        onSortChange: number => {
            this.setState({
                sort: number
            });
        },
        onSubmit: async _ => {
            this.setState({
                loading: true,
                tip: '数据提交中'
            });
            let response = await createCategory(this.state);
            let { code } = response.data;
            this.setState({
                loading: false
            });
            if (code === 200) {
                message.success('商品分类创建成功');
                if (this.props.onSuccess) {
                    this.props.onSuccess({
                        parentId: this.state.parentId
                    });
                }
            }
        }
    }

    render() {
        return (
            <>
                <LoadingLayer loading={this.state.loading} tip={this.state.tip}/>
                <PageHeader
                    title="新建商品分类信息"
                />
                <Form
                    labelCol={{ span: 2 }}
                    wrapperCol={{ span: 10 }}
                    layout="horizontal"
                    style={{ marginTop: 10 }}
                >
                    <Form.Item label="分类名称">
                        <Input value={this.state.name} onChange={this.formHandler.onNameChange}/>
                    </Form.Item>
                    <Form.Item label="二级分类（可选）">
                        <ProductCategoryFirstClassSelector value={this.state.parentId} onChange={this.formHandler.onParentIdChange}/>
                    </Form.Item>
                    <Form.Item label="分类icon">
                        <UploadImage value={this.state.icon} onChange={this.formHandler.onIconChange}/>
                    </Form.Item>
                    <Form.Item label="关键词">
                        <Input value={this.state.keywords} onChange={this.formHandler.onKeywordsChange}/>
                    </Form.Item>
                    <Form.Item label="描述">
                        <Input.TextArea value={this.state.description} onChange={this.formHandler.onDescriptionChange}/>
                    </Form.Item>
                    <Form.Item label="商品单位">
                        <Input value={this.state.productUnit} onChange={this.formHandler.onProductUnitChange}/>
                    </Form.Item>
                    <Form.Item label="导航栏展示状态">
                        <Radio.Group value={this.state.navStatus} onChange={this.formHandler.onNavStatusChange}>
                            <Radio value={1}>展示</Radio>
                            <Radio value={0}>不展示</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item label="展示状态">
                        <Radio.Group value={this.state.showStatus} onChange={this.formHandler.onShowStatusChange}>
                            <Radio value={1}>展示</Radio>
                            <Radio value={0}>不展示</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item label="排序">
                        <InputNumber value={this.state.sort} onChange={this.formHandler.onSortChange}/>
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 2 }}>
                        <Button type="primary" onClick={this.formHandler.onSubmit}>提交</Button>
                    </Form.Item>
                </Form>
            </>
        );
    }
}

export default AddMallProductCategory;
