import React from 'react';
import './../css/login.scss';
import { Button, Card, Form, Input } from 'antd';
import LocalStorageUtil from '../util/LocalStorageUtil';
import { login, getUserInfo } from '../api/LoginApi';
import LoadingLayer from '../components/common/LoadingLayer';

class Login extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            username: '',
            password: '',
            loading: false,
            tip: '登录中'
        };
    }

    formHandler = {
        username: value => {
            this.setState({
                username: value.target.value
            });
        },
        password: value => {
            this.setState({
                password: value.target.value
            });
        },
        login: async _ => {
            this.setState({
                loading: true
            });
            let response = await login(this.state.username, this.state.password);
            let { code, data } = response.data;
            if (code === 200) {
                LocalStorageUtil.setWithExpire('Authorization', data.tokenHead + data.token, 86400 * 7);
                await this.getUserInfo();
                this.props.history.push('/');
            }
            this.setState({
                loading: false
            });
        }
    }

    getUserInfo = async _ => {
        let response = await getUserInfo();
        let { code, data } = response.data;
        if (code === 200) {
            LocalStorageUtil.setWithExpire('userInfo', JSON.stringify(data), 86400 * 7);
        }
    }

    render() {
        return (
            <div className="Login">
                <LoadingLayer loading={this.state.loading} tip={this.state.tip}/>
                <div className="card">
                    <Card hoverable bodyStyle={{ textAlign: 'center' }} size="small" title="登录">
                        <Form layout="vertical" labelCol={{ span: 8 }}>
                            <Form.Item label="用户名">
                                <Input value={this.state.username} onChange={this.formHandler.username.bind(this)} />
                            </Form.Item>
                            <Form.Item label="密码">
                                <Input.Password
                                    value={this.state.password}
                                    onChange={this.formHandler.password.bind(this)}
                                    onPressEnter={this.formHandler.login}
                                />
                            </Form.Item>
                            <Form.Item noStyle>
                                <Button type="primary" onClick={this.formHandler.login}>登录</Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </div>
                <div className="icp">
                    <a href="https://beian.miit.gov.cn" target="_blank">
                        京ICP备2022015606号-1
                    </a>
                </div>
            </div>
        );
    }
}

export default Login;
