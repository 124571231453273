import React from "react";
import CommonLayout from '../../components/layout/CommonLayout';
import DataDailyPv from '../../components/data/DailyPv';

class DataPv extends React.Component {

    render() {
        return (
            <CommonLayout
                topNavSelectedKey="data"
                siderNavCategory="data"
                siderNavSelectedKey="pv"
            >
                <DataDailyPv/>
            </CommonLayout>
        );
    }
}

export default DataPv;
