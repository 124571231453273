import React from 'react';
import '../css/403.scss';
import NoPermissionImg from '../image/403.png';

class NoPermission extends React.Component {

    render() {
        return (
            <div className="NoPermission">
                <img src={NoPermissionImg} alt="403没有访问权限" />
            </div>
        );
    }
}

export default NoPermission;
