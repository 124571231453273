import React from 'react';
import { Select } from 'antd';
import { getEventDict, getEventListByChannelId } from '../../api/ChannelApi';

const { Option } = Select;

class EventSelector extends React.Component {

    state = {
        channelId: '',
        list: [],
        value: ''
    };

    componentDidMount = async _ => {
        this.setState({
            value: this.props.value,
            channelId: this.props.channelId
        }, async _ => await this.getEventDict());
    }

    componentWillReceiveProps = async props => {
        if (props) {
            this.setState({
                value: props.value ? props.value : undefined,
                channelId: props.channelId
            }, async _ => await this.getEventDict());
        }
    }

    getEventDict = async _ => {
        let response = await getEventListByChannelId(this.state.channelId);
        let { code, data } = response.data;
        if (code === 200) {
            this.setState({
                list: data
            });
        }
    }

    onChange = key => {
        if (this.props.onChange) {
            this.props.onChange(key);
        }
    }

    render() {
        const options = this.state.list.map(item => <Option key={item.key}>{item.value}</Option>)

        return (
            <>
                <Select
                    allowClear
                    showSearch
                    style={{ width: this.props.width ? this.props.width : 300 }}
                    placeholder="选择一个事件"
                    value={this.state.value}
                    optionFilterProp="children"
                    onChange={this.onChange}
                    filterOption={(input, option) =>
                        option.children.toLocaleLowerCase().indexOf(input.toLocaleLowerCase()) >= 0
                    }
                >
                    {options}
                </Select>
            </>
        );
    }
}

export default EventSelector;
