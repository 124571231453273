import React from 'react';
import CommonLayout from '../../components/layout/CommonLayout';
import MemberList from '../../components/user/MemberList';

class Members extends React.Component {

    render() {
        return (
            <CommonLayout
                topNavSelectedKey="user"
                siderNavCategory="user"
                siderNavSelectedKey="members"
            >
                <MemberList/>
            </CommonLayout>
        );
    }
}

export default Members;
