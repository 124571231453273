import RequestUtil from "../util/request/RequestUtil";
import qs from 'qs';

export function getSkuList(categoryId, pageNum = 1, pageSize = 5) {
    return RequestUtil.getInstance().get(`/sku/list?pageNum=${pageNum}&pageSize=${pageSize}&categoryId=${categoryId}`);
}

export function getStockPageList(params) {
    let data = {
        brandId: params.brandId,
        currentPage: params.currentPage,
        pageSize: params.pageSize,
        primaryCategoryId: params.primaryCategoryId,
        secondCategoryId: params.secondCategoryId,
        productName: params.productName,
        type: params.type
    };
    return RequestUtil.getInstance().post(`/sku/getStockPageList`, data);
}

export function getOverSkuList(pageNum = 1, pageSize = 5) {
    return RequestUtil.getInstance().post(`/sku/SkuListOver?pageNum=${pageNum}&pageSize=${pageSize}`);
}

export function getHistorySkuList(pageNum = 1, pageSize = 5) {
    return RequestUtil.getInstance().post(`/sku/historylist?pageNum=${pageNum}&pageSize=${pageSize}`);
}

export function uploadSkuFile(categoryId, file) {
    let formData = new FormData();
    formData.append('file', file, file.name);
    return RequestUtil.getInstance(30000, {
        headers: {'Content-Type': 'multipart/form-data'}
    }).post(`/sku/upload?categoryId=${categoryId}`, formData);
}

export function deleteSku(skuCode) {
    return RequestUtil.getInstance().post(`/sku/deletesku/${skuCode}`);
}

export function stopCater(skuCode) {
    return RequestUtil.getInstance().post(`/sku/changestatus/${skuCode}`);
}

export function createSku(params) {
    let data = {
        attr_1_name: params.attr_1_name,
        attr_1_val: params.attr_1_val,
        attr_2_name: params.attr_2_name,
        attr_2_val: params.attr_2_val,
        brandName: params.brandName,
        categoryName: params.categoryName,
        productName: params.productName,
        salesPrice: params.salesPrice,
        skuStockCode: params.skuStockCode,
        stockNum: params.stockNum,
        sales: params.sales,
        spucode: params.spucode
    };
    return RequestUtil.getInstance().post(`/sku/create`, data);
}

export function getProductPublishStatus(skuCode) {
    return RequestUtil.getInstance().get(`/sku/getProductPublishStatus?skuCode=${skuCode}`);
}

export function getInfo(skuId) {
    return RequestUtil.getInstance().get(`/sku/infor?id=${skuId}`);
}

export function updateSku(skuId, params) {
    let data = {
        skuId: skuId,
        attr_1_name: params.attr_1_name,
        attr_1_val: params.attr_1_val,
        attr_2_name: params.attr_2_name,
        attr_2_val: params.attr_2_val,
        brandName: params.brandName,
        categoryName: params.categoryName,
        productName: params.productName,
        salesPrice: params.salesPrice,
        spuCode: params.spuCode,
        skuStockCode: params.skuStockCode,
        stockNum: params.stockNum,
        sales: params.sales
    };
    return RequestUtil.getInstance().post(`/sku/edit`, qs.stringify(data));
}

export function batchAddSkuStock(catalogId, headers, skuStockList) {
    let data = {
        catalogId: catalogId,
        headers: headers,
        skuStockList: skuStockList
    }
    return RequestUtil.getInstance().post(`/stock/batchAddSkuStock`, data);
}

export function uploadSkuPriceFile(file) {
    let formData = new FormData();
    formData.append('file', file, file.name);
    return RequestUtil.getInstance(30000, {
        headers: {'Content-Type': 'multipart/form-data'}
    }).post(`/price/uploadProduct`, formData);
}

export function batchModifySkuPrice(skuPriceList) {
    let data = {
        productListRespList: skuPriceList
    }
    return RequestUtil.getInstance().post(`/price/changeSkuPrice`, data);
}
