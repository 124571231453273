import RequestUtil from "../util/request/RequestUtil";

export function getList(pageNum = 1, pageSize = 5) {
    return RequestUtil.getInstance().get(`/productAttribute/category/list?pageNum=${pageNum}&pageSize=${pageSize}`);
}

export function createCategory(name) {
    return RequestUtil.getInstance().post(`/productAttribute/category/create?name=${name}`);
}

export function updateCategory(id, name) {
    return RequestUtil.getInstance().post(`/productAttribute/category/update/${id}?name=${name}`);
}

export function getInfo(id) {
    return RequestUtil.getInstance().get(`/productAttribute/category/${id}`);
}

export function deleteCategory(id) {
    return RequestUtil.getInstance().get(`/productAttribute/category/delete/${id}`);
}
