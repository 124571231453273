import React from 'react';
import CommonLayout from '../../components/layout/CommonLayout';
import EditActivityPage from '../../components/activity/EditActivityPage';

class ActivityPageEdit extends React.Component {

    state = {
        activityPageId: this.props.match.params.id
    };

    render() {
        return (
            <CommonLayout
                topNavSelectedKey="mall"
                siderNavCategory="mall"
                siderNavSelectedKey="activity"
            >
                <EditActivityPage
                    value={this.state.activityPageId}
                    onSuccess={() => this.props.history.push('/mall/activity')}
                />
            </CommonLayout>
        );
    }
}

export default ActivityPageEdit;
