import React from 'react';
import { InputNumber, List, Space, Tag } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

class MarketingProductItem extends React.Component {

    state = {
        data: this.props.value
    };

    componentWillReceiveProps = props => {
        this.setState({
            data: props.value
        });
    }

    onDelete = _ => {
        if (this.props.onDelete) {
            this.props.onDelete();
        }
    }

    onSortChange = number => {
        if (this.props.onSortChange) {
            this.props.onSortChange(number);
        }
    }

    render() {
        return (
            <>
                <List.Item
                    actions={[
                        <Space style={{ color: 'red' }} onClick={this.onDelete}>
                            <DeleteOutlined/>
                            删除
                        </Space>,
                        <Space>
                            排序
                            <InputNumber min={0} size="small" value={this.state.data.sort} onChange={this.onSortChange}/>
                        </Space>
                    ]}
                    extra={
                        <img
                            width={160}
                            alt="logo"
                            src={this.state.data.pic}
                        />
                    }
                >
                    <List.Item.Meta
                        title={this.state.data.name}
                        description={this.state.data.subTitle}
                    />
                    <Space>
                        <Tag>品牌：{this.state.data.brandName}</Tag>
                        <Tag>分类：{this.state.data.productCategoryName}</Tag>
                        <Tag>销量：{this.state.data.sale}</Tag>
                        <Tag>库存：{this.state.data.stock}</Tag>
                    </Space>
                </List.Item>
            </>
        );
    }
}

export default MarketingProductItem;
