import React from 'react';
import CommonLayout from '../../components/layout/CommonLayout';
import EditPainterPage from '../../components/painter/EditPainterPage';

class PainterPageEdit extends React.Component {

    state = {
        pageId: this.props.match.params.id
    };

    render() {
        return (
            <CommonLayout
                topNavSelectedKey="painter"
                siderNavCategory="painter"
                siderNavSelectedKey="page"
            >
                <EditPainterPage
                    value={this.state.pageId}
                    onSuccess={() => this.props.history.push('/painter/page')}
                />
            </CommonLayout>
        );
    }
}

export default PainterPageEdit;
