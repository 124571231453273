import React from 'react';
import CommonLayout from '../../components/layout/CommonLayout';
import PainterPageList from '../../components/painter/PainterPageList';

class PainterPage extends React.Component {

    render() {
        return (
            <CommonLayout
                topNavSelectedKey="painter"
                siderNavCategory="painter"
                siderNavSelectedKey="page"
            >
                <PainterPageList/>
            </CommonLayout>
        );
    }
}

export default PainterPage;
