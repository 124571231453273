import React from 'react';
import CommonLayout from '../../components/layout/CommonLayout';
import AddAdmin from '../../components/user/AddAdmin';
import UpdateAdmin from '../../components/user/UpdateAdmin';

class AdminEdit extends React.Component {

    state = {
        userId: this.props.match.params.id
    };

    render() {
        return (
            <CommonLayout
                topNavSelectedKey="user"
                siderNavCategory="user"
                siderNavSelectedKey="admins"
            >
                {this.props.location.pathname === '/user/admins/add' ?
                    <AddAdmin onSuccess={() => this.props.history.push('/user/admins')}/> :
                    <UpdateAdmin
                        value={this.state.userId}
                        onSuccess={() => this.props.history.push('/user/admins')}
                    />
                }
            </CommonLayout>
        );
    }
}

export default AdminEdit;
