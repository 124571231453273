import React from 'react';
import CommonLayout from '../../components/layout/CommonLayout';
import EventList from '../../components/channel/EventList';

class Event extends React.Component {

    render() {
        return (
            <CommonLayout
                topNavSelectedKey="channel"
                siderNavCategory="channel"
                siderNavSelectedKey="event"
            >
                <EventList/>
            </CommonLayout>
        );
    }
}

export default Event;
