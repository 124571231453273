import React from 'react';
import CommonLayout from '../../components/layout/CommonLayout';
import EditPainterTemplate from '../../components/painter/EditPainterTemplate';

class PainterTemplateEdit extends React.Component {

    state = {
        templateId: this.props.match.params.id
    };

    render() {
        return (
            <CommonLayout
                topNavSelectedKey="painter"
                siderNavCategory="painter"
                siderNavSelectedKey="template"
            >
                <EditPainterTemplate
                    value={this.state.templateId}
                    onSuccess={() => this.props.history.push('/painter/template')}
                />
            </CommonLayout>
        );
    }
}

export default PainterTemplateEdit;
