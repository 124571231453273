import React from 'react';
import { Cascader } from 'antd';
import { getListWithChildren } from '../../api/ProductCategoryApi';

class ProductCategorySecondClassSelector extends React.Component {

    state = {
        options: [],
        value: this.props.value ? parseInt(this.props.value) : undefined,
        handlerValue: undefined
    };

    componentDidMount = async _ => {
        let response = await getListWithChildren();
        let { code, data } = response.data;
        if (code === 200) {
            let list = [];
            data.forEach(item => {
                let dataItem = {
                    value: item.id,
                    label: item.name,
                    children: []
                };
                if (item.children) {
                    item.children.forEach(cItem => {
                        let cDataItem = {
                            value: cItem.id,
                            label: cItem.name
                        };
                        dataItem.children.push(cDataItem);
                        if (this.state.value === cItem.id) {
                            this.setState({
                                handlerValue: [item.id, cItem.id]
                            });
                        }
                    });
                }
                list.push(dataItem);
            });
            this.setState({
                options: list
            });
        }
    }

    componentWillReceiveProps = async props => {
        if (props.value) {
            this.state.options.forEach(item => {
                if (item.children) {
                    item.children.forEach(cItem => {
                        if (props.value === cItem.value) {
                            this.setState({
                                handlerValue: [item.value, cItem.value]
                            });
                        }
                    });
                }
            });
        }
    }

    onChange = (value, selectedOptions) => {
        if (this.props.onChange) {
            this.props.onChange(value[value.length - 1], value, selectedOptions);
        }
    }

    render() {
        return (
            <Cascader
                options={this.state.options}
                showSearch
                style={{ width: this.props.width ? this.props.width : 200 }}
                placeholder="选择一个分类"
                value={this.state.handlerValue}
                onChange={this.onChange}
            />
        );
    }
}

export default ProductCategorySecondClassSelector;
