import RequestUtil from "../util/request/RequestUtil";

export function getInfo() {
    return RequestUtil.getInstance().get(`/operation-beta/info`);
}

export function editInfo(float, payPopup) {
    let data = {
        operations: []
    }
    data.operations.push(float)
    data.operations.push(payPopup)
    return RequestUtil.getInstance().post(`/operation-beta/edit`, data);
}
