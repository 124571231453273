import React from 'react';
import { Select } from 'antd';
import { getCouponList } from '../../api/CouponApi';

const { Option } = Select;

class CouponSelector extends React.Component {

    state = {
        list: [],
        value: []
    };

    componentDidMount = async _ => {
        this.setState({
            value: this.props.value
        });
        await this.getCouponList();
    }

    componentWillReceiveProps = async props => {
        if (props) {
            this.setState({
                value: props.value ? props.value : undefined
            });
        }
    }

    getCouponList = async _ => {
        let response = await getCouponList('', '', 0, 1, 1, 500);
        let { code, data } = response.data;
        if (code === 200 && data.list) {
            this.setState({
                list: data.list
            });
        }
    }

    onChange = key => {
        if (this.props.onChange) {
            this.props.onChange(key);
        }
    }

    render() {
        const options = this.state.list.map(item => <Option key={item.voucherId} code={item.voucherCode}>{item.showName}</Option>)

        return (
            <>
                <Select
                    allowClear
                    showSearch
                    mode="multiple"
                    style={{ width: this.props.width ? this.props.width : 300 }}
                    placeholder="选择优惠券"
                    value={this.state.value}
                    optionFilterProp="children"
                    onChange={this.onChange}
                    filterOption={(input, option) =>
                        option.children.toLocaleLowerCase().indexOf(input.toLocaleLowerCase()) >= 0
                        || option.code.toLocaleLowerCase().indexOf(input.toLocaleLowerCase()) >= 0
                        || option.key.toLocaleLowerCase().indexOf(input.toLocaleLowerCase()) >= 0
                    }
                >
                    {options}
                </Select>
            </>
        );
    }
}

export default CouponSelector;
