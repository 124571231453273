import React from 'react';
import { message, Button, Form, Input } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { getInfo, updateInfo } from '../../api/AdminApi';
import UploadImage from '../upload/Image';
import LoadingLayer from '../common/LoadingLayer';

class UpdateAdmin extends React.Component {

    state = {
        id: this.props.value,
        email: '',
        icon: '',
        nickName: '',
        note: '',
        username: '',
        loading: false,
        tip: ''
    };

    componentDidMount = async _ => {
        this.setState({
            loading: true,
            tip: '数据加载中'
        });
        let response = await getInfo(this.state.id);
        let { code, data } = response.data;
        this.setState({
            loading: false
        });
        if (code === 200) {
            this.setState({
                email: data.email,
                icon: data.icon,
                nickName: data.nickName,
                note: data.note,
                username: data.username
            });
        }
    }

    formHandler = {
        onUsernameChange: e => {
            this.setState({
                username: e.target.value
            });
        },
        onNicknameChange: e => {
            this.setState({
                nickName: e.target.value
            });
        },
        onNoteChange: e => {
            this.setState({
                note: e.target.value
            });
        },
        onEmailChange: e => {
            this.setState({
                email: e.target.value
            });
        },
        onIconChange: url => {
            this.setState({
                icon: url
            });
        }
    }

    updateInfo = async _ => {
        this.setState({
            loading: true,
            tip: '数据提交中'
        });
        let response = await updateInfo(this.state.id, this.state);
        let { code } = response.data;
        this.setState({
            loading: false
        });
        if (code === 200) {
            message.success('更新成功');
            if (this.props.onSuccess) {
                this.props.onSuccess();
            }
        }
    }

    render() {
        return (
            <>
                <LoadingLayer loading={this.state.loading} tip={this.state.tip}/>
                <PageHeader
                    title="编辑用户信息"
                />
                <Form
                    labelCol={{ span: 2 }}
                    wrapperCol={{ span: 10 }}
                    layout="horizontal"
                    style={{ marginTop: 10 }}
                >
                    <Form.Item label="用户名">
                        <Input value={this.state.username} onChange={this.formHandler.onUsernameChange}/>
                    </Form.Item>
                    <Form.Item label="昵称">
                        <Input value={this.state.nickName} onChange={this.formHandler.onNicknameChange}/>
                    </Form.Item>
                    <Form.Item label="备注">
                        <Input value={this.state.note} onChange={this.formHandler.onNoteChange}/>
                    </Form.Item>
                    <Form.Item label="email">
                        <Input value={this.state.email} onChange={this.formHandler.onEmailChange}/>
                    </Form.Item>
                    <Form.Item label="头像">
                        <UploadImage value={this.state.icon} onChange={this.formHandler.onIconChange}/>
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 2 }}>
                        <Button type="primary" onClick={this.updateInfo}>提交</Button>
                    </Form.Item>
                </Form>
            </>
        );
    }
}

export default UpdateAdmin;
