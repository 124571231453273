import React from 'react';
import { Alert, Form, Tag, Divider, Row, Col, Image, Table, Space, Button, Modal, Input, Select } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import LoadingLayer from '../common/LoadingLayer';
import { getDetail, updateNote, updateReceiverInfo, updateDelivery, getDeliveryCompanyList } from "../../api/OrderApi";
import moment from 'moment';

const { Option } = Select;

const orderStatus = {
    0: {
        name: '待付款',
        color: ''
    },
    1: {
        name: '待发货',
        color: 'magenta'
    },
    2: {
        name: '已发货',
        color: 'lime'
    },
    3: {
        name: '已完成',
        color: '#87d068'
    },
    4: {
        name: '已关闭',
        color: 'purple'
    },
    5: {
        name: '无效订单',
        color: '#f50'
    }
};

const orderType = {
    0: '正常订单',
    1: '秒杀订单'
}

const payType = {
    0: '未支付',
    1: '支付宝',
    2: '微信'
}

const sourceType = {
    0: 'PC订单',
    1: 'App订单'
}

class OrderInfo extends React.Component {

    state = {
        id: this.props.value,
        loading: false,
        tip: '',
        orderInfo: {
            status: 0
        },
        noteModal: {
            visible: false,
            note: ''
        },
        receiverDisable: false,
        receiverModal: {
            visible: false,
            receiverCity: '',
            receiverDetailAddress: '',
            receiverName: '',
            receiverPhone: '',
            receiverPostCode: '',
            receiverProvince: '',
            receiverRegion: ''
        },
        deliveryDisable: false,
        deliveryModal: {
            visible: false,
            deliveryCompany: '',
            deliverySn: ''
        },
        deliveryCompanys: []
    };

    componentDidMount = async _ => {
        await this.getOrderDetail();
        await this.getDeliveryCompanyList();
    }

    getDeliveryCompanyList = async _ => {
        let response = await getDeliveryCompanyList();
        let { code, data } = response.data;
        if (code === 200) {
            this.setState({
                deliveryCompanys: data
            });
        }
    }

    getOrderDetail = async _ => {
        this.setState({
            loading: true,
            tip: '数据加载中'
        });
        let response = await getDetail(this.state.id);
        let { code, data } = response.data;
        if (code === 200) {
            let receiverDisable = true;
            if (data.status === 0 || data.status === 1) {
                receiverDisable = false;
            }
            let deliveryDisable = true;
            if (data.status === 1) {
                deliveryDisable = false;
            }
            this.setState({
                loading: false,
                orderInfo: data,
                receiverDisable: receiverDisable,
                deliveryDisable: deliveryDisable
            });
        }
    }

    noteModalHandler = {
        onShow: _ => {
            let noteModal = this.state.noteModal;
            noteModal.visible = true;
            this.setState({
                noteModal: noteModal
            });
        },
        onOk: async _ => {
            this.setState({
                loading: true,
                tip: '数据更新中'
            });
            let response = await updateNote(this.state.id, this.state.noteModal.note, this.state.orderInfo.status);
            let { code } = response.data;
            this.setState({
                loading: false
            });
            if (code === 200) {
                await this.getOrderDetail();
                this.noteModalHandler.onCancel();
            }
        },
        onCancel: _ => {
            let noteModal = this.state.noteModal;
            noteModal.visible = false;
            noteModal.note = '';
            this.setState({
                noteModal: noteModal
            });
        },
        onNoteChange: e => {
            let noteModal = this.state.noteModal;
            noteModal.note = e.target.value;
            this.setState({
                noteModal: noteModal
            });
        }
    }

    receiverModalHandler = {
        onShow: _ => {
            let receiverModal = {
                visible: true,
                receiverCity: this.state.orderInfo.receiverCity,
                receiverDetailAddress: this.state.orderInfo.receiverDetailAddress,
                receiverName: this.state.orderInfo.receiverName,
                receiverPhone: this.state.orderInfo.receiverPhone,
                receiverPostCode: this.state.orderInfo.receiverPostCode,
                receiverProvince: this.state.orderInfo.receiverProvince,
                receiverRegion: this.state.orderInfo.receiverRegion
            };
            this.setState({
                receiverModal: receiverModal
            });
        },
        onOk: async _ => {
            this.setState({
                loading: true,
                tip: '数据更新中'
            });
            let receiverModal = this.state.receiverModal;
            receiverModal.status = this.state.orderInfo.status;
            receiverModal.orderId = this.state.id;
            let response = await updateReceiverInfo(receiverModal);
            let { code } = response.data;
            this.setState({
                loading: false
            });
            if (code === 200) {
                await this.getOrderDetail();
                this.receiverModalHandler.onCancel();
            }
        },
        onCancel: _ => {
            let receiverModal = {
                visible: false,
                receiverCity: '',
                receiverDetailAddress: '',
                receiverName: '',
                receiverPhone: '',
                receiverPostCode: '',
                receiverProvince: '',
                receiverRegion: ''
            };
            this.setState({
                receiverModal: receiverModal
            });
        },
        onProvinceChange: e => {
            let receiverModal = this.state.receiverModal;
            receiverModal.receiverProvince = e.target.value;
            this.setState({
                receiverModal: receiverModal
            });
        },
        onCityChange: e => {
            let receiverModal = this.state.receiverModal;
            receiverModal.receiverCity = e.target.value;
            this.setState({
                receiverModal: receiverModal
            });
        },
        onRegionChange: e => {
            let receiverModal = this.state.receiverModal;
            receiverModal.receiverRegion = e.target.value;
            this.setState({
                receiverModal: receiverModal
            });
        },
        onAddressChange: e => {
            let receiverModal = this.state.receiverModal;
            receiverModal.receiverDetailAddress = e.target.value;
            this.setState({
                receiverModal: receiverModal
            });
        },
        onNameChange: e => {
            let receiverModal = this.state.receiverModal;
            receiverModal.receiverName = e.target.value;
            this.setState({
                receiverModal: receiverModal
            });
        },
        onPhoneChange: e => {
            let receiverModal = this.state.receiverModal;
            receiverModal.receiverPhone = e.target.value;
            this.setState({
                receiverModal: receiverModal
            });
        },
        onPostCodeChange: e => {
            let receiverModal = this.state.receiverModal;
            receiverModal.receiverPostCode = e.target.value;
            this.setState({
                receiverModal: receiverModal
            });
        }
    }

    deliveryModalHandler = {
        onShow: _ => {
            let deliveryModal = {
                visible: true,
                deliveryCompany: '',
                deliverySn: ''
            };
            this.setState({
                deliveryModal: deliveryModal
            });
        },
        onOk: async _ => {
            this.setState({
                loading: true,
                tip: '数据更新中'
            });
            let deliveryModal = this.state.deliveryModal;
            deliveryModal.orderId = this.state.id;
            let response = await updateDelivery(deliveryModal);
            let { code } = response.data;
            this.setState({
                loading: false
            });
            if (code === 200) {
                await this.getOrderDetail();
                this.deliveryModalHandler.onCancel();
            }
        },
        onCancel: _ => {
            let deliveryModal = {
                visible: false,
                deliveryCompany: '',
                deliverySn: ''
            };
            this.setState({
                deliveryModal: deliveryModal
            });
        },
        onDeliveryCompanyChange: value => {
            let deliveryModal = this.state.deliveryModal;
            deliveryModal.deliveryCompany = value;
            this.setState({
                deliveryModal: deliveryModal
            });
        },
        onDeliverySnChange: e => {
            let deliveryModal = this.state.deliveryModal;
            deliveryModal.deliverySn = e.target.value;
            this.setState({
                deliveryModal: deliveryModal
            });
        }
    }

    render() {
        const columns = [{
            title: '商品ID',
            dataIndex: 'productId',
            key: 'productId',
            width: 80
        }, {
            title: '商品名称',
            dataIndex: 'productName',
            key: 'productName',
            width: 200
        }, {
            title: '商品头图',
            dataIndex: 'productPic',
            key: 'productPic',
            width: 160,
            render: pic => (
                <Image width={150} src={pic}/>
            )
        }, {
            title: '品牌名称',
            dataIndex: 'productBrand',
            key: 'productBrand',
        }, {
            title: '货号',
            dataIndex: 'productSn',
            key: 'productSn',
        }, {
            title: '售价',
            dataIndex: 'productPrice',
            key: 'productPrice',
        }, {
            title: '数量',
            dataIndex: 'productQuantity',
            key: 'productQuantity',
        }, {
            title: 'SKU ID',
            dataIndex: 'productSkuId',
            key: 'productSkuId',
        }, {
            title: 'SKU CODE',
            dataIndex: 'productSkuCode',
            key: 'productSkuCode',
        }, {
            title: '供应商',
            dataIndex: 'supplierName',
            key: 'supplierName',
        }, {
            title: '规格',
            dataIndex: 'productAttr',
            key: 'productAttr',
            render: productAttr => (
                <Space>
                    {JSON.parse(productAttr).map(item => <Tag>{item.key}：{item.value}</Tag>)}
                </Space>
            )
        }];

        const columnsHistory = [{
            title: '操作人',
            dataIndex: 'operateMan',
            key: 'operateMan',
        }, {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
            render: time => (
                moment(time).format('yyyy-MM-DD HH:mm:ss')
            )
        }, {
            title: '订单状态',
            dataIndex: 'orderStatus',
            key: 'orderStatus',
            render: status => (
                <Tag color={orderStatus[status].color}>{orderStatus[status].name}</Tag>
            )
        }, {
            title: '备注',
            dataIndex: 'note',
            key: 'note',
        }];

        const deliveryCompanyOptions = this.state.deliveryCompanys.map(item => <Option key={item.deliveryCompany} value={item.deliveryCompany}>{item.deliveryCompany}</Option>);

        const deliveryCompanyAlter = this.state.deliveryModal.deliveryCompany ? (this.state.deliveryModal.deliveryCompany === '闪送' ? <Alert message="3-5小时送达" type="success"/> : <Alert message="3-5天送达" type="success"/>) : '';

        return (
            <>
                <LoadingLayer loading={this.state.loading} tip={this.state.tip}/>
                <PageHeader
                    title="查看订单信息"
                />
                <Space>
                    <Button onClick={this.noteModalHandler.onShow}>修改订单备注</Button>
                    <Button disabled={this.state.receiverDisable} onClick={this.receiverModalHandler.onShow}>修改收货人信息</Button>
                    <Button disabled={this.state.deliveryDisable} onClick={this.deliveryModalHandler.onShow}>订单发货</Button>
                </Space>
                <Divider orientation="left" plain>
                    订单基础信息
                </Divider>
                <Row>
                    <Col span={12}>
                        <Form
                            labelCol={{ span: 4 }}
                            wrapperCol={{ span: 10 }}
                            layout="horizontal"
                        >
                            <Form.Item label="ID">{this.state.orderInfo.id}</Form.Item>
                            <Form.Item label="订单编号">{this.state.orderInfo.orderSn}</Form.Item>
                            <Form.Item label="创建时间">{moment(this.state.orderInfo.createTime).format('yyyy-MM-DD HH:mm:ss')}</Form.Item>
                            <Form.Item label="支付时间">{this.state.orderInfo.paymentTime ? moment(this.state.orderInfo.paymentTime).format('yyyy-MM-DD HH:mm:ss') : '--'}</Form.Item>
                            <Form.Item label="修改时间">{this.state.orderInfo.modifyTime ? moment(this.state.orderInfo.modifyTime).format('yyyy-MM-DD HH:mm:ss') : '--'}</Form.Item>
                            <Form.Item label="订单类型">{orderType[this.state.orderInfo.orderType]}</Form.Item>
                            <Form.Item label="订单来源">{sourceType[this.state.orderInfo.sourceType]}</Form.Item>
                            <Form.Item label="删除状态">{this.state.orderInfo.deleteStatus ? <Tag color="#f50">已删除</Tag> : <Tag color="#87d068">未删除</Tag>}</Form.Item>
                            <Form.Item label="订单备注">{this.state.orderInfo.note}</Form.Item>
                        </Form>
                    </Col>
                    <Col span={12}>
                        <Form
                            labelCol={{ span: 4 }}
                            wrapperCol={{ span: 12 }}
                            layout="horizontal"
                        >
                            <Form.Item label="订单标题">{this.state.orderInfo.orderTitle}</Form.Item>
                            <Form.Item label="订单状态">
                                <Tag color={orderStatus[this.state.orderInfo.status].color}>{orderStatus[this.state.orderInfo.status].name}</Tag>
                            </Form.Item>
                            <Form.Item label="下单用户ID">{this.state.orderInfo.userInfo ? this.state.orderInfo.userInfo.userId : ''}</Form.Item>
                            <Form.Item label="下单用户名称">{this.state.orderInfo.userInfo ? this.state.orderInfo.userInfo.wxNickName : ''}</Form.Item>
                            <Form.Item label="下单手机号">{this.state.orderInfo.userInfo ? this.state.orderInfo.userInfo.mobile : ''}</Form.Item>
                            <Form.Item label="订单总金额">{this.state.orderInfo.totalAmount}</Form.Item>
                            <Form.Item label="实际支付金额">{this.state.orderInfo.payAmount}</Form.Item>
                            <Form.Item label="支付方式">{payType[this.state.orderInfo.payType]}</Form.Item>
                        </Form>
                    </Col>
                </Row>

                <Divider orientation="left" plain>
                    优惠券信息
                </Divider>
                <Row>
                    <Col span={12}>
                        <Form
                            labelCol={{ span: 4 }}
                            wrapperCol={{ span: 10 }}
                            layout="horizontal"
                        >
                            <Form.Item label="ID">{this.state.orderInfo.voucherInfo ? this.state.orderInfo.voucherInfo.voucherId : '-'}</Form.Item>
                            <Form.Item label="优惠券名称">{this.state.orderInfo.voucherInfo ? this.state.orderInfo.voucherInfo.showName : '-'}</Form.Item>
                        </Form>
                    </Col>
                    <Col span={12}>
                        <Form
                            labelCol={{ span: 4 }}
                            wrapperCol={{ span: 12 }}
                            layout="horizontal"
                        >
                            <Form.Item label="优惠金额">{this.state.orderInfo.voucherInfo ? this.state.orderInfo.voucherInfo.discountAmounts : '-'}</Form.Item>
                        </Form>
                    </Col>
                </Row>

                <Divider orientation="left" plain>
                    收货信息
                </Divider>
                <Row>
                    <Col span={12}>
                        <Form
                            labelCol={{ span: 4 }}
                            wrapperCol={{ span: 10 }}
                            layout="horizontal"
                        >
                            <Form.Item label="收件人">{this.state.orderInfo.receiverName}</Form.Item>
                            <Form.Item label="手机号">{this.state.orderInfo.receiverPhone}</Form.Item>
                            <Form.Item label="邮编">{this.state.orderInfo.receiverPostCode}</Form.Item>
                        </Form>
                    </Col>
                    <Col span={12}>
                        <Form
                            labelCol={{ span: 4 }}
                            wrapperCol={{ span: 12 }}
                            layout="horizontal"
                        >
                            <Form.Item label="省/市/区">{this.state.orderInfo.receiverProvince + ' / ' + this.state.orderInfo.receiverCity + ' / ' + this.state.orderInfo.receiverRegion}</Form.Item>
                            <Form.Item label="地址">{this.state.orderInfo.receiverDetailAddress}</Form.Item>
                        </Form>
                    </Col>
                </Row>

                <Divider orientation="left" plain>
                    物流信息
                </Divider>
                <Row>
                    <Col span={12}>
                        <Form
                            labelCol={{ span: 4 }}
                            wrapperCol={{ span: 10 }}
                            layout="horizontal"
                        >
                            <Form.Item label="物流单号">{this.state.orderInfo.deliverySn}</Form.Item>
                            <Form.Item label="物流公司">{this.state.orderInfo.deliveryCompany}</Form.Item>
                            <Form.Item label="自动确认时间（天）">{this.state.orderInfo.autoConfirmDay}</Form.Item>
                        </Form>
                    </Col>
                    <Col span={12}>
                        <Form
                            labelCol={{ span: 4 }}
                            wrapperCol={{ span: 12 }}
                            layout="horizontal"
                        >
                            <Form.Item label="发货时间">{this.state.orderInfo.deliveryTime ? moment(this.state.orderInfo.deliveryTime).format('yyyy-MM-DD HH:mm:ss') : '--'}</Form.Item>
                            <Form.Item label="确认收货状态">{this.state.orderInfo.confirmStatus ? <Tag color="#87d068">已确认</Tag> : <Tag color="#f50">未确认</Tag>}</Form.Item>
                        </Form>
                    </Col>
                </Row>

                <Divider orientation="left" plain>
                    商品信息
                </Divider>
                <Table
                    size="small"
                    columns={columns}
                    dataSource={this.state.orderInfo.orderItemList}
                    bordered
                />

                <Divider orientation="left" plain>
                    操作记录
                </Divider>
                <Table
                    size="small"
                    columns={columnsHistory}
                    dataSource={this.state.orderInfo.historyList}
                    bordered
                />

                <Modal
                    title="修改订单备注"
                    visible={this.state.noteModal.visible}
                    onOk={this.noteModalHandler.onOk}
                    onCancel={this.noteModalHandler.onCancel}
                >
                    <Form
                        labelCol={{ span: 4 }}
                        wrapperCol={{ span: 16 }}
                        layout="horizontal"
                        style={{ marginTop: 10 }}
                    >
                        <Form.Item label="备注">
                            <Input.TextArea autoSize={{ minRows: 2, maxRows: 6 }} value={this.state.noteModal.note} onChange={this.noteModalHandler.onNoteChange}/>
                        </Form.Item>
                    </Form>
                </Modal>

                <Modal
                    title="修改收货人信息"
                    visible={this.state.receiverModal.visible}
                    onOk={this.receiverModalHandler.onOk}
                    onCancel={this.receiverModalHandler.onCancel}
                >
                    <Form
                        labelCol={{ span: 4 }}
                        wrapperCol={{ span: 16 }}
                        layout="horizontal"
                        style={{ marginTop: 10 }}
                    >
                        <Form.Item label="省">
                            <Input value={this.state.receiverModal.receiverProvince} onChange={this.receiverModalHandler.onProvinceChange}/>
                        </Form.Item>
                        <Form.Item label="市">
                            <Input value={this.state.receiverModal.receiverCity} onChange={this.receiverModalHandler.onCityChange}/>
                        </Form.Item>
                        <Form.Item label="区">
                            <Input value={this.state.receiverModal.receiverRegion} onChange={this.receiverModalHandler.onRegionChange}/>
                        </Form.Item>
                        <Form.Item label="详细地址">
                            <Input.TextArea autoSize={{ minRows: 2, maxRows: 6 }} value={this.state.receiverModal.receiverDetailAddress} onChange={this.receiverModalHandler.onAddressChange}/>
                        </Form.Item>
                        <Form.Item label="收件人">
                            <Input value={this.state.receiverModal.receiverName} onChange={this.receiverModalHandler.onNameChange}/>
                        </Form.Item>
                        <Form.Item label="收件人电话">
                            <Input value={this.state.receiverModal.receiverPhone} onChange={this.receiverModalHandler.onPhoneChange}/>
                        </Form.Item>
                        <Form.Item label="邮编">
                            <Input value={this.state.receiverModal.receiverPostCode} onChange={this.receiverModalHandler.onPostCodeChange}/>
                        </Form.Item>
                    </Form>
                </Modal>

                <Modal
                    title="订单发货"
                    visible={this.state.deliveryModal.visible}
                    onOk={this.deliveryModalHandler.onOk}
                    onCancel={this.deliveryModalHandler.onCancel}
                >
                    <Form
                        labelCol={{ span: 4 }}
                        wrapperCol={{ span: 16 }}
                        layout="horizontal"
                        style={{ marginTop: 10 }}
                    >
                        <Form.Item label="快递公司">
                            <Select
                                value={this.state.deliveryModal.deliveryCompany}
                                onChange={this.deliveryModalHandler.onDeliveryCompanyChange}
                            >
                                {deliveryCompanyOptions}
                            </Select>
                            {deliveryCompanyAlter}
                        </Form.Item>
                        <Form.Item label="快递单号">
                            <Input value={this.state.deliveryModal.deliverySn} onChange={this.deliveryModalHandler.onDeliverySnChange}/>
                        </Form.Item>
                    </Form>
                </Modal>
            </>
        );
    }
}

export default OrderInfo;
