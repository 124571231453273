/** https://www.jianshu.com/p/c552af25b56b */

import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { upload } from '../../api/MinIOApi';

class HtmlEditor extends React.Component {

    constructor(props) {
        super(props);
        this.reactQuillRef = null;
    }

    state = {
        defaultValue: this.props.defaultValue ? this.props.defaultValue : ''
    };

    imageHandler = _ => {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');
        input.setAttribute('multiple', 'multiple');
        input.click();
        const self = this;
        input.onchange = async _ => {
            for (let i = 0; i < input.files.length; i++) {
                let file = input.files[i];
                let response = await upload(file);
                let { code, data } = response.data;
                if (code === 200) {
                    let quill = self.reactQuillRef.getEditor();//获取到编辑器本身
                    const cursorPosition = quill.getSelection().index;//获取当前光标位置
                    const link = data.url;
                    quill.insertEmbed(cursorPosition, "image", link);//插入图片
                    quill.setSelection(cursorPosition + 1);//光标位置加1
                }
            }
        }
    }

    onChange = value => {
        if (this.props.onChange) {
            this.props.onChange(value);
        }
    }

    render() {
        const modules = {
            toolbar: {
                container: [
                    [{ 'size': ['small', false, 'large', 'huge'] }], //字体设置
                    [{ 'header': [1, 2, 3, 4, 5, 6, false] }], //标题字号，不能设置单个字大小
                    ['bold', 'italic', 'underline', 'strike'],  
                    [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
                    ['link', 'image'], // a链接和图片的显示
                    [{ 'align': [] }],
                    [{
                        'background': ['rgb(0, 0, 0)', 'rgb(230, 0, 0)', 'rgb(255, 153, 0)',
                            'rgb(255, 255, 0)', 'rgb(0, 138, 0)', 'rgb(0, 102, 204)',
                            'rgb(153, 51, 255)', 'rgb(255, 255, 255)', 'rgb(250, 204, 204)',
                            'rgb(255, 235, 204)', 'rgb(255, 255, 204)', 'rgb(204, 232, 204)',
                            'rgb(204, 224, 245)', 'rgb(235, 214, 255)', 'rgb(187, 187, 187)',
                            'rgb(240, 102, 102)', 'rgb(255, 194, 102)', 'rgb(255, 255, 102)',
                            'rgb(102, 185, 102)', 'rgb(102, 163, 224)', 'rgb(194, 133, 255)',
                            'rgb(136, 136, 136)', 'rgb(161, 0, 0)', 'rgb(178, 107, 0)',
                            'rgb(178, 178, 0)', 'rgb(0, 97, 0)', 'rgb(0, 71, 178)',
                            'rgb(107, 36, 178)', 'rgb(68, 68, 68)', 'rgb(92, 0, 0)',
                            'rgb(102, 61, 0)', 'rgb(102, 102, 0)', 'rgb(0, 55, 0)',
                            'rgb(0, 41, 102)', 'rgb(61, 20, 10)']
                    }],
                    [{
                        'color': ['rgb(0, 0, 0)', 'rgb(230, 0, 0)', 'rgb(255, 153, 0)',
                            'rgb(255, 255, 0)', 'rgb(0, 138, 0)', 'rgb(0, 102, 204)',
                            'rgb(153, 51, 255)', 'rgb(255, 255, 255)', 'rgb(250, 204, 204)',
                            'rgb(255, 235, 204)', 'rgb(255, 255, 204)', 'rgb(204, 232, 204)',
                            'rgb(204, 224, 245)', 'rgb(235, 214, 255)', 'rgb(187, 187, 187)',
                            'rgb(240, 102, 102)', 'rgb(255, 194, 102)', 'rgb(255, 255, 102)',
                            'rgb(102, 185, 102)', 'rgb(102, 163, 224)', 'rgb(194, 133, 255)',
                            'rgb(136, 136, 136)', 'rgb(161, 0, 0)', 'rgb(178, 107, 0)',
                            'rgb(178, 178, 0)', 'rgb(0, 97, 0)', 'rgb(0, 71, 178)',
                            'rgb(107, 36, 178)', 'rgb(68, 68, 68)', 'rgb(92, 0, 0)',
                            'rgb(102, 61, 0)', 'rgb(102, 102, 0)', 'rgb(0, 55, 0)',
                            'rgb(0, 41, 102)', 'rgb(61, 20, 10)']
                    }],
                    ['clean'], //清空
                ],
                handlers: {
                    'image': this.imageHandler.bind(this), //点击图片标志会调用的方法
                },
            }
        }

        return (
            <ReactQuill
                ref={el => this.reactQuillRef = el}
                theme="snow"
                modules={modules}
                defaultValue={this.state.defaultValue}
                onChange={this.onChange}
            />
        );
    }
}

export default HtmlEditor;
