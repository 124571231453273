import RequestUtil from "../util/request/RequestUtil";

export function getChannelDict() {
    return RequestUtil.getInstance().get(`/channel/getChannelDict`);
}

export function getChannelList(keyword = '', pageNum = 1, pageSize = 5) {
    let data = {
        currentPage: pageNum,
        keyword: keyword,
        pageSize: pageSize
    };
    return RequestUtil.getInstance().post(`/channel/pageList`, data);
}

export function addChannel(title) {
    let data = {
        title: title
    };
    return RequestUtil.getInstance().post(`/channel`, data);
}

export function updateChannel(id, title) {
    let data = {
        id: id,
        title: title
    };
    return RequestUtil.getInstance().put(`/channel`, data);
}

export function changeChannelEnabledStatus(id, enabledStatus) {
    return RequestUtil.getInstance().patch(`/channel/changeEnabledStatus/${id}?enabledStatus=${enabledStatus}`);
}

export function getEventList(keyword = '', pageNum = 1, pageSize = 5, eventId = '') {
    let data = {
        currentPage: pageNum,
        eventId: eventId,
        keyword: keyword,
        pageSize: pageSize
    };
    return RequestUtil.getInstance().post(`/event/pageList`, data);
}

export function changeEventEnabledStatus(id, enabledStatus) {
    return RequestUtil.getInstance().patch(`/event/changeEnabledStatus/${id}?enabledStatus=${enabledStatus}`);
}

export function addEvent(title, channelIds) {
    let data = {
        title: title,
        channelIds: channelIds
    };
    return RequestUtil.getInstance().post(`/event`, data);
}

export function getEvent(id) {
    return RequestUtil.getInstance().get(`/event/${id}`);
}

export function updateEvent(id, title, channelIds) {
    let data = {
        id: id,
        title: title,
        channelIds: channelIds
    };
    return RequestUtil.getInstance().put(`/event`, data);
}

export function getEventDict() {
    return RequestUtil.getInstance().get(`/event/getEventDict`);
}

export function getEventListByChannelId(channelId) {
    return RequestUtil.getInstance().get(`/event/getEventListByChannelId?channelId=${channelId}`);
}
