import React from 'react';
import CommonLayout from '../../components/layout/CommonLayout';
import EditOperation from '../../components/operation/EditOperation';

class OperationEdit extends React.Component {

    render() {
        return (
            <CommonLayout
                topNavSelectedKey="mall"
                siderNavCategory="mall"
                siderNavSelectedKey="operation"
            >
                <EditOperation
                    onSuccess={() => this.props.history.push('/mall/operation')}
                />
            </CommonLayout>
        );
    }
}

export default OperationEdit;
