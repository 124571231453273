import React from 'react';
import CommonLayout from '../../components/layout/CommonLayout';
import EditMarketing from '../../components/marketing/EditMarketing';

class MarketingEdit extends React.Component {

    state = {
        promotionId: this.props.match.params.id
    };

    render() {
        return (
            <CommonLayout
                topNavSelectedKey="mall"
                siderNavCategory="mall"
                siderNavSelectedKey="marketing"
            >
                <EditMarketing
                    value={this.state.promotionId}
                    onSuccess={() => this.props.history.push('/mall/marketing')}
                />
            </CommonLayout>
        );
    }
}

export default MarketingEdit;
