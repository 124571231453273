import React from 'react';
import '../css/404.scss';
import NotFoundImg from '../image/404.png';

class NotFound extends React.Component {

    render() {
        return (
            <div className="NotFound">
                <img src={NotFoundImg} alt="404页面不存在" />
            </div>
        );
    }
}

export default NotFound;
