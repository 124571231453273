import React from 'react';
import { Icon, Button, Table, Space, Tag, message, Avatar, Image } from 'antd';
import { getList, changeStatus } from '../../api/MemberApi';
import LoadingLayer from '../common/LoadingLayer';
import moment from 'moment';

const genderMap = {
    0: '未知',
    1: '男性',
    2: '女性'
};

class MemberList extends React.Component {

    state = {
        loading: false,
        loadingLayer: false,
        tip: '',
        list: [],
        pageNum: 1,
        pageSize: 20,
        total: 0
    };

    componentDidMount = async _ => {
        await this.getMemberList();
    }

    getMemberList = async _ => {
        this.setState({
            loading: true
        });
        let response = await getList(this.state.pageNum, this.state.pageSize);
        let { code, data } = response.data;
        if (code === 200) {
            data.list.map(item => item.key = item.id);
            this.setState({
                total: data.total,
                list: data.list,
                loading: false
            });
        }
    }

    onPageChange = (pageNum, pageSize) => {
        this.setState({
            pageNum: pageNum,
            pageSize: pageSize
        }, () => this.getMemberList());
    }

    changeStatus = async (userId, status) => {
        this.setState({
            loadingLayer: true,
            tip: '变更用户状态中'
        });
        let response = await changeStatus(userId, status);
        let { code } = response.data;
        this.setState({
            loadingLayer: false
        });
        if (code === 200) {
            message.success('变更用户状态成功');
            await this.getMemberList();
        } else {
            message.error('变更用户状态失败');
        }
    }

    render() {
        const columns = [{
            title: 'ID',
            dataIndex: 'userId',
            key: 'userId',
        }, {
            title: '微信openid',
            dataIndex: 'openid',
            key: 'openid',
        }, {
            title: '手机号',
            dataIndex: 'phone',
            key: 'phone',
        }, {
            title: '头像',
            dataIndex: 'icon',
            key: 'icon',
            render: icon => (
                <Avatar shape="square" src={<Image src={icon} style={{ width: 32 }} />} />
            )
        }, {
            title: '昵称',
            dataIndex: 'nickname',
            key: 'nickname'
        }, {
            title: '性别',
            dataIndex: 'gender',
            key: 'gender',
            render: gender => (
                gender ? genderMap[gender] : '未知'
            )
        }, {
            title: '城市',
            dataIndex: 'city',
            key: 'city',
            render: (city, record) => (
                city ? <span>{record.country} / {record.province} / {record.city}</span> : ''
            )
        }, {
            title: '账号状态',
            dataIndex: 'status',
            key: 'status',
            render: status => (
                status ? <Tag color="#87d068">正常</Tag> : <Tag color="#f50">禁用</Tag>
            )
        }, {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
            render: text => (
                text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : '--'
            )
        }, {
            title: '订单数量',
            dataIndex: 'orderNumber',
            key: 'orderNumber'
        }, {
            title: '操作',
            key: 'action',
            render: (text, record) => (
              <Space size="middle">
                {record.status ? 
                    <Button type="link" onClick={this.changeStatus.bind(this, record.userId, 0)} style={{ color: 'red' }}>禁用</Button> :
                    <Button type="link" onClick={this.changeStatus.bind(this, record.userId, 1)}>启用</Button>
                }
              </Space>
            ),
        }];

        return (
            <div>
                <LoadingLayer loading={this.state.loadingLayer} tip={this.state.tip}/>
                <Table
                    loading={this.state.loading}
                    size="small"
                    columns={columns}
                    dataSource={this.state.list}
                    bordered
                    pagination={{ total: this.state.total, pageSize: this.state.pageSize, onChange: this.onPageChange }}
                />
            </div>
        );
    }
}

export default MemberList;
