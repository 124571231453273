import React from 'react';
import { Tabs } from 'antd';
import CommonLayout from '../../components/layout/CommonLayout';
import MallProductAttributeCategoryList from '../../components/mall/ProductAttributeCategoryList';
import MallProductAttributeList from '../../components/mall/ProductAttributeList';
import UrlUtil from '../../util/UrlUtil';

const { TabPane } = Tabs;

class MallProductAttribute extends React.Component {

    state = {
        tab: UrlUtil.getQueryString(this.props.location.search, 'tab'),
        category: UrlUtil.getQueryString(this.props.location.search, 'category'),
        type: UrlUtil.getQueryString(this.props.location.search, 'type')
    };

    render() {
        return (
            <CommonLayout
                topNavSelectedKey="mall"
                siderNavCategory="mall"
                siderNavSelectedKey="product_attribute"
            >
                <Tabs defaultActiveKey={this.state.tab ? this.state.tab : 'category'}>
                    <TabPane tab="商品属性分类" key="category">
                        <MallProductAttributeCategoryList/>
                    </TabPane>
                    <TabPane tab="商品属性" key="attribute">
                        <MallProductAttributeList category={this.state.category} type={this.state.type}/>
                    </TabPane>
                </Tabs>
            </CommonLayout>
        );
    }
}

export default MallProductAttribute;
