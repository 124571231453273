import RequestUtil from "../util/request/RequestUtil";

export function getList(cid, type, pageNum = 1, pageSize = 5) {
    return RequestUtil.getInstance().get(`/productAttribute/list/${cid}?type=${type}&pageNum=${pageNum}&pageSize=${pageSize}`);
}

export function createAttribute(params) {
    let data = {
        filterType: params.filterType,
        handAddStatus: params.handAddStatus,
        inputList: params.inputList,
        inputType: params.inputType,
        name: params.name,
        productAttributeCategoryId: params.productAttributeCategoryId,
        relatedStatus: params.relatedStatus,
        searchType: params.searchType,
        selectType: params.selectType,
        sort: params.sort,
        type: params.type
    };
    return RequestUtil.getInstance().post(`/productAttribute/create`, data);
}

export function getInfo(id) {
    return RequestUtil.getInstance().get(`/productAttribute/${id}`);
}

export function updateAttribute(id, params) {
    let data = {
        filterType: params.filterType,
        handAddStatus: params.handAddStatus,
        inputList: params.inputList,
        inputType: params.inputType,
        name: params.name,
        productAttributeCategoryId: params.productAttributeCategoryId,
        relatedStatus: params.relatedStatus,
        searchType: params.searchType,
        selectType: params.selectType,
        sort: params.sort,
        type: params.type
    };
    return RequestUtil.getInstance().post(`/productAttribute/update/${id}`, data);
}

export function deleteAttribute(ids) {
    return RequestUtil.getInstance().post(`/productAttribute/delete?ids=${ids}`);
}
