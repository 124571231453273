import React from 'react';
import { CheckCircleFilled, DeleteFilled } from '@ant-design/icons';
import {
    Button,
    Card,
    Col,
    Collapse,
    Drawer,
    Form,
    Input,
    Pagination,
    Popconfirm,
    Radio,
    Row,
    Space,
    message
} from 'antd';
import { getList } from "../../api/ProductApi";
import UploadFileSpu from "../upload/FileSpu";

const { Panel } = Collapse;

class SpuSelectorDrawer extends React.Component {

    state = {
        open: this.props.open,
        selectType: 1,
        spuSearchKeyword: '',
        spuPageNum: 1,
        spuPageSize: 10,
        spuList: [],
        spuTotal: 0,
        checkedSpuMap: new Map()
    };

    componentWillReceiveProps = props => {
        if (props.value) {
            let checkedSpuMap = new Map();
            props.value.forEach(item => {
                checkedSpuMap.set(item.id.toString(), {
                    id: item.id.toString(),
                    name: item.name,
                    spuCode: item.spuCode,
                    pic: item.pic
                });
            });
            this.setState({
                checkedSpuMap: checkedSpuMap
            });
        }
        this.setState({
            open: props.open
        });
    }

    getDataFromRemote = {
        getSpuList: async _ => {
            let params = {
                keyword: this.state.spuSearchKeyword,
                publishStatus: 1
            };
            let response = await getList(params, this.state.spuPageNum, this.state.spuPageSize);
            let { code, data } = response.data;
            if (code === 200) {
                this.setState({
                    spuList: data.list,
                    spuTotal: data.total
                });
            }
        }
    };

    formHandler = {
        onSelectTypeChange: async e => {
            this.setState({
                selectType: e.target.value
            });
            if (e.target.value === 2) {
                await this.getDataFromRemote.getSpuList();
            }
        },
        onUploadChange: (data, errorMsg) => {
            if (errorMsg) {
                message.error(errorMsg);
            } else {
                let checkedSpuMap = this.state.checkedSpuMap;
                data.forEach(item => checkedSpuMap.set(item.productId, {
                    id: item.productId,
                    name: item.productName,
                    spuCode: item.spu,
                    pic: item.pic
                }));
                this.setState({
                    checkedSpuMap: checkedSpuMap
                });
            }
        },
        onPageChange: async (page, pageSize) => {
            this.setState({
                spuPageNum: page,
                spuPageSize: pageSize
            }, async () => await this.getDataFromRemote.getSpuList());
        },
        onSpuKeywordChange: e => {
            this.setState({
                spuSearchKeyword: e.target.value
            });
        },
        isChecked: spuId => {
            return this.state.checkedSpuMap.has(spuId.toString());
        },
        checkedTrigger: item => {
            let checkedSpuMap = this.state.checkedSpuMap;
            if (this.formHandler.isChecked(item.id)) {
                checkedSpuMap.delete(item.id.toString());
            } else {
                checkedSpuMap.set(item.id.toString(), {
                    id: item.id.toString(),
                    name: item.name,
                    spuCode: item.spuCode,
                    pic: item.pic
                });
            }
            this.setState({
                checkedSpuMap: checkedSpuMap
            });
        }
    };

    onClose = _ => {
        this.setState({
            open: false
        });
        if (this.props.onClose) {
            this.props.onClose(true);
        }
    }

    onSubmit = async _ => {
        if (this.state.checkedSpuMap.size === 0) {
            message.error("请选择至少一个商品");
            return;
        }

        if (this.props.onSubmit) {
            let checkedSpuList = [];
            this.state.checkedSpuMap.forEach(item => checkedSpuList.push({
                id: item.id.toString(),
                name: item.name,
                spuCode: item.spuCode,
                pic: item.pic
            }));
            this.props.onSubmit(checkedSpuList);
        }
    }

    render() {
        const spuListModule = this.state.spuList.map(item =>
            <Col style={{padding: 10}}>
                <Card
                    hoverable
                    size="small"
                    style={{ width: 150 }}
                    cover={<img src={item.pic} style={{ width: 150, height: 150 }} />}
                    onClick={this.formHandler.checkedTrigger.bind(this, {
                        id: item.id,
                        name: item.name,
                        spuCode: item.spuCode,
                        pic: item.pic
                    })}
                >
                    <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', fontSize: 12, fontWeight: 500 }}>{item.name}</div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                        <CheckCircleFilled style={{ color: this.formHandler.isChecked(item.id) ? '#52c41a' : '#d9d9d9' }} />
                    </div>
                </Card>
            </Col>);

        let checkedSpuList = [];
        this.state.checkedSpuMap.forEach(item => checkedSpuList.push(item));

        const spuCheckedModule = checkedSpuList.map(item =>
            <Col style={{padding: 5}}>
                <Card
                    hoverable
                    size="small"
                    style={{ width: 126 }}
                    cover={<img src={item.pic} style={{ width: 126, height: 126 }} />}
                >
                    <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', fontSize: 12, fontWeight: 500 }}>{item.name}</div>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                        <Popconfirm
                            placement="right"
                            title="请确认是否删除该商品？"
                            description="删除已选商品"
                            onConfirm={this.formHandler.checkedTrigger.bind(this, {
                                id: item.id,
                                name: item.name,
                                spuCode: item.spuCode,
                                pic: item.pic
                            })}
                            okText="Yes"
                            cancelText="No"
                        >
                            <DeleteFilled />
                        </Popconfirm>
                    </div>
                </Card>
            </Col>
        );

        return (
            <Drawer
                onClose={this.onClose}
                title="商品圈选"
                width={900}
                open={this.state.open}
                extra={
                    <Space>
                        <Button onClick={() => this.setState({ open: false })}>取消</Button>
                        <Button type="primary" onClick={this.onSubmit}>提交</Button>
                    </Space>
                }
            >
                <Form
                    labelCol={{ span: 0 }}
                    layout="vertical"
                >
                    <Form.Item label="圈选方式">
                        <Radio.Group value={this.state.selectType} onChange={this.formHandler.onSelectTypeChange}>
                            <Radio value={1}>文件上传</Radio>
                            <Radio value={2}>后台圈选（Beta）</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item label="操作">
                        {this.state.selectType === 1 ? <Space>
                            <UploadFileSpu onChange={this.formHandler.onUploadChange} />
                            <a href='https://bj-prod-1309320985.cos.ap-beijing.myqcloud.com/20220525/3bccc9d45d2c7d78f9fe4fa81177a33.xls' target="_blank">下载模版</a>
                        </Space> : <></>}
                        {this.state.selectType === 2 ? <>
                            <Space>
                                <Input
                                    placeholder="请输入商品名称"
                                    value={this.state.spuSearchKeyword}
                                    onChange={this.formHandler.onSpuKeywordChange}
                                    onPressEnter={this.getDataFromRemote.getSpuList}
                                />
                                <Button onClick={this.getDataFromRemote.getSpuList}>搜索</Button>
                            </Space>
                            <br/><br/>
                            <Pagination
                                size="small"
                                current={this.state.spuPageNum}
                                total={this.state.spuTotal}
                                onChange={this.formHandler.onPageChange}
                            />
                            <Row>
                                {spuListModule}
                            </Row>
                            <Pagination
                                size="small"
                                current={this.state.spuPageNum}
                                total={this.state.spuTotal}
                                onChange={this.formHandler.onPageChange}
                            />
                        </> : <></>}
                    </Form.Item>
                    <Form.Item label="已选商品">
                        <Collapse accordion>
                            <Panel header={"共"+ this.state.checkedSpuMap.size +"个商品"} key="1">
                                <Row>
                                    {spuCheckedModule}
                                </Row>
                            </Panel>
                        </Collapse>
                    </Form.Item>
                </Form>
            </Drawer>
        );
    }
}

export default SpuSelectorDrawer;
