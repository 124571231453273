import React from 'react';
import {
    message,
    Row,
    Col,
    Table,
    Input,
    InputNumber,
    Space,
    Button,
    Tag,
    Select,
    Image,
    Modal,
    Form
} from 'antd';
import { Link } from 'react-router-dom';
import { getList, setBoom, publishStatus, exportProduct } from '../../api/ProductApi';
import { batchModifySkuPrice } from '../../api/StockApi';
import { generateSpuLink } from '../../api/WechatLinkApi';
import LoadingLayer from '../common/LoadingLayer';
import BrandSelector from '../selector/BrandSelector';
import UploadFileBatchModifyPrice from '../upload/FileBatchModifyPrice';
import UploadFileSpuDelayConfig from '../upload/FileSpuDelayConfig';
import UploadFileBatchModifyCouponUseStatus from '../upload/FileBatchModifyCouponUseStatus';
import SkuSelector from '../selector/SkuSelector';
import ChannelSelector from '../selector/ChannelSelector';
import EventSelector from '../selector/EventSelector';
import '../../css/mall/productList.scss';

const { Option } = Select;
const { TextArea } = Input;

class MallProductList extends React.Component {
    
    state = {
        loading: false,
        loadingLayer: false,
        tip: '',
        searchParams: {
            brandId: 0,
            keyword: '',
            productCategoryId: 0,
            productSn: '',
            publishStatus: undefined,
            verifyStatus: undefined
        },
        list: [],
        pageNum: 1,
        pageSize: 20,
        total: 0,
        batchModifyPriceVis: false,
        batchDelayConfigVis: false,
        modifyPriceFileData: [],
        batchModifyCouponUseStatusVis: false,
        channelLinkVis: false,
        channelLinkParams: {
            linkType: 'url_scheme',
            spuId: 0,
            skuCode: '',
            expireInterval: 30,
            channelId: '',
            eventId: '',
            title: '',
            result: ''
        }
    };

    componentDidMount = async _ => {
        await this.getProductList();
    }

    getProductList = async _ => {
        this.setState({
            loading: true
        });
        let response = await getList(this.state.searchParams, this.state.pageNum, this.state.pageSize);
        let { code, data } = response.data;
        this.setState({
            loading: false
        });
        if (code === 200) {
            if (data.list) {
                data.list.map(item => item.key = item.id);
                this.setState({
                    total: data.total,
                    list: data.list
                });
            } else {
                this.setState({
                    total: 0,
                    list: []
                });
            }
        }
    }

    searchHandler = {
        onBrandIdChange: value => {
            let searchParams = this.state.searchParams;
            searchParams.brandId = value;
            this.setState({
                searchParams: searchParams
            });

            this.searchHandler.onSearch();
        },
        onKeywordChange: e => {
            let searchParams = this.state.searchParams;
            searchParams.keyword = e.target.value;
            this.setState({
                searchParams: searchParams
            });
        },
        onProductCategoryIdChange: value => {
            let searchParams = this.state.searchParams;
            searchParams.productCategoryId = value;
            this.setState({
                searchParams: searchParams
            });
        },
        onProductSnChange: e => {
            let searchParams = this.state.searchParams;
            searchParams.productSn = e.target.value;
            this.setState({
                searchParams: searchParams
            });
        },
        onPublishStatusChange: value => {
            let searchParams = this.state.searchParams;
            searchParams.publishStatus = value;
            this.setState({
                searchParams: searchParams
            });

            this.searchHandler.onSearch();
        },
        onSearch: async _ => {
            this.setState({
                pageNum: 1
            }, async () => await this.getProductList());
        },
        export: async _ => {
            this.setState({
                loadingLayer: true,
                tip: '导出中'
            });
            let response = await exportProduct(this.state.searchParams);
            this.setState({
                loadingLayer: false
            });

            let url = window.URL.createObjectURL(new Blob([response.data], {type: "application/vnd.ms-excel;charset=UTF-8"}))
            let link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', `商品${new Date().getTime()}.xls`)
            document.body.appendChild(link)
            link.click()
            window.URL.revokeObjectURL(url)
            document.body.removeChild(link)
        }
    }

    setBoom = async (productId, isBoom) => {
        this.setState({
            loadingLayer: true,
            tip: '爆款设置中'
        });
        let response = await setBoom(productId, isBoom);
        let { code } = response.data;
        this.setState({
            loadingLayer: false
        });
        if (code === 200) {
            message.success('设置爆款成功');
            await this.getProductList();
        } else {
            message.error('设置爆款失败');
        }
    }

    publishStatus = async (productId, status) => {
        this.setState({
            loadingLayer: true,
            tip: '上下架设置中'
        });
        let response = await publishStatus(productId, status);
        let { code } = response.data;
        this.setState({
            loadingLayer: false
        });
        if (code === 200) {
            message.success('设置上下架成功');
            await this.getProductList();
        } else {
            message.error('设置上下架失败');
        }
    }

    onPageChange = (pageNum, pageSize) => {
        this.setState({
            pageNum: pageNum,
            pageSize: pageSize
        }, () => this.getProductList());
    }

    onModifyPriceFileDataChange = data => {
        this.setState({
            modifyPriceFileData: data
        });
    }

    onSubmitModifyPriceFileData = async _ => {
        let data = [];
        this.state.modifyPriceFileData.forEach(item => {
            if (item.status) {
                data.push({
                    changePrice: item.changePrice,
                    price: item.price,
                    sku: item.sku,
                    spu: item.spu
                });
            }
        });

        this.setState({
            loadingLayer: true,
            tip: '批量改价中'
        });
        let response = await batchModifySkuPrice(data);
        let { code } = response.data;
        this.setState({
            loadingLayer: false
        });
        if (code === 200) {
            message.success('批量改价成功');
            this.setState({
                batchModifyPriceVis: false,
                modifyPriceFileData: []
            });
        } else {
            message.error('批量改价失败');
        }
    }

    onChannelLinkModalShow = async record => {
        this.setState({
            channelLinkVis: true,
            channelLinkParams: {
                linkType: 'url_scheme',
                spuId: record.id,
                skuCode: '',
                expireInterval: 30,
                channelId: '',
                eventId: '',
                title: record.name,
                result: ''
            }
        });
    }

    channelLinkFormHandler = {
        onLinkTypeChange: key => {
            let channelLinkParams = this.state.channelLinkParams;
            channelLinkParams.linkType = key;
            this.setState({
                channelLinkParams: channelLinkParams
            });
        },
        onSkuChange: key => {
            let channelLinkParams = this.state.channelLinkParams;
            channelLinkParams.skuCode = key;
            this.setState({
                channelLinkParams: channelLinkParams
            });
        },
        onExpireIntervalChange: number => {
            let channelLinkParams = this.state.channelLinkParams;
            channelLinkParams.expireInterval = number;
            this.setState({
                channelLinkParams: channelLinkParams
            });
        },
        onTitleChange: e => {
            let channelLinkParams = this.state.channelLinkParams;
            channelLinkParams.title = e.target.value;
            this.setState({
                channelLinkParams: channelLinkParams
            });
        },
        onChannelChange: key => {
            let channelLinkParams = this.state.channelLinkParams;
            channelLinkParams.channelId = key;
            this.setState({
                channelLinkParams: channelLinkParams
            });
        },
        onEventChange: key => {
            let channelLinkParams = this.state.channelLinkParams;
            channelLinkParams.eventId = key;
            this.setState({
                channelLinkParams: channelLinkParams
            });
        },
        onSubmit: async _ => {
            let channelLinkParams = this.state.channelLinkParams;
            if (!channelLinkParams.skuCode) {
                message.error('SKU不能为空');
                return;
            }
            let response = await generateSpuLink(channelLinkParams);
            let { code, data } = response.data;
            if (code === 200) {
                channelLinkParams.result = data;
                this.setState({
                    channelLinkParams: channelLinkParams
                });
            }
        }
    }

    render() {
        const columns = [{
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            align: 'center',
            width: 60,
        }, {
            title: '商品编码',
            dataIndex: 'spuCode',
            key: 'spuCode',
            align: 'center',
            width: 60,
        }, {
            title: '商品头图',
            dataIndex: 'pic',
            key: 'pic',
            align: 'center',
            width: 100,
            render: pic => (
                <Image width={100} src={pic}/>
            )
        }, {
            title: '商品名称',
            dataIndex: 'name',
            key: 'name',
            align: 'center',
            width: 200,
            render: (name, record) => (
                <>
                    <p>{name}</p>
                    <p>品牌：{record.brandName}</p>
                </>
            )
        }, {
            title: '价格',
            dataIndex: 'originalPrice',
            key: 'originalPrice',
            align: 'center',
            width: 100,
            render: (originalPrice, record) => (
                <>
                    <p>市场价：{originalPrice}</p>
                    <p>售价：{record.price}</p>
                    <p>折扣：{record.discountStr}</p>
                </>
            )
        }, {
            title: '库存',
            dataIndex: 'stock',
            key: 'stock',
            align: 'center',
            width: 60,
        }, {
            title: '标签',
            dataIndex: 'tags',
            key: 'tags',
            align: 'center',
            width: 100,
            render: (tags, record) => (
                <>
                    <p>状态：{record.publishStatus ? <Tag color="#87d068">上架</Tag> : <Tag color="#f50">下架</Tag>}</p>
                    {/* <p>爆款：{record.isBoom ? <Tag color="magenta">是</Tag> : <Tag>否</Tag>}</p> */}
                </>
            )
        },
        //     {
        //     title: '数据',
        //     dataIndex: 'sevenDayAverageSales',
        //     key: 'sevenDayAverageSales',
        //     align: 'center',
        //     width: 120,
        //     render: (sevenDayAverageSales, record) => (
        //         <>
        //             <p>7日平均销量：{sevenDayAverageSales ?? 0}</p>
        //             <p>7日DOS：{record.sevenDayDOS ?? 0}</p>
        //             <p>浏览量：{record.pageView ?? 0}</p>
        //         </>
        //     )
        // },
            {
            title: '操作',
            key: 'action',
            fixed: 'right',
            align: 'center',
            width: 200,
            render: (text, record) => (
                <>
                    <Space>
                        <Link to={`/mall/product/edit/${record.id}`}><Button type="link">编辑</Button></Link>
                        {record.publishStatus ? 
                            <Button type="link" style={{ color: 'red' }} onClick={this.publishStatus.bind(this, record.id, 0)}>下架</Button> :
                            <Button type="link" onClick={this.publishStatus.bind(this, record.id, 1)}>上架</Button>
                        }
                        <Button type="link" onClick={this.onChannelLinkModalShow.bind(this, record)}>渠道链接</Button>
                    </Space>
                </>
            )
        }];

        const modifyPriceColumns = [{
            title: 'SPU',
            dataIndex: 'spu',
            key: 'spu'
        }, {
            title: 'SKU',
            dataIndex: 'sku',
            key: 'sku'
        }, {
            title: '规格',
            dataIndex: 'spList',
            key: 'spList',
            render: spList => (
                <>
                    {spList && spList.map(item => <p>{item.key}：{item.value}</p>)}
                </>
            )
        }, {
            title: '现价',
            dataIndex: 'price',
            key: 'price'
        }, {
            title: '改后价',
            dataIndex: 'changePrice',
            key: 'changePrice'
        }];

        return (
            <div className="ProductList">
                <LoadingLayer loading={this.state.loadingLayer} tip={this.state.tip}/>
                <Row>
                    <Col span={5}>
                        品牌：<BrandSelector value={this.state.searchParams.brandId} onChange={this.searchHandler.onBrandIdChange}/>
                    </Col>
                    <Col span={5}>
                        商品名称：<Input style={{ width: 200 }} onChange={this.searchHandler.onKeywordChange} onPressEnter={this.searchHandler.onSearch}/>
                    </Col>
                    <Col span={5}>
                        上架状态：<Select className="select" allowClear value={this.state.searchParams.publishStatus} onChange={this.searchHandler.onPublishStatusChange}>
                            <Option value={0}>下架</Option>
                            <Option value={1}>上架</Option>
                        </Select>
                    </Col>
                    <Col span={5}>
                        <Space>
                            <Button type="primary" onClick={this.searchHandler.onSearch}>搜索</Button>
                            <Button type="primary" onClick={this.searchHandler.export}>导出</Button>
                        </Space>
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col>
                        <Space>
                            <Button onClick={() => this.setState({batchModifyPriceVis: true})}>批量改价</Button>
                            <Button onClick={() => this.setState({batchDelayConfigVis: true})}>批量设置晚发必赔</Button>
                            <Button onClick={() => this.setState({batchModifyCouponUseStatusVis: true})}>批量设置优惠券开关</Button>
                        </Space>
                    </Col>
                </Row>
                
                <br/>
                <Table
                    scroll={{ x: 1200 }}
                    loading={this.state.loading}
                    size="small"
                    columns={columns}
                    dataSource={this.state.list}
                    bordered
                    pagination={{ total: this.state.total, pageSize: this.state.pageSize, onChange: this.onPageChange }}
                />

                <Modal
                    title="批量改价"
                    centered
                    visible={this.state.batchModifyPriceVis}
                    onOk={this.onSubmitModifyPriceFileData}
                    onCancel={() => this.setState({batchModifyPriceVis: false})}
                    width={1000}
                >
                    <Space>
                        <div>
                            <a
                                href="https://bj-prod-1309320985.cos.ap-beijing.myqcloud.com/20220223/88e68257be119e5180a72fc0f23702e.xls"
                                target="_blank"
                            >下载模版</a>
                        </div>
                        <div>
                            <UploadFileBatchModifyPrice onChange={this.onModifyPriceFileDataChange} />
                        </div>
                    </Space>
                    <br/><br/>
                    <Table
                        size="small"
                        columns={modifyPriceColumns}
                        dataSource={this.state.modifyPriceFileData}
                        bordered
                        pagination={false}
                    />
                </Modal>

                <Modal
                    title="批量设置晚发必赔"
                    centered
                    visible={this.state.batchDelayConfigVis}
                    onCancel={() => this.setState({batchDelayConfigVis: false})}
                >
                    <Space>
                        <div>
                            <a
                                href="https://bj-prod-1309320985.cos.ap-beijing.myqcloud.com/template/delayUploadBatchConfig.xlsx"
                                target="_blank"
                            >下载模版</a>
                        </div>
                        <div>
                            <UploadFileSpuDelayConfig />
                        </div>
                    </Space>
                </Modal>

                <Modal
                    title="批量设置优惠券开关"
                    centered
                    visible={this.state.batchModifyCouponUseStatusVis}
                    onCancel={() => this.setState({batchModifyCouponUseStatusVis: false})}
                >
                    <Space>
                        <div>
                            <a
                                href="https://bj-prod-1309320985.cos.ap-beijing.myqcloud.com/template/spuNoVoucherBatchConfig.xlsx"
                                target="_blank"
                            >下载模版</a>
                        </div>
                        <div>
                            <UploadFileBatchModifyCouponUseStatus />
                        </div>
                    </Space>
                </Modal>

                <Modal
                    title="生成渠道链接"
                    visible={this.state.channelLinkVis}
                    onOk={this.channelLinkFormHandler.onSubmit}
                    onCancel={() => this.setState({channelLinkVis: false})}
                >
                    <Form
                        labelCol={{ span: 4 }}
                        wrapperCol={{ span: 18 }}
                        layout="horizontal"
                        style={{ marginTop: 10 }}
                    >
                        <Form.Item label="链接类型">
                            <Select
                                value={this.state.channelLinkParams.linkType}
                                onChange={this.channelLinkFormHandler.onLinkTypeChange}
                            >
                                <Option key="url_scheme">URL Scheme</Option>
                                <Option key="url_link">URL Link</Option>
                                <Option key="short_link">Short Link</Option>
                                <Option key="qrcode">小程序码</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item label="sku">
                            <SkuSelector
                                spuId={this.state.channelLinkParams.spuId}
                                value={this.state.channelLinkParams.skuCode}
                                onChange={this.channelLinkFormHandler.onSkuChange}
                            />
                        </Form.Item>
                        {this.state.channelLinkParams.linkType !== 'qrcode' &&
                            <Form.Item label="过期天数">
                                <InputNumber
                                    min={1}
                                    max={180}
                                    value={this.state.channelLinkParams.expireInterval}
                                    onChange={this.channelLinkFormHandler.onExpireIntervalChange}
                                />
                            </Form.Item>
                        }
                        {this.state.channelLinkParams.linkType === 'short_link' &&
                            <Form.Item label="标题">
                                <Input
                                    style={{ width: 300 }}
                                    value={this.state.channelLinkParams.title}
                                    onChange={this.channelLinkFormHandler.onTitleChange}
                                />
                            </Form.Item>
                        }
                        <Form.Item label="渠道">
                            <ChannelSelector
                                value={this.state.channelLinkParams.channelId}
                                onChange={this.channelLinkFormHandler.onChannelChange}
                            />
                        </Form.Item>
                        {this.state.channelLinkParams.channelId &&
                            <Form.Item label="事件">
                                <EventSelector
                                    channelId={this.state.channelLinkParams.channelId}
                                    value={this.state.channelLinkParams.eventId}
                                    onChange={this.channelLinkFormHandler.onEventChange}
                                />
                            </Form.Item>
                        }
                        <Form.Item label="生成结果">
                            <TextArea
                                disabled
                                value={this.state.channelLinkParams.result}
                            />
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        );
    }
}

export default MallProductList;
