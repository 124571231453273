import React from 'react';
import {
    Button,
    Form,
    Input,
    message
} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import {
    create,
    getInfo,
    update
} from '../../api/painter/PainterPageApi';
import LoadingLayer from '../common/LoadingLayer';

class EditPainterPage extends React.Component {

    state = {
        id: this.props.value,
        loading: false,
        tip: '',
        name: '',
        pageCode: ''
    };

    componentDidMount = async _ => {
        if (this.state.id) {
            this.setState({
                loading: true,
                tip: '数据加载中'
            });
            let response = await getInfo(this.state.id);
            let { code, data } = response.data;
            if (code === 200) {
                this.setState({
                    name: data.name,
                    pageCode: data.pageCode,
                    loading: false
                });
            }
        }
    }

    formHandler = {
        onNameChange: e => {
            this.setState({
                name: e.target.value
            });
        },
        onPageCodeChange: e => {
            this.setState({
                pageCode: e.target.value
            });
        },
        onSubmit: async _ => {
            let params = {
                name: this.state.name,
                pageCode: this.state.pageCode
            };
            if (this.state.id) {
                this.setState({
                    loading: true,
                    tip: '数据提交中'
                });
                let response = await update(this.state.id, params);
                let { code } = response.data;
                this.setState({
                    loading: false
                });
                if (code === 200) {
                    message.success('页面更新成功');
                    if (this.props.onSuccess) {
                        this.props.onSuccess();
                    }
                }
            } else {
                this.setState({
                    loading: true,
                    tip: '数据提交中'
                });
                let response = await create(params);
                let { code } = response.data;
                this.setState({
                    loading: false
                });
                if (code === 200) {
                    message.success('页面创建成功');
                    if (this.props.onSuccess) {
                        this.props.onSuccess();
                    }
                }
            }
        }
    }

    render() {
        return (
            <>
                <LoadingLayer loading={this.state.loading} tip={this.state.tip}/>
                <PageHeader
                    title={this.state.id ? '编辑页面' : '新建页面'}
                />
                <Form
                    labelCol={{ span: 2 }}
                    wrapperCol={{ span: 10 }}
                    layout="horizontal"
                    style={{ marginTop: 10 }}
                >
                    <Form.Item label="名称">
                        <Input value={this.state.name} onChange={this.formHandler.onNameChange}/>
                    </Form.Item>
                    <Form.Item label="页面编码">
                        <Input value={this.state.pageCode} onChange={this.formHandler.onPageCodeChange}/>
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 2 }}>
                        <Button type="primary" onClick={this.formHandler.onSubmit}>提交</Button>
                    </Form.Item>
                </Form>
            </>
        );
    }
}

export default EditPainterPage;
