import RequestUtil from "../../util/request/RequestUtil";

export function create(params) {
    let data = {
        pageCode: params.pageCode,
	    name: params.name
    };
    return RequestUtil.getInstance().post('/painter/pagePlan', data);
}

export function update(id, params) {
    let data = {
        pageCode: params.pageCode,
	    name: params.name
    };
    return RequestUtil.getInstance().put(`/painter/pagePlan/${id}`, data);
}

export function triggerStatus(id) {
    return RequestUtil.getInstance().put(`/painter/pagePlan/triggerStatus/${id}`);
}

export function getInfo(id) {
    return RequestUtil.getInstance().get(`/painter/pagePlan/${id}`);
}

export function getList(pageNum = 1, pageSize = 10) {
    return RequestUtil.getInstance().get(`/painter/pagePlan/list?pageNum=${pageNum}&pageSize=${pageSize}`);
}
