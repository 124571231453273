import React from 'react';
import moment from 'moment';
import {
    Button,
    Form,
    Input,
    message,
    DatePicker,
    Table,
    Space
} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import {
    addActivityPage,
    updateActivityPage,
    getActivityPage
} from '../../api/ActivityPageApi';
import UploadImage from '../upload/Image';
import UploadFileSpu from '../upload/FileSpu';
import LoadingLayer from '../common/LoadingLayer';
import CouponSelector from '../selector/CouponSelector';

const { RangePicker } = DatePicker;

class EditActivityPage extends React.Component {

    state = {
        id: this.props.value,
        loading: false,
        tip: '',
        name: '',
        title: '',
        startTime: '',
        endTime: '',
        showTime: [],
        headerPic: '',
        headerPicJumpUrl: '',
        bottomPic: '',
        bottomPicJumpUrl: '',
        navColor: '#ffffff',
        backgroundColor: '#ffffff',
        shareTitle: '',
        sharePic: '',
        productIds: [],
        productList: [],
        tableLoading: false,
        couponIds: []
    };

    componentDidMount = async _ => {
        if (this.state.id) {
            this.setState({
                loading: true,
                tip: '数据加载中'
            });
            let response = await getActivityPage(this.state.id);
            let { code, data } = response.data;
            if (code === 200) {
                let productList = data.productList
                let productIds = []
                if (productList && productList.length > 0) {
                    productList.forEach(item => productIds.push(item.productId))
                }

                let startTime = data.startTime ? moment(data.startTime).format() : null;
                let endTime = data.endTime ? moment(data.endTime).format() : null;

                let couponIds = []
                if (data.voucherList) {
                    data.voucherList.forEach(item => couponIds.push(item.voucherId))
                }

                this.setState({
                    loading: false,
                    name: data.innerName,
                    title: data.title,
                    startTime: startTime,
                    endTime: endTime,
                    showTime: [startTime ? moment(data.startTime) : null, endTime ? moment(data.endTime) : null],
                    headerPic: data.headerPic,
                    headerPicJumpUrl: data.headerPicRoute,
                    bottomPic: data.bottomPic,
                    bottomPicJumpUrl: data.bottomPicRoute,
                    navColor: data.topBackgroundColor,
                    backgroundColor: data.pageBackgroundColor,
                    shareTitle: data.shareTitle,
                    sharePic: data.sharePic,
                    productIds: productIds,
                    productList: productList,
                    couponIds: couponIds
                });
            }
        }
    }

    formHandler = {
        onNameChange: e => {
            this.setState({
                name: e.target.value
            });
        },
        onTitleChange: e => {
            this.setState({
                title: e.target.value
            });
        },
        onShowTimeChange: (dates, dateStrings) => {
            this.setState({
                showTime: dates,
                startTime: dates[0].format(),
                endTime: dates[1].format()
            });
        },
        onHeaderPicChange: value => {
            this.setState({
                headerPic: value
            });
        },
        onHeaderPicJumpUrlChange: e => {
            this.setState({
                headerPicJumpUrl: e.target.value
            });
        },
        onBottomPicChange: value => {
            this.setState({
                bottomPic: value
            });
        },
        onBottomPicJumpUrlChange: e => {
            this.setState({
                bottomPicJumpUrl: e.target.value
            });
        },
        onNavColorChange: e => {
            this.setState({
                navColor: e.target.value
            });
        },
        onBackgroundColorChange: e => {
            this.setState({
                backgroundColor: e.target.value
            });
        },
        onShareTitleChange: e => {
            this.setState({
                shareTitle: e.target.value
            });
        },
        onSharePicChange: value => {
            this.setState({
                sharePic: value
            });
        },
        onFileSpuChange: data => {
            let productIds = []
            if (data) {
                data.forEach(item => productIds.push(item.productId))
            }
            this.setState({
                productList: data ? data : [],
                productIds: productIds
            });
        },
        onCouponChange: data => {
            this.setState({
                couponIds: data
            });
        },
        onSubmit: async _ => {
            if (this.state.id) {
                this.setState({
                    loading: true,
                    tip: '数据提交中'
                });
                let response = await updateActivityPage(this.state.id, this.state);
                let { code } = response.data;
                this.setState({
                    loading: false
                });
                if (code === 200) {
                    message.success('页面更新成功');
                    if (this.props.onSuccess) {
                        this.props.onSuccess();
                    }
                }
            } else {
                this.setState({
                    loading: true,
                    tip: '数据提交中'
                });
                let response = await addActivityPage(this.state);
                let { code } = response.data;
                this.setState({
                    loading: false
                });
                if (code === 200) {
                    message.success('活动页面创建成功');
                    if (this.props.onSuccess) {
                        this.props.onSuccess();
                    }
                }
            }
        }
    }

    render() {
        const columns = [{
            title: '商品ID',
            dataIndex: 'productId',
            key: 'productId'
        }, {
            title: '商品名称',
            dataIndex: 'productName',
            key: 'productName'
        }, {
            title: 'spu编码',
            dataIndex: 'spu',
            key: 'spu'
        }];

        return (
            <>
                <LoadingLayer loading={this.state.loading} tip={this.state.tip}/>
                <PageHeader
                    title={this.state.id ? '编辑活动页面' : '新建活动页面'}
                />
                <Form
                    labelCol={{ span: 2 }}
                    wrapperCol={{ span: 10 }}
                    layout="horizontal"
                    style={{ marginTop: 10 }}
                >
                    <Form.Item label="活动名称">
                        <Input value={this.state.name} onChange={this.formHandler.onNameChange}/>
                    </Form.Item>
                    <Form.Item label="页面标题">
                        <Input value={this.state.title} onChange={this.formHandler.onTitleChange}/>
                    </Form.Item>
                    <Form.Item label="活动周期">
                        <RangePicker showTime value={this.state.showTime} onChange={this.formHandler.onShowTimeChange}/>
                    </Form.Item>
                    <Form.Item label="活动头图">
                        <UploadImage value={this.state.headerPic} onChange={this.formHandler.onHeaderPicChange}/>
                    </Form.Item>
                    <Form.Item label="点击头图跳转">
                        <Input value={this.state.headerPicJumpUrl} onChange={this.formHandler.onHeaderPicJumpUrlChange}/>
                    </Form.Item>
                    <Form.Item label="活动底图">
                        <UploadImage value={this.state.bottomPic} onChange={this.formHandler.onBottomPicChange}/>
                    </Form.Item>
                    <Form.Item label="点击底图跳转">
                        <Input value={this.state.bottomPicJumpUrl} onChange={this.formHandler.onBottomPicJumpUrlChange}/>
                    </Form.Item>
                    <Form.Item label="导航区域背景色">
                        <Input value={this.state.navColor} onChange={this.formHandler.onNavColorChange}/>
                    </Form.Item>
                    <Form.Item label="背景色">
                        <Input value={this.state.backgroundColor} onChange={this.formHandler.onBackgroundColorChange}/>
                    </Form.Item>
                    <Form.Item label="分享标题">
                        <Input value={this.state.shareTitle} onChange={this.formHandler.onShareTitleChange}/>
                    </Form.Item>
                    <Form.Item label="分享图片">
                        <UploadImage value={this.state.sharePic} onChange={this.formHandler.onSharePicChange}/>
                    </Form.Item>
                    <Form.Item label="优惠券">
                        <CouponSelector value={this.state.couponIds} onChange={this.formHandler.onCouponChange}/>
                    </Form.Item>
                    <Form.Item label="关联商品">
                        <Space>
                            <UploadFileSpu onChange={this.formHandler.onFileSpuChange}/>
                            <a href='https://bj-prod-1309320985.cos.ap-beijing.myqcloud.com/20220525/3bccc9d45d2c7d78f9fe4fa81177a33.xls' target="_blank">下载模版</a>
                        </Space>
                        <br/><br/>
                        <Table
                            loading={this.state.tableLoading}
                            size="small"
                            columns={columns}
                            dataSource={this.state.productList}
                            bordered
                            pagination={false}
                        />
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 2 }}>
                        <Button type="primary" onClick={this.formHandler.onSubmit}>提交</Button>
                    </Form.Item>
                </Form>
            </>
        );
    }
}

export default EditActivityPage;
