import React from 'react';
import { Select } from 'antd';
import { getTabs } from '../../api/PromotionApi';

const { Option } = Select;

class PromotionTabSelector extends React.Component {

    state = {
        list: [],
        value: ''
    };

    componentDidMount = async _ => {
        this.setState({
            value: this.props.value
        });
        await this.getTabs(this.props.promotionId);
    }

    componentWillReceiveProps = async props => {
        if (props.promotionId) {
            await this.getTabs(props.promotionId);
            this.setState({
                value: props.value ? props.value : undefined
            });
        }
    }

    getTabs = async promotionId => {
        let response = await getTabs(promotionId);
        let { code, data } = response.data;
        if (code === 200 && data) {
            this.setState({
                list: data
            });
        }
    }

    onChange = key => {
        if (this.props.onChange) {
            this.props.onChange(key);
        }
    }

    render() {
        const options = this.state.list.map(item => <Option key={item.id}>{item.title}</Option>)

        return (
            <>
                <Select
                    allowClear
                    showSearch
                    style={{ width: this.props.width ? this.props.width : 300 }}
                    placeholder="选择一个touchBar"
                    value={this.state.value}
                    optionFilterProp="children"
                    onChange={this.onChange}
                    filterOption={(input, option) =>
                        option.children.toLocaleLowerCase().indexOf(input.toLocaleLowerCase()) >= 0
                    }
                >
                    {options}
                </Select>
            </>
        );
    }
}

export default PromotionTabSelector;
