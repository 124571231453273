import React from 'react';
import CommonLayout from '../../components/layout/CommonLayout';
import StockList from '../../components/stock/StockList';

class Stock extends React.Component {

    state = {
        categoryId: this.props.match.params.id
    };

    componentWillReceiveProps = props => {
        this.setState({
            categoryId: props.match.params.id
        });
    }

    render() {
        return (
            <CommonLayout
                topNavSelectedKey="stock"
                siderNavCategory="stock"
                siderNavSelectedKey={`list`}
            >
                <StockList categoryId={this.state.categoryId}/>
            </CommonLayout>
        );
    }
}

export default Stock;
