import RequestUtil from "../../util/request/RequestUtil";

export function create(params) {
    let data = {
        css: params.css,
        pagePlanId: params.pagePlanId,
        sort: params.sort,
        templateCode: params.templateCode
    };
    return RequestUtil.getInstance().post('/painter/pagePlanTemplate', data);
}

export function update(id, params) {
    let data = {
        css: params.css
    };
    return RequestUtil.getInstance().put(`/painter/pagePlanTemplate/${id}`, data);
}

export function updateSort(params = []) {
    let data = [];
    params.forEach(item => {
        data.push({
            id: item.id,
            sort: item.sort
        });
    });
    return RequestUtil.getInstance().put(`/painter/pagePlanTemplate/sort`, data);
}

export function deleteInfo(id) {
    return RequestUtil.getInstance().delete(`/painter/pagePlanTemplate/${id}`);
}

export function getInfo(id) {
    return RequestUtil.getInstance().get(`/painter/pagePlanTemplate/${id}`);
}

export function getList(pagePlanId = '', pageNum = 1, pageSize = 10) {
    return RequestUtil.getInstance().get(`/painter/pagePlanTemplate/list?pagePlanId=${pagePlanId}&pageNum=${pageNum}&pageSize=${pageSize}`);
}
