import React from 'react';
import {
    Button,
    Form,
    Input,
    message,
    Radio,
    Divider
} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import {
    getInfo,
    editInfo
} from '../../api/OperationApi';
import UploadImage from '../upload/Image';
import LoadingLayer from '../common/LoadingLayer';

class EditOperation extends React.Component {

    state = {
        id: this.props.value,
        loading: false,
        tip: '',
        float: {
            enabledStatus: 0,
            image: '',
            jumpUrl: '',
            type: 1
        },
        payPopup: {
            enabledStatus: 0,
            image: '',
            jumpUrl: '',
            type: 2
        }
    };

    componentDidMount = async _ => {
        this.setState({
            loading: true,
            tip: '数据加载中'
        });
        let response = await getInfo();
        this.setState({
            loading: false
        });
        let { code, data } = response.data;
        if (code === 200) {
            if (data.operationsMapping) {
                this.setState({
                    float: data.operationsMapping[1],
                    payPopup: data.operationsMapping[2]
                })
            }
        }
    }

    formHandler = {
        onStatusChange: (type, e) => {
            if (type === 1) {
                let data = this.state.float
                data.enabledStatus = e.target.value
                this.setState({
                    float: data
                });
            } else {
                let data = this.state.payPopup
                data.enabledStatus = e.target.value
                this.setState({
                    payPopup: data
                });
            }
        },
        onImageChange: (type, value) => {
            if (type === 1) {
                let data = this.state.float
                data.image = value
                this.setState({
                    float: data
                });
            } else {
                let data = this.state.payPopup
                data.image = value
                this.setState({
                    payPopup: data
                });
            }
        },
        onJumpUrlChange: (type, e) => {
            if (type === 1) {
                let data = this.state.float
                data.jumpUrl = e.target.value
                this.setState({
                    float: data
                });
            } else {
                let data = this.state.payPopup
                data.jumpUrl = e.target.value
                this.setState({
                    payPopup: data
                });
            }
        },
        onSubmit: async _ => {
            this.setState({
                loading: true,
                tip: '数据提交中'
            });
            let response = await editInfo(this.state.float, this.state.payPopup);
            let { code } = response.data;
            this.setState({
                loading: false
            });
            if (code === 200) {
                message.success('更新成功');
                if (this.props.onSuccess) {
                    this.props.onSuccess();
                }
            }
        }
    }

    render() {
        return (
            <>
                <LoadingLayer loading={this.state.loading} tip={this.state.tip}/>
                <PageHeader title="首页浮窗"/>
                <Form
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 10 }}
                    layout="horizontal"
                    style={{ marginTop: 10 }}
                >
                    <Form.Item label="开关">
                        <Radio.Group onChange={this.formHandler.onStatusChange.bind(this, 1)} value={this.state.float.enabledStatus}>
                            <Radio value={0}>关闭</Radio>
                            <Radio value={1}>开启</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item label="图片上传">
                        <UploadImage value={this.state.float.image} onChange={this.formHandler.onImageChange.bind(this, 1)}/>
                    </Form.Item>
                    <Form.Item label="跳转地址">
                        <Input value={this.state.float.jumpUrl} onChange={this.formHandler.onJumpUrlChange.bind(this, 1)}/>
                    </Form.Item>
                </Form>

                <Divider/>

                <PageHeader title="支付成功弹窗"/>
                <Form
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 10 }}
                    layout="horizontal"
                    style={{ marginTop: 10 }}
                >
                    <Form.Item label="开关">
                        <Radio.Group onChange={this.formHandler.onStatusChange.bind(this, 2)} value={this.state.payPopup.enabledStatus}>
                            <Radio value={0}>关闭</Radio>
                            <Radio value={1}>开启</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item label="图片上传">
                        <UploadImage value={this.state.payPopup.image} onChange={this.formHandler.onImageChange.bind(this, 2)}/>
                    </Form.Item>
                    <Form.Item label="跳转地址">
                        <Input value={this.state.payPopup.jumpUrl} onChange={this.formHandler.onJumpUrlChange.bind(this, 2)}/>
                    </Form.Item>
                </Form>

                <Button type="primary" onClick={this.formHandler.onSubmit}>提交</Button>
            </>
        );
    }
}

export default EditOperation;
