import RequestUtil from "../util/request/RequestUtil";

export function createBrand(params) {
    let data = {
        bigPic: params.bigPic,
        brandStory: params.brandStory,
        factoryStatus: params.factoryStatus,
        firstLetter: params.firstLetter,
        logo: params.logo,
        name: params.name,
        showStatus: params.showStatus,
        sort: params.sort
    };
    return RequestUtil.getInstance().post('/brand/create', data);
}

export function getList(keyword = '', pageNum = 1, pageSize = 5) {
    return RequestUtil.getInstance().get(`/brand/list?keyword=${keyword}&pageNum=${pageNum}&pageSize=${pageSize}`);
}

export function deleteBrand(id) {
    return RequestUtil.getInstance().get(`/brand/delete/${id}`);
}

export function updateBrand(id, params) {
    let data = {
        bigPic: params.bigPic,
        brandStory: params.brandStory,
        factoryStatus: params.factoryStatus,
        firstLetter: params.firstLetter,
        logo: params.logo,
        name: params.name,
        showStatus: params.showStatus,
        sort: params.sort
    };
    return RequestUtil.getInstance().post(`/brand/update/${id}`, data);
}

export function getInfo(id) {
    return RequestUtil.getInstance().get(`/brand/${id}`);
}
