import React from 'react';
import { Upload, message } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

class UploadVideo extends React.Component {

    state = {
        videoUrl: '',
        loading: false
    };

    beforeUpload = file => {
        const isMpegOrMp4 = file.type === 'video/webm' || file.type === 'video/mp4' || file.type === 'video/avi'
            || file.type === 'video/mpeg4' || file.type === 'video/x-ms-wmv' || file.type === 'video/x-flv'
            || file.type === 'video/vnd.dlna.mpeg-tts' || file.type === 'video/mpg' || file.type === 'video/vob';
        if (!isMpegOrMp4) {
            message.error('You can only upload video file!');
        }

        let maxFileSize = this.props.maxFileSize ? this.props.maxFileSize : 100;
        const isLt100M = file.size / 1024 / 1024 < maxFileSize;
        if (!isLt100M) {
            message.error(`Image must smaller than ${maxFileSize}MB!`);
        }
        return isMpegOrMp4 && isLt100M;
    }

    handleChange = info => {
        if (info.file.status === 'uploading') {
            this.setState({
                loading: true
            });
        }

        // todo: 上传oss后的url在info里
        if (info.file.status === 'done') {
            if (info.file.response.code === 200) {
                this.setState({
                    videoUrl: info.file.response.data.url,
                    loading: false
                }, () => {
                    if (this.props.onChange) {
                        this.props.onChange(this.state.videoUrl);
                    }
                });
            }
        }
    };

    render() {
        let { videoUrl, loading } = this.state;
        const value = this.props.value;
        videoUrl = value ? value : videoUrl;
        const uploadButton = (
            <div>
                {loading ? <LoadingOutlined /> : <PlusOutlined />}
                <div style={{ marginTop: 8 }}>Upload</div>
            </div>
        );

        return (
            <Upload
                name="file"
                listType="picture-card"
                showUploadList={false}
                action={process.env.REACT_APP_MINIO_API}
                beforeUpload={this.beforeUpload}
                onChange={this.handleChange}
            >
                {videoUrl ? <video src={videoUrl} style={{ width: '100%' }} /> : uploadButton}
            </Upload>
        );
    }
}

export default UploadVideo;
