import React from 'react';
import CommonLayout from '../../components/layout/CommonLayout';
import StoreBrandList from '../../components/store/BrandList';

class StoreBrand extends React.Component {

    render() {
        return (
            <CommonLayout
                topNavSelectedKey="mall"
                siderNavCategory="mall"
                siderNavSelectedKey="brand"
            >
                <StoreBrandList/>
            </CommonLayout>
        );
    }
}

export default StoreBrand;
