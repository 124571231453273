import React from 'react';
import { Select } from 'antd';
import { getList } from '../../api/ProductAttributeApi';

const { Option } = Select;

class ProductAttributeSelector extends React.Component {

    state = {
        list: [],
        cid: this.props.categoryId ? this.props.categoryId : 0,
        type: this.props.type ? this.props.type : 0
    };

    componentDidMount = async _ => {
        if (this.state.cid) {
            await this.getAttributeList();
        }
    }

    componentWillReceiveProps = props => {
        if (props.categoryId && props.categoryId !== this.state.cid) {
            this.setState({
                cid: props.categoryId,
                type: props.type
            }, async _ => await this.getAttributeList());
        }
    }

    getAttributeList = async _ => {
        let response = await getList(this.state.cid, this.state.type, 1, 500);
        let { code, data } = response.data;
        if (code === 200) {
            this.setState({
                list: data.list
            });
        }
    }

    onChange = value => {
        if (this.props.onChange) {
            let data;
            this.state.list.forEach(item => {
                if (item.id === value) {
                    data = item;
                }
            });
            this.props.onChange(value ? value : 0, data);
        }
    }

    render() {
        const options = this.state.list.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)

        return (
            <Select
                allowClear
                showSearch
                style={{ width: this.props.width ? this.props.width : 200 }}
                placeholder="选择一个属性"
                value={this.props.value ? parseInt(this.props.value) : undefined}
                optionFilterProp="children"
                onChange={this.onChange}
                filterOption={(input, option) =>
                    option.children.indexOf(input) >= 0
                }
            >
                {options}
            </Select>
        );
    }
}

export default ProductAttributeSelector;
