import React from 'react';
import { Select, Space } from 'antd';
import { getListByParentId } from '../../api/ProductCategoryApi';
import { fuzzyByCategoryId } from '../../api/ProductApi';

const { Option } = Select;

class ProductCategoryMultiClassSelector extends React.Component {

    state = {
        firstClass: [],
        secondClass: [],
        products: [],
        selectedFirstId: 0,
        selectedSecondId: 0,
        selectedProductIds: []
    };

    componentDidMount = async _ => {
        await this.getListByParentId(0);
        await this.fuzzyByCategoryId(0);
    }

    componentWillReceiveProps = async props => {
        if (props.value) {
            let products = this.state.products;
            let value = []
            let valueSet = new Set();
            props.value.forEach(item => valueSet.add(item));
            products.forEach(item => {
                if (valueSet.has(item.productId.toString())) {
                    value.push(item.productId.toString());
                }
            });

            this.setState({
                selectedProductIds: value
            });
        }
    }

    getListByParentId = async parentId => {
        parentId = parentId ? parentId : 0;
        let response = await getListByParentId(parentId);
        let { code, data } = response.data;
        if (code === 200) {
            if (parentId === 0) {
                this.setState({
                    firstClass: data
                });
            } else {
                this.setState({
                    secondClass: data
                });
            }
        }
    }

    fuzzyByCategoryId = async parentId => {
        let response = await fuzzyByCategoryId(parentId);
        let { code, data } = response.data;
        if (code === 200) {
            let value = []
            let valueSet = new Set();
            this.state.selectedProductIds.forEach(item => valueSet.add(item));
            data.forEach(item => {
                if (valueSet.has(item.productId.toString())) {
                    value.push(item.productId.toString());
                }
            });
            this.setState({
                products: data,
                selectedProductIds: value
            });
        }
    }

    onFirstClassChange = async parentId => {
        this.setState({
            selectedFirstId: parentId,
            selectedSecondId: 0
        });
        await this.getListByParentId(parentId);
        await this.fuzzyByCategoryId(parentId);

        if (this.props.onClear) {
            this.props.onClear();
        }
    }

    onSecondClassChange = async parentId => {
        this.setState({
            selectedSecondId: parentId
        });
        await this.getListByParentId(parentId);
        await this.fuzzyByCategoryId(parentId);

        if (this.props.onClear) {
            this.props.onClear();
        }
    }

    onProductIdChange = productIds => {
        if (this.props.onChange) {
            this.props.onChange(productIds);
        }
    }

    render() {
        const firstClassOptions = this.state.firstClass.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)
        const secondClassOptions = this.state.secondClass.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)
        const productOptions = this.state.products.map(item => <Option key={item.productId} value={item.productId}>{item.productName}</Option>)

        return (
            <>
                <Space>
                    <Select
                        allowClear
                        showSearch
                        style={{ width: this.props.width ? this.props.width : 140 }}
                        placeholder="选择一级品类"
                        value={this.state.selectedFirstId ? parseInt(this.state.selectedFirstId) : undefined}
                        optionFilterProp="children"
                        onChange={this.onFirstClassChange}
                        filterOption={(input, option) =>
                            option.children.toLocaleLowerCase().indexOf(input.toLocaleLowerCase()) >= 0
                        }
                    >
                        {firstClassOptions}
                    </Select>
                    <Select
                        allowClear
                        showSearch
                        style={{ width: this.props.width ? this.props.width : 140 }}
                        placeholder="选择二级品类"
                        value={this.state.selectedSecondId ? parseInt(this.state.selectedSecondId) : undefined}
                        optionFilterProp="children"
                        onChange={this.onSecondClassChange}
                        filterOption={(input, option) =>
                            option.children.toLocaleLowerCase().indexOf(input.toLocaleLowerCase()) >= 0
                        }
                    >
                        {secondClassOptions}
                    </Select>
                    <Select
                        allowClear
                        showSearch
                        mode="tags"
                        maxTagCount={3}
                        style={{ width: this.props.width ? this.props.width : 300 }}
                        placeholder="选择商品"
                        value={this.state.selectedProductIds ? this.state.selectedProductIds : []}
                        optionFilterProp="children"
                        onChange={this.onProductIdChange}
                        filterOption={(input, option) =>
                            option.children.toLocaleLowerCase().indexOf(input.toLocaleLowerCase()) >= 0
                        }
                    >
                        {productOptions}
                    </Select>
                </Space>
            </>
        );
    }
}

export default ProductCategoryMultiClassSelector;
