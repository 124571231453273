class ArrayUtil {

    // 上移动一格
    static upGo = (arr, index) => {
        if (index !== 0) {
            arr[index] = arr.splice(index - 1, 1, arr[index])[0];
        } else {
            arr.push(arr.shift());
        }
    }

    // 下移动一格
    static upDown = (arr, index) => {
        if (index !== arr.length - 1) {
            arr[index] = arr.splice(index + 1, 1, arr[index])[0];
        } else {
            arr.unshift(arr.splice(index, 1)[0]);
        }
    }

    static partition = (array, size) => {
        let newArr = []
        for (let i = 0; i < array.length;) {
            newArr.push(array.slice(i, i += size));
        }
        return newArr
    }

    static getArrDifference = (arr1, arr2) => {
        return arr1.concat(arr2).filter(function(v, i, arr) {
            return arr.indexOf(v) === arr.lastIndexOf(v);
        });
    }
}

export default ArrayUtil;
