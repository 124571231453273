import RequestUtil from "../util/request/RequestUtil";

export function getList(keyword = '', pageNum = 1, pageSize = 5) {
    return RequestUtil.getInstance().get(`/admin/list?keyword=${keyword}&pageNum=${pageNum}&pageSize=${pageSize}`);
}

export function updateStatus(userId, status) {
    return RequestUtil.getInstance().post(`/admin/updateStatus/${userId}?status=${status}`);
}

export function register(params) {
    let data = {
        email: params.email,
        icon: params.icon,
        nickName: params.nickName,
        note: params.note,
        password: params.password,
        username: params.username
    };
    return RequestUtil.getInstance().post('/admin/register', data);
}

export function getInfo(userId) {
    return RequestUtil.getInstance().get(`/admin/${userId}`);
}

export function updateInfo(userId, params) {
    let data = {
        id: userId,
        email: params.email,
        icon: params.icon,
        nickName: params.nickName,
        note: params.note,
        password: params.password,
        username: params.username
    };
    return RequestUtil.getInstance().post(`/admin/update/${userId}`, data);
}

export function updatePassword(username, newPassword, oldPassword) {
    let data = {
        newPassword: newPassword,
        oldPassword: oldPassword,
        username: username
    }
    return RequestUtil.getInstance().post(`/admin/updatePassword`, data);
}
