import React from 'react';
import {
    Row,
    Col,
    Table,
    Input,
    Space,
    Button,
    Tag,
    Select,
    DatePicker,
    Modal,
    Form,
    Image,
 message,    Radio
} from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';
import LoadingLayer from '../common/LoadingLayer';
import {
    getList,
    orderExport,
    refundConfirm,
    refundFollowMerchant,
    refundDone
} from '../../api/OrderApi';
import '../../css/order/orderList.scss';

const { Option } = Select;
const { RangePicker } = DatePicker;

const orderStatus = {
    0: {
        name: '待付款',
        color: ''
    },
    1: {
        name: '待发货',
        color: 'magenta'
    },
    2: {
        name: '已发货',
        color: 'lime'
    },
    3: {
        name: '已完成',
        color: '#87d068'
    },
    4: {
        name: '已关闭',
        color: 'purple'
    },
    5: {
        name: '无效订单',
        color: ''
    },
    99: {
        name: '已退款',
        color: 'blue'
    }
};

const orderRefundStatus = {
    0: {
        name: '商家未收货',
        color: 'purple'
    },
    1: {
        name: '商家已收货',
        color: 'gold'
    },
    2: {
        name: '商家已换货/已退款',
        color: 'blue'
    }
};

const orderRefundApplyStatus = {
    0: {
        name: '待处理',
        color: ''
    },
    1: {
        name: '审核通过',
        color: 'green'
    },
    2: {
        name: '驳回',
        color: 'red'
    },
    3: {
        name: '已完成',
        color: '#87d068'
    }
};

class OrderList extends React.Component {

    state = {
        loading: false,
        loadingLayer: false,
        tip: '',
        keyword: '',
        list: [],
        pageNum: 1,
        pageSize: 20,
        total: 0,
        searchParams: {
            orderSn: '',
            orderType: '',
            receiverKeyword: '',
            sourceType: '',
            status: '',
            createTime: '',
            showTime: [],
            startTime: 0,
            endTime: 0
        },
        refundInfoVis: false,
        refundInfo: {
            type: 1,
            descText: '',
            images: []
        },
        rejectInfoVis: false,
        rejectInfo: {
            orderId: '',
            touchStatus: 0,
            confirmStatus: 2,
            confirmDesc: ''
        },
        confirmInfoVis: false,
        confirmInfo: {
            orderId: '',
            confirmStatus: 1
        },
        merchantInfoVis: false,
        merchantInfo: {
            orderId: '',
            merchantStatus: 1
        },
        refundDoneVis: false,
        refundDoneInfo: {
            orderId: ''
        }
    };

    componentDidMount = async _ => {
        await this.getOrderList();
    }

    getOrderList = async _ => {
        this.setState({
            loading: true
        });
        let response = await getList(this.state.searchParams, this.state.pageNum, this.state.pageSize);
        let { code, data } = response.data;
        this.setState({
            loading: false
        });
        if (code === 200) {
            data.list.map(item => {
                item.key = item.id
                return item
            });
            this.setState({
                total: data.total,
                list: data.list
            });
        }
    }

    exportOrder = async _ => {
        this.setState({
            loading: true
        });
        let response = await orderExport(this.state.searchParams);
        this.setState({
            loading: false
        });
        let { code, data } = response.data;
        if (code === 200) {
            window.open(data.url);
        }
    }

    onPageChange = (pageNum, pageSize) => {
        this.setState({
            pageNum: pageNum,
            pageSize: pageSize
        }, () => this.getOrderList());
    }

    searchHandler = {
        onOrderSnChange: e => {
            let searchParams = this.state.searchParams;
            searchParams.orderSn = e.target.value
            this.setState({
                searchParams: searchParams
            });
        },
        onReceiverKeywordChange: e => {
            let searchParams = this.state.searchParams;
            searchParams.receiverKeyword = e.target.value;
            this.setState({
                searchParams: searchParams
            });
        },
        onStatusChange: value => {
            let searchParams = this.state.searchParams;
            searchParams.status = value;
            this.setState({
                searchParams: searchParams
            });

            this.searchHandler.onSearch();
        },
        onShowTimeChange: (dates, dateStrings) => {
            let searchParams = this.state.searchParams;
            searchParams.showTime = dates;
            searchParams.startTime = parseInt(dates[0].valueOf() / 1000);
            searchParams.endTime = parseInt(dates[1].valueOf() / 1000);
            this.setState({
                searchParams: searchParams
            });
        },
        onSearch: async _ => {
            this.setState({
                pageNum: 1
            }, async _ => await this.getOrderList());
        }
    }

    onRefundInfoModalShow = info => {
        this.setState({
            refundInfoVis: true,
            refundInfo: {
                type: info.type,
                descText: info.descText,
                images: info.images
            }
        })
    }

    rejectInfoHandler = {
        onRejectInfoModalShow: orderId => {
            this.setState({
                rejectInfoVis: true,
                rejectInfo: {
                    orderId: orderId,
                    touchStatus: 0,
                    confirmStatus: 2,
                    confirmDesc: ''
                }
            })
        },
        onTouchStatusChange: e => {
            let rejectInfo = this.state.rejectInfo
            rejectInfo.touchStatus = e.target.value
            this.setState({
                rejectInfo: rejectInfo
            })
        },
        onConfirmDescChange: e => {
            let rejectInfo = this.state.rejectInfo
            rejectInfo.confirmDesc = e.target.value
            this.setState({
                rejectInfo: rejectInfo
            })
        },
        onSubmit: async _ => {
            if (this.state.rejectInfo.touchStatus === 0) {
                message.warning('请与买家沟通后再申请驳回')
                return
            }
            if (!this.state.rejectInfo.confirmDesc) {
                message.warning('请填写驳回原因')
                return
            }

            this.setState({
                loadingLayer: true,
                tip: '提交中'
            });
            let response = await refundConfirm(this.state.rejectInfo);
            this.setState({
                loadingLayer: false
            });
            let { code } = response.data;
            if (code === 200) {
                this.setState({
                    rejectInfoVis: false
                })
                message.success('驳回成功')
                await this.getOrderList()
            }
        }
    }

    onConfirmInfoModalShow = orderId => {
        this.setState({
            confirmInfoVis: true,
            confirmInfo: {
                orderId: orderId,
                confirmStatus: 1
            }
        })
    }

    onConfirmInfoSubmit = async _ => {
        this.setState({
            loadingLayer: true,
            tip: '提交中'
        });
        let response = await refundConfirm(this.state.confirmInfo);
        this.setState({
            loadingLayer: false
        });
        let { code } = response.data;
        if (code === 200) {
            this.setState({
                confirmInfoVis: false
            })
            message.success('成功')
            await this.getOrderList()
        }
    }

    onMerchantInfoModalShow = (orderId, status) => {
        this.setState({
            merchantInfoVis: true,
            merchantInfo: {
                orderId: orderId,
                merchantStatus: status
            }
        })
    }

    onMerchantInfoSubmit = async _ => {
        this.setState({
            loadingLayer: true,
            tip: '提交中'
        });
        let response = await refundFollowMerchant(this.state.merchantInfo);
        this.setState({
            loadingLayer: false
        });
        let { code } = response.data;
        if (code === 200) {
            this.setState({
                merchantInfoVis: false
            })
            message.success('成功')
            await this.getOrderList()
        }
    }

    onRefundDoneModalShow = orderId => {
        this.setState({
            refundDoneVis: true,
            refundDoneInfo: {
                orderId: orderId
            }
        })
    }

    onRefundDoneSubmit = async _ => {
        this.setState({
            loadingLayer: true,
            tip: '提交中'
        });
        let response = await refundDone(this.state.refundDoneInfo.orderId);
        this.setState({
            loadingLayer: false
        });
        let { code } = response.data;
        if (code === 200) {
            this.setState({
                refundDoneVis: false
            })
            message.success('成功')
            await this.getOrderList()
        }
    }

    render() {
        const columns = [{
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: 60,
            fixed: 'left'
        }, {
            title: '订单编号',
            dataIndex: 'orderSn',
            key: 'orderSn',
            width: 140,
            fixed: 'left'
        }, {
            title: '订单标题',
            dataIndex: 'orderTitle',
            key: 'orderTitle',
            width: 160,
            fixed: 'left'
        }, {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
            width: 80,
            fixed: 'left',
            render: status => (
                <Tag color={orderStatus[status].color}>{orderStatus[status].name}</Tag>
            )
        }, {
            title: '订单金额',
            dataIndex: 'totalAmount',
            key: 'totalAmount',
            width: 140,
            render: (totalAmount, item) => (
                <>
                    <div>总金额：{totalAmount}</div>
                    <div>实付金额：{item.payAmount}</div>
                </>
            )
        }, {
            title: '时间信息',
            dataIndex: 'createTime',
            key: 'createTime',
            width: 220,
            render: (createTime, item) => (
                <>
                    <div>下单时间：{createTime ? moment(createTime).format('yyyy-MM-DD HH:mm:ss') : ''}</div>
                    <div>支付时间：{item.paymentTime ? moment(item.paymentTime).format('yyyy-MM-DD HH:mm:ss') : ''}</div>
                </>
            )
        }, {
            title: '订单备注',
            dataIndex: 'note',
            key: 'note',
            width: 100
        }, {
            title: '收件人',
            dataIndex: 'receiverName',
            key: 'receiverName',
            width: 160,
            render: (receiverName, item) => (
                <>
                    <div>姓名：{receiverName}</div>
                    <div>手机号：{item.receiverPhone}</div>
                </>
            )
        }, {
            title: '收货地址',
            dataIndex: 'receiverDetailAddress',
            key: 'receiverDetailAddress',
            width: 200,
            render: (address, record) => (
                <>{record.receiverProvince + record.receiverCity + record.receiverRegion + address}</>
            )
        }, {
            title: '确认收货状态',
            dataIndex: 'confirmStatus',
            key: 'confirmStatus',
            width: 120,
            render: status => (
                status === 1 ? <Tag color="#87d068">已确认</Tag> : <Tag>未确认</Tag>
            )
        }, {
            title: '退换货类型',
            dataIndex: 'orderReturnInfo',
            key: 'orderReturnInfo',
            width: 120,
            render: orderReturnInfo => {
                if (!orderReturnInfo) {
                    return '--'
                }

                let returnType = orderReturnInfo.type === 1 ? <Tag color="magenta">退货</Tag> : <Tag color="cyan">换货</Tag>
                return (
                    <>
                        {returnType}
                    </>
                )
            }
        }, {
            title: '退换货处理状态',
            dataIndex: 'orderReturnInfo',
            key: 'orderReturnInfo',
            width: 120,
            render: orderReturnInfo => {
                if (!orderReturnInfo) {
                    return '--'
                }

                return <Tag color={orderRefundApplyStatus[orderReturnInfo.applyStatus].color}>{orderRefundApplyStatus[orderReturnInfo.applyStatus].name}</Tag>
            }
        }, {
            title: '退换货商家状态',
            dataIndex: 'orderReturnInfo',
            key: 'orderReturnInfo',
            width: 120,
            render: orderReturnInfo => {
                if (!orderReturnInfo) {
                    return '--'
                }

                return <Tag color={orderRefundStatus[orderReturnInfo.returnStatus].color}>{orderRefundStatus[orderReturnInfo.returnStatus].name}</Tag>
            }
        }, {
            title: '退换货描述/驳回描述',
            dataIndex: 'orderReturnInfo',
            key: 'orderReturnInfo',
            width: 200,
            render: orderReturnInfo => {
                if (!orderReturnInfo) {
                    return '--'
                }

                return <span>{orderReturnInfo.descText}</span>
            }
        }, {
            title: '供应商',
            dataIndex: 'supplierName',
            key: 'supplierName',
            width: 100,
            render: (supplierName, item) => (
                <div>{item.orderItemList[0].supplierName}</div>
            )
        }, {
            title: '操作',
            key: 'action',
            fixed: 'right',
            width: 200,
            render: (text, record) => {
                let returnBtn = ''
                if (record.orderReturnInfo) {
                    if (record.orderReturnInfo.applyStatus === 0) {
                        returnBtn = <div>
                            <Button type="link" onClick={this.onRefundInfoModalShow.bind(this, record.orderReturnInfo)}>查看退换原因</Button>
                            <Button type="link" onClick={this.onConfirmInfoModalShow.bind(this, record.id)}>同意申请</Button>
                            <Button style={{color: 'red'}} type="link" onClick={this.rejectInfoHandler.onRejectInfoModalShow.bind(this, record.id)}>驳回</Button>
                        </div>
                    } else if (record.orderReturnInfo.applyStatus === 2) {
                        returnBtn = <div>
                            <Button type="link" onClick={this.onRefundInfoModalShow.bind(this, record.orderReturnInfo)}>查看退换原因</Button>
                        </div>
                    } else if (record.orderReturnInfo.applyStatus === 1) {
                        if (record.orderReturnInfo.returnStatus === 0) {
                            returnBtn = <div>
                                <Button type="link" onClick={this.onRefundInfoModalShow.bind(this, record.orderReturnInfo)}>查看退换原因</Button>
                                <Button type="link" onClick={this.onMerchantInfoModalShow.bind(this, record.id, 1)}>商家已收货</Button>
                                <Button style={{color: 'red'}} type="link" onClick={this.rejectInfoHandler.onRejectInfoModalShow.bind(this, record.id)}>驳回</Button>
                            </div>
                        } else if (record.orderReturnInfo.returnStatus === 1) {
                            returnBtn = <div>
                                <Button type="link" onClick={this.onRefundInfoModalShow.bind(this, record.orderReturnInfo)}>查看退换原因</Button>
                                <Button type="link" onClick={this.onMerchantInfoModalShow.bind(this, record.id, 2)}>商家已换货/已退款</Button>
                                <Button style={{color: 'red'}} type="link" onClick={this.rejectInfoHandler.onRejectInfoModalShow.bind(this, record.id)}>驳回</Button>
                            </div>
                        } else if (record.orderReturnInfo.returnStatus === 2) {
                            if (record.orderReturnInfo.type === 1) {
                                returnBtn = <div>
                                    <Button type="link" onClick={this.onRefundInfoModalShow.bind(this, record.orderReturnInfo)}>查看退换原因</Button>
                                    <Button type="link" onClick={this.onRefundDoneModalShow.bind(this, record.id)}>确认打款</Button>
                                </div>
                            } else if (record.orderReturnInfo.type === 2) {
                                returnBtn = <div>
                                    <Button type="link" onClick={this.onRefundInfoModalShow.bind(this, record.orderReturnInfo)}>查看退换原因</Button>
                                    <Button type="link" onClick={this.onRefundDoneModalShow.bind(this, record.id)}>确认完成</Button>
                                </div>
                            }
                        }
                    } else if (record.orderReturnInfo.applyStatus === 3) {
                        returnBtn = <div>
                            <Button type="link" onClick={this.onRefundInfoModalShow.bind(this, record.orderReturnInfo)}>查看退换原因</Button>
                        </div>
                    }
                }
                return (
                    <Space size="middle">
                        <Link to={`/order/detail/${record.id}`}><Button type="link">查看</Button></Link>
                        {returnBtn}
                    </Space>
                )
            }
        }];

        return (
            <div className="OrderList">
                <LoadingLayer loading={this.state.loadingLayer} tip={this.state.tip}/>
                <Row>
                    <Col span={5}>
                        订单编号：<Input style={{ width: 200 }} value={this.state.searchParams.orderSn} onChange={this.searchHandler.onOrderSnChange} onPressEnter={this.searchHandler.onSearch}/>
                    </Col>
                    <Col span={6}>
                        收货人姓名/号码：<Input style={{ width: 200 }} value={this.state.searchParams.receiverKeyword} onChange={this.searchHandler.onReceiverKeywordChange} onPressEnter={this.searchHandler.onSearch}/>
                    </Col>
                    <Col span={5}>
                        状态：<Select className="select" allowClear value={this.state.searchParams.status} onChange={this.searchHandler.onStatusChange}>
                            <Option value={0}>待付款</Option>
                            <Option value={1}>待发货</Option>
                            <Option value={2}>已发货</Option>
                            <Option value={3}>已完成</Option>
                            <Option value={4}>已关闭</Option>
                            <Option value={5}>无效订单</Option>
                        </Select>
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col span={9}>
                        订单提交时间：<RangePicker showTime value={this.state.showTime} onChange={this.searchHandler.onShowTimeChange}/>
                    </Col>
                    <Col span={2}>
                        <Button type="primary" onClick={this.searchHandler.onSearch}>搜索</Button>
                    </Col>
                    <Col>
                        <Button onClick={this.exportOrder}>导出</Button>
                    </Col>
                </Row>

                <br/>
                <Table
                    scroll={{ x: 2400 }}
                    loading={this.state.loading}
                    size="small"
                    columns={columns}
                    dataSource={this.state.list}
                    bordered
                    pagination={{ total: this.state.total, pageSize: this.state.pageSize, onChange: this.onPageChange }}
                />

                <Modal
                    title="退换货原因"
                    visible={this.state.refundInfoVis}
                    onOk={() => this.setState({refundInfoVis: false})}
                    onCancel={() => this.setState({refundInfoVis: false})}
                >
                    <Form layout="vertical">
                        <Form.Item label="申请类型">
                            {this.state.refundInfo.type === 1 ? <Tag color="magenta">退货</Tag> : <Tag color="cyan">换货</Tag>}
                        </Form.Item>
                        <Form.Item label="申请原因">
                            <Input.TextArea value={this.state.refundInfo.descText} disabled={true} />
                        </Form.Item>
                        <Form.Item label="图片说明">
                            <div>
                                {this.state.refundInfo.images && this.state.refundInfo.images.map(item => <Image width={150} src={item} />)}
                            </div>
                        </Form.Item>
                    </Form>
                </Modal>

                <Modal
                    title="驳回申请"
                    visible={this.state.rejectInfoVis}
                    onOk={this.rejectInfoHandler.onSubmit}
                    onCancel={() => this.setState({rejectInfoVis: false})}
                >
                    <Form layout="vertical">
                        <Form.Item label="是否已与买家沟通">
                            <Radio.Group onChange={this.rejectInfoHandler.onTouchStatusChange} value={this.state.rejectInfo.touchStatus}>
                                <Radio value={0}>否</Radio>
                                <Radio value={1}>是</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item label="驳回原因">
                            <Input.TextArea onChange={this.rejectInfoHandler.onConfirmDescChange} value={this.state.rejectInfo.confirmDesc} />
                        </Form.Item>
                    </Form>
                </Modal>

                <Modal
                    title="同意申请"
                    visible={this.state.confirmInfoVis}
                    onOk={this.onConfirmInfoSubmit}
                    onCancel={() => this.setState({confirmInfoVis: false})}
                >
                    <div>建议您与买家联系后再确认同意退换货</div>
                </Modal>

                <Modal
                    title="商家流转状态更新"
                    visible={this.state.merchantInfoVis}
                    onOk={this.onMerchantInfoSubmit}
                    onCancel={() => this.setState({merchantInfoVis: false})}
                >
                    <div>{this.state.merchantInfo.merchantStatus === 1 ? '请确认商家已收货' : '请确认商家已退换货'}</div>
                </Modal>

                <Modal
                    title="商家流转状态更新"
                    visible={this.state.refundDoneVis}
                    onOk={this.onRefundDoneSubmit}
                    onCancel={() => this.setState({refundDoneVis: false})}
                >
                    <div>请在换货完成/商家退款完成/微信支付退款完成后确认</div>
                </Modal>
            </div>
        );
    }
}

export default OrderList;
