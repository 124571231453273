class LocalStorageUtil {

    static setWithExpire = (key, data, seconds) => {
        let obj = {
            data: data,
            expire: new Date().getTime() + 1000 * seconds
        };

        localStorage.setItem(key, JSON.stringify(obj));
    }

    static get = key => {
        let data = localStorage.getItem(key);
        if (data) {
            let time = new Date().getTime();
            let obj = JSON.parse(data);
            if (time < obj.expire) {
                return obj.data;
            }
            localStorage.removeItem(key);
        }
        return null;
    }

    static remove = key => {
        localStorage.removeItem(key);
    }
}

export default LocalStorageUtil;
