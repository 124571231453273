import React from 'react';
import CommonLayout from '../../components/layout/CommonLayout';
import AddMallProductAttributeCategory from '../../components/mall/AddProductAttributeCategory';
import UpdateMallProductAttributeCategory from '../../components/mall/UpdateProductAttributeCategory';

class MallProductAttributeCategoryEdit extends React.Component {

    state = {
        categoryId: this.props.match.params.id
    };

    render() {
        return (
            <CommonLayout
                topNavSelectedKey="mall"
                siderNavCategory="mall"
                siderNavSelectedKey="product_attribute"
            >
                {this.props.location.pathname === '/mall/product_attribute/category/add' ?
                    <AddMallProductAttributeCategory
                        onSuccess={() => this.props.history.push('/mall/product_attribute')}
                    /> :
                    <UpdateMallProductAttributeCategory
                        value={this.state.categoryId}
                        onSuccess={() => this.props.history.push('/mall/product_attribute')}
                    />
                }
            </CommonLayout>
        );
    }
}

export default MallProductAttributeCategoryEdit;
