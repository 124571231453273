import React from 'react';
import CommonLayout from '../../components/layout/CommonLayout';
import AddStoreBrand from '../../components/store/AddBrand';
import UpdateStoreBrand from '../../components/store/UpdateBrand';

class StoreBrandEdit extends React.Component {

    state = {
        brandId: this.props.match.params.id
    };

    render() {
        return (
            <CommonLayout
                topNavSelectedKey="mall"
                siderNavCategory="mall"
                siderNavSelectedKey="brand"
            >
                {this.props.location.pathname === '/store/brand/add' ?
                    <AddStoreBrand onSuccess={() => this.props.history.push('/store/brand')}/> :
                    <UpdateStoreBrand
                        value={this.state.brandId}
                        onSuccess={() => this.props.history.push('/store/brand')}
                    />
                }
            </CommonLayout>
        );
    }
}

export default StoreBrandEdit;
