import RequestBase from './RequestBase';
import LocalStorageUtil from '../LocalStorageUtil';
import { message } from 'antd';

class RequestUtil extends RequestBase {

    static getInstance = (timeout = 30000, params = {}) => {
        let headers = params.headers;
        if (headers) {
            headers.Authorization = this.getHeaders().Authorization;
        }
        let axios = super.getInstance({
            headers: headers ? headers : this.getHeaders(),
            timeout: timeout
        });
        this.getLoginInterceptor(axios);
        this.getResCodeInterceptor(axios);
        return axios;
    }

    static getHeaders = () => {
        return {'Authorization': LocalStorageUtil.get('Authorization')};
    }

    static getLoginInterceptor = axios => {
        axios.interceptors.response.use(function(response) {
            if (response.data.code === 401) {
                //未登录
                message.warn('请先登录');
                window.location.href = "/login";
            }
            return response;
        }, function (error) {
            // 对响应错误做点什么
            return Promise.reject(error);
        });
    }

    static getResCodeInterceptor = axios => {
        axios.interceptors.response.use(function(response) {
            if (response.data.code === 403) {
                window.location.href = "/403";
            }
            if (response.data.code !== 200 && typeof response.data.code !== 'undefined') {
                message.error(response.data.message);
            }
            return response;
        }, function (error) {
            message.error('请求出错');
            // 对响应错误做点什么
            return Promise.reject(error);
        });
    }
}

export default RequestUtil;
