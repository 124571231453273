import React from 'react';
import CommonLayout from '../../components/layout/CommonLayout';
import AddPainterPagePlan from '../../components/painter/AddPainterPagePlan';
import EditPainterPagePlan from '../../components/painter/EditPainterPagePlan';

class PainterPagePlanEdit extends React.Component {

    state = {
        pagePlanId: this.props.match.params.id
    };

    render() {
        return (
            <CommonLayout
                topNavSelectedKey="painter"
                siderNavCategory="painter"
                siderNavSelectedKey="pagePlan"
            >
                {this.state.pagePlanId ? 
                    <EditPainterPagePlan
                        value={this.state.pagePlanId}
                        onSuccess={() => this.props.history.push('/painter/pagePlan')}
                    />
                    : <AddPainterPagePlan onSuccess={() => this.props.history.push('/painter/pagePlan')}/>
                }
            </CommonLayout>
        );
    }
}

export default PainterPagePlanEdit;
