import React from 'react';
import './../../css/marketing/editMarketingV2.scss';
import dayjs from 'dayjs';
import {
    CloseCircleFilled,
    DeleteFilled,
    DownOutlined,
    EditOutlined,
    LeftOutlined,
    ShoppingOutlined,
    RightOutlined,
    UpOutlined
} from '@ant-design/icons';
import {
    Breadcrumb,
    Button,
    Card,
    Carousel,
    DatePicker,
    Drawer,
    Empty,
    Form,
    Input,
    Popconfirm,
    Radio,
    Space,
    Tag,
    message
} from 'antd';
import {
    createInfo,
    updateInfo,
    getInfo,
    getTabs,
    editTabs,
    deleteTab,
    getTabProducts,
    batchAddProduct,
    sortProducts,
    addOperation,
    updateOperation,
    deleteTouchBarContent,
    addFloor,
    updateFloor,
    getFloorList,
    getFloorProducts,
    updateFloorProducts,
    exportProduct,
    sortFloors,
    deleteFloor
} from "../../api/PromotionApi";
import LoadingLayer from '../common/LoadingLayer';
import ArrayUtil from "../../util/ArrayUtil";
import UploadImage from '../upload/Image';
import SpuSelectorDrawer from "../spu/SpuSelectorDrawer";

const { RangePicker } = DatePicker;

class EditMarketingV2 extends React.Component {

    state = {
        id: this.props.value,
        loading: false,
        tip: '',
        baseInfo: {
            title: '',
            startTime: null,
            endTime: null,
            showTime: []
        },
        editJingangDrawerOpen: false,
        jingangItemInfo: {
            id: null,
            title: '',
            icon: '',
            isHome: 0,
            score: 0
        },
        indexJingangItemInfo: {
            id: null,
            title: '',
            icon: '',
            isHome: 1,
            score: 0
        },
        jingangSortStatus: false,
        jingangDeleteStatus: false,
        jingangList: [],
        indexBannerList: [],
        indexBannerDrawerOpen: false,
        indexOperationDrawerOpen: false,
        indexOperationItemInfo: {
            image: '',
            jumpUrl: '',
            id: null
        },
        floorMap: new Map(),
        floorItemInfo: {
            floorId: null,
            title: '',
            styleEnable: 0,
            floorLabelsTitle: '',
            floorLabels: '',
            tabId: null
        },
        floorDrawerOpen: false,
        spuSelectorDrawerOpen: false,
        spuSelectorDrawerValue: [],
        spuSelectorDrawerInfo: {
            type: 1,
            position: 'index',
            id: null,
            floorId: null
        }
    };

    componentDidMount = async _ => {
        if (this.state.id) {
            await this.getDataFromRemote.getBaseInfo();
            await this.getDataFromRemote.getJingangList();
        }
    }

    getDataFromRemote = {
        getBaseInfo: async _ => {
            this.setState({
                loading: true,
                tip: '数据加载中'
            });
            let response = await getInfo(this.state.id);
            this.setState({
                loading: false
            });
            let { code, data } = response.data;
            if (code === 200) {
                let startTime = data.startTime ? dayjs(data.startTime).format() : null;
                let endTime = data.endTime ? dayjs(data.endTime).format() : null;
                let baseInfo = {
                    title: data.title,
                    startTime: startTime,
                    endTime: endTime,
                    showTime: [startTime ? dayjs(data.startTime) : null, endTime ? dayjs(data.endTime) : null]
                }
                this.setState({
                    baseInfo: baseInfo
                })
            }
        },
        getJingangList: async _ => {
            this.setState({
                loading: true,
                tip: '数据加载中'
            });
            let response = await getTabs(this.state.id);
            this.setState({
                loading: false
            });
            let { code, data } = response.data;
            if (code === 200) {
                let jingangList = [];
                let indexJingangItemInfo = {};
                let floorMap = this.state.floorMap;
                data.forEach(item => {
                    if (item.isHome) {
                        indexJingangItemInfo = item;
                        this.getDataFromRemote.getJingangProducts(item.id);
                    } else {
                        jingangList.push(item);
                    }
                    floorMap.set(item.id.toString(), {
                        id: item.id.toString(),
                        title: item.title,
                        list: []
                    });
                    this.getDataFromRemote.getJingangFloorList(item.id);
                });
                this.setState({
                    jingangList: jingangList,
                    indexJingangItemInfo: indexJingangItemInfo,
                    floorMap: floorMap
                });
            }
        },
        getJingangProducts: async id => {
            this.setState({
                loading: true,
                tip: '数据加载中'
            });
            let response = await getTabProducts(this.state.id, id);
            this.setState({
                loading: false
            });
            let { code, data } = response.data;
            if (code === 200) {
                if (data.boomList) {
                    this.setState({
                        indexBannerList: data.boomList
                    });
                }
            }
        },
        getJingangFloorList: async id => {
            this.setState({
                loading: true,
                tip: '数据加载中'
            });
            let response = await getFloorList(id);
            this.setState({
                loading: false
            });
            let { code, data } = response.data;
            if (code === 200) {
                let floorMap = this.state.floorMap;
                let floorItem = floorMap.get(id.toString());
                floorItem.list = data;
                floorMap.set(id.toString(), floorItem);
                this.setState({
                    floorMap: floorMap
                });
            }
        }
    };

    baseFormHandler = {
        onTitleChange: e => {
            let baseInfo = this.state.baseInfo
            baseInfo.title = e.target.value
            this.setState({
                baseInfo: baseInfo
            })
        },
        onTimeChange: (dates, dateStrings) => {
            let baseInfo = this.state.baseInfo
            baseInfo.startTime = dates[0].format()
            baseInfo.endTime = dates[1].format()
            baseInfo.showTime = dates
            this.setState({
                baseInfo: baseInfo
            });
        },
        onSubmit: async _ => {
            let baseInfo = {
                startTime: this.state.baseInfo.showTime[0].format(),
                endTime: this.state.baseInfo.showTime[1].format(),
                title: this.state.baseInfo.title,
                type: 1
            };
            this.setState({
                loading: true,
                tip: '数据提交中'
            });

            if (this.state.id) {
                let response = await updateInfo(this.state.id, baseInfo);
                this.setState({
                    loading: false
                });
                let { code } = response.data;
                if (code === 200) {
                    message.success('营销活动更新成功');
                }
            } else {
                let response = await createInfo(baseInfo);
                this.setState({
                    loading: false
                });
                let { code } = response.data;
                if (code === 200) {
                    message.success('营销活动创建成功');
                    if (this.props.onSuccess) {
                        this.props.onSuccess();
                    }
                }
            }
        }
    };

    jingangFormHandler = {
        onLeft: index => {
            let jingangList = this.state.jingangList;
            ArrayUtil.upGo(jingangList, index);
            this.setState({
                jingangList: jingangList
            });
        },
        onRight: index => {
            let jingangList = this.state.jingangList;
            ArrayUtil.upDown(jingangList, index);
            this.setState({
                jingangList: jingangList
            });
        },
        onClickItem: info => {
            this.setState({
                jingangItemInfo: info,
                editJingangDrawerOpen: true
            });
        },
        onSubmitSort: async _ => {
            let jingangList = JSON.parse(JSON.stringify(this.state.jingangList));
            let index = jingangList.length;
            jingangList.map(item => {
                item.score = index--;
            });
            this.setState({
                loading: true,
                tip: '金刚位数据提交中'
            });
            let response = await editTabs(this.state.id, jingangList);
            this.setState({
                loading: false
            });
            let { code } = response.data;
            if (code === 200) {
                await this.getDataFromRemote.getJingangList();
                this.setState({
                    jingangSortStatus: false
                });
            }
        },
        onDelete: async id => {
            this.setState({
                loading: true,
                tip: '金刚位删除中'
            });
            let response = await deleteTab(id);
            this.setState({
                loading: false
            });
            let { code } = response.data;
            if (code === 200) {
                this.setState({
                    jingangDeleteStatus: false,
                    floorMap: new Map()
                });
                await this.getDataFromRemote.getJingangList();
            }
        }
    };

    jingangEditDrawerFormHandler = {
        onClose: e => {
            this.setState({
                editJingangDrawerOpen: false,
                jingangItemInfo: {
                    id: null,
                    title: '',
                    icon: '',
                    isHome: 0,
                    score: 0
                }
            });
        },
        onTitleChange: e => {
            let item = this.state.jingangItemInfo;
            item.title = e.target.value;
            this.setState({
                jingangItemInfo: item
            });
        },
        onImageChange: value => {
            let item = this.state.jingangItemInfo
            item.icon = value
            this.setState({
                jingangItemInfo: item
            });
        },
        onSubmit: async _ => {
            let jingangList = JSON.parse(JSON.stringify(this.state.jingangList));
            let jingangItemInfo = this.state.jingangItemInfo;
            if (!jingangItemInfo.id) {
                if (jingangList.length === 0) {
                    jingangItemInfo.score = 99;
                } else {
                    jingangItemInfo.score = jingangList[jingangList.length - 1].score - 1;
                }
            }

            this.setState({
                loading: true,
                tip: '金刚位数据提交中'
            });
            let response = await editTabs(this.state.id, [jingangItemInfo]);
            this.setState({
                loading: false
            });
            let { code } = response.data;
            if (code === 200) {
                await this.getDataFromRemote.getJingangList();
                this.jingangEditDrawerFormHandler.onClose();
            }
        }
    };

    indexBannerFormHandler = {
        onSpuSelectorDrawerOpen: _ => {
            this.setState({
                spuSelectorDrawerOpen: true,
                spuSelectorDrawerInfo: {
                    type: 1,
                    position: 'index',
                    id: this.state.indexJingangItemInfo.id,
                    floorId: null
                }
            });
        }
    };

    indexOperationFormHandler = {
        onClose: _ => {
            this.setState({
                indexOperationDrawerOpen: false,
                indexOperationItemInfo: {
                    image: '',
                    jumpUrl: '',
                    id: null
                }
            });
        },
        onJumpUrlChange: e => {
            let item = this.state.indexOperationItemInfo;
            item.jumpUrl = e.target.value;
            this.setState({
                indexOperationItemInfo: item
            });
        },
        onImageChange: value => {
            let item = this.state.indexOperationItemInfo
            item.image = value
            this.setState({
                indexOperationItemInfo: item
            });
        },
        onClickEditOperation: item => {
            this.setState({
                indexOperationDrawerOpen: true,
                indexOperationItemInfo: {
                    image: item.operationInfo.image,
                    jumpUrl: item.operationInfo.jumpUrl,
                    id: item.promotionProductId
                }
            });
        },
        onSubmit: async _ => {
            let params = {
                image: this.state.indexOperationItemInfo.image,
                jumpUrl: this.state.indexOperationItemInfo.jumpUrl,
                promotionId: this.state.id,
                sort: 0,
                tabId: this.state.indexJingangItemInfo.id,
                type: 1
            }
            this.setState({
                loading: true,
                tip: '运营位数据提交中'
            });
            let response;
            if (this.state.indexOperationItemInfo.id) {
                response = await updateOperation(this.state.indexOperationItemInfo.id, params);
            } else {
                response = await addOperation(params);
            }
            this.setState({
                loading: false
            });
            let { code } = response.data;
            if (code === 200) {
                await this.getDataFromRemote.getJingangProducts(this.state.indexJingangItemInfo.id);
                this.indexOperationFormHandler.onClose();
            }
        }
    };

    indexBannerDrawerFormHandler = {
        onClose: _ => {
            this.setState({
                indexBannerDrawerOpen: false
            });
        },
        onUp: index => {
            let indexBannerList = this.state.indexBannerList;
            ArrayUtil.upGo(indexBannerList, index);
            this.setState({
                indexBannerList: indexBannerList
            });
        },
        onDown: index => {
            let indexBannerList = this.state.indexBannerList;
            ArrayUtil.upDown(indexBannerList, index);
            this.setState({
                indexBannerList: indexBannerList
            });
        },
        onSubmit: async _ => {
            let data = [];
            let indexBannerList = this.state.indexBannerList;
            indexBannerList.forEach((item, index) => {
                data.push({
                    productId: item.id,
                    type: 1,
                    sort: indexBannerList.length - index,
                    jumpType: item.jumpType,
                    promotionProductId: item.promotionProductId
                });
            });
            this.setState({
                loading: true,
                tip: '数据提交中'
            });
            let response = await sortProducts(this.state.id, this.state.indexJingangItemInfo.id, data);
            this.setState({
                loading: false
            });
            let { code } = response.data;
            if (code === 200) {
                await this.getDataFromRemote.getJingangProducts(this.state.indexJingangItemInfo.id);
                this.indexBannerDrawerFormHandler.onClose();
            }
        },
        onDelete: async id => {
            this.setState({
                loading: true,
                tip: '数据删除中'
            });
            let response = await deleteTouchBarContent(id);
            this.setState({
                loading: false
            });
            let { code } = response.data;
            if (code === 200) {
                await this.getDataFromRemote.getJingangProducts(this.state.indexJingangItemInfo.id);
            }
        }
    };

    floorFormHandler = {
        onOpen: (jingangId, item) => {
            let floorItemInfo = this.state.floorItemInfo;
            floorItemInfo.tabId = jingangId;
            if (item) {
                floorItemInfo.floorId = item.floorId;
                floorItemInfo.title = item.title;
                floorItemInfo.styleEnable = item.styleEnable;
                floorItemInfo.floorLabelsTitle = item.floorLabelsTitle;
                floorItemInfo.floorLabels = item.floorLabels;
            }
            this.setState({
                floorDrawerOpen: true,
                floorItemInfo: floorItemInfo
            });
        },
        onClose: _ => {
            this.setState({
                floorDrawerOpen: false,
                floorItemInfo: {
                    floorId: null,
                    title: '',
                    styleEnable: 0,
                    floorLabelsTitle: '',
                    floorLabels: '',
                    tabId: null
                }
            });
        },
        onTitleChange: e => {
            let floorItemInfo = this.state.floorItemInfo;
            floorItemInfo.title = e.target.value;
            this.setState({
                floorItemInfo: floorItemInfo
            });
        },
        onStyleEnableChange: e => {
            let floorItemInfo = this.state.floorItemInfo;
            floorItemInfo.styleEnable = e.target.value;
            this.setState({
                floorItemInfo: floorItemInfo
            });
        },
        onFloorLabelsTitleChange: e => {
            let floorItemInfo = this.state.floorItemInfo;
            floorItemInfo.floorLabelsTitle = e.target.value;
            this.setState({
                floorItemInfo: floorItemInfo
            });
        },
        onFloorLabelsChange: e => {
            let floorItemInfo = this.state.floorItemInfo;
            floorItemInfo.floorLabels = e.target.value;
            this.setState({
                floorItemInfo: floorItemInfo
            });
        },
        onFloorProductsDrawerOpen: async (floorId, jingangId) => {
            this.setState({
                loading: true,
                tip: '数据加载中'
            });
            let response = await getFloorProducts(floorId);
            this.setState({
                loading: false
            });
            let { code, data } = response.data;
            if (code === 200) {
                let list = []
                if (data) {
                    data.forEach(item => list.push({
                        id: item.id.toString(),
                        name: item.name,
                        spuCode: item.spuCode,
                        pic: item.pic
                    }));
                }

                this.setState({
                    spuSelectorDrawerValue: list,
                    spuSelectorDrawerOpen: true,
                    spuSelectorDrawerInfo: {
                        type: 0,
                        position: 'jingang',
                        id: jingangId,
                        floorId: floorId
                    }
                });
            }
        },
        onExportProducts: async (floorId, jingangId) => {
            let params = {
                promotionId: this.state.id,
                tabId: jingangId,
                type: 0,
                floorId: floorId
            }
            this.setState({
                loading: true,
                tip: '导出中'
            });
            let response = await exportProduct(params)
            this.setState({
                loading: false
            });
            let url = window.URL.createObjectURL(new Blob([response.data], {type: "application/vnd.ms-excel;charset=UTF-8"}))
            let link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            link.setAttribute('download', `楼层商品${new Date().getTime()}.xls`)
            document.body.appendChild(link)
            link.click()
            window.URL.revokeObjectURL(url)
            document.body.removeChild(link)
        },
        onUp: (jingangId, index) => {
            let floorMap = this.state.floorMap;
            let floorList = floorMap.get(jingangId).list;
            ArrayUtil.upGo(floorList, index);
            this.setState({
                floorMap: floorMap
            });
        },
        onDown: (jingangId, index) => {
            let floorMap = this.state.floorMap;
            let floorList = floorMap.get(jingangId).list;
            ArrayUtil.upDown(floorList, index);
            this.setState({
                floorMap: floorMap
            });
        },
        onSortFloors: async jingangId => {
            let floorMap = this.state.floorMap;
            let floorList = floorMap.get(jingangId).list;
            let data = [];
            floorList.forEach((item, index) => data.push({
                floorId: item.floorId,
                sort: floorList.length - index
            }));
            this.setState({
                loading: true,
                tip: '提交楼层排序中'
            });
            let response = await sortFloors(data);
            this.setState({
                loading: false
            });
            let { code } = response.data;
            if (code === 200) {
                await this.getDataFromRemote.getJingangFloorList(jingangId);
            }
        },
        onSubmit: async _ => {
            this.setState({
                loading: true,
                tip: '数据提交中'
            });
            let response;
            if (this.state.floorItemInfo.floorId) {
                response = await updateFloor(this.state.floorItemInfo.floorId, this.state.floorItemInfo);
            } else {
                response = await addFloor(this.state.floorItemInfo);
            }
            this.setState({
                loading: false
            });
            let { code } = response.data;
            if (code === 200) {
                await this.getDataFromRemote.getJingangFloorList(this.state.floorItemInfo.tabId);
                this.floorFormHandler.onClose();
            }
        },
        onDelete: async (jingangId, floorId) => {
            this.setState({
                loading: true,
                tip: '数据删除中'
            });
            let response = await deleteFloor(floorId);
            this.setState({
                loading: false
            });
            let { code } = response.data;
            if (code === 200) {
                await this.getDataFromRemote.getJingangFloorList(jingangId);
            }
        }
    };

    onSpuSelectorDrawerSubmit = async data => {
        let productIds = [];
        data.forEach(item => productIds.push(item.id));
        if (!this.state.spuSelectorDrawerInfo.floorId) {
            let params = {
                promotionId: this.state.id,
                tabId: this.state.spuSelectorDrawerInfo.id,
                productIds: productIds,
                type: this.state.spuSelectorDrawerInfo.type
            };
            this.setState({
                loading: true,
                tip: '数据提交中'
            });
            let response = await batchAddProduct(params);
            this.setState({
                loading: false
            });
            let { code } = response.data;
            if (code === 200) {
                message.success('商品添加成功');
                await this.getDataFromRemote.getJingangProducts(this.state.spuSelectorDrawerInfo.id);
                this.setState({
                    spuSelectorDrawerOpen: false
                });
            }
        } else {
            this.setState({
                loading: true,
                tip: '数据提交中'
            });
            let response = await updateFloorProducts(this.state.spuSelectorDrawerInfo.floorId, productIds);
            this.setState({
                loading: false
            });
            let { code } = response.data;
            if (code === 200) {
                message.success('商品更新成功');
                await this.getDataFromRemote.getJingangFloorList(this.state.spuSelectorDrawerInfo.id);
                this.setState({
                    spuSelectorDrawerOpen: false
                });
            }
        }
    }

    getJingangFloorListRender = jingangId => {
        let floorList = <></>;
        if (this.state.floorMap && this.state.floorMap.get(jingangId)
            && this.state.floorMap.get(jingangId).list
            && this.state.floorMap.get(jingangId).list.length > 0) {
            let floorData = this.state.floorMap.get(jingangId).list;
            floorList = floorData.map((item, index) =>
                <div className="floor-list" key={item.floorId}>
                    <div className="left">
                        <div className="title-box">
                            <div className="title">{item.title}</div>
                            <div className="label-title">{item.floorLabelsTitle}</div>
                        </div>
                        {item.floorLabels &&
                        <div className="tag-box">
                            {item.floorLabels.split(',').map(tag =>
                                <div style={{ marginBottom: 3 }}>
                                    <Tag size="small" color="gold">{tag}</Tag>
                                </div>)}
                        </div>}

                        <div className="spu-box">
                            <div style={{ fontSize: 12 }}>
                                <span>关联商品数量：</span>
                                <span className="spu-num">{item.spuNum}</span>
                            </div>
                            {item.spuNum > 0 &&
                            <div>
                                <Button type="link" size="small" onClick={this.floorFormHandler.onExportProducts.bind(this, item.floorId, jingangId)}>下载商品</Button>
                            </div>}
                        </div>
                    </div>
                    <div className="right">
                        {floorData.length > 1 &&
                        <div className="sort">
                            {index > 0 && <div className="item"><UpOutlined onClick={this.floorFormHandler.onUp.bind(this, jingangId, index)} /></div>}
                            {index < floorData.length - 1 && <div className="item"><DownOutlined onClick={this.floorFormHandler.onDown.bind(this, jingangId, index)} /></div>}
                        </div>}

                        <div className="edit">
                            <EditOutlined onClick={this.floorFormHandler.onOpen.bind(this, jingangId, item)} />
                        </div>
                        <div className="spu">
                            <ShoppingOutlined onClick={this.floorFormHandler.onFloorProductsDrawerOpen.bind(this, item.floorId, jingangId)} />
                        </div>
                        <div className="delete">
                            <Popconfirm
                                placement="right"
                                title="请确认是否删除该楼层？"
                                description="删除楼层"
                                onConfirm={this.floorFormHandler.onDelete.bind(this, jingangId, item.floorId)}
                                okText="Yes"
                                cancelText="No"
                            >
                                <DeleteFilled />
                            </Popconfirm>
                        </div>
                    </div>
                </div>)
        }
        return floorList;
    }

    getJingangFloorListSortButtonRender = jingangId => {
        let floorSortButton = <></>;
        if (this.state.floorMap && this.state.floorMap.get(jingangId)
            && this.state.floorMap.get(jingangId).list
            && this.state.floorMap.get(jingangId).list.length > 0
            && this.state.floorMap.get(jingangId).list.length > 1) {
            floorSortButton = <Button size="small" type="primary" onClick={this.floorFormHandler.onSortFloors.bind(this, jingangId)}>提交楼层排序</Button>;
        }
        return floorSortButton;
    }

    render() {
        const jingang = this.state.jingangList.map((item, index) =>
            <div className="item" key={item.id}>
                <div className="image-box" onClick={this.jingangFormHandler.onClickItem.bind(this, item)}>
                    <img src={item.icon}/>
                </div>
                <div className="title" onClick={this.jingangFormHandler.onClickItem.bind(this, item)}>{item.title}</div>
                {this.state.jingangSortStatus ?
                    <div>
                        <Space>
                            {index > 0 ? <LeftOutlined onClick={this.jingangFormHandler.onLeft.bind(this, index)} style={{ fontSize: 10, cursor: 'pointer', color: '#8c8c8c' }}/> : <></>}
                            {index < this.state.jingangList.length - 1 ? <RightOutlined onClick={this.jingangFormHandler.onRight.bind(this, index)} style={{ fontSize: 10, cursor: 'pointer', color: '#8c8c8c' }}/> : <></>}
                        </Space>
                    </div> : ''}
                {this.state.jingangDeleteStatus ?
                    <div>
                        <Popconfirm
                            placement="right"
                            title="请确认是否删除该金刚位？"
                            description="删除金刚位"
                            onConfirm={this.jingangFormHandler.onDelete.bind(this, item.id)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <CloseCircleFilled style={{ color: '#ff4d4f', cursor: 'pointer' }} />
                        </Popconfirm>
                    </div> : ''}
            </div>
        );

        const indexBanner = this.state.indexBannerList.map(item =>
            <div key={item.promotionProductId}>
                {item.jumpType === 1 &&
                    <div className="banner-item">
                        <div className="left">
                            <div className="title">{item.name}</div>
                            <div style={{ display: item.productCategoryName ? '' : 'none' }}>
                                <Tag color="#2db7f5">{item.productCategoryName}</Tag>
                            </div>
                        </div>
                        <div className="right">
                            <img width={131} height={131} src={item.pic} />
                        </div>
                    </div>}
                {item.jumpType === 2 &&
                    <div className="banner-item">
                        <div className="image-box">
                            <img width={474} height={151} src={item.operationInfo.image} />
                        </div>
                        <div className="edit-button">
                            <Button
                                type="primary"
                                size="small"
                                onClick={this.indexOperationFormHandler.onClickEditOperation.bind(this, item)}
                            >点击编辑运营位</Button>
                        </div>
                    </div>}
            </div>);

        const bannerItemCss = {
            borderRadius: 15,
            width: 360,
            height: 114,
            backgroundImage: 'url("https://bj-prod-1309320985.cos.ap-beijing.myqcloud.com/20230110/ab349d4d6d7caa9bfd22aa88d4abe0af.png")',
            backgroundSize: '100%',
            backgroundRepeat: 'no-repeat',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
        }

        const bannerItemLeftCss = {
            padding: 10,
            width: 230,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
        }

        const bannerList = this.state.indexBannerList.map((item, index) =>
            <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
                {item.jumpType === 1 &&
                    <div style={bannerItemCss}>
                        <div style={bannerItemLeftCss}>
                            <div style={{ color: '#262626', fontSize: 16, fontWeight: 500 }}>{item.name}</div>
                            <div style={{ display: item.productCategoryName ? '' : 'none' }}>
                                <Tag color="#2db7f5">{item.productCategoryName}</Tag>
                            </div>
                        </div>
                        <div style={{ padding: 10 }}>
                            <img style={{ borderRadius: 15 }} width={94} height={94} src={item.pic} />
                        </div>
                    </div>}
                {item.jumpType === 2 &&
                    <div style={bannerItemCss}>
                        <div>
                            <img style={{ borderRadius: 15 }} width={360} height={114} src={item.operationInfo.image} />
                        </div>
                    </div>}
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%', alignItems: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        {index !== 0 && <div style={{ marginRight: 10 }}>
                            <UpOutlined style={{ cursor: 'pointer' }} onClick={this.indexBannerDrawerFormHandler.onUp.bind(this, index)} />
                        </div>}
                        {index !== this.state.indexBannerList.length - 1 && <div>
                            <DownOutlined style={{ cursor: 'pointer' }} onClick={this.indexBannerDrawerFormHandler.onDown.bind(this, index)} />
                        </div>}
                    </div>
                    <div style={{ marginTop: 10 }}>
                        <Popconfirm
                            placement="right"
                            title="请确认是否删除该运营位？"
                            description="删除运营位"
                            onConfirm={this.indexBannerDrawerFormHandler.onDelete.bind(this, item.promotionProductId)}
                            okText="Yes"
                            cancelText="No"
                        >
                            <DeleteFilled style={{ color: '#ff4d4f', cursor: 'pointer' }} />
                        </Popconfirm>
                    </div>
                </div>
            </div>);

        let indexFloorList = <></>;
        let indexFloorSortButton = <></>;
        if (this.state.floorMap && this.state.floorMap.get(this.state.indexJingangItemInfo.id)
            && this.state.floorMap.get(this.state.indexJingangItemInfo.id).list
            && this.state.floorMap.get(this.state.indexJingangItemInfo.id).list.length > 0) {
            let indexFloorData = this.state.floorMap.get(this.state.indexJingangItemInfo.id).list;
            indexFloorList = indexFloorData.map((item, index) =>
                <div className="floor-list" key={item.floorId}>
                    <div className="left">
                        <div className="title-box">
                            <div className="title">{item.title}</div>
                            <div className="label-title">{item.floorLabelsTitle}</div>
                        </div>
                        {item.floorLabels &&
                        <div className="tag-box">
                            {item.floorLabels.split(',').map(tag =>
                                <div style={{ marginBottom: 3 }}>
                                    <Tag size="small" color="gold">{tag}</Tag>
                                </div>)}
                        </div>}

                        <div className="spu-box">
                            <div style={{ fontSize: 12 }}>
                                <span>关联商品数量：</span>
                                <span className="spu-num">{item.spuNum}</span>
                            </div>
                            {item.spuNum > 0 &&
                            <div>
                                <Button type="link" size="small" onClick={this.floorFormHandler.onExportProducts.bind(this, item.floorId, this.state.indexJingangItemInfo.id)}>下载商品</Button>
                            </div>}
                        </div>
                    </div>
                    <div className="right">
                        {indexFloorData.length > 1 &&
                        <div className="sort">
                            {index > 0 && <div className="item"><UpOutlined onClick={this.floorFormHandler.onUp.bind(this, this.state.indexJingangItemInfo.id, index)} /></div>}
                            {index < indexFloorData.length - 1 && <div className="item"><DownOutlined onClick={this.floorFormHandler.onDown.bind(this, this.state.indexJingangItemInfo.id, index)} /></div>}
                        </div>}

                        <div className="edit">
                            <EditOutlined onClick={this.floorFormHandler.onOpen.bind(this, this.state.indexJingangItemInfo.id, item)} />
                        </div>
                        <div className="spu">
                            <ShoppingOutlined onClick={this.floorFormHandler.onFloorProductsDrawerOpen.bind(this, item.floorId, this.state.indexJingangItemInfo.id)} />
                        </div>
                        <div className="delete">
                            <Popconfirm
                                placement="right"
                                title="请确认是否删除该楼层？"
                                description="删除楼层"
                                onConfirm={this.floorFormHandler.onDelete.bind(this, this.state.indexJingangItemInfo.id, item.floorId)}
                                okText="Yes"
                                cancelText="No"
                            >
                                <DeleteFilled />
                            </Popconfirm>
                        </div>
                    </div>
                </div>)

            if (indexFloorData.length > 1) {
                indexFloorSortButton = <Button size="small" type="primary" onClick={this.floorFormHandler.onSortFloors.bind(this, this.state.indexJingangItemInfo.id)}>提交楼层排序</Button>;
            }
        }

        let jingangFloorList = [];
        this.state.floorMap.forEach(item => item.id !== this.state.indexJingangItemInfo.id && jingangFloorList.push({
            jingangId: item.id,
            jingangTitle: item.title,
            floorList: item.list
        }));

        let jingangFloorListPartition = ArrayUtil.partition(jingangFloorList, Math.ceil(jingangFloorList.length / 2));
        let leftJingangFloorModule = <></>;
        let rightJingangFloorModule = <></>;
        if (jingangFloorListPartition && jingangFloorListPartition.length > 1) {
            leftJingangFloorModule = jingangFloorListPartition[0].map(item =>
                <div style={{ display: this.state.id ? '' : 'none', marginTop: 10 }} key={item.jingangId}>
                    <Card
                        size="small"
                        title={`${item.jingangTitle}商品流`}
                        extra={
                            <Space>
                                <Button type="link" size="small" onClick={this.floorFormHandler.onOpen.bind(this, item.jingangId, null)}>新增楼层</Button>
                            </Space>
                        }
                        style={{
                            width: 500
                        }}
                    >
                        {(this.state.floorMap && this.state.floorMap.get(item.jingangId)
                            && this.state.floorMap.get(item.jingangId).list
                            && this.state.floorMap.get(item.jingangId).list.length > 0) ?
                            <>{this.getJingangFloorListRender(item.jingangId)}{this.getJingangFloorListSortButtonRender(item.jingangId)}</> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                    </Card>
                </div>);

            rightJingangFloorModule = jingangFloorListPartition[1].map(item =>
                <div style={{ display: this.state.id ? '' : 'none', margin: '10px 0 0 10px' }} key={item.jingangId}>
                    <Card
                        size="small"
                        title={`${item.jingangTitle}商品流`}
                        extra={
                            <Space>
                                <Button type="link" size="small" onClick={this.floorFormHandler.onOpen.bind(this, item.jingangId, null)}>新增楼层</Button>
                            </Space>
                        }
                        style={{
                            width: 546
                        }}
                    >
                        {(this.state.floorMap && this.state.floorMap.get(item.jingangId)
                            && this.state.floorMap.get(item.jingangId).list
                            && this.state.floorMap.get(item.jingangId).list.length > 0) ?
                            <>{this.getJingangFloorListRender(item.jingangId)}{this.getJingangFloorListSortButtonRender(item.jingangId)}</> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                    </Card>
                </div>);
        }

        return (
            <div className="EditMarketingV2">
                <LoadingLayer loading={this.state.loading} tip={this.state.tip}/>
                <Breadcrumb>
                    <Breadcrumb.Item>商城</Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <a href="/mall/marketing">营销活动</a>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>{this.state.id ? '编辑' : '新建'}营销活动</Breadcrumb.Item>
                </Breadcrumb>
                <br/>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div>
                            <Card
                                size="small"
                                title="活动基础信息"
                                style={{
                                    width: 500
                                }}
                            >
                                <Form
                                    labelCol={{ span: 0 }}
                                    layout="horizontal"
                                    style={{ marginTop: 10 }}
                                    size="small"
                                >
                                    <Form.Item label="活动名称">
                                        <Input value={this.state.baseInfo.title} onChange={this.baseFormHandler.onTitleChange} />
                                    </Form.Item>
                                    <Form.Item label="售卖时段">
                                        <RangePicker
                                            showTime={{
                                                defaultValue: [dayjs('00:00:00', 'HH:mm:ss'), dayjs('23:59:59', 'HH:mm:ss')]
                                            }}
                                            value={this.state.baseInfo.showTime}
                                            onChange={this.baseFormHandler.onTimeChange}
                                        />
                                    </Form.Item>
                                    <Form.Item noStyle>
                                        <Button type="primary" onClick={this.baseFormHandler.onSubmit}>保存活动信息</Button>
                                    </Form.Item>
                                </Form>
                            </Card>
                        </div>
                        <div style={{ display: this.state.id ? '' : 'none', marginTop: 10 }}>
                            <Card
                                size="small"
                                title="首页轮播图"
                                extra={
                                    <Space>
                                        <Button size="small" type="link" onClick={this.indexBannerFormHandler.onSpuSelectorDrawerOpen}>新增商品</Button>
                                        <Button size="small" type="link" onClick={() => this.setState({ indexOperationDrawerOpen: true })}>新增运营位</Button>
                                        {this.state.indexBannerList && this.state.indexBannerList.length > 0
                                        && <Button size="small" type="link" onClick={() => this.setState({ indexBannerDrawerOpen: true })}>管理</Button>}
                                    </Space>
                                }
                                style={{
                                    width: 500
                                }}
                            >
                                {(this.state.indexBannerList && this.state.indexBannerList.length > 0) ?
                                    <Carousel autoplay className="index-banner">
                                        {indexBanner}
                                    </Carousel> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                            </Card>
                        </div>
                        {leftJingangFloorModule}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ marginLeft: 10, display: this.state.id ? '' : 'none' }}>
                            <Card
                                size="small"
                                title="金刚位信息"
                                extra={
                                    <Space>
                                        <Button size="small" type="link" danger onClick={() => this.setState({ jingangDeleteStatus: true, jingangSortStatus: false })}>删除</Button>
                                        <Button size="small" type="link" onClick={() => this.setState({ editJingangDrawerOpen: true })}>创建</Button>
                                        <Button size="small" type="link" onClick={() => this.setState({ jingangSortStatus: true, jingangDeleteStatus: false })}>排序</Button>
                                    </Space>
                                }
                                style={{
                                    width: 546
                                }}
                            >
                                <div className="jingang">
                                    {(!this.state.jingangList || this.state.jingangList.length === 0) && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                                    <div className="main">
                                        {(this.state.jingangList && this.state.jingangList.length > 0) && jingang}
                                    </div>
                                    {this.state.jingangSortStatus ? <Space>
                                        <Button type="primary" size="small" onClick={this.jingangFormHandler.onSubmitSort}>保存排序</Button>
                                        <Button size="small" onClick={() => this.setState({ jingangSortStatus: false })}>取消排序</Button>
                                    </Space> : <></>}
                                    {this.state.jingangDeleteStatus ? <Space>
                                        <Button size="small" onClick={() => this.setState({ jingangDeleteStatus: false })}>取消删除</Button>
                                    </Space> : <></>}
                                </div>
                            </Card>
                        </div>
                        <div style={{ display: this.state.id ? '' : 'none', margin: '10px 0 0 10px' }}>
                            <Card
                                size="small"
                                title="首页商品流"
                                extra={
                                    <Space>
                                        <Button type="link" size="small" onClick={this.floorFormHandler.onOpen.bind(this, this.state.indexJingangItemInfo.id, null)}>新增楼层</Button>
                                    </Space>
                                }
                                style={{
                                    width: 546
                                }}
                            >
                                {(this.state.floorMap && this.state.floorMap.get(this.state.indexJingangItemInfo.id)
                                    && this.state.floorMap.get(this.state.indexJingangItemInfo.id).list
                                    && this.state.floorMap.get(this.state.indexJingangItemInfo.id).list.length > 0) ?
                                    <>{indexFloorList}{indexFloorSortButton}</> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                            </Card>
                        </div>
                        {rightJingangFloorModule}
                    </div>
                </div>

                <br/>

                <Drawer
                    title={(this.state.jingangItemInfo.id ? '编辑' : '创建') + '金刚位'}
                    width={500}
                    onClose={this.jingangEditDrawerFormHandler.onClose}
                    open={this.state.editJingangDrawerOpen}
                    bodyStyle={{ paddingBottom: 80 }}
                    extra={
                        <Space>
                            <Button onClick={this.jingangEditDrawerFormHandler.onClose}>取消</Button>
                            <Button type="primary" onClick={this.jingangEditDrawerFormHandler.onSubmit}>提交</Button>
                        </Space>
                    }
                >
                    <Form
                        labelCol={{ span: 0 }}
                        layout="vertical"
                    >
                        <Form.Item label="金刚位名称">
                            <Input placeholder="请输入金刚位名称"
                                   value={this.state.jingangItemInfo.title}
                                   onChange={this.jingangEditDrawerFormHandler.onTitleChange}
                            />
                        </Form.Item>
                        <Form.Item label="金刚位图标">
                            <UploadImage
                                value={this.state.jingangItemInfo.icon}
                                onChange={this.jingangEditDrawerFormHandler.onImageChange}
                            />
                        </Form.Item>
                    </Form>
                </Drawer>

                <Drawer
                    title={(this.state.indexOperationItemInfo.id ? '编辑' : '创建') + '运营位'}
                    width={500}
                    onClose={this.indexOperationFormHandler.onClose}
                    open={this.state.indexOperationDrawerOpen}
                    extra={
                        <Space>
                            <Button onClick={this.indexOperationFormHandler.onClose}>取消</Button>
                            <Button type="primary" onClick={this.indexOperationFormHandler.onSubmit}>提交</Button>
                        </Space>
                    }
                >
                    <Form
                        labelCol={{ span: 0 }}
                        layout="vertical"
                    >
                        <Form.Item label="小程序跳转路径">
                            <Input placeholder="请输入小程序跳转路径"
                                   value={this.state.indexOperationItemInfo.jumpUrl}
                                   onChange={this.indexOperationFormHandler.onJumpUrlChange}
                            />
                        </Form.Item>
                        <Form.Item label="金刚位图标">
                            <UploadImage
                                value={this.state.indexOperationItemInfo.image}
                                onChange={this.indexOperationFormHandler.onImageChange}
                            />
                        </Form.Item>
                    </Form>
                </Drawer>

                <Drawer
                    title="管理运营位"
                    width={500}
                    onClose={this.indexBannerDrawerFormHandler.onClose}
                    open={this.state.indexBannerDrawerOpen}
                    extra={
                        <Space>
                            <Button onClick={this.indexBannerDrawerFormHandler.onClose}>取消</Button>
                            <Button type="primary" onClick={this.indexBannerDrawerFormHandler.onSubmit}>提交</Button>
                        </Space>
                    }
                >
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {bannerList}
                    </div>
                </Drawer>

                <Drawer
                    title={(this.state.floorItemInfo.floorId ? '编辑' : '创建') + '楼层'}
                    width={500}
                    onClose={this.floorFormHandler.onClose}
                    open={this.state.floorDrawerOpen}
                    extra={
                        <Space>
                            <Button onClick={this.floorFormHandler.onClose}>取消</Button>
                            <Button type="primary" onClick={this.floorFormHandler.onSubmit}>提交</Button>
                        </Space>
                    }
                >
                    <Form
                        labelCol={{ span: 0 }}
                        layout="vertical"
                    >
                        <Form.Item label="楼层名称">
                            <Input placeholder="请输入楼层名称"
                                   value={this.state.floorItemInfo.title}
                                   onChange={this.floorFormHandler.onTitleChange}
                            />
                        </Form.Item>
                        <Form.Item label="楼层样式">
                            <Radio.Group value={this.state.floorItemInfo.styleEnable} onChange={this.floorFormHandler.onStyleEnableChange}>
                                <Radio value={0}>不启用</Radio>
                                <Radio value={1}>启用</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item label="推荐标题">
                            <Input placeholder="请输入推荐标题"
                                   value={this.state.floorItemInfo.floorLabelsTitle}
                                   onChange={this.floorFormHandler.onFloorLabelsTitleChange}
                            />
                        </Form.Item>
                        <Form.Item label="标签">
                            <Input placeholder="请输入标签"
                                   value={this.state.floorItemInfo.floorLabels}
                                   onChange={this.floorFormHandler.onFloorLabelsChange}
                            />
                        </Form.Item>
                    </Form>
                </Drawer>

                <SpuSelectorDrawer
                    open={this.state.spuSelectorDrawerOpen}
                    onClose={() => this.setState({ spuSelectorDrawerOpen: false })}
                    value={this.state.spuSelectorDrawerValue}
                    onSubmit={this.onSpuSelectorDrawerSubmit}
                />
            </div>
        );
    }
}

export default EditMarketingV2;