import RequestUtil from "../util/request/RequestUtil";

export function getList(parentId = 0, pageNum = 1, pageSize = 5) {
    return RequestUtil.getInstance().get(`/productCategory/list/${parentId}?pageNum=${pageNum}&pageSize=${pageSize}`);
}

export function deleteCategory(id) {
    return RequestUtil.getInstance().post(`/productCategory/delete/${id}`);
}

export function createCategory(params) {
    let data = {
        description: params.description,
        icon: params.icon,
        keywords: params.keywords,
        name: params.name,
        navStatus: params.navStatus,
        parentId: params.parentId,
        productUnit: params.productUnit,
        showStatus: params.showStatus,
        sort: params.sort
    };
    return RequestUtil.getInstance().post(`/productCategory/create`, data);
}

export function updateCategory(id, params) {
    let data = {
        description: params.description,
        icon: params.icon,
        keywords: params.keywords,
        name: params.name,
        navStatus: params.navStatus,
        parentId: params.parentId,
        productUnit: params.productUnit,
        showStatus: params.showStatus,
        sort: params.sort
    };
    return RequestUtil.getInstance().post(`/productCategory/update/${id}`, data);
}

export function getInfo(id) {
    return RequestUtil.getInstance().get(`/productCategory/${id}`);
}

export function getListWithChildren() {
    return RequestUtil.getInstance().get(`/productCategory/list/withChildren`);
}

export function getListByParentId(parentId) {
    let data = {
        parentId: parentId
    };
    return RequestUtil.getInstance().post(`/category/getListByParentId`, data);
}
