import React from 'react';
import CommonLayout from '../../components/layout/CommonLayout';
import EditStock from '../../components/stock/EditStock';

class StockEdit extends React.Component {

    state = {
        skuId: this.props.match.params.id
    };

    render() {
        return (
            <CommonLayout
                topNavSelectedKey="stock"
                siderNavCategory="stock"
                siderNavSelectedKey={`list`}
            >
                <EditStock value={this.state.skuId} onSuccess={() => this.props.history.push('/stock/list')}/>
            </CommonLayout>
        );
    }
}

export default StockEdit;
