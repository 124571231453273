import React from "react";
import {
    Button,
    Space,
    Row,
    Col,
    DatePicker,
    Alert,
    Table
} from 'antd';
import * as echarts from 'echarts';
import LoadingLayer from '../common/LoadingLayer';
import { getBiDailyPvData, getBiTotalPvData } from '../../api/BiApi';

const { RangePicker } = DatePicker;

const column = [{
    title: '累计PV',
    dataIndex: 'pv',
    key: 'pv',
    align: 'center'
}, {
    title: '累计UV',
    dataIndex: 'uv',
    key: 'uv',
    align: 'center'
}]

class DataDailyPv extends React.Component {

    state = {
        loadingLayer: false,
        tip: '',
        showTime: [],
        startDate: '',
        endDate: '',
        totalPvData: {
            pv: 0,
            uv: 0
        }
    }

    onShowTimeChange = (dates, dateStrings) => {
        this.setState({
            showTime: dates,
            startDate: dateStrings[0].replaceAll('-', ''),
            endDate: dateStrings[1].replaceAll('-', '')
        });
    }

    onSearch = async _ => {
        this.setState({
            loadingLayer: true,
            tip: '数据加载中'
        });

        await this.getDailyPvData()
        await this.getTotalPvData()

        this.setState({
            loadingLayer: false
        });
    }

    getDailyPvData = async _ => {
        let response = await getBiDailyPvData(this.state);
        let { code, data } = response.data
        if (code === 200 && data) {
            let xAxisData = []
            let pvData = []
            let uvData = []
            data.forEach(item => {
                xAxisData.push(item.date)
                pvData.push(item.pv)
                uvData.push(item.uv)
            })

            let chartDom = document.getElementById('echarts-pv')
            let pvChart = echarts.init(chartDom)
            let option = {
                title: {
                    text: '用户访问数据'
                },
                tooltip: {
                    trigger: 'axis'
                },
                legend: {
                    data: ['PV', 'UV']
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                toolbox: {
                    feature: {
                        saveAsImage: {}
                    }
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: xAxisData
                },
                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                        name: 'PV',
                        type: 'line',
                        data: pvData
                    },
                    {
                        name: 'UV',
                        type: 'line',
                        data: uvData
                    }
                ]
            }

            pvChart.setOption(option)
        }
    }

    getTotalPvData = async _ => {
        let response = await getBiTotalPvData(this.state);
        let { code, data } = response.data
        if (code === 200) {
            this.setState({
                totalPvData: {
                    pv: data.pv,
                    uv: data.uv
                }
            })
        }
    }

    render() {
        return (
            <>
                <LoadingLayer loading={this.state.loadingLayer} tip={this.state.tip}/>
                <Row>
                    <Col span={6}>
                        日期范围：<RangePicker value={this.state.showTime} onChange={this.onShowTimeChange} />
                    </Col>
                    <Col span={5}>
                        <Space>
                            <Button type="primary" onClick={this.onSearch}>搜索</Button>
                        </Space>
                    </Col>
                </Row>
                <br/>
                <Alert message="PV、UV已去除内部用户数据" type="warning" />
                <br/>

                <>
                    <h1>{this.state.startDate}至{this.state.endDate}数据总览</h1>
                    <Table
                        size="small"
                        columns={column}
                        dataSource={[this.state.totalPvData]}
                        bordered
                        pagination={{
                            position: ['none', 'none']
                        }}
                    />
                </>
                <br/>

                <Row>
                    <Col span={24}>
                        <div id="echarts-pv" style={{width: '100%', height: 600}}/>
                    </Col>
                </Row>
            </>
        )
    }
}

export default DataDailyPv;
