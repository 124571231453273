import RequestUtil from "../util/request/RequestUtil";

export function generateSpuLink(params) {
    let data = {
        channelId: params.channelId,
        eventId: params.eventId,
        expireInterval: params.expireInterval,
        linkType: params.linkType,
        skuCode: params.skuCode,
        spuId: params.spuId,
        title: params.title
    };
    return RequestUtil.getInstance().post(`/wechat/link/generateSpuLink`, data);
}

export function generateInsouringLink(params) {
    let data = {
        channelId: params.channelId,
        eventId: params.eventId,
        expireInterval: params.expireInterval,
        linkType: params.linkType,
        promotionId: params.promotionId,
        touchBarId: params.touchBarId,
        title: params.title
    };
    return RequestUtil.getInstance().post(`/wechat/link/generateInsourcingLink`, data);
}

export function generateActivityPageLink(params) {
    let data = {
        channelId: params.channelId,
        eventId: params.eventId,
        expireInterval: params.expireInterval,
        linkType: params.linkType,
        activityPageId: params.activityPageId,
        title: params.title
    };
    return RequestUtil.getInstance().post(`/wechat/link/generateActivityPageLink`, data);
}
