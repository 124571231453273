import React from 'react';
import { Upload, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

class UploadImageList extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            previewVisible: false,
            previewImage: '',
            previewTitle: '',
            fileList: this.props.value ? this.props.value : []
        };
    }

    handleCancel = () => this.setState({ previewVisible: false });

    handlePreview = async file => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }

        this.setState({
            previewImage: file.url || file.preview,
            previewVisible: true,
            previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
        });
    };

    handleChange = ({ fileList }) => {        
        this.setState({ fileList });
        if (this.props.onChange) {
            let data = [];
            fileList.forEach(item => {
                if (item.url) {
                    data.push(item);
                } else if (item.status === 'done') {
                    data.push({
                        uid: item.uid,
                        name: item.name,
                        status: 'done',
                        url: item.response.data.url
                    });
                }
            });
            this.props.onChange(data);
        }
    };

    render() {
        let { previewVisible, previewImage, fileList, previewTitle } = this.state;
        const uploadButton = (
            <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Upload</div>
            </div>
        );

        return (
            <>
                <Upload
                    name="file"
                    action={process.env.REACT_APP_MINIO_API}
                    listType="picture-card"
                    fileList={fileList}
                    onPreview={this.handlePreview}
                    onChange={this.handleChange}
                    multiple
                >
                    {fileList.length >= (this.props.limit ? this.props.limit : 5) ? null : uploadButton}
                </Upload>
                <Modal
                    visible={previewVisible}
                    title={previewTitle}
                    footer={null}
                    onCancel={this.handleCancel}
                >
                    <img alt="imageList" style={{ width: '100%' }} src={previewImage} />
                </Modal>
            </>
        );
    }
}

export default UploadImageList;
