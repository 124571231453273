import React from 'react';
import { message, Button, Form, Input } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { getInfo, updateCategory } from '../../api/ProductAttributeCategoryApi';
import LoadingLayer from '../common/LoadingLayer';

class UpdateMallProductAttributeCategory extends React.Component {

    state = {
        id: this.props.value,
        name: '',
        loading: false,
        tip: ''
    };

    componentDidMount = async _ => {
        this.setState({
            loading: true,
            tip: '数据加载中'
        });
        let response = await getInfo(this.state.id);
        let { code, data } = response.data;
        if (code === 200) {
            this.setState({
                name: data.name,
                loading: false
            });
        }
    }

    formHandler = {
        onNameChange: e => {
            this.setState({
                name: e.target.value
            });
        },
        onSubmit: async _ => {
            this.setState({
                loading: true,
                tip: '数据提交中'
            });
            let response = await updateCategory(this.state.id, this.state.name);
            let { code } = response.data;
            this.setState({
                loading: false
            });
            if (code === 200) {
                message.success('商品属性分类更新成功');
                if (this.props.onSuccess) {
                    this.props.onSuccess();
                }
            }
        }
    }

    render() {
        return (
            <>
                <LoadingLayer loading={this.state.loading} tip={this.state.tip}/>
                <PageHeader
                    title="编辑商品属性分类信息"
                />
                <Form
                    labelCol={{ span: 2 }}
                    wrapperCol={{ span: 10 }}
                    layout="horizontal"
                    style={{ marginTop: 10 }}
                >
                    <Form.Item label="分类名称">
                        <Input value={this.state.name} onChange={this.formHandler.onNameChange}/>
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 2 }}>
                        <Button type="primary" onClick={this.formHandler.onSubmit}>提交</Button>
                    </Form.Item>
                </Form>
            </>
        );
    }
}

export default UpdateMallProductAttributeCategory;
