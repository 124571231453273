import React from 'react';
import CommonLayout from '../../components/layout/CommonLayout';
import PainterTemplateList from '../../components/painter/PainterTemplateList';

class PainterTemplate extends React.Component {

    render() {
        return (
            <CommonLayout
                topNavSelectedKey="painter"
                siderNavCategory="painter"
                siderNavSelectedKey="template"
            >
                <PainterTemplateList/>
            </CommonLayout>
        );
    }
}

export default PainterTemplate;
