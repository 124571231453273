import React from 'react';
import CommonLayout from '../../components/layout/CommonLayout';
import ActivityPageList from '../../components/activity/ActivityPageList';

class ActivityPage extends React.Component {

    render() {
        return (
            <CommonLayout
                topNavSelectedKey="mall"
                siderNavCategory="mall"
                siderNavSelectedKey="activity"
            >
                <ActivityPageList/>
            </CommonLayout>
        );
    }
}

export default ActivityPage;
