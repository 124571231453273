import React from 'react';
import {
    Button,
    Form,
    Input,
    message
} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import {
    create
} from '../../api/painter/PainterPagePlanApi';
import LoadingLayer from '../common/LoadingLayer';
import PainterPageSelector from '../selector/PainterPageSelector';

class AddPainterPagePlan extends React.Component {

    state = {
        id: this.props.value,
        loading: false,
        tip: '',
        name: '',
        pageCode: ''
    };

    formHandler = {
        onNameChange: e => {
            this.setState({
                name: e.target.value
            });
        },
        onPageCodeChange: value => {
            this.setState({
                pageCode: value
            });
        },
        onSubmit: async _ => {
            let params = {
                name: this.state.name,
                pageCode: this.state.pageCode
            };
            this.setState({
                loading: true,
                tip: '数据提交中'
            });
            let response = await create(params);
            let { code } = response.data;
            this.setState({
                loading: false
            });
            if (code === 200) {
                message.success('页面方案创建成功');
                if (this.props.onSuccess) {
                    this.props.onSuccess();
                }
            }
        }
    }

    render() {
        return (
            <>
                <LoadingLayer loading={this.state.loading} tip={this.state.tip}/>
                <PageHeader
                    title="新建页面方案"
                />
                <Form
                    labelCol={{ span: 2 }}
                    wrapperCol={{ span: 10 }}
                    layout="horizontal"
                    style={{ marginTop: 10 }}
                >
                    <Form.Item label="名称">
                        <Input value={this.state.name} onChange={this.formHandler.onNameChange}/>
                    </Form.Item>
                    <Form.Item label="页面编码">
                        <PainterPageSelector width='300px' value={this.state.pageCode} onChange={this.formHandler.onPageCodeChange}/>
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 2 }}>
                        <Button type="primary" onClick={this.formHandler.onSubmit}>提交</Button>
                    </Form.Item>
                </Form>
            </>
        );
    }
}

export default AddPainterPagePlan;
