import React from 'react';
import {
    Button,
    Form,
    Input,
    Select,
    message
} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import {
    create,
    getInfo,
    update
} from '../../api/painter/PainterModelApi';
import LoadingLayer from '../common/LoadingLayer';

const { Option } = Select;

class EditPainterModel extends React.Component {

    state = {
        id: this.props.value,
        loading: false,
        tip: '',
        name: '',
        type: 0,
        className: '',
        func: '',
        script: ''
    };

    componentDidMount = async _ => {
        if (this.state.id) {
            this.setState({
                loading: true,
                tip: '数据加载中'
            });
            let response = await getInfo(this.state.id);
            let { code, data } = response.data;
            if (code === 200) {
                this.setState({
                    name: data.name,
                    type: data.type,
                    className: data.className,
                    func: data.func,
                    script: data.script,
                    loading: false
                });
            }
        }
    }

    formHandler = {
        onNameChange: e => {
            this.setState({
                name: e.target.value
            });
        },
        onTypeChange: value => {
            this.setState({
                type: value
            });
        },
        onClassNameChange: e => {
            this.setState({
                className: e.target.value
            });
        },
        onFuncChange: e => {
            this.setState({
                func: e.target.value
            });
        },
        onScriptChange: e => {
            this.setState({
                script: e.target.value
            });
        },
        onSubmit: async _ => {
            let params = {
                name: this.state.name,
                type: this.state.type,
                className: this.state.className,
                func: this.state.func,
                script: this.state.script
            };
            if (this.state.id) {
                this.setState({
                    loading: true,
                    tip: '数据提交中'
                });
                let response = await update(this.state.id, params);
                let { code } = response.data;
                this.setState({
                    loading: false
                });
                if (code === 200) {
                    message.success('数据模型更新成功');
                    if (this.props.onSuccess) {
                        this.props.onSuccess();
                    }
                }
            } else {
                this.setState({
                    loading: true,
                    tip: '数据提交中'
                });
                let response = await create(params);
                let { code } = response.data;
                this.setState({
                    loading: false
                });
                if (code === 200) {
                    message.success('数据模型创建成功');
                    if (this.props.onSuccess) {
                        this.props.onSuccess();
                    }
                }
            }
        }
    }

    render() {
        return (
            <>
                <LoadingLayer loading={this.state.loading} tip={this.state.tip}/>
                <PageHeader
                    title={this.state.id ? '编辑数据模型' : '新建数据模型'}
                />
                <Form
                    labelCol={{ span: 2 }}
                    wrapperCol={{ span: 10 }}
                    layout="horizontal"
                    style={{ marginTop: 10 }}
                >
                    <Form.Item label="名称">
                        <Input value={this.state.name} onChange={this.formHandler.onNameChange}/>
                    </Form.Item>
                    <Form.Item label="数据源类型">
                        <Select
                            value={this.state.type}
                            onChange={this.formHandler.onTypeChange}
                            style={{ width: 300 }}
                        >
                            <Option value={0}>反射</Option>
                            <Option value={1}>SpEL</Option>
                        </Select>
                    </Form.Item>
                    {this.state.type === 0 ? 
                        <>
                            <Form.Item label="类名称">
                                <Input value={this.state.className} onChange={this.formHandler.onClassNameChange}/>
                            </Form.Item>
                            <Form.Item label="方法名称">
                                <Input value={this.state.func} onChange={this.formHandler.onFuncChange}/>
                            </Form.Item>
                        </>
                        :
                        <Form.Item label="脚本">
                            <Input.TextArea autoSize={{ minRows: 4, maxRows: 20 }} value={this.state.script} onChange={this.formHandler.onScriptChange}/>
                        </Form.Item>
                    }
                    
                    <Form.Item wrapperCol={{ offset: 2 }}>
                        <Button type="primary" onClick={this.formHandler.onSubmit}>提交</Button>
                    </Form.Item>
                </Form>
            </>
        );
    }
}

export default EditPainterModel;
