import RequestUtil from "../util/request/RequestUtil";

export function getBiDailyData(params) {
    let data = {
        startDate: params.startDate,
        endDate: params.endDate
    };
    return RequestUtil.getInstance().post('/bi/daily', data);
}

export function getBiDailyPvData(params) {
    let data = {
        startDate: params.startDate,
        endDate: params.endDate
    };
    return RequestUtil.getInstance().post('/bi/daily/pv', data);
}

export function getBiTotalPvData(params) {
    let data = {
        startDate: params.startDate,
        endDate: params.endDate
    };
    return RequestUtil.getInstance().post('/bi/total/pv', data);
}

export function getBiTotalSpuClickData(params) {
    let data = {
        startDate: params.startDate,
        endDate: params.endDate
    };
    return RequestUtil.getInstance().post('/bi/total/spuClick', data);
}

export function getBiTotalSpuClickPositionData(params) {
    let data = {
        startDate: params.startDate,
        endDate: params.endDate
    };
    return RequestUtil.getInstance().post('/bi/total/spuClickPosition', data);
}

export function getBiTotalTabClickData(params) {
    let data = {
        startDate: params.startDate,
        endDate: params.endDate
    };
    return RequestUtil.getInstance().post('/bi/total/tabClick', data);
}

export function getBiTotalGeneralClickData(params) {
    let data = {
        startDate: params.startDate,
        endDate: params.endDate,
        type: params.type
    };
    return RequestUtil.getInstance().post('/bi/total/generalClick', data);
}

export function exportSpuClickDetail(params) {
    let data = {
        startDate: params.startDate,
        endDate: params.endDate
    };
    return RequestUtil.getInstance().post('/bi/export/spuClickDetail', data, {responseType: 'blob'});
}

export function exportTabClickDetail(params) {
    let data = {
        startDate: params.startDate,
        endDate: params.endDate
    };
    return RequestUtil.getInstance().post('/bi/export/tabClickDetail', data, {responseType: 'blob'});
}
