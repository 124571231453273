import React from 'react';
import { Table, Space, Button, Tag } from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';
import LoadingLayer from '../common/LoadingLayer';
import { getList, triggerStatus } from '../../api/painter/PainterPageApi';

class PainterPageList extends React.Component {

    state = {
        loading: false,
        loadingLayer: false,
        tip: '',
        list: [],
        pageNum: 1,
        pageSize: 20,
        total: 0
    };

    componentDidMount = async _ => {
        await this.getPageList();
    }

    getPageList = async _ => {
        this.setState({
            loading: true
        });
        let response = await getList(this.state.pageNum, this.state.pageSize);
        let { code, data } = response.data;
        this.setState({
            loading: false
        });
        if (code === 200) {
            data.list.map(item => item.key = item.id);
            this.setState({
                total: data.total,
                list: data.list
            });
        }
    }

    triggerStatus = async id => {
        if (window.confirm('确定上/下线吗？')) {
            this.setState({
                loadingLayer: true,
                tip: '状态更新中'
            });
            let response = await triggerStatus(id);
            this.setState({
                loadingLayer: false
            });
            let { code } = response.data;
            if (code === 200) {
                await this.getPageList();
            }
        }
    }

    onPageChange = (pageNum, pageSize) => {
        this.setState({
            pageNum: pageNum,
            pageSize: pageSize
        }, () => this.getPageList());
    }

    render() {
        const columns = [{
            title: 'ID',
            dataIndex: 'id',
            key: 'id'
        }, {
            title: '页面名称',
            dataIndex: 'name',
            key: 'name'
        }, {
            title: '页面编码',
            dataIndex: 'pageCode',
            key: 'pageCode'
        }, {
            title: '在线状态',
            dataIndex: 'status',
            key: 'status',
            render: status => (
                status ? <Tag color="#87d068">在线</Tag> : <Tag color="#f50">下线</Tag>
            )
        }, {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
            render: time => (
                <>{time ? moment(time).format('yyyy-MM-DD HH:mm:ss') : ''}</>
            )
        }, {
            title: '修改时间',
            dataIndex: 'updateTime',
            key: 'updateTime',
            render: time => (
                <>{time ? moment(time).format('yyyy-MM-DD HH:mm:ss') : ''}</>
            )
        }, {
            title: '操作',
            key: 'action',
            fixed: 'right',
            render: (text, record) => (
              <Space size="middle">
                <Link to={`/painter/page/${record.id}`}><Button type="link">编辑</Button></Link>
                {record.status ? 
                    <Button type="link" style={{ color: 'red' }} onClick={this.triggerStatus.bind(this, record.id)}>下线</Button> :
                    <Button type="link" onClick={this.triggerStatus.bind(this, record.id)}>上线</Button>
                }
              </Space>
            )
        }];

        return (
            <>
                <LoadingLayer loading={this.state.loadingLayer} tip={this.state.tip}/>
                <Space size={30}>
                    <Link to={"/painter/page/add"}><Button type="primary">新建页面</Button></Link>
                    <a href='https://veklcj82gp.feishu.cn/docs/doccn6l38LhlqUT28ACYi9QKrnd?from=from_copylink' target="_blank">操作手册-PM运营使用</a>
                    <a href='https://veklcj82gp.feishu.cn/docs/doccnfoFYJMVamG8h3AoEL8JJIf?from=from_copylink' target="_blank">技术手册-RD使用</a>
                </Space>
                <br/><br/>
                <Table
                    loading={this.state.loading}
                    size="small"
                    columns={columns}
                    dataSource={this.state.list}
                    bordered
                    pagination={{ total: this.state.total, pageSize: this.state.pageSize, onChange: this.onPageChange }}
                />
            </>
        );
    }
}

export default PainterPageList;
