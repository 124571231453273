import React from 'react';
import {
    Button,
    Form,
    Input,
    message,
    Select
} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import {
    create,
    getInfo,
    update
} from '../../api/painter/PainterTemplateApi';
import LoadingLayer from '../common/LoadingLayer';
import UploadImage from '../upload/Image';
import PainterModelSelector from '../selector/PainterModelSelector';
import PainterPageSelector from '../selector/PainterPageSelector';

const { Option } = Select;

class EditPainterTemplate extends React.Component {

    state = {
        id: this.props.value,
        loading: false,
        tip: '',
        name: '',
        pageCode: '',
        modelId: 0,
        previewImage: '',
        protocolScript: '',
        protocolType: 0,
        templateCode: ''
    };

    componentDidMount = async _ => {
        if (this.state.id) {
            this.setState({
                loading: true,
                tip: '数据加载中'
            });
            let response = await getInfo(this.state.id);
            let { code, data } = response.data;
            if (code === 200) {
                this.setState({
                    name: data.name,
                    pageCode: data.pageCode,
                    modelId: data.modelId,
                    previewImage: data.previewImage,
                    protocolScript: data.protocolScript,
                    protocolType: data.protocolType,
                    templateCode: data.templateCode,
                    loading: false
                });
            }
        }
    }

    formHandler = {
        onNameChange: e => {
            this.setState({
                name: e.target.value
            });
        },
        onTemplateCodeChange: e => {
            this.setState({
                templateCode: e.target.value
            });
        },
        onPreviewImageChange: value => {
            this.setState({
                previewImage: value
            });
        },
        onPageCodeChange: value => {
            this.setState({
                pageCode: value
            });
        },
        onModelIdChange: value => {
            this.setState({
                modelId: value
            });
        },
        onProtocolTypeChange: value => {
            this.setState({
                protocolType: value
            });
        },
        onProtocolScriptChange: e => {
            this.setState({
                protocolScript: e.target.value
            });
        },
        onSubmit: async _ => {
            let params = {
                name: this.state.name,
                pageCode: this.state.pageCode,
                modelId: this.state.modelId,
                previewImage: this.state.previewImage,
                protocolScript: this.state.protocolScript,
                protocolType: this.state.protocolType,
                templateCode: this.state.templateCode
            };
            if (this.state.id) {
                this.setState({
                    loading: true,
                    tip: '数据提交中'
                });
                let response = await update(this.state.id, params);
                let { code } = response.data;
                this.setState({
                    loading: false
                });
                if (code === 200) {
                    message.success('模版更新成功');
                    if (this.props.onSuccess) {
                        this.props.onSuccess();
                    }
                }
            } else {
                this.setState({
                    loading: true,
                    tip: '数据提交中'
                });
                let response = await create(params);
                let { code } = response.data;
                this.setState({
                    loading: false
                });
                if (code === 200) {
                    message.success('模版创建成功');
                    if (this.props.onSuccess) {
                        this.props.onSuccess();
                    }
                }
            }
        }
    }

    render() {
        return (
            <>
                <LoadingLayer loading={this.state.loading} tip={this.state.tip}/>
                <PageHeader
                    title={this.state.id ? '编辑模版' : '新建模版'}
                />
                <Form
                    labelCol={{ span: 2 }}
                    wrapperCol={{ span: 10 }}
                    layout="horizontal"
                    style={{ marginTop: 10 }}
                >
                    <Form.Item label="名称">
                        <Input value={this.state.name} onChange={this.formHandler.onNameChange}/>
                    </Form.Item>
                    <Form.Item label="模版编码">
                        <Input value={this.state.templateCode} onChange={this.formHandler.onTemplateCodeChange}/>
                    </Form.Item>
                    <Form.Item label="示例图">
                        <UploadImage value={this.state.previewImage} onChange={this.formHandler.onPreviewImageChange}/>
                    </Form.Item>
                    <Form.Item label="所属页面">
                        <PainterPageSelector width="300px" value={this.state.pageCode} onChange={this.formHandler.onPageCodeChange} />
                    </Form.Item>
                    <Form.Item label="数据模型">
                        <PainterModelSelector width="300px" value={this.state.modelId} onChange={this.formHandler.onModelIdChange} />
                    </Form.Item>
                    <Form.Item label="前后端转换协议">
                        <Select
                            value={this.state.protocolType}
                            onChange={this.formHandler.onProtocolTypeChange}
                            style={{ width: 300 }}
                        >
                            <Option value={0}>不需要</Option>
                            <Option value={1}>freemarker</Option>
                        </Select>
                    </Form.Item>
                    {this.state.protocolType === 1 && 
                        <Form.Item label="前后端转换脚本">
                            <Input.TextArea autoSize={{ minRows: 4, maxRows: 20 }} value={this.state.protocolScript} onChange={this.formHandler.onProtocolScriptChange}/>
                        </Form.Item>
                    }
                    <Form.Item wrapperCol={{ offset: 2 }}>
                        <Button type="primary" onClick={this.formHandler.onSubmit}>提交</Button>
                    </Form.Item>
                </Form>
            </>
        );
    }
}

export default EditPainterTemplate;
