import React from 'react';
import CommonLayout from '../../components/layout/CommonLayout';
import PainterPagePlanList from '../../components/painter/PainterPagePlanList';

class PainterPagePlan extends React.Component {

    render() {
        return (
            <CommonLayout
                topNavSelectedKey="painter"
                siderNavCategory="painter"
                siderNavSelectedKey="pagePlan"
            >
                <PainterPagePlanList/>
            </CommonLayout>
        );
    }
}

export default PainterPagePlan;
