import React from 'react';
import CommonLayout from './components/layout/CommonLayout';

class App extends React.Component {

    render() {
        return (
            <CommonLayout/>
        );
    }
}

export default App;
