import React from 'react';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import { getListByParentId } from '../../api/ProductCategoryApi';
import LocalStorageUtil from '../../util/LocalStorageUtil';

class SiderNav extends React.Component {

    state = {
        menu: {
            'user': [{
                'key': 'admins',
                'name': '管理员'
            }, {
                'key': 'members',
                'name': '会员列表'
            }],
            'store': [{
                'key': 'brand',
                'name': '品牌管理'
            }],
            'mall': [
            // {
            //     'key': 'product_category',
            //     'name': '商品分类管理'
            // },
            {
                'key': 'product',
                'name': '商品管理'
            }, {
                'key': 'marketing',
                'name': '营销管理'
            }, {
                'key': 'activity',
                'name': '活动页管理'
            }, {
                'key': 'brand',
                'name': '品牌管理'
            }, {
                'key': 'operation',
                'name': '运营位管理'
            }, {
                'key': 'coupon',
                'name': '优惠券管理'
            }
            // {
            //     'key': 'product_attribute',
            //     'name': '商品属性管理'
            // },
            ],
            'stock': [{
                'key': 'list',
                'name': '库存列表'
            }],
            'order': [{
                'key': 'list',
                'name': '订单列表'
            }],
            'painter': [{
                'key': 'model',
                'name': '数据模型管理'
            }, {
                'key': 'template',
                'name': '模版管理'
            }, {
                'key': 'page',
                'name': '页面管理'
            }, {
                'key': 'pagePlan',
                'name': '页面方案管理'
            }],
            'channel': [{
                'key': 'list',
                'name': '渠道管理'
            }, {
                'key': 'event',
                'name': '事件管理'
            }],
            'data': [{
                'key': 'core',
                'name': '核心数据'
            }, {
                'key': 'pv',
                'name': '访问数据'
            }, {
                'key': 'behavior',
                'name': '关键行为数据'
            }]
        }
    };

    componentDidMount = async _ => {
        // let response = await getListByParentId(0);
        // let { code, data } = response.data;
        // if (code === 200) {
        //     let menu = this.state.menu;
        //     data.forEach((item, index) => {
        //         if (index === 0) {
        //             LocalStorageUtil.setWithExpire('menu_stock_default_key', item.id, 86400 * 7);
        //         }
        //         menu.stock.push({
        //             key: `list/${item.id}`,
        //             name: item.name
        //         });
        //     });
        //     this.setState({
        //         menu: menu
        //     });
        // }
    }

    render() {
        const data = this.state.menu[this.props.category] 
            ? this.state.menu[this.props.category].map(item => 
                <Menu.Item key={item.key}>
                    <Link to={"/" + this.props.category + "/" + item.key}>{item.name}</Link>
                </Menu.Item>) 
            : <></>;

        return (
            <div>
                <Menu mode="inline" defaultSelectedKeys={[this.props.selectedKey]}>
                    {data}
                </Menu>
            </div>
        );
    }
}

export default SiderNav;
