import React from 'react';
import { Tabs } from 'antd';
import CommonLayout from '../../components/layout/CommonLayout';
import MallProductCategoryFirstClassList from '../../components/mall/ProductCategoryFirstClassList';
import MallProductCategorySecondClassList from '../../components/mall/ProductCategorySecondClassList';
import UrlUtil from '../../util/UrlUtil';

const { TabPane } = Tabs;

class MallProductCategory extends React.Component {

    state = {
        tab: UrlUtil.getQueryString(this.props.location.search, 'tab'),
        category: UrlUtil.getQueryString(this.props.location.search, 'category')
    };

    render() {
        return (
            <CommonLayout
                topNavSelectedKey="mall"
                siderNavCategory="mall"
                siderNavSelectedKey="product_category"
            >
                <Tabs defaultActiveKey={this.state.tab ? this.state.tab : 'first_class'}>
                    <TabPane tab="一级分类" key="first_class">
                        <MallProductCategoryFirstClassList/>
                    </TabPane>
                    <TabPane tab="二级分类" key="second_class">
                        <MallProductCategorySecondClassList value={this.state.category}/>
                    </TabPane>
                </Tabs>
            </CommonLayout>
        );
    }
}

export default MallProductCategory;
