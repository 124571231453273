import React from 'react';
import { message, Button, Form, Input, Radio, InputNumber } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import UploadImage from '../upload/Image';
import LoadingLayer from '../common/LoadingLayer';
import { createBrand } from '../../api/BrandApi';

class AddStoreBrand extends React.Component {

    state = {
        bigPic: '',
        brandStory: '',
        firstLetter: '',
        logo: '',
        name: '',
        showStatus: 1,
        factoryStatus: 1,
        sort: 0,
        loading: false,
        tip: ''
    };

    formHandler = {
        onNameChange: e => {
            this.setState({
                name: e.target.value
            });
        },
        onFirstLetterChange: e => {
            this.setState({
                firstLetter: e.target.value
            });
        },
        onBrandStoryChange: e => {
            this.setState({
                brandStory: e.target.value
            });
        },
        onLogoChange: url => {
            this.setState({
                logo: url
            });
        },
        onBigPicChange: url => {
            this.setState({
                bigPic: url
            });
        },
        onFactoryStatusChange: e => {
            this.setState({
                factoryStatus: e.target.value
            });
        },
        onShowStatusChange: e => {
            this.setState({
                showStatus: e.target.value
            });
        },
        onSortChange: number => {
            this.setState({
                sort: number
            });
        },
        onSubmit: async _ => {
            this.setState({
                loading: true,
                tip: '数据提交中'
            });
            let response = await createBrand(this.state);
            let { code } = response.data;
            this.setState({
                loading: false
            });
            if (code === 200) {
                message.success('品牌创建成功');
                if (this.props.onSuccess) {
                    this.props.onSuccess();
                }
            }
        }
    }

    render() {
        return (
            <>
                <LoadingLayer loading={this.state.loading} tip={this.state.tip}/>
                <PageHeader
                    title="新建品牌信息"
                />
                <Form
                    labelCol={{ span: 2 }}
                    wrapperCol={{ span: 10 }}
                    layout="horizontal"
                    style={{ marginTop: 10 }}
                >
                    <Form.Item label="品牌名称">
                        <Input value={this.state.name} onChange={this.formHandler.onNameChange}/>
                    </Form.Item>
                    <Form.Item label="品牌logo">
                        <UploadImage value={this.state.logo} onChange={this.formHandler.onLogoChange}/>
                    </Form.Item>
                    <Form.Item label="大图">
                        <UploadImage value={this.state.bigPic} onChange={this.formHandler.onBigPicChange}/>
                    </Form.Item>
                    <Form.Item label="首字母">
                        <Input value={this.state.firstLetter} onChange={this.formHandler.onFirstLetterChange}/>
                    </Form.Item>
                    <Form.Item label="品牌故事">
                        <Input.TextArea value={this.state.brandStory} onChange={this.formHandler.onBrandStoryChange}/>
                    </Form.Item>
                    <Form.Item label="工厂状态">
                        <Radio.Group value={this.state.factoryStatus} onChange={this.formHandler.onFactoryStatusChange}>
                            <Radio value={1}>正常</Radio>
                            <Radio value={0}>异常</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item label="展示状态">
                        <Radio.Group value={this.state.showStatus} onChange={this.formHandler.onShowStatusChange}>
                            <Radio value={1}>展示</Radio>
                            <Radio value={0}>不展示</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item label="排序">
                        <InputNumber value={this.state.sort} onChange={this.formHandler.onSortChange}/>
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 2 }}>
                        <Button type="primary" onClick={this.formHandler.onSubmit}>提交</Button>
                    </Form.Item>
                </Form>
            </>
        );
    }
}

export default AddStoreBrand;
