import React from 'react';
import CommonLayout from '../../components/layout/CommonLayout';
import MarketingList from '../../components/marketing/MarketingList';

class Marketing extends React.Component {

    render() {
        return (
            <CommonLayout
                topNavSelectedKey="mall"
                siderNavCategory="mall"
                siderNavSelectedKey="marketing"
            >
                <MarketingList/>
            </CommonLayout>
        );
    }
}

export default Marketing;
