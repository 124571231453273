import React from 'react';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import LocalStorageUtil from '../../util/LocalStorageUtil';

const menu = [{
        key: 'stock',
        name: '库存',
        link: `/stock/list/${LocalStorageUtil.get('menu_stock_default_key') ? LocalStorageUtil.get('menu_stock_default_key') : 1}`
    }, {
        key: 'mall',
        name: '商城',
        link: '/mall/product'
    }, {
        key: 'channel',
        name: '渠道',
        link: '/channel/list'
    }, {
        key: 'order',
        name: '订单',
        link: '/order/list'
    }, {
        key: 'user',
        name: '用户',
        link: '/user/admins'
    }, {
        key: 'painter',
        name: 'Painter',
        link: '/painter/model'
    }, {
        key: 'data',
        name: '数据',
        link: '/data/core'
    }
]

class TopNav extends React.Component {

    render() {
        const menuItem = menu.map(item => 
            <Menu.Item key={item.key}>
                <Link to={item.link}>{item.name}</Link>
            </Menu.Item>
        );

        return (
            <Menu mode="horizontal" defaultSelectedKeys={[this.props.selectedKey]}>
                {menuItem}
            </Menu>
        );
    }
}

export default TopNav;
