import React from 'react';
import {
    message,
    Button,
    Form,
    Input,
    InputNumber
} from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import LoadingLayer from '../common/LoadingLayer';
import { addCoupon, getCoupon, updateCoupon } from '../../api/CouponApi';

class EditCoupon extends React.Component {

    state = {
        id: this.props.value,
        showName: '',
        desc: '',
        discountAmounts: 0,
        stock: 0,
        useTimePlusDay: 1,
        loading: false,
        tip: ''
    };

    componentDidMount = async _ => {
        if (this.state.id) {
            this.setState({
                loading: true,
                tip: '数据加载中'
            });
            let response = await getCoupon(this.state.id);
            let { code, data } = response.data;
            if (code === 200) {
                this.setState({
                    loading: false,
                    showName: data.showName,
                    desc: data.desc,
                    discountAmounts: data.discountAmounts,
                    stock: data.stock,
                    useTimePlusDay: data.useTimePlusDay
                });
            }
        }
    }

    formHandler = {
        onShowNameChange: e => {
            this.setState({
                showName: e.target.value
            });
        },
        onDescChange: e => {
            this.setState({
                desc: e.target.value
            });
        },
        onDiscountAmountsChange: number => {
            this.setState({
                discountAmounts: number
            });
        },
        onStockChange: number => {
            this.setState({
                stock: number
            });
        },
        onBuyerLimitChange: number => {
            this.setState({
                buyerLimit: number
            });
        },
        onUseTimePlusDayChange: number => {
            this.setState({
                useTimePlusDay: number
            });
        },
        onSubmit: async _ => {
            this.setState({
                loading: true,
                tip: '提交中'
            });
            let response;
            if (this.state.id) {
                response = await updateCoupon(this.state.id, this.state);
            } else {
                response = await addCoupon(this.state);
            }
            this.setState({
                loading: false
            });
            let { code } = response.data;
            if (code === 200) {
                message.success('数据提交成功');
                if (this.props.onSuccess) {
                    this.props.onSuccess();
                }
            }
        }
    }

    render() {
        return (
            <>
                <LoadingLayer loading={this.state.loading} tip={this.state.tip}/>
                <PageHeader
                    title={this.state.id ? "编辑优惠券" : "新建优惠券"}
                />
                <Form
                    labelCol={{ span: 2 }}
                    wrapperCol={{ span: 10 }}
                    layout="horizontal"
                    style={{ marginTop: 10 }}
                >
                    <Form.Item label="优惠券名称">
                        <Input value={this.state.showName} onChange={this.formHandler.onShowNameChange}/>
                    </Form.Item>
                    <Form.Item label="规则说明">
                        <Input.TextArea value={this.state.desc} onChange={this.formHandler.onDescChange}/>
                    </Form.Item>
                    <Form.Item label="优惠金额">
                        <InputNumber value={this.state.discountAmounts} onChange={this.formHandler.onDiscountAmountsChange}/> 元
                    </Form.Item>
                    <Form.Item label="库存">
                        <InputNumber min={0} value={this.state.stock} onChange={this.formHandler.onStockChange}/> 张
                    </Form.Item>
                    <Form.Item label="有效期">
                        <InputNumber showTime value={this.state.useTimePlusDay} onChange={this.formHandler.onUseTimePlusDayChange}/> 天
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 2 }}>
                        <Button type="primary" onClick={this.formHandler.onSubmit}>提交</Button>
                    </Form.Item>
                </Form>
            </>
        );
    }
}

export default EditCoupon;
