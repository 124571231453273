import React from 'react';
import { Select } from 'antd';
import { getList } from '../../api/painter/PainterPageApi';

const { Option } = Select;

class PainterPageSelector extends React.Component {

    state = {
        list: []
    };

    componentDidMount = async _ => {
        let response = await getList(1, 500);
        let { code, data } = response.data;
        if (code === 200) {
            this.setState({
                list: data.list
            });
        }
    }

    onChange = value => {
        if (this.props.onChange) {
            this.props.onChange(value ? value : "0");
        }
    }

    render() {
        const options = this.state.list.map(item => <Option key={item.id} value={item.pageCode}>{item.name}</Option>)

        return (
            <Select
                allowClear
                showSearch
                style={{ width: this.props.width ? this.props.width : 200 }}
                placeholder="选择页面"
                value={this.props.value ? this.props.value : undefined}
                optionFilterProp="children"
                onChange={this.onChange}
                filterOption={(input, option) =>
                    option.children.indexOf(input) >= 0
                }
            >
                {options}
            </Select>
        );
    }
}

export default PainterPageSelector;
