import React from 'react';
import CommonLayout from '../../components/layout/CommonLayout';
import AddMallProduct from '../../components/mall/AddProduct';
import UpdateMallProduct from '../../components/mall/UpdateProduct';

class MallProductEdit extends React.Component {

    state = {
        productId: this.props.match.params.id
    };

    render() {
        return (
            <CommonLayout
                topNavSelectedKey="mall"
                siderNavCategory="mall"
                siderNavSelectedKey="product"
            >
                {this.props.location.pathname === '/mall/product/add' ?
                    <AddMallProduct
                        onSuccess={() => this.props.history.push('/mall/product')}
                    /> :
                    <UpdateMallProduct
                        value={this.state.productId}
                        onSuccess={() => this.props.history.push('/mall/product')}
                    />
                }
            </CommonLayout>
        );
    }
}

export default MallProductEdit;
