import React from 'react';
import CommonLayout from '../../components/layout/CommonLayout';
import ChannelList from '../../components/channel/ChannelList';

class Channel extends React.Component {

    render() {
        return (
            <CommonLayout
                topNavSelectedKey="channel"
                siderNavCategory="channel"
                siderNavSelectedKey="list"
            >
                <ChannelList/>
            </CommonLayout>
        );
    }
}

export default Channel;
