import React from "react";
import CommonLayout from '../../components/layout/CommonLayout';
import DataDaily from '../../components/data/Daily';

class DataCore extends React.Component {

    render() {
        return (
            <CommonLayout
                topNavSelectedKey="data"
                siderNavCategory="data"
                siderNavSelectedKey="core"
            >
                <DataDaily/>
            </CommonLayout>
        );
    }
}

export default DataCore;
