import React from 'react';
import { Upload, Button, Space, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { delayUploadBatchConfig } from '../../api/ProductApi';

class UploadFileSpuDelayConfig extends React.Component {

    state = {
        fileList: [],
        uploading: false
    }

    beforeUpload = file => {
        this.setState(state => ({
            fileList: [file]
        }));
        return false;
    }

    onRemove = file => {
        this.setState(state => {
            const index = state.fileList.indexOf(file);
            const newFileList = state.fileList.slice();
            newFileList.splice(index, 1);
            return {
                fileList: newFileList
            };
        });
    }

    handleUpload = async _ => {
        const { fileList } = this.state;
        this.setState({
            uploading: true
        });

        let response = await delayUploadBatchConfig(fileList[0]);
        this.setState({
            uploading: false
        });
        let { code, data } = response.data;
        if (code === 200) {
            this.setState({
                fileList: []
            });
            if (data.code === 0) {
                message.success('上传成功');
            } else {
                message.error(data.errorText);
            }
        }
    };

    render() {
        return (
            <>
                <Space>
                    <Upload
                        name="file"
                        action={process.env.REACT_APP_UPLOAD_SPU_DELAY_CONFIG_API}
                        fileList={this.state.fileList}
                        beforeUpload={this.beforeUpload}
                        onRemove={this.onRemove}
                        onChange={this.handleChange}
                    >
                        <Button icon={<UploadOutlined />}>选择文件</Button>
                    </Upload>
                    <Button
                        type="primary"
                        onClick={this.handleUpload}
                        disabled={this.state.fileList.length === 0}
                        loading={this.state.uploading}
                    >开始上传</Button>
                </Space>
            </>
        );
    }
}

export default UploadFileSpuDelayConfig;
