import React from 'react';
import { Tag, Select, message, Row, Col, Table, Space, Button } from 'antd';
import { Link } from 'react-router-dom';
import { getList, deleteAttribute } from '../../api/ProductAttributeApi';
import LoadingLayer from '../common/LoadingLayer';
import ProductAttributeCategorySelector from '../selector/ProductAttributeCategorySelector';

const { Option } = Select;

const selectTypeMap = {
    0: '唯一',
    1: '单选',
    2: '多选'
};

const inputTypeMap = {
    0: '手工录入',
    1: '从列表中选取'
};

const filterTypeMap = {
    0: '普通',
    1: '颜色'
};

const searchTypeMap = {
    0: '不需要进行检索',
    1: '关键字检索',
    2: '范围检索'
};

const relatedStatusMap = {
    0: '不关联',
    1: '关联'
};

const handAddStatusMap = {
    0: '不支持',
    1: '支持'
};

class MallProductAttributeList extends React.Component {
    
    state = {
        loading: false,
        loadingLayer: false,
        tip: '',
        list: [],
        pageNum: 1,
        pageSize: 20,
        total: 0,
        categoryId: this.props.category ? this.props.category : 0,
        type: this.props.type ? parseInt(this.props.type) : 0
    };

    componentDidMount = async _ => {
        if (this.state.categoryId) {
            await this.getAttributeList();
        }
    }

    getAttributeList = async _ => {
        this.setState({
            loading: true
        });
        let response = await getList(this.state.categoryId, this.state.type, this.state.pageNum, this.state.pageSize);
        let { code, data } = response.data;
        if (code === 200) {
            data.list.map(item => item.key = item.id);
            this.setState({
                total: data.total,
                list: data.list,
                loading: false
            });
        }
    }

    searchHandler = {
        onCategoryChange: value => {
            this.setState({
                categoryId: value
            });
        },
        onTypeChange: value => {
            this.setState({
                type: value
            });
        },
        onSearch: async _ => {
            if (!this.state.categoryId) {
                message.warning('属性分类不能为空');
                return;
            }
            await this.getAttributeList();
        }    
    }

    onDelete = async id => {
        if (window.confirm('确定删除？')) {
            this.setState({
                loadingLayer: true,
                tip: '数据删除中'
            });
            let response = await deleteAttribute(id);
            let { code } = response.data;
            this.setState({
                loadingLayer: false
            });
            if (code === 200) {
                message.success('删除分类成功');
                await this.getAttributeList();
            }
        }
    }

    onPageChange = (pageNum, pageSize) => {
        this.setState({
            pageNum: pageNum,
            pageSize: pageSize
        }, () => this.getAttributeList());
    }

    render() {
        const columns = [{
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        }, {
            title: '名称',
            dataIndex: 'name',
            key: 'name',
        }, {
            title: '属性选择类型',
            dataIndex: 'selectType',
            key: 'selectType',
            render: selectType => (
                <span>{selectTypeMap[selectType]}</span>
            )
        }, {
            title: '属性录入方式',
            dataIndex: 'inputType',
            key: 'inputType',
            render: inputType => (
                <span>{inputTypeMap[inputType]}</span>
            )
        },  {
            title: '可选值列表',
            dataIndex: 'inputList',
            key: 'inputList',
            render: inputList => (
                <Space>
                    {inputList && inputList.split(',').map(item => <Tag color="blue">{item}</Tag>)}
                </Space>
            )
        }, {
            title: '分类筛选样式',
            dataIndex: 'filterType',
            key: 'filterType',
            render: filterType => (
                <span>{filterTypeMap[filterType]}</span>
            )
        }, {
            title: '检索类型',
            dataIndex: 'searchType',
            key: 'searchType',
            render: searchType => (
                <span>{searchTypeMap[searchType]}</span>
            )
        }, {
            title: '相同属性产品是否关联',
            dataIndex: 'relatedStatus',
            key: 'relatedStatus',
            render: relatedStatus => (
                <span>{relatedStatusMap[relatedStatus]}</span>
            )
        }, {
            title: '是否支持手动新增',
            dataIndex: 'handAddStatus',
            key: 'handAddStatus',
            render: handAddStatus => (
                <span>{handAddStatusMap[handAddStatus]}</span>
            )
        }, {
            title: '排序',
            dataIndex: 'sort',
            key: 'sort'
        }, {
            title: '操作',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <Link to={`/mall/product_attribute/edit/${record.id}`}><Button type="link">编辑</Button></Link>
                    <Button type="link" style={{ color: 'red' }} onClick={this.onDelete.bind(this, record.id)}>删除</Button>
                </Space>
            )
        }];

        return (
            <div>
                <LoadingLayer loading={this.state.loadingLayer} tip={this.state.tip}/>
                <Row>
                    <Col span={4}>
                        <ProductAttributeCategorySelector
                            value={this.state.categoryId}
                            onChange={this.searchHandler.onCategoryChange}
                        />
                    </Col>
                    <Col span={4}>
                        <Select
                            style={{ width: 150 }}
                            value={this.state.type}
                            onChange={this.searchHandler.onTypeChange}
                        >
                            <Option value={0}>规格</Option>
                            <Option value={1}>参数</Option>
                        </Select>
                    </Col>
                    <Col>
                        <Space>
                            <Button type="primary" onClick={this.searchHandler.onSearch}>搜索</Button>
                            <Link to="/mall/product_attribute/add">
                                <Button>新建属性</Button>
                            </Link>
                        </Space>
                    </Col>
                </Row>

                <br/>
                <Table
                    loading={this.state.loading}
                    size="small"
                    columns={columns}
                    dataSource={this.state.list}
                    bordered
                    pagination={{ total: this.state.total, pageSize: this.state.pageSize, onChange: this.onPageChange }}
                />
            </div>
        );
    }
}

export default MallProductAttributeList;
