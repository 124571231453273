import React from 'react';
import CommonLayout from '../../components/layout/CommonLayout';
import AddMallProductCategory from '../../components/mall/AddProductCategory';
import UpdateMallProductCategory from '../../components/mall/UpdateProductCategory';

class MallProductCategoryEdit extends React.Component {

    state = {
        categoryId: this.props.match.params.id
    };

    render() {
        return (
            <CommonLayout
                topNavSelectedKey="mall"
                siderNavCategory="mall"
                siderNavSelectedKey="product_category"
            >
                {this.props.location.pathname === '/mall/product_category/add' ?
                    <AddMallProductCategory
                        onSuccess={data => this.props.history.push('/mall/product_category' + (data.parentId ? `?tab=second_class&category=${data.parentId}` : ''))}
                    /> :
                    <UpdateMallProductCategory
                        value={this.state.categoryId}
                        onSuccess={data => this.props.history.push('/mall/product_category' + (data.parentId ? `?tab=second_class&category=${data.parentId}` : ''))}
                    />
                }
            </CommonLayout>
        );
    }
}

export default MallProductCategoryEdit;
