import React from 'react';
import CommonLayout from '../../components/layout/CommonLayout';
import PainterModelList from '../../components/painter/PainterModelList';

class PainterModel extends React.Component {

    render() {
        return (
            <CommonLayout
                topNavSelectedKey="painter"
                siderNavCategory="painter"
                siderNavSelectedKey="model"
            >
                <PainterModelList/>
            </CommonLayout>
        );
    }
}

export default PainterModel;
