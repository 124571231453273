import React from 'react';
import { message, Row, Col, Table, Space, Button, Tag } from 'antd';
import { Link } from 'react-router-dom';
import ProductCategoryFirstClassSelector from '../selector/ProductCategoryFirstClassSelector';
import LoadingLayer from '../common/LoadingLayer';
import { getList, deleteCategory } from '../../api/ProductCategoryApi';

class MallProductCategorySecondClassList extends React.Component {
    
    state = {
        loading: false,
        loadingLayer: false,
        tip: '',
        parentId: this.props.value ? parseInt(this.props.value) : 0,
        list: [],
        pageNum: 1,
        pageSize: 20,
        total: 0
    };

    componentDidMount = async _ => {
        if (this.state.parentId) {
            await this.getCategroyList();
        }
    }

    onCategoryChange = value => {
        this.setState({
            parentId: value
        });
    }

    onSearch = async _ => {
        if (!this.state.parentId) {
            message.error('请选择分类');
            return;
        }
        await this.getCategroyList();
    }

    getCategroyList = async _ => {
        this.setState({
            loading: true
        });
        let response = await getList(this.state.parentId, this.state.pageNum, this.state.pageSize);
        let { code, data } = response.data;
        if (code === 200) {
            data.list.map(item => item.key = item.id);
            this.setState({
                total: data.total,
                list: data.list,
                loading: false
            });
        }
    }

    onDelete = async id => {
        if (window.confirm('确定删除？')) {
            this.setState({
                loadingLayer: true,
                tip: '数据删除中'
            });
            let response = await deleteCategory(id);
            let { code } = response.data;
            this.setState({
                loadingLayer: false
            });
            if (code === 200) {
                message.success('删除分类成功');
                await this.getCategroyList();
            }
        }
    }

    onPageChange = (pageNum, pageSize) => {
        this.setState({
            pageNum: pageNum,
            pageSize: pageSize
        }, () => this.getCategroyList());
    }

    render() {
        const columns = [{
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        }, {
            title: '分类名称',
            dataIndex: 'name',
            key: 'name',
        }, {
            title: '关联商品数',
            dataIndex: 'productCount',
            key: 'productCount'
        }, {
            title: '关联商品单位',
            dataIndex: 'productUnit',
            key: 'productUnit'
        }, {
            title: '导航展示状态',
            dataIndex: 'navStatus',
            key: 'navStatus',
            render: status => (
                status === 1 ? <Tag color="#87d068">展示</Tag> : <Tag color="#f50">不展示</Tag>
            )
        }, {
            title: '排序',
            dataIndex: 'sort',
            key: 'sort',
        }, {
            title: '操作',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <Link to={`/mall/product_category/edit/${record.id}`}><Button type="link">编辑</Button></Link>
                    <Button type="link" style={{ color: 'red' }} onClick={this.onDelete.bind(this, record.id)}>删除</Button>
                </Space>
            )
        }];

        return (
            <div>
                <LoadingLayer loading={this.state.loadingLayer} tip={this.state.tip}/>
                <Row>
                    <Col span={4}>
                        <ProductCategoryFirstClassSelector value={this.state.parentId} onChange={this.onCategoryChange}/>
                    </Col>
                    <Col>
                        <Space>
                            <Button type="primary" onClick={this.onSearch}>搜索</Button>
                            <Link to="/mall/product_category/add">
                                <Button>新建分类</Button>
                            </Link>
                        </Space>
                    </Col>
                </Row>

                <br/>
                <Table
                    loading={this.state.loading}
                    size="small"
                    columns={columns}
                    dataSource={this.state.list}
                    bordered
                    pagination={{ total: this.state.total, pageSize: this.state.pageSize, onChange: this.onPageChange }}
                />
            </div>
        );
    }
}

export default MallProductCategorySecondClassList;
