import axios from 'axios';

class RequestBase {

    static getInstance = (params = {}) => {
        let data = {};
        if (params.baseURL || this.getBaseURL()) {
            data.baseURL = params.baseURL ? params.baseURL : this.getBaseURL();
        }
        if (params.headers || this.getHeaders()) {
            data.headers = params.headers ? params.headers : this.getHeaders();
        }
        if (params.timeout || this.getTimeout()) {
            data.timeout = params.timeout ? params.timeout : this.getTimeout();
        }

        this.getInterceptor();
        return axios.create(data);
    }

    static getBaseURL = () => {
        return window.location.protocol + '//' + window.location.host + process.env.REACT_APP_API_PATH;
    }

    static getHeaders = () => {
        return null;
    }

    static getTimeout = () => {
        return 3000;
    }

    static getInterceptor = _ => {
        axios.interceptors.response.use(function(response) {
            return response;
        }, function (error) {
            // 对响应错误做点什么
            return Promise.reject(error);
        });
    }
}

export default RequestBase;
