import React from "react";
import {
    Table,
    Button,
    Space,
    Row,
    Col,
    DatePicker,
    Alert
} from 'antd';
import LoadingLayer from '../common/LoadingLayer';
import { getBiDailyData } from '../../api/BiApi';
import { getChannelDict, getEventDict } from '../../api/ChannelApi';
import { wxSceneIdMap } from '../../const/WxSceneId';

const { RangePicker } = DatePicker;

const column = [{
    title: '渠道',
    dataIndex: 'channel',
    key: 'channel',
    align: 'center',
    width: 300
}, {
    title: 'PV',
    dataIndex: 'pv',
    key: 'pv',
    align: 'center'
}, {
    title: '新用户',
    dataIndex: 'newUser',
    key: 'newUser',
    align: 'center'
}, {
    title: '新注册用户',
    dataIndex: 'registerUser',
    key: 'registerUser',
    align: 'center'
}, {
    title: '订单',
    dataIndex: 'order',
    key: 'order',
    align: 'center'
}]

const columnOverall = [{
    title: '累计用户数',
    dataIndex: 'userNum',
    key: 'userNum',
    align: 'center'
}, {
    title: '累计注册用户数',
    dataIndex: 'registerNum',
    key: 'registerNum',
    align: 'center'
}, {
    title: '累计PV',
    dataIndex: 'pv',
    key: 'pv',
    align: 'center'
}, {
    title: '累计UV',
    dataIndex: 'uv',
    key: 'uv',
    align: 'center'
}, {
    title: '累计订单',
    dataIndex: 'orderNum',
    key: 'orderNum',
    align: 'center'
}]

class DataDaily extends React.Component {

    state = {
        loadingLayer: false,
        tip: '',
        showTime: [],
        startDate: '',
        endDate: '',
        channelMap: new Map(),
        eventMap: new Map(),
        tables: [],
        overall: {
            userNum: 0,
            registerNum: 0,
            orderNum: 0,
            pv: 0,
            uv: 0
        }
    }

    componentDidMount = async _ => {
        // await this.getChannelDict()
        // await this.getEventDict()
    }

    getChannelDict = async _ => {
        let response = await getChannelDict()
        let { code, data } = response.data
        if (code === 200) {
            let channelMap = new Map()
            data.forEach(item => channelMap.set(item.key, item.value))
            this.setState({
                channelMap: channelMap
            })
        }
    }

    getEventDict = async _ => {
        let response = await getEventDict()
        let { code, data } = response.data
        if (code === 200) {
            let eventMap = new Map()
            data.forEach(item => eventMap.set(item.key, item.value))
            this.setState({
                eventMap: eventMap
            })
        }
    }

    onSearch = async _ => {
        this.setState({
            loadingLayer: true,
            tip: '数据加载中'
        });
        let response = await getBiDailyData(this.state);
        this.setState({
            loadingLayer: false
        });
        let { code, data } = response.data;
        if (code === 200) {
            let tables = []
            data.total.channel = '全渠道'
            tables.push({
                name: '日期范围内数据总览',
                column: column,
                list: [data.total]
            })

            let insideTotalKeys = Object.keys(data.insideTotal)
            if (insideTotalKeys) {
                let tableItem = {
                    name: '站内数据',
                    column: column,
                    list: []
                }
                insideTotalKeys.forEach(key => {
                    let channelData = data.insideTotal[key]
                    channelData.channel = key
                    tableItem.list.push(channelData)
                })
                tables.push(tableItem)
            }

            let outsideTotalKeys = Object.keys(data.outsideTotal)
            if (outsideTotalKeys) {
                let tableItem = {
                    name: '站外数据',
                    column: column,
                    list: []
                }
                outsideTotalKeys.forEach(key => {
                    let channelData = data.outsideTotal[key]
                    channelData.channel = key
                    tableItem.list.push(channelData)
                })
                tables.push(tableItem)
            }

            this.setState({
                tables: tables,
                overall: {
                    userNum: data.userNum,
                    registerNum: data.registerNum,
                    orderNum: data.orderNum,
                    pv: data.pv,
                    uv: data.uv
                }
            })
        }
    }

    onShowTimeChange = (dates, dateStrings) => {
        this.setState({
            showTime: dates,
            startDate: dateStrings[0].replaceAll('-', ''),
            endDate: dateStrings[1].replaceAll('-', '')
        });
    }

    render() {
        const tables = this.state.tables.map(item => (
            <>
                <h1>{item.name}</h1>
                <Table
                    size="small"
                    columns={item.column}
                    dataSource={item.list}
                    bordered
                    pagination={{
                        position: ['none', 'none']
                    }}
                />
                <br/>
            </>
        ))

        return (
            <>
                <LoadingLayer loading={this.state.loadingLayer} tip={this.state.tip}/>
                <Row>
                    <Col span={6}>
                        日期范围：<RangePicker value={this.state.showTime} onChange={this.onShowTimeChange} />
                    </Col>
                    <Col span={5}>
                        <Space>
                            <Button type="primary" onClick={this.onSearch}>搜索</Button>
                        </Space>
                    </Col>
                </Row>
                <br/>
                <Alert message="PV、UV已去除内部用户数据" type="warning" />
                <br/>
                <>
                    <h1>截至{this.state.endDate}数据总览</h1>
                    <Table
                        size="small"
                        columns={columnOverall}
                        dataSource={[this.state.overall]}
                        bordered
                        pagination={{
                            position: ['none', 'none']
                        }}
                    />
                </>
                <br/>
                {tables}
            </>
        );
    }
}

export default DataDaily;
