import React from 'react';
import { Upload, message } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

class UploadImage extends React.Component {

    state = {
        imageUrl: '',
        loading: false
    };

    beforeUpload = file => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif';
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG/GIF file!');
        }

        let maxFileSize = this.props.maxFileSize ? this.props.maxFileSize : 2;
        const isLt2M = file.size / 1024 / 1024 < maxFileSize;
        if (!isLt2M) {
            message.error(`Image must smaller than ${maxFileSize}MB!`);
        }
        return isJpgOrPng && isLt2M;
    }

    handleChange = info => {
        if (info.file.status === 'uploading') {
            this.setState({
                loading: true
            });
        }

        // todo: 上传oss后的url在info里
        if (info.file.status === 'done') {
            if (info.file.response.code === 200) {
                this.setState({
                    imageUrl: info.file.response.data.url,
                    loading: false
                }, () => {
                    if (this.props.onChange) {
                        this.props.onChange(this.state.imageUrl);
                    }
                });
            }
        }
    };

    render() {
        let { imageUrl, loading } = this.state;
        const value = this.props.value;
        imageUrl = value ? value : imageUrl;
        const uploadButton = (
            <div>
              {loading ? <LoadingOutlined /> : <PlusOutlined />}
              <div style={{ marginTop: 8 }}>Upload</div>
            </div>
        );

        return (
            <Upload
                name="file"
                listType="picture-card"
                showUploadList={false}
                action={process.env.REACT_APP_MINIO_API}
                beforeUpload={this.beforeUpload}
                onChange={this.handleChange}
            >
                {imageUrl ? <img src={imageUrl} alt="singleImage" style={{ width: '100%' }} /> : uploadButton}
            </Upload>
        );
    }
}

export default UploadImage;
