import React from 'react';
import { message, Row, Col, Table, Input, Space, Button, Tag } from 'antd';
import { Link } from 'react-router-dom';
import { getList, deleteBrand } from '../../api/BrandApi';
import LoadingLayer from '../common/LoadingLayer';
import '../../css/store/brandList.scss';

class StoreBrandList extends React.Component {
    
    state = {
        loading: false,
        loadingLayer: false,
        tip: '',
        keyword: '',
        list: [],
        pageNum: 1,
        pageSize: 20,
        total: 0
    };

    componentDidMount = async _ => {
        await this.getBrandList();
    }

    getBrandList = async _ => {
        this.setState({
            loading: true
        });
        let response = await getList(this.state.keyword, this.state.pageNum, this.state.pageSize);
        let { code, data } = response.data;
        this.setState({
            loading: false
        });
        if (code === 200) {
            data.list.map(item => item.key = item.id);
            this.setState({
                total: data.total,
                list: data.list
            });
        }
    }

    onKeywordChange = e => {
        this.setState({
            keyword: e.target.value
        });
    }

    onDelete = async id => {
        this.setState({
            loadingLayer: true,
            tip: '数据删除中'
        });
        if (window.confirm('确定删除？')) {
            let response = await deleteBrand(id);
            let { code } = response.data;
            if (code === 200) {
                message.success('删除品牌成功');
                this.setState({
                    loadingLayer: false
                });
                await this.getBrandList();
            }
        }
    }

    onPageChange = (pageNum, pageSize) => {
        this.setState({
            pageNum: pageNum,
            pageSize: pageSize
        }, () => this.getBrandList());
    }

    render() {
        const columns = [{
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        }, {
            title: '品牌名称',
            dataIndex: 'name',
            key: 'name',
        }, {
            title: '首字母',
            dataIndex: 'firstLetter',
            key: 'firstLetter',
        }, {
            title: '品牌故事',
            dataIndex: 'brandStory',
            key: 'brandStory',
        }, {
            title: '工厂状态',
            dataIndex: 'factoryStatus',
            key: 'factoryStatus',
            render: status => (
                status === 1 ? <Tag color="#87d068">正常</Tag> : <Tag color="#f50">异常</Tag>
            )
        }, {
            title: '展示状态',
            dataIndex: 'showStatus',
            key: 'showStatus',
            render: status => (
                status === 1 ? <Tag color="#87d068">展示</Tag> : <Tag color="#f50">不展示</Tag>
            )
        }, {
            title: '产品数量',
            dataIndex: 'productCount',
            key: 'productCount',
        }, {
            title: '产品评论数量',
            dataIndex: 'productCommentCount',
            key: 'productCommentCount',
        }, {
            title: '排序',
            dataIndex: 'sort',
            key: 'sort',
        }, {
            title: '操作',
            key: 'action',
            render: (text, record) => (
              <Space size="middle">
                <Link to={`/store/brand/edit/${record.id}`}><Button type="link">编辑</Button></Link>
                <Button type="link" style={{ color: 'red' }} onClick={this.onDelete.bind(this, record.id)}>删除</Button>
              </Space>
            )
        }];

        return (
            <div className="BrandList">
                <LoadingLayer loading={this.state.loadingLayer} tip={this.state.tip}/>
                <Row className="search-box">
                    <Col>
                        品牌名：<Input value={this.state.keyword} onChange={this.onKeywordChange}/>
                    </Col>
                    <Col>
                        <Space>
                            <Button type="primary" onClick={this.getBrandList}>搜索</Button>
                            <Link to="/store/brand/add">
                                <Button>新建品牌</Button>
                            </Link>
                        </Space>
                    </Col>
                </Row>

                <br/>
                <Table
                    loading={this.state.loading}
                    size="small"
                    columns={columns}
                    dataSource={this.state.list}
                    bordered
                    pagination={{ total: this.state.total, pageSize: this.state.pageSize, onChange: this.onPageChange }}
                />
            </div>
        );
    }
}

export default StoreBrandList;
