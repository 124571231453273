import React from 'react';
import {
    Table,
    Tag,
    Button,
    Space,
    Modal,
    Form,
    Input,
    message
} from 'antd';
import LoadingLayer from '../common/LoadingLayer';
import ChannelMultiSelector from '../selector/ChannelMultiSelector';
import {
    getEventList,
    changeEventEnabledStatus,
    addEvent,
    getEvent,
    updateEvent
} from '../../api/ChannelApi';

class EventList extends React.Component {

    state = {
        loadingLayer: false,
        tip: '',
        loading: false,
        list: [],
        total: 0,
        pageNum: 1,
        pageSize: 20,
        modalVis: false,
        modalType: 1,
        modalTitle: '新建事件',
        modalParams: {
            id: 0,
            title: '',
            channelIds: []
        }
    };

    componentDidMount = async _ => {
        await this.getEventList();
    }

    getEventList = async _ => {
        this.setState({
            loading: true
        });
        let response = await getEventList('', this.state.pageNum, this.state.pageSize, '');
        let { code, data } = response.data;
        this.setState({
            loading: false
        });
        if (code === 200) {
            data.list.map(item => item.key = item.id);
            this.setState({
                total: data.total,
                list: data.list
            });
        }
    }

    onPageChange = (pageNum, pageSize) => {
        this.setState({
            pageNum: pageNum,
            pageSize: pageSize
        }, () => this.getEventList());
    }

    changeEventEnabledStatus = async (id, enabledStatus) => {
        this.setState({
            loadingLayer: true,
            tip: '提交中'
        });
        let response = await changeEventEnabledStatus(id, enabledStatus);
        this.setState({
            loadingLayer: false
        });
        let { code } = response.data;
        if (code === 200) {
            await this.getEventList();
        }
    }

    onModalShow = async (title, type, record) => {
        let modalParams = this.state.modalParams;
        if (record) {
            let response = await getEvent(record.eventId);
            let { code, data } = response.data;
            if (code === 200) {
                modalParams.id = data.id;
                modalParams.title = data.title;
                modalParams.channelIds = data.channels.map(item => item.key);
            }
        }
        this.setState({
            modalTitle: title,
            modalVis: true,
            modalType: type,
            modalParams: modalParams
        });
    }

    formHandler = {
        onTitleChange: e => {
            let params = this.state.modalParams;
            params.title = e.target.value;
            this.setState({
                modalParams: params
            });
        },
        onChannelChange: ids => {
            let params = this.state.modalParams;
            params.channelIds = ids;
            this.setState({
                modalParams: params
            });
        },
        onSubmit: async _ => {
            this.setState({
                loadingLayer: true,
                tip: '提交中'
            });
            let response;
            if (this.state.modalType === 1) {
                response = await addEvent(this.state.modalParams.title, this.state.modalParams.channelIds);
            } else {
                response = await updateEvent(this.state.modalParams.id, this.state.modalParams.title, this.state.modalParams.channelIds);
            }
        
            this.setState({
                loadingLayer: false
            });
            let { code } = response.data;
            if (code === 200) {
                message.success('数据提交成功');
                this.getEventList();
                this.setState({
                    modalVis: false,
                    modalParams: {
                        id: 0,
                        title: '',
                        channelIds: []
                    }
                });
            }
        }
    }

    render() {
        const columns = [{
            title: '事件ID',
            dataIndex: 'eventId',
            key: 'eventId',
            align: 'center'
        }, {
            title: '事件名称',
            dataIndex: 'eventName',
            key: 'eventName',
            align: 'center'
        }, {
            title: '关联渠道',
            dataIndex: 'channelNames',
            key: 'channelNames',
            align: 'center',
            render: channelNames => (
                channelNames ? channelNames.map(item => <div>{item}</div>) : ''
            )
        }, {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
            align: 'center'
        }, {
            title: '启用状态',
            dataIndex: 'enabledStatus',
            key: 'enabledStatus',
            align: 'center',
            render: enabledStatus => (
                enabledStatus ? <Tag color="#87d068">启用</Tag> : <Tag color="#f50">禁用</Tag>
            )
        }, {
            title: '操作人',
            dataIndex: 'operator',
            key: 'operator',
            align: 'center'
        }, {
            title: '操作',
            key: 'action',
            align: 'center',
            render: (text, record) => (
                <>
                    <Space>
                        <Button type="link" onClick={this.onModalShow.bind(this, '编辑事件', 2, record)}>编辑</Button>
                        {record.enabledStatus ? 
                            <Button type="link" onClick={this.changeEventEnabledStatus.bind(this, record.eventId, 0)} style={{ color: 'red' }}>禁用</Button> :
                            <Button type="link" onClick={this.changeEventEnabledStatus.bind(this, record.eventId, 1)}>启用</Button>
                        }
                    </Space>
                </>
            )
        }];

        return (
            <>
                <LoadingLayer loading={this.state.loadingLayer} tip={this.state.tip}/>
                <Button type="primary" onClick={this.onModalShow.bind(this, '新建事件', 1, null)}>新建事件</Button>
                <br/><br/>
                <Table
                    loading={this.state.loading}
                    size="small"
                    columns={columns}
                    dataSource={this.state.list}
                    bordered
                    pagination={{ total: this.state.total, pageSize: this.state.pageSize, onChange: this.onPageChange }}
                />

                <Modal
                    title={this.state.modalTitle}
                    visible={this.state.modalVis}
                    onOk={this.formHandler.onSubmit}
                    onCancel={() => this.setState({modalVis: false})}
                >
                    <Form
                        labelCol={{ span: 4 }}
                        wrapperCol={{ span: 10 }}
                        layout="horizontal"
                        style={{ marginTop: 10 }}
                    >
                        <Form.Item label="关联渠道">
                            <ChannelMultiSelector onChange={this.formHandler.onChannelChange} value={this.state.modalParams.channelIds}/>
                        </Form.Item>
                        <Form.Item label="事件名称">
                            <Input value={this.state.modalParams.title} onChange={this.formHandler.onTitleChange}/>
                        </Form.Item>
                    </Form>
                </Modal>
            </>
        );
    }
}

export default EventList;
