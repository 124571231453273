import React from 'react';
import CommonLayout from '../../components/layout/CommonLayout';
import EditCoupon from '../../components/coupon/EditCoupon';

class CouponEdit extends React.Component {

    state = {
        couponId: this.props.match.params.id
    };

    render() {
        return (
            <CommonLayout
                topNavSelectedKey="mall"
                siderNavCategory="mall"
                siderNavSelectedKey="coupon"
            >
                <EditCoupon
                    value={this.state.couponId}
                    onSuccess={() => this.props.history.push('/mall/coupon')}
                />
            </CommonLayout>
        );
    }
}

export default CouponEdit;
