import RequestUtil from "../../util/request/RequestUtil";

export function create(params) {
    let data = {
        type: params.type,
        className: params.className,
	    func: params.func,
	    name: params.name,
        script: params.script
    };
    return RequestUtil.getInstance().post('/painter/model', data);
}

export function update(id, params) {
    let data = {
        type: params.type,
        className: params.className,
	    func: params.func,
	    name: params.name,
        script: params.script
    };
    return RequestUtil.getInstance().put(`/painter/model/${id}`, data);
}

export function triggerStatus(id) {
    return RequestUtil.getInstance().put(`/painter/model/triggerStatus/${id}`);
}

export function getInfo(id) {
    return RequestUtil.getInstance().get(`/painter/model/${id}`);
}

export function getList(pageNum = 1, pageSize = 10) {
    return RequestUtil.getInstance().get(`/painter/model/list?pageNum=${pageNum}&pageSize=${pageSize}`);
}
