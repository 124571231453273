import React from 'react';
import CommonLayout from '../../components/layout/CommonLayout';

class Operation extends React.Component {

    render() {
        return (
            <CommonLayout
                topNavSelectedKey="mall"
                siderNavCategory="mall"
                siderNavSelectedKey="operation"
            >
                
            </CommonLayout>
        );
    }
}

export default Operation;
