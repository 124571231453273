import React from 'react';
import { InputNumber, message, Button, Form, Input, Select, Radio } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { getInfo, updateAttribute } from '../../api/ProductAttributeApi';
import LoadingLayer from '../common/LoadingLayer';
import ProductAttributeCategorySelector from '../selector/ProductAttributeCategorySelector';

const { Option } = Select;

class UpdateMallProductAttribute extends React.Component {

    state = {
        id: this.props.value,
        filterType: 0,
        handAddStatus: 0,
        inputList: [],
        inputType: 0,
        name: '',
        productAttributeCategoryId: 0,
        relatedStatus: 0,
        searchType: 0,
        selectType: 0,
        sort: 0,
        type: 0,
        loading: false,
        tip: ''
    };

    componentDidMount = async _ => {
        this.setState({
            loading: true,
            tip: '数据加载中'
        });
        let response = await getInfo(this.state.id);
        let { code, data } = response.data;
        this.setState({
            loading: false
        });
        if (code === 200) {
            this.setState({
                filterType: data.filterType,
                handAddStatus: data.handAddStatus,
                inputList: data.inputList ? data.inputList.split(',') : [],
                inputType: data.inputType,
                name: data.name,
                productAttributeCategoryId: data.productAttributeCategoryId,
                relatedStatus: data.relatedStatus,
                searchType: data.searchType,
                selectType: data.selectType,
                sort: data.sort,
                type: data.type
            });
        }
    }

    formHandler = {
        onNameChange: e => {
            this.setState({
                name: e.target.value
            });
        },
        onProductAttributeCategoryIdChange: value => {
            this.setState({
                productAttributeCategoryId: value
            });
        },
        onTypeChange: value => {
            this.setState({
                type: value
            });
        },
        onFilterTypeChange: e => {
            this.setState({
                filterType: e.target.value
            });
        },
        onHandAddStatusChange: e => {
            this.setState({
                handAddStatus: e.target.value
            });
        },
        onInputTypeChange: e => {
            this.setState({
                inputType: e.target.value
            });
        },
        onInputListChange: list => {
            this.setState({
                inputList: list
            });
        },
        onRelatedStatusChange: e => {
            this.setState({
                relatedStatus: e.target.value
            });
        },
        onSearchTypeChange: e => {
            this.setState({
                searchType: e.target.value
            });
        },
        onSelectTypeChange: e => {
            this.setState({
                selectType: e.target.value
            });
        },
        onSortChange: number => {
            this.setState({
                sort: number
            });
        },
        onSubmit: async _ => {
            this.setState({
                loading: true,
                tip: '数据提交中'
            });
            let data = this.state;
            data.inputList = data.inputList.join(',');
            let response = await updateAttribute(data.id, data);
            let { code } = response.data;
            this.setState({
                loading: false
            });
            if (code === 200) {
                message.success('商品属性更新成功');
                if (this.props.onSuccess) {
                    this.props.onSuccess({
                        categoryId: data.productAttributeCategoryId,
                        type: data.type
                    });
                }
            }
        }
    }

    render() {
        return (
            <>
                <LoadingLayer loading={this.state.loading} tip={this.state.tip}/>
                <PageHeader
                    title="编辑商品属性信息"
                />
                <Form
                    labelCol={{ span: 3 }}
                    wrapperCol={{ span: 10 }}
                    layout="horizontal"
                    style={{ marginTop: 10 }}
                >
                    <Form.Item label="属性名称">
                        <Input value={this.state.name} onChange={this.formHandler.onNameChange}/>
                    </Form.Item>
                    <Form.Item label="属性分类">
                        <ProductAttributeCategorySelector
                            value={this.state.productAttributeCategoryId}
                            onChange={this.formHandler.onProductAttributeCategoryIdChange}
                        />
                    </Form.Item>
                    <Form.Item label="属性类型">
                        <Select
                            style={{ width: 150 }}
                            value={this.state.type}
                            onChange={this.formHandler.onTypeChange}
                        >
                            <Option value={0}>规格</Option>
                            <Option value={1}>参数</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item label="分类筛选样式">
                        <Radio.Group value={this.state.filterType} onChange={this.formHandler.onFilterTypeChange}>
                            <Radio value={0}>普通</Radio>
                            <Radio value={1}>颜色</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item label="是否支持手动新增">
                        <Radio.Group value={this.state.handAddStatus} onChange={this.formHandler.onHandAddStatusChange}>
                            <Radio value={0}>不支持</Radio>
                            <Radio value={1}>支持</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item label="是否支持手动新增">
                        <Radio.Group value={this.state.handAddStatus} onChange={this.formHandler.onHandAddStatusChange}>
                            <Radio value={0}>不支持</Radio>
                            <Radio value={1}>支持</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item label="属性录入方式">
                        <Radio.Group value={this.state.inputType} onChange={this.formHandler.onInputTypeChange}>
                            <Radio value={0}>手工录入</Radio>
                            <Radio value={1}>从列表中选取</Radio>
                        </Radio.Group>
                    </Form.Item>
                    {this.state.inputType ? 
                        <Form.Item label="可选值列表">
                            <Select mode="tags" value={this.state.inputList} onChange={this.formHandler.onInputListChange}></Select>
                        </Form.Item> :
                        <></>
                    }
                    <Form.Item label="相同属性产品是否关联">
                        <Radio.Group value={this.state.relatedStatus} onChange={this.formHandler.onRelatedStatusChange}>
                            <Radio value={0}>不关联</Radio>
                            <Radio value={1}>关联</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item label="检索类型">
                        <Radio.Group value={this.state.searchType} onChange={this.formHandler.onRelatedStatusChange}>
                            <Radio value={0}>不需要进行检索</Radio>
                            <Radio value={1}>关键字检索</Radio>
                            <Radio value={2}>范围检索</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item label="属性选择类型">
                        <Radio.Group value={this.state.selectType} onChange={this.formHandler.onSelectTypeChange}>
                            <Radio value={0}>唯一</Radio>
                            <Radio value={1}>单选</Radio>
                            <Radio value={2}>多选</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item label="排序">
                        <InputNumber value={this.state.sort} onChange={this.formHandler.onSortChange}/>
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 3 }}>
                        <Button type="primary" onClick={this.formHandler.onSubmit}>提交</Button>
                    </Form.Item>
                </Form>
            </>
        );
    }
}

export default UpdateMallProductAttribute;
