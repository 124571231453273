import RequestUtil from "../util/request/RequestUtil";

export function getList(params, pageNum = 1, pageSize = 5) {
    let queryString = '';
    if (params.brandId) {
        queryString += `&brandId=${params.brandId}`;
    }
    if (params.keyword) {
        queryString += `&keyword=${params.keyword}`;
    }
    if (params.productCategoryId) {
        queryString += `&productCategoryId=${params.productCategoryId}`;
    }
    if (params.productSn) {
        queryString += `&productSn=${params.productSn}`;
    }
    if (params.publishStatus || params.publishStatus === 0) {
        queryString += `&publishStatus=${params.publishStatus}`;
    }
    if (params.verifyStatus || params.verifyStatus === 0) {
        queryString += `&verifyStatus=${params.verifyStatus}`;
    }
    return RequestUtil.getInstance().get(`/product/list?pageNum=${pageNum}&pageSize=${pageSize}${queryString}`);
}

export function createProduct(params) {
    let data = {
        albumPics: params.albumPics,
        brandId: params.brandId,
        description: params.description,
        detailHtml: params.detailHtml,
        name: params.name,
        newStatus: params.newStatus,
        originalPrice: params.originalPrice,
        pic: params.pic,
        previewStatus: params.previewStatus,
        price: params.price,
        productAttributeCategoryId: params.productAttributeCategoryId,
        productAttributeValueList: params.productAttributeValueList,
        productCategoryId: params.productCategoryId,
        productSn: params.productSn,
        publishStatus: params.publishStatus,
        recommandStatus: params.recommandStatus,
        serviceIds: params.serviceIds,
        skuStockList: params.skuStockList,
        sort: params.sort,
        subTitle: params.subTitle,
        unit: params.unit,
        verifyStatus: params.verifyStatus
    };
    return RequestUtil.getInstance().post(`/product/create`, data);
}

export function getInfo(id) {
    return RequestUtil.getInstance().get(`/product/updateInfo/${id}`);
}

export function updateProduct(id, params) {
    let data = {
        albumPics: params.albumPics,
        brandId: params.brandId,
        description: params.description,
        detailHtml: params.detailHtml,
        name: params.name,
        newStatus: params.newStatus,
        originalPrice: params.originalPrice,
        pic: params.pic,
        previewStatus: params.previewStatus,
        price: params.price,
        productAttributeCategoryId: params.productAttributeCategoryId,
        productAttributeValueList: params.productAttributeValueList,
        productCategoryId: params.productCategoryId,
        productSn: params.productSn,
        enabledStatus: params.enabledStatus,
        recommandStatus: params.recommandStatus,
        serviceIds: params.serviceIds,
        skuStockList: params.skuStockList,
        sort: params.sort,
        subTitle: params.subTitle,
        unit: params.unit,
        verifyStatus: params.verifyStatus,
        delaySwitch: params.delaySwitch,
        delayHour: params.delayHour,
        delayVoucherId: params.delayVoucherId,
        noCoupon: params.noCoupon,
        topVideo: params.topVideo
    };
    return RequestUtil.getInstance().post(`/product/update/${id}`, data);
}

export function getSimpleList() {
    return RequestUtil.getInstance().get(`/product/simpleList`);
}

export function setBoom(productId, isBoom) {
    let data = {
        isBoom: isBoom,
        productId: productId
    };
    return RequestUtil.getInstance().patch(`/product/boom`, data);
}

export function publishStatus(id, status) {
    return RequestUtil.getInstance().post(`/product/update/publishStatus?ids=${id}&publishStatus=${status}`);
}

export function fuzzyByCategoryId(categoryId) {
    let data = {
        categoryId: categoryId,
    };
    return RequestUtil.getInstance().post(`/product/fuzzyByCategoryId`, data);
}

export function exportProduct(params) {
    let queryString = '';
    if (params.brandId) {
        queryString += `&brandId=${params.brandId}`;
    }
    if (params.keyword) {
        queryString += `&keyword=${params.keyword}`;
    }
    if (params.publishStatus || params.publishStatus === 0) {
        queryString += `&publishStatus=${params.publishStatus}`;
    }

    return RequestUtil.getInstance().get(`/product/export?${queryString}`, {responseType: 'blob'});
}

export function delayUploadBatchConfig(file) {
    let formData = new FormData();
    formData.append('file', file, file.name);
    return RequestUtil.getInstance(30000, {
        headers: {'Content-Type': 'multipart/form-data'}
    }).post(`/product-relation/delayUploadBatchConfig`, formData);
}

export function spuNoVoucherBatchConfig(file) {
    let formData = new FormData();
    formData.append('file', file, file.name);
    return RequestUtil.getInstance(30000, {
        headers: {'Content-Type': 'multipart/form-data'}
    }).post(`/product-relation/spuNoVoucherBatchConfig`, formData);
}
