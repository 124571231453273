import React from 'react';
import { message, Button, Form, Input, Select, Table, Space, Modal, InputNumber, Radio, Tabs, Checkbox, DatePicker } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import UploadImage from '../upload/Image';
import UploadImageList from '../upload/ImageList';
import BrandSelector from '../selector/BrandSelector';
import LoadingLayer from '../common/LoadingLayer';
import ProductCategorySecondClassSelector from '../selector/ProductCategorySecondClassSelector';
import ProductAttributeCategorySelector from '../selector/ProductAttributeCategorySelector';
import ProductAttributeSelector from '../selector/ProductAttributeSelector';
import HtmlEditor from '../common/HtmlEditor';
import RandomUtil from '../../util/RandomUtil';
import { createProduct } from '../../api/ProductApi';

const { Option } = Select;
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;
let detailHtml = '';

class AddMallProduct extends React.Component {

    state = {
        name: '',
        subTitle: '',
        description: '',
        brandId: 0,
        productSn: '',
        pic: '',
        albumPics: [],
        newStatus: 0,
        unit: '件',
        productCategoryId: 0,
        originalPrice: 0,
        price: 0,
        productAttributeCategoryId: 0,
        recommandStatus: 0,
        publishStatus: 0,
        verifyStatus: 0,
        showTime: [],
        promotionStartTime: null,
        promotionEndTime: null,
        serviceIds: [],
        detailHtml: '',
        productAttributeValueList: [],
        attrModalVis: false,
        attrItem: {
            attrData: undefined,
            productAttributeId: undefined,
            name: '',
            value: '',
            valueCheckBox: []
        },
        specsModalVis: false,
        specsItem: {
            attrData: undefined,
            productAttributeId: undefined,
            name: '',
            value: '',
            valueCheckBox: []
        },
        productSpecsValueList: [],
        spDataList: [],
        skuStockList: [],
        skuStockItem: {
            skuCode: undefined,
            price: 0,
            stock: 0,
            spData: '',
            spDataList: [],
            productAttributeId: undefined,
            attrData: undefined
        },
        skuStockModalVis: false,
        sort: 0,
        loading: false,
        tip: ''
    };

    formHandler = {
        onNameChange: e => {
            this.setState({
                name: e.target.value
            });
        },
        onSubTitleChange: e => {
            this.setState({
                subTitle: e.target.value
            });
        },
        onDescriptionChange: e => {
            this.setState({
                description: e.target.value
            });
        },
        onBrandIdChange: value => {
            this.setState({
                brandId: value
            });
        },
        onProductSnChange: e => {
            this.setState({
                productSn: e.target.value
            });
        },
        onPicChange: value => {
            this.setState({
                pic: value
            });
        },
        onAlbumPicsChange: files => {
            this.setState({
                albumPics: files
            });
        },
        onNewStatusChange: value => {
            this.setState({
                newStatus: value
            });
        },
        onUnitChange: e => {
            this.setState({
                unit: e.target.value
            });
        },
        onProductCategoryIdChange: value => {
            this.setState({
                productCategoryId: value
            });
        },
        onOriginalPriceChange: value => {
            this.setState({
                originalPrice: value
            });
        },
        onPriceChange: value => {
            this.setState({
                price: value
            });
        },
        onProductAttributeCategoryIdChange: value => {
            this.setState({
                productAttributeCategoryId: value,
                productAttributeValueList: [],
                productSpecsValueList: [],
                skuStockList: []
            });
        },
        onRecommandStatusChange: e => {
            this.setState({
                recommandStatus: e.target.value
            });
        },
        onPublishStatusChange: e => {
            this.setState({
                publishStatus: e.target.value
            });
        },
        onVerifyStatusChange: e => {
            this.setState({
                verifyStatus: e.target.value
            });
        },
        onServiceIdsChange: values => {
            this.setState({
                serviceIds: values
            });
        },
        onDetailHtmlChange: value => {
            detailHtml = value;
        },
        onShowTimeChange: (dates, dateStrings) => {
            this.setState({
                showTime: dates,
                promotionStartTime: dates[0].format(),
                promotionEndTime: dates[1].format(),
            })
        },
        onSortChange: number => {
            this.setState({
                sort: number
            });
        },
        onSubmit: async _ => {
            this.setState({
                loading: true,
                tip: '提交中'
            });
            let data = JSON.parse(JSON.stringify(this.state));
            data.albumPics = data.albumPics.map(item => item.url).join(',');
            data.serviceIds = data.serviceIds.join(',');
            data.productAttributeValueList = data.productAttributeValueList.concat(data.productSpecsValueList);
            data.detailHtml = detailHtml;
            let response = await createProduct(data);
            let { code } = response.data;
            this.setState({
                loading: false
            });
            if (code === 200) {
                message.success('商品创建成功');
                if (this.props.onSuccess) {
                    this.props.onSuccess();
                }
            }
        }
    }

    attrModalHandler = {
        onAddOpen: () => {
            this.setState({
                attrModalVis: true
            });
        },
        onCancel: () => {
            let attrItem = this.state.attrItem;
            attrItem = {
                attrData: undefined,
                productAttributeId: undefined,
                name: '',
                value: '',
                valueCheckBox: []
            };
            this.setState({
                attrModalVis: false,
                attrItem: attrItem
            });
        },
        onOk: () => {
            let data = this.state.productAttributeValueList;
            let attrItem = this.state.attrItem;
            attrItem.key = RandomUtil.randomString(16);
            data.push(attrItem);
            this.setState({
                productAttributeValueList: JSON.parse(JSON.stringify(data))
            }, () => this.attrModalHandler.onCancel());
        },
        onDelete: key => {
            let data = this.state.productAttributeValueList;
            data = data.filter(item => item.key !== key);
            this.setState({
                productAttributeValueList: JSON.parse(JSON.stringify(data))
            });
        },
        onProductAttributeIdChange: (value, data) => {
            let attrItem = {
                attrData: data,
                productAttributeId: value,
                name: data.name,
                value: '',
                valueCheckBox: []
            };
            this.setState({
                attrItem: attrItem
            });
        },
        onValueInputChange: e => {
            let attrItem = this.state.attrItem;
            attrItem.value = e.target.value;
            this.setState({
                attrItem: attrItem
            });
        },
        onValueCheckboxChange: values => {
            let attrItem = this.state.attrItem;
            attrItem.valueCheckBox = values;
            attrItem.value = values.join(',');
            this.setState({
                attrItem: attrItem
            });
        }
    }

    specsModalHandler = {
        onAddOpen: () => {
            this.setState({
                specsModalVis: true
            });
        },
        onCancel: () => {
            let specsItem = this.state.specsItem;
            specsItem = {
                attrData: undefined,
                productAttributeId: undefined,
                name: '',
                value: '',
                valueCheckBox: []
            };
            this.setState({
                specsModalVis: false,
                specsItem: specsItem
            });
        },
        onOk: () => {
            let data = this.state.productSpecsValueList;
            let specsItem = this.state.specsItem;
            specsItem.key = RandomUtil.randomString(16);
            data.push(specsItem);
            this.setState({
                productSpecsValueList: JSON.parse(JSON.stringify(data))
            }, () => this.specsModalHandler.onCancel());
        },
        onDelete: key => {
            let data = this.state.productSpecsValueList;
            data = data.filter(item => item.key !== key);
            this.setState({
                productSpecsValueList: JSON.parse(JSON.stringify(data))
            });
        },
        onProductAttributeIdChange: (value, data) => {
            let specsItem = {
                attrData: data,
                productAttributeId: value,
                name: data.name,
                value: '',
                valueCheckBox: []
            };
            this.setState({
                specsItem: specsItem
            });
        },
        onValueInputChange: e => {
            let specsItem = this.state.specsItem;
            specsItem.value = e.target.value;
            this.setState({
                attrItem: specsItem
            });
        },
        onValueCheckboxChange: values => {
            let specsItem = this.state.specsItem;
            specsItem.valueCheckBox = values;
            specsItem.value = values.join(',');
            this.setState({
                specsItem: specsItem
            });
        }
    }

    skuStockModalHandler = {
        onAddOpen: () => {
            this.setState({
                skuStockModalVis: true
            });
        },
        onCancel: () => {
            let skuStockItem = this.state.skuStockItem;
            skuStockItem = {
                skuCode: undefined,
                price: 0,
                stock: 0,
                spData: '',
                spDataList: [],
                productAttributeId: undefined,
                attrData: undefined
            };
            this.setState({
                skuStockModalVis: false,
                skuStockItem: skuStockItem
            });
        },
        onOk: () => {
            let data = this.state.skuStockList;
            let skuStockItem = this.state.skuStockItem;
            skuStockItem.key = RandomUtil.randomString(16);
            data.push(skuStockItem);
            this.setState({
                skuStockList: JSON.parse(JSON.stringify(data))
            }, () => this.skuStockModalHandler.onCancel());
        },
        onDelete: key => {
            let data = this.state.skuStockList;
            data = data.filter(item => item.key !== key);
            this.setState({
                skuStockList: JSON.parse(JSON.stringify(data))
            });
        },
        onSkuCodeChange: e => {
            let skuStockItem = this.state.skuStockItem;
            skuStockItem.skuCode = e.target.value;
            this.setState({
                skuStockItem: skuStockItem
            });
        },
        onPriceChange: number => {
            let skuStockItem = this.state.skuStockItem;
            skuStockItem.price = number;
            this.setState({
                skuStockItem: skuStockItem
            });
        },
        onStockChange: number => {
            let skuStockItem = this.state.skuStockItem;
            skuStockItem.stock = number;
            this.setState({
                skuStockItem: skuStockItem
            });
        },
        onProductAttributeIdChange: (value, data) => {
            let skuStockItem = {
                skuCode: undefined,
                price: 0,
                stock: 0,
                spData: '',
                spDataList: [],
                productAttributeId: value,
                attrData: data
            };
            this.setState({
                skuStockItem: skuStockItem
            });
        },
        onSpDataChange: (name, e) => {
            let skuStockItem = this.state.skuStockItem;
            let replace = false;
            skuStockItem.spDataList.map(item => {
                if (item.key === name) {
                    item.value = e.target.value;
                    replace = true;
                }
            });

            if (!replace) {
                skuStockItem.spDataList.push({
                    key: name,
                    value: e.target.value
                });
            }

            skuStockItem.spData = JSON.stringify(skuStockItem.spDataList);
            this.setState({
                skuStockItem: skuStockItem
            });
        }
    }

    render() {
        const attributeColumn = [{
            title: '属性id',
            dataIndex: 'productAttributeId',
            key: 'productAttributeId',
            width: 120
        }, {
            title: '属性名称',
            dataIndex: 'name',
            key: 'name',
            width: 120
        }, {
            title: '属性参数',
            dataIndex: 'value',
            key: 'value',
            width: 300
        }, {
            title: '操作',
            key: 'action',
            width: 150,
            render: (_, record) => (
                <Space size="middle">
                    <Button type="link" onClick={this.attrModalHandler.onDelete.bind(this, record.key)}>删除</Button>
                </Space>
            )
        }];

        const specsColumn = [{
            title: '规格id',
            dataIndex: 'productAttributeId',
            key: 'productAttributeId',
            width: 120
        }, {
            title: '规格名称',
            dataIndex: 'name',
            key: 'name',
            width: 120
        }, {
            title: '规格参数',
            dataIndex: 'value',
            key: 'value',
            width: 300
        }, {
            title: '操作',
            key: 'action',
            width: 150,
            render: (_, record) => (
                <Space size="middle">
                    <Button type="link" onClick={this.specsModalHandler.onDelete.bind(this, record.key)}>删除</Button>
                </Space>
            )
        }];

        const skuStockColumn = [{
            title: 'skuCode',
            dataIndex: 'skuCode',
            key: 'skuCode',
            width: 300
        }, {
            title: '规格',
            dataIndex: 'spData',
            key: 'spData',
            width: 400
        }, {
            title: '价格',
            dataIndex: 'price',
            key: 'price',
            width: 120
        }, {
            title: '库存',
            dataIndex: 'stock',
            key: 'stock',
            width: 120
        }, {
            title: '操作',
            key: 'action',
            width: 150,
            render: (_, record) => (
                <Space size="middle">
                    <Button type="link" onClick={this.skuStockModalHandler.onDelete.bind(this, record.key)}>删除</Button>
                </Space>
            )
        }];

        let attrOption = '';
        if (this.state.attrItem.attrData) {
            if (this.state.attrItem.attrData.inputType === 0) {
                attrOption = <Input value={this.state.attrItem.value} onChange={this.attrModalHandler.onValueInputChange}/>;
            } else if (this.state.attrItem.attrData.inputType === 1) {
                let checkboxItem = this.state.attrItem.attrData.inputList.split(',').map(item => <Checkbox value={item}>{item}</Checkbox>);
                attrOption = 
                <Checkbox.Group
                    value={this.state.attrItem.valueCheckBox}
                    onChange={this.attrModalHandler.onValueCheckboxChange}
                >{checkboxItem}</Checkbox.Group>
            }
        }

        let specsOption = '';
        if (this.state.specsItem.attrData) {
            if (this.state.specsItem.attrData.inputType === 0) {
                specsOption = <Input value={this.state.specsItem.value} onChange={this.specsModalHandler.onValueInputChange}/>;
            } else if (this.state.specsItem.attrData.inputType === 1) {
                let checkboxItem = this.state.specsItem.attrData.inputList.split(',').map(item => <Checkbox value={item}>{item}</Checkbox>);
                specsOption = 
                <Checkbox.Group
                    value={this.state.specsItem.valueCheckBox}
                    onChange={this.specsModalHandler.onValueCheckboxChange}
                >{checkboxItem}</Checkbox.Group>
            }
        }

        const skuSpecsForm = this.state.productSpecsValueList.map(item => {
            if (item.attrData.inputType === 0) {
                return (
                    <Form.Item label={item.name}>
                        <Input value={item.value} onChange={this.skuStockModalHandler.onSpDataChange.bind(this, item.name)}/>
                    </Form.Item>
                );
            } else if (item.attrData.inputType === 1) {
                return (
                    <Form.Item label={item.name}>
                        <Radio.Group onChange={this.skuStockModalHandler.onSpDataChange.bind(this, item.name)}>
                            {item.valueCheckBox.map(value => <Radio value={value}>{value}</Radio>)}
                        </Radio.Group>
                    </Form.Item>
                );
            }
        });

        return (
            <>
                <LoadingLayer loading={this.state.loading} tip={this.state.tip}/>
                <PageHeader
                    title="新建商品信息"
                />
                <Tabs defaultActiveKey="base">
                    <TabPane tab="基础信息" key="base">
                        <Form
                            labelCol={{ span: 2 }}
                            wrapperCol={{ span: 10 }}
                            layout="horizontal"
                            style={{ marginTop: 10 }}
                        >
                            <Form.Item label="商品名称">
                                <Input value={this.state.name} onChange={this.formHandler.onNameChange}/>
                            </Form.Item>
                            <Form.Item label="副标题">
                                <Input value={this.state.subTitle} onChange={this.formHandler.onSubTitleChange}/>
                            </Form.Item>
                            <Form.Item label="商品描述">
                                <Input.TextArea value={this.state.description} onChange={this.formHandler.onDescriptionChange}/>
                            </Form.Item>
                            <Form.Item label="品牌">
                                <BrandSelector value={this.state.brandId} onChange={this.formHandler.onBrandIdChange}/>
                            </Form.Item>
                            <Form.Item label="货号">
                                <Input value={this.state.productSn} onChange={this.formHandler.onProductSnChange}/>
                            </Form.Item>
                            <Form.Item label="新品状态">
                                <Select style={{ width: 200 }} value={this.state.newStatus} onChange={this.formHandler.onNewStatusChange}>
                                    <Option value={0}>不是新品</Option>
                                    <Option value={1}>新品</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item label="商品单位">
                                <Input value={this.state.unit} onChange={this.formHandler.onUnitChange}/>
                            </Form.Item>
                            <Form.Item label="商品分类">
                                <ProductCategorySecondClassSelector value={this.state.productCategoryId} onChange={this.formHandler.onProductCategoryIdChange}/>
                            </Form.Item>
                            <Form.Item label="市场价">
                                <InputNumber min={0} precision={2} value={this.state.originalPrice} onChange={this.formHandler.onOriginalPriceChange}/>
                            </Form.Item>
                            <Form.Item label="售价">
                                <InputNumber min={0} precision={2} value={this.state.price} onChange={this.formHandler.onPriceChange}/>
                            </Form.Item>
                            <Form.Item label="服务">
                                <Checkbox.Group value={this.state.serviceIds} onChange={this.formHandler.onServiceIdsChange}>
                                    <Checkbox value={1}>无忧退货</Checkbox>
                                    <Checkbox value={2}>快速退款</Checkbox>
                                    <Checkbox value={3}>免费包邮</Checkbox>
                                </Checkbox.Group>
                            </Form.Item>
                            <Form.Item label="售卖时段">
                                <RangePicker showTime value={this.state.showTime} onChange={this.formHandler.onShowTimeChange}/>
                            </Form.Item>
                            <Form.Item label="推荐状态">
                                <Radio.Group value={this.state.recommandStatus} onChange={this.formHandler.onRecommandStatusChange}>
                                    <Radio value={0}>不推荐</Radio>
                                    <Radio value={1}>推荐</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item label="上架状态">
                                <Radio.Group value={this.state.publishStatus} onChange={this.formHandler.onPublishStatusChange}>
                                    <Radio value={0}>下架</Radio>
                                    <Radio value={1}>上架</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item label="审核状态">
                                <Radio.Group value={this.state.verifyStatus} onChange={this.formHandler.onVerifyStatusChange}>
                                    <Radio value={0}>未审核</Radio>
                                    <Radio value={1}>审核通过</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item label="排序">
                                <InputNumber value={this.state.sort} onChange={this.formHandler.onSortChange}/>
                            </Form.Item>
                            <Form.Item wrapperCol={{ offset: 2 }}>
                                <Button type="primary" onClick={this.formHandler.onSubmit}>提交</Button>
                            </Form.Item>
                        </Form>
                    </TabPane>
                    <TabPane tab="封面信息" key="cover">
                        <Form
                            labelCol={{ span: 2 }}
                            wrapperCol={{ span: 10 }}
                            layout="horizontal"
                            style={{ marginTop: 10 }}
                        >
                            <Form.Item label="封面图片">
                                <UploadImage value={this.state.pic} onChange={this.formHandler.onPicChange}/>
                            </Form.Item>
                            <Form.Item label="商品轮播图">
                                <UploadImageList value={this.state.albumPics} onChange={this.formHandler.onAlbumPicsChange}/>
                            </Form.Item>
                            <Form.Item wrapperCol={{ offset: 2 }}>
                                <Button type="primary" onClick={this.formHandler.onSubmit}>提交</Button>
                            </Form.Item>
                        </Form>
                    </TabPane>
                    <TabPane tab="规格和属性及SKU" key="attribute">
                        <Form
                            labelCol={{ span: 2 }}
                            wrapperCol={{ span: 10 }}
                            layout="horizontal"
                            style={{ marginTop: 10 }}
                        >
                            <Form.Item label="商品属性分类">
                                <ProductAttributeCategorySelector value={this.state.productAttributeCategoryId} onChange={this.formHandler.onProductAttributeCategoryIdChange}/>
                            </Form.Item>
                            {this.state.productAttributeCategoryId > 0 &&
                                <>
                                    <Form.Item label="商品属性">
                                        <Space direction="vertical">
                                            <Button
                                                size="small"
                                                type="primary"
                                                onClick={this.attrModalHandler.onAddOpen}
                                            >增加一个属性</Button>
                                            <Table
                                                size="small"
                                                bordered
                                                columns={attributeColumn}
                                                dataSource={this.state.productAttributeValueList}
                                            />
                                        </Space>
                                    </Form.Item>
                                    <Form.Item label="商品规格">
                                        <Space direction="vertical">
                                            <Button
                                                size="small"
                                                type="primary"
                                                onClick={this.specsModalHandler.onAddOpen}
                                            >增加一个规格</Button>
                                            <Table
                                                size="small"
                                                bordered
                                                columns={specsColumn}
                                                dataSource={this.state.productSpecsValueList}
                                            />
                                        </Space>
                                    </Form.Item>
                                </>
                            }
                            
                            {this.state.productSpecsValueList.length > 0 &&
                                <Form.Item label="sku">
                                    <Space direction="vertical">
                                        <Button
                                            size="small"
                                            type="primary"
                                            onClick={this.skuStockModalHandler.onAddOpen}
                                        >增加一个sku</Button>
                                        <Table
                                            size="small"
                                            bordered
                                            columns={skuStockColumn}
                                            dataSource={this.state.skuStockList}
                                        />
                                    </Space>
                                </Form.Item>
                            }
                            <Form.Item wrapperCol={{ offset: 2 }}>
                                <Button type="primary" onClick={this.formHandler.onSubmit}>提交</Button>
                            </Form.Item>
                        </Form>
                    </TabPane>
                    <TabPane tab="详情信息" key="detail">
                        <Form
                            labelCol={{ span: 2 }}
                            wrapperCol={{ span: 10 }}
                            layout="horizontal"
                            style={{ marginTop: 10 }}
                        >
                            <Form.Item label="详情">
                                <HtmlEditor defaultValue={this.state.detailHtml} onChange={this.formHandler.onDetailHtmlChange}/>
                            </Form.Item>
                            <Form.Item wrapperCol={{ offset: 2 }}>
                                <Button type="primary" onClick={this.formHandler.onSubmit}>提交</Button>
                            </Form.Item>
                        </Form>
                    </TabPane>
                </Tabs>

                <Modal
                    title="编辑属性"
                    visible={this.state.attrModalVis}
                    onOk={this.attrModalHandler.onOk}
                    onCancel={this.attrModalHandler.onCancel}
                >
                    <Form
                        labelCol={{ span: 4 }}
                        wrapperCol={{ span: 16 }}
                        layout="horizontal"
                        style={{ marginTop: 10 }}
                    >
                        <Form.Item label="属性">
                            <ProductAttributeSelector
                                categoryId={this.state.productAttributeCategoryId}
                                type={1}
                                value={this.state.attrItem.productAttributeId}
                                onChange={this.attrModalHandler.onProductAttributeIdChange}
                            />
                        </Form.Item>
                        <Form.Item label="属性值">
                            {attrOption}
                        </Form.Item>
                    </Form>
                </Modal>

                <Modal
                    title="编辑规格"
                    visible={this.state.specsModalVis}
                    onOk={this.specsModalHandler.onOk}
                    onCancel={this.specsModalHandler.onCancel}
                >
                    <Form
                        labelCol={{ span: 4 }}
                        wrapperCol={{ span: 16 }}
                        layout="horizontal"
                        style={{ marginTop: 10 }}
                    >
                        <Form.Item label="规格">
                            <ProductAttributeSelector
                                categoryId={this.state.productAttributeCategoryId}
                                type={0}
                                value={this.state.specsItem.productAttributeId}
                                onChange={this.specsModalHandler.onProductAttributeIdChange}
                            />
                        </Form.Item>
                        <Form.Item label="规格值">
                            {specsOption}
                        </Form.Item>
                    </Form>
                </Modal>

                <Modal
                    title="编辑SKU"
                    visible={this.state.skuStockModalVis}
                    onOk={this.skuStockModalHandler.onOk}
                    onCancel={this.skuStockModalHandler.onCancel}
                >
                    <Form
                        labelCol={{ span: 4 }}
                        wrapperCol={{ span: 10 }}
                        layout="horizontal"
                        style={{ marginTop: 10 }}
                    >
                        <Form.Item label="skuCode">
                            <Input value={this.state.skuStockItem.skuCode} onChange={this.skuStockModalHandler.onSkuCodeChange}/>
                        </Form.Item>
                        {skuSpecsForm}
                        <Form.Item label="销售价">
                            <InputNumber precision={2} min={0} value={this.state.skuStockItem.price} onChange={this.skuStockModalHandler.onPriceChange}/>
                        </Form.Item>
                        <Form.Item label="库存">
                            <InputNumber precision={0} min={0} value={this.state.skuStockItem.stock} onChange={this.skuStockModalHandler.onStockChange}/>
                        </Form.Item>
                    </Form>
                </Modal>
            </>
        );
    }
}

export default AddMallProduct;
