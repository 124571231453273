import React from 'react';
import CommonLayout from '../../components/layout/CommonLayout';
import OrderList from '../../components/order/OrderList';

class Order extends React.Component {

    render() {
        return (
            <CommonLayout
                topNavSelectedKey="order"
                siderNavCategory="order"
                siderNavSelectedKey="list"
            >
                <OrderList/>
            </CommonLayout>
        );
    }
}

export default Order;
