import React from "react";
import CommonLayout from '../../components/layout/CommonLayout';
import DataDailyBehavior from '../../components/data/DailyBehavior';

class DataBehavior extends React.Component {

    render() {
        return (
            <CommonLayout
                topNavSelectedKey="data"
                siderNavCategory="data"
                siderNavSelectedKey="behavior"
            >
                <DataDailyBehavior/>
            </CommonLayout>
        );
    }
}

export default DataBehavior;
